import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useNotify } from 'react-admin';

import {
  ThunkGetCPDefaultsAutocomplete,
  ThunkGetClientPortalConfigurationById,
} from '@store/slices/clientPortalAdmin/thunks';
import { useDispatch, useSelector } from 'react-redux';
import {
  ThunkAddClientPortalDefaultsMappings,
  ThunkGetClientPortalCompanies,
  ThunkGetClientPortalDefaultsMappings,
} from '@store/slices/clientPortalDefaultMapping/thunks';

import {
  addNew,
  ClientPortalMappingsSelector,
  setError,
} from '@store/slices/clientPortalDefaultMapping';

import { CLIENT_PORTAL_CONFIGS } from '@constants/routes';

import { Typography } from '@material-ui/core';
import Loading from '@common/Loading/Loading';
import iconPlus from '@assets/icons/plus.svg';
import iconAccept from '@assets/icons/accept.svg';
import ActionButton from '@common/buttons/ActionButton/ActionButton';
import useThunk from '@store/slices/useThunk';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CwCompanyMappingItem from './CwCompanyMappingItem';

import useStyles from '../styles';

const CwCompanyMapping = ({ match }) => {
  const classes = useStyles();

  const [configuration, setConfiguration] = useState({});
  const { configId } = match?.params;
  const { push } = useHistory();
  const dispatch = useDispatch();
  const notify = useNotify();

  const [fetchDefaultsMapping, isDefaultMappingPending] = useThunk(
    ThunkGetClientPortalDefaultsMappings,
  );
  const [fetchCompanies, isCompaniesPending] = useThunk(
    ThunkGetClientPortalCompanies,
  );
  const [fetchDefaults, isDefaultsPending] = useThunk(
    ThunkGetCPDefaultsAutocomplete,
  );

  const mappings = useSelector(ClientPortalMappingsSelector);

  const isAllPending = useMemo(
    () => isDefaultMappingPending || isCompaniesPending || isDefaultsPending,
    [isCompaniesPending, isDefaultMappingPending, isDefaultsPending],
  );

  const addNewItemHandler = () => dispatch(addNew());

  const validate = useCallback(() => {
    let isValid = true;
    const companies = [];
    mappings.forEach(m => {
      if (m.psaCompanyId === 0) {
        dispatch(setError({ id: m.id, error: 'Company required' }));
        isValid = false;
      }

      if (m.clientPortalDefaultId === 0) {
        dispatch(setError({ id: m.id, error: 'Default required' }));
        isValid = false;
      }

      if (!companies.includes(m.psaCompanyId)) {
        companies.push(m.psaCompanyId);
      } else {
        dispatch(setError({ id: m.id, error: 'Company duplication' }));
        isValid = false;
      }
    });

    return isValid;
  }, [dispatch, mappings]);

  const submitHandler = () => {
    if (validate()) {
      dispatch(
        ThunkAddClientPortalDefaultsMappings({
          id: configId,
          payload: mappings,
        }),
      )
        .unwrap()
        .then(() => notify('Configuration successfully saved'));
    }
  };

  const onMount = useCallback(() => {
    if (configId) {
      fetchDefaultsMapping({ id: configId });
      fetchCompanies({ id: configId });
      fetchDefaults();

      dispatch(ThunkGetClientPortalConfigurationById({ id: configId }))
        .unwrap()
        .then(res => {
          setConfiguration(res);
        });
    }
  }, [configId, fetchCompanies, fetchDefaults, fetchDefaultsMapping]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  if (isAllPending) return <Loading />;

  return (
    <div className={classes.container}>
      <ActionButton
        icon={<ArrowBackIcon />}
        handler={() => push(CLIENT_PORTAL_CONFIGS)}
        toolTip="Back to configurations"
      />
      <Typography>
        {`${configuration.portalName} - ${configuration.crmName}`}
      </Typography>
      <br />
      {mappings &&
        mappings.map(i => (
          <CwCompanyMappingItem
            key={i.id}
            item={i}
            crmType={configuration.crmType}
          />
        ))}
      <ActionButton
        icon={<img src={iconPlus} alt="iconAdd" />}
        handler={addNewItemHandler}
        toolTip="Add"
      />
      <ActionButton
        icon={<img src={iconAccept} alt="iconSave" />}
        handler={submitHandler}
        toolTip="Save"
      />
    </div>
  );
};

export default CwCompanyMapping;
