import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { useNotify } from 'react-admin';

import ReusableButton from '@common/Button/Button';
import { ThunkPushNoteToTicket } from '@store/slices/liveChats/thunks';

import useSharedStyles from '@components/CrmTicket/sharedStyles';

const PushMessagesToTicketNoteDialog = ({ open, setOpen, liveChatId }) => {
  const [processing, setProcessing] = useState(false);

  const dispatch = useDispatch();
  const sharedClasses = useSharedStyles();
  const notify = useNotify();

  const handlePushNoteToTicket = useCallback(async () => {
    setProcessing(true);
    dispatch(ThunkPushNoteToTicket({ chatId: liveChatId }))
      .unwrap()
      .then(() => {
        notify('Notes Added to Ticket', 'success');
        setOpen(true);
      })
      .finally(() => {
        setProcessing(false);
      });
  }, [dispatch, liveChatId, notify, setOpen]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogTitle>Push Message to Ticket Note</DialogTitle>
      <DialogContent>
        Are you sure you want to push all messages to a ticket note?
        <div className={sharedClasses.dialogActionsContainer}>
          <ReusableButton
            label="Close"
            onClick={() => {
              setOpen(false);
            }}
            disabled={processing}
          />
          <ReusableButton
            label="Submit"
            onClick={handlePushNoteToTicket}
            disabled={processing}
            loading={processing}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

PushMessagesToTicketNoteDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  liveChatId: PropTypes.number.isRequired,
};
export default PushMessagesToTicketNoteDialog;
