import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'react-admin';

import ReusableButton from '@common/Button/Button';
import iconPlus from '@assets/icons/plusWhite.svg';

const AddGroupActions = ({ handleOnClick }) => {
  const translate = useTranslate();

  return (
    <ReusableButton onClick={handleOnClick} viewType="orange">
      <>
        <img src={iconPlus} alt="iconPlus" />
        {translate('common.queryBuilder.addGroup')}
      </>
    </ReusableButton>
  );
};

AddGroupActions.propTypes = {
  handleOnClick: PropTypes.func,
};

export default AddGroupActions;
