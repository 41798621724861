import React from 'react';
import { useTranslate } from 'react-admin';
import cx from 'classnames';
import PropTypes from 'prop-types';
import xlsIcon from '@assets/exports/xls.svg';
import csvIcon from '@assets/exports/csv.svg';
import jsonIcon from '@assets/exports/json.svg';
import Card from '@common/CardDownload/Card';
import IncludeServiceInfoCheckBox from '../common/IncludeServiceInfoCheckBox';
import toggleSysInfoKey from './helpers';

import useStyles from './styles';

const Download = ({
  resource,
  setResource,
  includeServiceInfo,
  setIncludeServiceInfo,
}) => {
  const classes = useStyles();
  const translate = useTranslate();

  const handleCheckBoxChange = event => {
    setResource(toggleSysInfoKey(resource, event.target.checked));
    setIncludeServiceInfo(!includeServiceInfo);
  };

  return (
    <div className={classes.tabBase}>
      <div className={classes.marginLeft}>
        {translate('resources.exporting.download.header')}
      </div>
      <div className={cx(classes.marginLeft, classes.displayFlex)}>
        <IncludeServiceInfoCheckBox
          onChange={handleCheckBoxChange}
          checked={includeServiceInfo}
        />
      </div>
      <div className={classes.displayFlex}>
        <Card
          data={resource?.xlsx}
          icon={<img src={xlsIcon} alt="xls" className={classes.iconWidth} />}
        />
        <Card
          data={resource?.csv}
          icon={<img src={csvIcon} alt="csv" className={classes.iconWidth} />}
        />
        <Card
          data={resource?.json}
          icon={<img src={jsonIcon} alt="json" className={classes.iconWidth} />}
        />
      </div>
    </div>
  );
};

Download.propTypes = {
  resource: PropTypes.objectOf(PropTypes.any),
  setResource: PropTypes.func,
  includeServiceInfo: PropTypes.bool,
  setIncludeServiceInfo: PropTypes.func,
};

export default Download;
