import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { ThunkAssignTicketToTechVerification } from '@store/slices/psaTechVerification/thunks/assignTicketToTechVerification';

import MuiVirtualizedTable from '@common/MuiVirtualizedTable/MuiVirtualizedTable';
import UnauthorizedProvider from '@ui/common/UnauthorizedProvider/UnauthorizedProvider';
import ReusableButton from '@common/Button/Button';
import VerifyTechDialog from '@ui/pages/technicianVerification/TechnicianVerificationTable/dialogs/VerifyTechDialog';
import TableDropDown from '@common/TableDropDown/TableDropDown';
import BooleanAccessor from '@common/MuiVirtualizedTable/components/accessors/BooleanAccessor';

import getFormattedDate from '@utils/getFormattedDate';
import { actions } from '@store/actions';
import useStyles from '../../styles';

const PsaPodTechVerification = ({ crmId, data }) => {
  const [openTechVerification, setOpenTechVerification] = useState(false);
  const [
    selectedVerificationRequest,
    setSelectedVerificationRequest,
  ] = useState(undefined);

  const classes = useStyles();
  const dispatch = useDispatch();

  const isVerifiedOptions = {
    true: { name: 'true', value: 'true' },
    false: { name: 'false', value: 'false' },
  };

  const valueHandler = key => isVerifiedOptions[key]?.value;
  const defaultSort = { field: 'createdDate', order: 'DESC' };

  const handleAssign = useCallback(
    ({ id, ...rest }) => {
      dispatch(
        ThunkAssignTicketToTechVerification({
          crmId: +crmId,
          itemId: +id,
          ticketId: data?.TicketId,
          contactId: data?.ContactId,
          contactName: data?.ContactName,
        }),
      )
        .unwrap()
        .then(() => {
          dispatch(
            actions.updateItemInList({
              ...rest,
              id,
              ticketId: data?.TicketId,
            }),
          );
        });
    },
    [crmId, dispatch, data],
  );

  const content = useMemo(() => {
    switch (openTechVerification) {
      case true:
        return (
          <VerifyTechDialog
            onClose={() => {
              setOpenTechVerification(false);
            }}
            open
            from={selectedVerificationRequest.from}
            verifiedPsaTechBySmsId={selectedVerificationRequest.id}
            code={selectedVerificationRequest?.code}
            contactId={selectedVerificationRequest?.contactId}
            crmIdFromRequest={selectedVerificationRequest?.crmId}
            type={selectedVerificationRequest?.type}
          />
        );
      case false:
        return (
          <MuiVirtualizedTable
            classNameWrapper={classes.table}
            actions={null}
            defaultSort={defaultSort}
            columns={[
              {
                name: (
                  <TableDropDown
                    item={{ name: 'Is Verified', field: 'isVerified' }}
                    options={isVerifiedOptions}
                    valueHandler={valueHandler}
                    hideSorting
                  />
                ),
                key: 'isVerified',
                accessor: rowData => (
                  <BooleanAccessor value={rowData.isVerified} useMultiColor />
                ),
              },
              {
                name: 'From',
                key: 'from',
                accessor: item => <>{item?.from || item?.contactName}</>,
              },
              {
                name: 'Code',
                key: 'code',
              },
              {
                name: 'Created Date',
                key: 'createdDate',
                accessor: rowData => (
                  <>{getFormattedDate(rowData.createdDate)}</>
                ),
              },
              {
                name: 'Ticket ID',
                key: 'ticketId',
              },
              {
                name: 'Actions',
                key: 'actions',
                accessor: rowData => (
                  <div style={{ display: 'flex', gap: '10px' }}>
                    {!rowData?.isVerified && (
                      <ReusableButton
                        label="Confirm"
                        onClick={() => {
                          setSelectedVerificationRequest(rowData);
                          setOpenTechVerification(true);
                        }}
                        viewType="blue"
                      />
                    )}

                    {rowData?.type.toLowerCase().includes('sms') && (
                      <ReusableButton
                        label="Assign"
                        onClick={() => {
                          handleAssign(rowData);
                        }}
                      />
                    )}
                  </div>
                ),
              },
            ]}
            resource="VerifiedPsaTechBySms"
          />
        );
      default:
        return <></>;
    }
  }, [
    crmId,
    openTechVerification,
    selectedVerificationRequest?.from,
    selectedVerificationRequest?.id,
  ]);

  return <UnauthorizedProvider>{content}</UnauthorizedProvider>;
};

PsaPodTechVerification.propTypes = {
  crmId: PropTypes.number.isRequired,
};

export default PsaPodTechVerification;
