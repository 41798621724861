import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useTranslate } from 'react-admin';

import Loader from '@assets/loader.gif';
import useStyles from './styles';

const Loading = ({
  classNameWrapper,
  customHeader,
  customText,
  customIcon,
}) => {
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <div className={cx(classes.container, classNameWrapper)}>
      <img src={customIcon || Loader} alt="Loading" />
      <h1>{customHeader || translate('ra.page.loading')}</h1>
      <div>{customText || translate('ra.message.loading')}</div>
    </div>
  );
};

Loading.propTypes = {
  classNameWrapper: PropTypes.string,
  customHeader: PropTypes.string,
  customText: PropTypes.string,
  customIcon: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};
export default Loading;
