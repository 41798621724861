import React, { useCallback, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useNotify } from 'react-admin';

import {
  ThunkAddIncidentNote,
  ThunkEditIncidentNote,
} from '@store/slices/incident/thunks';

import ReusableButton from '@ui/components/common/Button/Button';

import useStyles from './styles';

const AddNotesDialog = React.memo(
  ({ open, setOpen, incidentId, noteId, note }) => {
    const [value, setValue] = useState(noteId ? note : '');
    const [processing, setProcessing] = useState(false);
    const classes = useStyles();

    const dispatch = useDispatch();
    const notify = useNotify();

    const handleAddNote = useCallback(
      async payload => {
        if (!noteId) {
          setProcessing(true);
          await dispatch(ThunkAddIncidentNote(payload))
            .unwrap()
            .then(() => {
              setOpen(false);
              notify('Successfully added', 'success');
            })
            .finally(() => {
              setProcessing(false);
            });
        } else {
          setProcessing(true);
          await dispatch(
            ThunkEditIncidentNote({
              data: { note: value, incidentId },
              noteId,
            }),
          )
            .unwrap()
            .then(() => {
              setOpen(false);
              notify('Successfully edited', 'success');
            })
            .finally(() => {
              setProcessing(false);
            });
        }
      },
      [dispatch, notify, setOpen, noteId, value, incidentId],
    );

    return (
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        size="md"
      >
        <DialogTitle>
          <div className={classes.logsDialogHeader}>
            {noteId ? 'Edit ' : 'Add '}
            Note
          </div>
        </DialogTitle>
        <DialogContent>
          <div className={classes.noteDialogInput}>
            <TextField
              style={{ width: '100%' }}
              id="outlined-multiline-static"
              multiline
              placeholder="Input note here"
              rows={3}
              variant="outlined"
              value={value}
              onChange={e => setValue(e.target.value)}
            />
          </div>

          <div className={classes.noteDialogAction}>
            <ReusableButton
              label="Close"
              onClick={() => {
                setOpen(false);
              }}
              loading={processing}
              disabled={processing}
            />
            <ReusableButton
              label={noteId ? 'Update' : 'Create'}
              onClick={() => {
                handleAddNote({ note: value, incidentId });
              }}
              loading={processing}
              disabled={processing}
            />
          </div>
        </DialogContent>
      </Dialog>
    );
  },
);

AddNotesDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  incidentId: PropTypes.number,
  noteId: PropTypes.number,
  note: PropTypes.string,
};

export default AddNotesDialog;
