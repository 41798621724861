import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useForm } from 'react-final-form';
import { useNotify } from 'react-admin';

import {
  ThunkDeleteTicketCreationTemplate,
  ThunkGetTicketCreationTemplate,
  ThunkGetTicketCreationTemplates,
  ThunkUpdateTicketCreationTemplate,
} from '@store/slices/ticketCreationTemplates/thunks';
import { ticketCreationTemplatesSelector } from '@store/slices/ticketCreationTemplates';

import ReusableButton from '@common/Button/Button';
import CreateTicketCreationTemplateDialog from '@components/CreateTicket/dialogs/CreateTicketCreationTemplateDialog';
import ConfirmDialog from '@common/ConfirmDialog/ConfirmDialog';
import FieldWrapper from '@common/form/FieldWrapper';
import AutocompleteFormInput from '@common/AutocompleteFormInput/AutocompleteFormInput';

import useStyles from '../styles';

const TicketCreationTemplates = ({ psaType, onSelect }) => {
  const [loading, setLoading] = useState(true);
  const [openSaveTemplateDialog, setOpenSaveTemplateDialog] = useState(false);
  const [openDeleteTemplateDialog, setOpenDeleteTemplateDialog] = useState(
    false,
  );

  const [selectedTemplate, setSelectedTemplate] = useState(0);

  const dispatch = useDispatch();
  const ticketTemplates = useSelector(ticketCreationTemplatesSelector);
  const form = useForm();
  const notify = useNotify();
  const classes = useStyles();

  const handleDeleteTemplates = useCallback(async () => {
    setLoading(true);
    await dispatch(
      ThunkDeleteTicketCreationTemplate({ templateId: selectedTemplate }),
    )
      .unwrap()
      .then(() => {
        notify('Template deleted');
        setOpenDeleteTemplateDialog(false);
        setSelectedTemplate(0);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch, selectedTemplate, notify]);

  const handleLoadTemplate = useCallback(
    async templateId => {
      setLoading(true);
      const { data } = await dispatch(
        ThunkGetTicketCreationTemplate({ templateId }),
      )
        .unwrap()
        .finally(() => {
          setLoading(false);
        });

      if (data) {
        await onSelect(data, form);
      }
    },
    [dispatch, form, onSelect],
  );

  const handleEditClick = async () => {
    const selected = ticketTemplates.find(i => i.value === selectedTemplate);
    const { values } = form.getState();

    setLoading(true);
    await dispatch(
      ThunkUpdateTicketCreationTemplate({
        templateId: selected.value,
        payload: {
          name: selected.label,
          data: values,
        },
        psaType,
      }),
    )
      .unwrap()
      .then(() => {
        notify('Templated Edited', 'success');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onMount = useCallback(async () => {
    setLoading(true);
    await dispatch(ThunkGetTicketCreationTemplates({ psaType }));
    setLoading(false);
  }, [psaType, dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  return (
    <div className={classes.templatesContainer}>
      <FieldWrapper
        label="Configuration Templates"
        labelSize={12}
        contentSize={12}
        content={
          <AutocompleteFormInput
            render={AutocompleteFormInput}
            loading={loading}
            items={[
              {
                label: '-',
                value: 0,
              },
              ...ticketTemplates,
            ]}
            input={{
              value: selectedTemplate,
              onChange: e => {
                setSelectedTemplate(e);
                if (e && e !== 0) {
                  handleLoadTemplate(e);
                }
              },
            }}
          />
        }
      />

      {selectedTemplate === 0 && (
        <ReusableButton
          viewType="white"
          buttonType="simple"
          label="Save"
          onClick={() => {
            setOpenSaveTemplateDialog(true);
          }}
          disabled={loading}
        />
      )}
      {!!selectedTemplate && (
        <>
          <ReusableButton
            viewType="white"
            buttonType="simple"
            label="Update"
            onClick={handleEditClick}
            disabled={loading}
          />
          <ReusableButton
            viewType="white"
            buttonType="simple"
            label="Delete"
            onClick={() => {
              setOpenDeleteTemplateDialog(true);
            }}
            disabled={loading}
          />
        </>
      )}

      {openSaveTemplateDialog && (
        <CreateTicketCreationTemplateDialog
          setOpen={setOpenSaveTemplateDialog}
          open={openSaveTemplateDialog}
          psaType={psaType}
        />
      )}

      {openDeleteTemplateDialog && (
        <ConfirmDialog
          onAccept={handleDeleteTemplates}
          title="Confirm Dialog"
          content="Are you sure you want to delete this item?"
          onCancel={() => {
            setOpenDeleteTemplateDialog(false);
          }}
          open={openDeleteTemplateDialog}
          setOpen={setOpenDeleteTemplateDialog}
        />
      )}
    </div>
  );
};
TicketCreationTemplates.propTypes = {
  psaType: PropTypes.string.isRequired,
  onSelect: PropTypes.func,
};
export default TicketCreationTemplates;
