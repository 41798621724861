import { makeRequest } from '@services/requests/makeRequest';
import { HELPDESK_AI_ASSISTANTS } from '@services/requests/requestResources';

export const helpdeskToggleState = (id, state) =>
  makeRequest(
    'PUT',
    `${HELPDESK_AI_ASSISTANTS}/${id}/makeActive`,
    state,
    false,
    false,
  );

export const createNewHelpdeskConfiguration = data =>
  makeRequest('POST', '/HelpdeskAiAssistants', data);

export const deleteHelpdeskConfiguration = id =>
  makeRequest(
    'DELETE',
    `${HELPDESK_AI_ASSISTANTS}/${id}`,
    undefined,
    false,
    false,
  );

export const editHelpdeskConfiguration = data =>
  makeRequest('PUT', `${HELPDESK_AI_ASSISTANTS}/${data.id}`, data);

export const getHelpdeskLanguages = () =>
  makeRequest('GET', `${HELPDESK_AI_ASSISTANTS}/languages`);

export const getHelpdeskIncomingPhoneNumbers = () =>
  makeRequest('GET', `${HELPDESK_AI_ASSISTANTS}/incomingPhoneNumbers`);

export const getHelpdeskAiAgentTypes = () =>
  makeRequest('GET', `${HELPDESK_AI_ASSISTANTS}/types`);

export default {};
