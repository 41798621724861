import React, { useEffect, useState } from 'react';
import { useNotify, useRedirect } from 'react-admin';
import PropTypes from 'prop-types';
import TriggerForm from '@components/Triggers/components/TriggerForm';
import { getDetails } from '@components/Triggers/helpers';
import { TRIGGER } from '@constants/routes';
import Loading from '@common/Loading/Loading';

const TriggerUpdate = ({ match }) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const [item, setItem] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getDetails(match.params.id)
      .then(d => setItem(d))
      .catch(e => {
        notify(e.message, 'error');
        redirect(TRIGGER);
      })
      .finally(() => setLoading(false));
  }, [match]);

  if (loading) {
    return <Loading />;
  }
  return <TriggerForm item={item} />;
};

TriggerUpdate.propTypes = {
  match: PropTypes.objectOf(PropTypes.any),
};

export default TriggerUpdate;
