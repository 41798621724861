import React from 'react';
import { useTranslate } from 'react-admin';
import { Link } from 'react-router-dom';

import dangerIcon from '@assets/icons/dangerTriangle.svg';
import useStyles from '../GoogleSheets/styles';

const WebHooks = () => {
  const classes = useStyles();
  const translate = useTranslate();
  return (
    <div className={classes.tabBase}>
      <div className={classes.displayFlex}>
        <img src={dangerIcon} alt="dangerIcon" />
        <span className={classes.caption}>
          &nbsp;
          {translate('resources.exporting.webHooks.caption')}
        </span>
      </div>
      <div className={classes.marginTop}>
        {translate('resources.exporting.webHooks.body')}
        <Link to="/notificationSettings" className={classes.link}>
          link
        </Link>
      </div>
    </div>
  );
};

export default WebHooks;
