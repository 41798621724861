/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useNotify } from 'react-admin';
import { Accordion, AccordionDetails } from '@material-ui/core';
import { CheckCircle as CheckCircleIcon } from '@material-ui/icons';
import { useSelector } from 'react-redux';

import { currentUserDataSelector } from '@store/selectors';
import externalLoginType from '@constants/externalLoginType';

import GoogleLogo from '@assets/logos/google.png';
import MicrosoftLogo from '@assets/logos/microsoft.png';

import getExternalLogin from '@components/Auth/helpers';
import { linkAccount } from '@components/Profile/helpers';

import AccordionSummaryRequired from '@components/SetupWizard/components/AccordionSummaryRequired';
import useStyles from '../styles';

const ExternalLogin = ({ refetch }) => {
  const classes = useStyles();
  const notify = useNotify();
  const userData = useSelector(currentUserDataSelector);

  const onExternalLoginClick = useCallback(
    (type, isAlreadyChecked) => {
      if (isAlreadyChecked) {
        getExternalLogin(type)
          .then(token =>
            linkAccount(token, type).then(() => {
              notify('Successfully linked');
              refetch();
            }),
          )
          .catch(e => notify(e.message, 'error'));
      } else {
        notify('You already linked account');
      }
    },
    [notify, refetch],
  );

  return (
    <Accordion>
      <AccordionSummaryRequired
        title="Setup External Login"
        isChecked={userData.microsoftLinked || userData.googleLinked}
        hideRequired={userData.useMfa}
      />
      <AccordionDetails className={classes.userSetupExternalLogins}>
        <div className={classes.userSetupExternalLoginsItems}>
          <img
            src={GoogleLogo}
            alt="google"
            className={classes.userSetupExternalLoginsImages}
            onClick={() => {
              onExternalLoginClick(
                externalLoginType.Google,
                !userData?.googleLinked,
              );
            }}
          />
          {userData?.googleLinked && (
            <div className={classes.externalLoginCheckIcon}>
              <CheckCircleIcon />
            </div>
          )}
        </div>

        <div className={classes.userSetupExternalLoginsItems}>
          <img
            src={MicrosoftLogo}
            alt="microsoft"
            className={classes.userSetupExternalLoginsImages}
            onClick={() => {
              onExternalLoginClick(
                externalLoginType.Microsoft,
                !userData?.microsoftLinked,
              );
            }}
          />
          {userData?.microsoftLinked && (
            <div className={classes.externalLoginCheckIcon}>
              <CheckCircleIcon />
            </div>
          )}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

ExternalLogin.propTypes = {
  refetch: PropTypes.func.isRequired,
};

export default ExternalLogin;
