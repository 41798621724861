import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import MuiToolbar from '@material-ui/core/Toolbar';
import { Button } from 'react-admin';
import { useField } from 'react-final-form';

import { actions } from '@store/actions';
import DeleteButton from '../DeleteButton';

const Toolbar = ({ onToggle, record, resource, redirect, basePath }) => {
  const dispatch = useDispatch();
  const {
    input: { value: confirmPassword },
  } = useField('confirmPassword');

  const onClick = () =>
    dispatch(
      actions.deleteProfile({
        confirmPassword,
        record,
        resource,
        basePath,
        redirect,
      }),
    );
  const disabled = !confirmPassword || confirmPassword.length < 6;

  return (
    <MuiToolbar>
      <Button label="ra.action.cancel" onClick={onToggle} />
      <DeleteButton disabled={disabled} onClick={onClick} />
    </MuiToolbar>
  );
};

Toolbar.propTypes = {
  onToggle: PropTypes.func.isRequired,
  resource: PropTypes.string,
  basePath: PropTypes.string,
  redirect: PropTypes.string,
  record: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  ),
};

export default Toolbar;
