import React, { useMemo, useState } from 'react';
import { useNotify, useRedirect } from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import BugReportIcon from '@material-ui/icons/BugReport';

import resources from '@constants/resources';
import {
  CREATE_GENERIC_SOURCE,
  GENERIC_SOURCE_VIEW_LOGS,
  GENERIC_SOURCES,
} from '@constants/routes';
import ListActions from '@components/common/buttons/ListActionButtons/ListActions';
import ActionField from '@components/common/ActionFields/ActionField';
import StatusCircle from '@common/StatusCircle/StatusCircle';
import { toggleEnabled } from '@components/GenericSources/helpers';
import { actions } from '@store/actions';
import { listSelector } from '@store/selectors';
import useDeleteRecord from '@components/DeviceMonitors/hook/useDeleteRecord';
import ConfirmDialog from '@common/ConfirmDialog/ConfirmDialog';
import Table from '@components/Table';
import { TableTypes } from '@components/Table/constants';
import ActionFieldItem from '@common/ActionButtons/ActionFieldItem';
import useTranslatedText from '../common/hooks/useTranslatedText';
import { deleteRecord } from './helpers';
import BugIcon from '@assets/icons/bug.svg';
import useStyles from './styles';

import AddDetailedLogsDialog from './components/AddDetailedLogsDialog';

const GenericSources = () => {
  const classes = useStyles();
  const redirect = useRedirect();
  const notify = useNotify();
  const dispatch = useDispatch();
  const list = useSelector(listSelector);
  const { getText } = useTranslatedText(resources.genericSources);

  const [selectedAppBotId, setSelectedAppBotId] = useState(undefined);

  const {
    onCancel,
    open,
    loading,
    handleDeleteIcon,
    onDelete,
  } = useDeleteRecord(deleteRecord);

  const editItem = id => redirect(`${GENERIC_SOURCES}/${id}`);
  const createItem = () => redirect(CREATE_GENERIC_SOURCE);
  const viewLogs = id => redirect(GENERIC_SOURCE_VIEW_LOGS.replace(':id', id));

  const onToggleEnabled = (id, isActive) => {
    toggleEnabled(id, !isActive)
      .then(() => {
        const newArr = list.map(item =>
          item.id !== id ? item : { ...item, isActive: !isActive },
        );
        dispatch(actions.saveList(newArr));
      })
      .catch(e => notify(e.message, 'error'));
  };

  const addDetailedLog = (appBotId, period) => {
    const newArr = list.map(item =>
      item.id !== appBotId
        ? item
        : {
            ...item,
            detailedLogMinutesLeft: period.toString(),
            isDetailedLogEnabled: true,
          },
    );
    dispatch(actions.saveList(newArr));
    setSelectedAppBotId(undefined);
  };

  const columns = useMemo(
    () => [
      {
        name: 'Name',
        key: 'name',
        sortable: true,
        searchable: true,
        minWidth: 100,
      },
      {
        key: 'genericPipelineName',
        name: 'App Bot Type',
        sortable: true,
        searchable: true,
        minWidth: 200,
      },
      {
        name: 'Enabled',
        key: 'isActive',
        isCustomAccessor: true,
        accessor: row => (
          <div className={classes.center}>
            <StatusCircle
              handleClick={onToggleEnabled}
              row={row}
              rowKey="isActive"
            />
          </div>
        ),
        width: 100,
        minWidth: 100,
      },
      {
        name: 'Actions',
        key: TableTypes.actions,
        type: TableTypes.actions,
        width: 150,
        minWidth: 150,
        accessor: rowData => (
          <ActionField
            label="Actions"
            record={rowData}
            onEdit={row => editItem(row.id)}
            onViewLogs={row => viewLogs(row.id)}
            onDelete={row => handleDeleteIcon(row.id)}
          >
            <ActionFieldItem
              handler={() => setSelectedAppBotId(rowData.id)}
              toolTip="Enable Detauled Logs"
              icon={BugIcon}
            />
          </ActionField>
        ),
      },
    ],
    [classes.center, editItem, handleDeleteIcon, onToggleEnabled, viewLogs],
  );

  return (
    <div className={classes.container}>
      <ListActions onCreate={createItem} />
      <Table columns={columns} resource={resources.genericSources} />
      <ConfirmDialog
        open={open}
        onCancel={onCancel}
        onAccept={onDelete}
        loading={loading}
        title={getText('deleteHeader')}
        content={getText('deleteDetails')}
      />
      <AddDetailedLogsDialog
        appBotId={selectedAppBotId}
        onCloseDialog={() => setSelectedAppBotId(undefined)}
        onAccept={addDetailedLog}
      />
    </div>
  );
};

export default GenericSources;
