import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import {
  ThunkCheckIfTenantHasVerificationNumber,
  ThunkGetVerificationDuration,
  ThunkGetVerificationSettings,
} from '@store/slices/verificationSettings/thunks';
import eventBus from '@services/eventBus';
import { verificationEvents as events } from '@constants/events';

import ContactPsaInfo from '@components/integrations/connectwise/components/ContactPsaInfo';
import ContactPhoneEmails from '@components/integrations/connectwise/components/ContactPhoneEmails';
import VerifyContact from '@components/integrations/connectwise/components/VerifyContact';
import Loading from '@common/Loading/Loading';
import VerificationHistory from '@components/integrations/connectwise/components/VerificationHistory';
import crmSources from '@constants/crmSources';

import useSharedStyles from '@components/CrmTicket/sharedStyles';

const contentTypes = Object.freeze({
  CONTACT_DATA_LIST: 'contactDataList',
  PHONE_VERIFICATION: 'phoneVerification',
  EMAIL_VERIFICATION: 'emailVerification',
});

const EndUserVerification = ({
  data,
  match,
  onRefresh = null,
  psaType = crmSources.ConnectWise.label,
  fromTable = false,
  isLoader = true,
}) => {
  const sharedStyles = useSharedStyles();

  const [loading, setLoading] = useState(true);
  const [contentType, setContentType] = useState(
    contentTypes.CONTACT_DATA_LIST,
  );
  const [validateObject, setValidateObject] = useState();
  const [codeDuration, setCodeDuration] = useState(5);
  const [unableNotes, setUnableNotes] = useState(false);
  const [hasDuoIntegrationEnabled, setHasDuoIntegrationEnabled] = useState(
    false,
  );

  const [
    hasMicrosoftAuthPushIntegration,
    setHasMicrosoftAuthPushIntegration,
  ] = useState(false);

  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const dispatch = useDispatch();

  const handleVerificationClick = useCallback(obj => {
    setValidateObject(obj);
    setContentType(contentTypes.PHONE_VERIFICATION);
  }, []);

  const ticketId = useMemo(() => {
    if (!fromTable && urlParams.get('screen') === 'ticket') {
      return urlParams.get('ticketId');
    }
    if (fromTable && data.TicketId) {
      return data.TicketId;
    }
    return null;
  }, [urlParams]);

  const getBack = () => {
    setContentType(contentTypes.CONTACT_DATA_LIST);
  };

  const handleRedirectToVerification = obj => {
    setValidateObject(obj);
    setContentType(contentTypes.PHONE_VERIFICATION);
  };

  const content = useMemo(() => {
    switch (contentType) {
      case contentTypes.CONTACT_DATA_LIST:
        return (
          <>
            <ContactPsaInfo
              handleRedirectToVerification={handleRedirectToVerification}
            />
            <VerificationHistory />
          </>
        );
      case contentTypes.PHONE_VERIFICATION:
        return (
          <VerifyContact
            data={data}
            validatedObject={validateObject}
            contactName={data.ContactName}
            contactId={data.ContactId}
            contactCompanyName={data.CompanyName}
            crmId={match.params.crmId}
            codeDuration={codeDuration}
            ticketId={ticketId}
            onSuccess={() => {}}
            handleBack={() => {
              setContentType(contentTypes.CONTACT_DATA_LIST);
            }}
            companyId={data.CompanyId}
            unableNotes={unableNotes}
            psaType={psaType}
            hasDuoIntegration={hasDuoIntegrationEnabled}
            hasMicrosoftAuthPushIntegration={hasMicrosoftAuthPushIntegration}
          />
        );
      default:
        return <ContactPhoneEmails handleRefresh={onRefresh} />;
    }
  }, [
    contentType,
    data.ContactId,
    data.ContactName,
    data.Data,
    handleVerificationClick,
    match.params.crmId,
    urlParams,
    validateObject,
    psaType,
  ]);

  const onMount = useCallback(async () => {
    setLoading(true);
    await dispatch(ThunkGetVerificationDuration())
      .unwrap()
      .then(d => setCodeDuration(d));
    await dispatch(ThunkGetVerificationSettings())
      .unwrap()
      .then(
        ({
          requireNoteIfPhoneChanged,
          hasDuoAuthApiIntegration,
          hasMicrosoftAuthPushIntegration,
        }) => {
          setUnableNotes(requireNoteIfPhoneChanged);
          setHasDuoIntegrationEnabled(hasDuoAuthApiIntegration);
          setHasMicrosoftAuthPushIntegration(hasMicrosoftAuthPushIntegration);
        },
      );

    await dispatch(ThunkCheckIfTenantHasVerificationNumber())
      .unwrap()
      .then(res => {
        // if (!res.data) {
        //   setUnableSmsFeatures(true);
        // } else {
        //   setUnableSmsFeatures(false);
        // }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  useEffect(() => {
    eventBus.on(events.VerificationCompleted, getBack);
    return () => {
      eventBus.remove(events.VerificationCompleted, getBack);
    };
  }, []);

  if (loading && isLoader) {
    return <Loading classNameWrapper={sharedStyles.loadingWrapper} />;
  }

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns:
          contentType === contentTypes.CONTACT_DATA_LIST
            ? 'minmax(0px, 1fr) minmax(0px, 1fr)'
            : '1fr',
        height: '100%',
        margin: '0 auto',
        overflow: 'hidden',
      }}
    >
      {content}
    </div>
  );
};

EndUserVerification.propTypes = {
  data: PropTypes.shape({
    CompanyName: PropTypes.string,
    ContactName: PropTypes.string,
    ContactEmail: PropTypes.string,
    ContactPhone: PropTypes.string,
    contactPhoneVerified: PropTypes.bool,
    contactEmailVerified: PropTypes.bool,
    TicketId: PropTypes.string,
    Data: PropTypes.arrayOf(
      PropTypes.shape({
        Id: PropTypes.number,
        IsVerified: PropTypes.bool,
        Type: PropTypes.string,
        Value: PropTypes.string,
      }),
    ),
  }),
  onRefresh: PropTypes.func,
};
export default EndUserVerification;
