import React, { useEffect, useState } from 'react';
import { useNotify } from 'react-admin';
import PropTypes from 'prop-types';
import Loading from '@common/Loading/Loading';
import HookUrl from '@components/WebHooks/components/HookUrl';
import DisplayData from '@components/WebHooks/components/DisplayData';
import { getRecord } from '../helpers';
import useStyles from '../styles';

const Edit = ({ match }) => {
  const classes = useStyles();
  const notify = useNotify();
  const [url, setUrl] = useState('');
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getRecord(match.params.id)
      .then(d => {
        setUrl(d.hookUrl);
        setData(d);
      })
      .catch(e => notify(e.message, 'error'))
      .finally(() => setLoading(false));
  }, [match, notify]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className={classes.container}>
      <HookUrl url={url} setUrl={setUrl} />
      <DisplayData externalData={data} />
    </div>
  );
};

Edit.propTypes = {
  match: PropTypes.objectOf(PropTypes.any),
};

export default Edit;
