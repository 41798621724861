import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import ReusableButton from '@common/Button/Button';
import useAsync from '@services/api/common/useAsync';
import getData from '@services/api/common/getData';
import appConfig from '@configs/appConfig';
import { getArrayParams } from '@components/Helpers/queryHelpers';
import CreatorInput from '@components/Parsers/components/Create/components/DataViewCreator/CreatorInput';
import CreatorCheckBox from '@components/Parsers/components/Create/components/DataViewCreator/CreatorCheckBox';
import useStyles from '../styles';

const nameInputModel = {
  value: '',
  touched: false,
  error: '',
};

const DataViewCreator = ({
  open,
  setOpen,
  model,
  onSubmit,
  resource = 'linkHook',
}) => {
  const classes = useStyles();
  const [idFieldsExists, setIdFieldsExists] = useState(false);
  const [dataViews, setDataViews] = useState([]);
  const [nameInput, setNameInput] = useState(nameInputModel);
  const [linkWebHook, setLinkWebHook] = useState(false);
  const [loading, setLoading] = useState(false);

  const getSuitableDataViews = useCallback(async () => {
    if (open) {
      setLoading(true);
      const transformers = JSON.parse(model.fieldTransformer);
      const identifiers = getArrayParams(
        transformers.filter(i => i.identifier),
        'name',
        'id',
      );
      setIdFieldsExists(!!identifiers);
      const idents = await getData(
        `${appConfig.baseUrl}/DataViews/data-views?${identifiers}`,
      );
      setLoading(false);
      return idents.data;
    }
    return Promise.resolve([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    setNameInput(prev => ({ ...prev, value: model.name }));
  }, [model.name]);
  useAsync(getSuitableDataViews, setDataViews);
  const getResource = value => `resources.parsers.dataView.${value}`;

  const validateName = () => {
    if (!nameInput.value) {
      setNameInput({ ...nameInput, touched: true, error: 'required' });
      return false;
    }
    if (nameInput.value.length >= 45) {
      setNameInput({
        ...nameInput,
        touched: true,
        error: 'max 40 symbols length',
      });
      return false;
    }
    return true;
  };

  const handleCreateClick = () => {
    if (!validateName()) {
      return;
    }
    const dataView = {
      name: nameInput.value,
      linkWebHook,
    };
    onSubmit(model, dataView);
  };

  const handleIgnoreClick = () => {
    setLoading(true);
    setOpen(false);
    onSubmit(model);
  };

  const handleNameChange = value =>
    setNameInput({ value, touched: true, error: '' });

  const handleCreateAnywayClick = () => setDataViews([]);

  const createView = (
    <>
      <DialogTitle>Data View Creation</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <div className={cx(classes.displayFlex, classes.flexDirColumn)}>
            <CreatorInput
              input={nameInput}
              label={getResource('name')}
              setValue={handleNameChange}
            />
            <div className={cx(classes.displayFlex, classes.justifySpace)}>
              <CreatorCheckBox
                checked={linkWebHook}
                setChecked={setLinkWebHook}
                label={getResource(resource)}
              />
            </div>
          </div>
        </DialogContentText>
        <DialogActions>
          <ReusableButton
            label="resources.buttons.skip"
            viewType="black"
            onClick={handleIgnoreClick}
            size="md"
          />
          <ReusableButton
            label="resources.buttons.create"
            viewType="black"
            onClick={handleCreateClick}
            size="md"
          />
        </DialogActions>
      </DialogContent>
    </>
  );

  const viewsExists = (
    <>
      <DialogTitle>Data Views</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <span>Data from this parser will be available from</span>
          <ul>
            {dataViews.map(i => (
              <li key={i}>{i}</li>
            ))}
          </ul>
        </DialogContentText>
        <DialogActions>
          <ReusableButton
            label="Create anyway"
            viewType="red"
            onClick={handleCreateAnywayClick}
            size="md"
          />
          <ReusableButton
            label="resources.buttons.ok"
            viewType="black"
            onClick={handleIgnoreClick}
            size="md"
          />
        </DialogActions>
      </DialogContent>
    </>
  );

  if (!idFieldsExists) {
    return (
      <MuiDialog open={open} maxWidth="sm">
        <DialogTitle>There are some data views</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <span>Unable to create data view without ID fields</span>
          </DialogContentText>
          <DialogActions>
            <ReusableButton
              label="resources.buttons.ok"
              viewType="black"
              onClick={handleIgnoreClick}
              size="md"
            />
          </DialogActions>
        </DialogContent>
      </MuiDialog>
    );
  }

  const renderBody = () => {
    if (loading) return null;
    if (dataViews.length) return viewsExists;
    return createView;
  };

  return (
    <MuiDialog open={open} maxWidth="sm">
      {renderBody()}
    </MuiDialog>
  );
};

DataViewCreator.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default DataViewCreator;
