import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  contactInfoContainer: {
    height: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    margin: '20px 0px',
  },
  contactInitials: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  contactInitialsCircle: {
    color: 'white',
    width: '5rem',
    height: '5rem',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '50%',
    justifyContent: 'center',
    fontSize: '25px',
    fontWeight: 800,
    letterSpacing: '3px',
    '& svg': {
      width: '80px',
      height: '80px',
    },
  },
  contactInfoItem: {
    display: 'flex',
    gap: '10px',
    color: '#9B9B9B',
    '& b': {
      color: 'black',
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
    },
  },
  contactInfoBlock: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '10px',
  },
  verificationBlock: {
    display: 'grid',
    gridTemplateColumns: '1fr 20fr 20fr',
    margin: '10px',
    padding: '10px',
    gap: '20px',
    boxSizing: 'border-box',
  },
  verificationItem: {
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
  },
  verificationItemTitle: {
    color: 'grey',
    textTransform: 'capitalize',
    '& b': {
      color: 'black',
    },
  },
  verificationIcon: {
    '& svg': {
      cursor: 'pointer',
      '& path': { fill: 'red' },
    },
  },
  checkIcon: {
    cursor: 'pointer',
    '& path': { fill: 'green !important' },
  },
  greyIcon: {
    cursor: 'pointer',
    '& path': { fill: '#c1c1c1 !important' },
  },
  clientChatsList: {
    display: 'flex',
    gap: '10px',
    flexDirection: 'column',
    padding: '10px 0',
    overflow: 'auto',
    maxHeight: '500px',
  },
  clientChatItem: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '15px',
    border: '1px solid #0000001a',
    boxShadow: '1px 1px 0px 0px #0000004d',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '1px 1px 0px 0px black',
    },
  },
  clientChatItemName: {
    display: 'flex',
    alignItems: 'center',
  },
  link: {
    color: 'grey',
    fontWeight: '800',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  clientChatActions: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '5px 0',
    alignItems: 'center',
  },
  bold: {
    fontWeight: 'bold',
  },
  communicationItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '15px',
    margin: '5px 0px',
    '& b': {
      textTransform: 'capitalize',
    },
    '& p': {
      margin: '0px',
    },
  },
  communicationInfoContainer: {
    padding: '15px',
    boxSizing: 'border-box',
    overflow: 'auto',
  },
  contactData: {
    display: 'flex',
    alignItems: 'center',
    gap: '25px',
  },
  verificationContainer: {
    padding: '15px 15px',
    boxSizing: 'border-box',
    borderLeft: '1px solid #00000045',
    margin: '10px',
    overflow: 'hidden',
  },
  lowMargin: {
    marginBottom: '-10px',
    filter: 'grayscale(1)',
  },
  techVerificationContainer: {
    padding: '20px',
    boxSizing: 'border-box',
  },
  techVerificationDataContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontWeight: '700',
  },
  techVerificationDataItem: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
  },
  techVerificationDataItemGroup: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '10px',
    margin: '10px 0px',
  },
  row: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 6fr 4fr 4fr',
    alignItems: 'center',
    gap: '5px',
    padding: '0px 10px',
    boxSizing: 'border-box',
  },
  searchContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 2fr',
    alignItems: 'center',
    margin: '10px',
    fontWeight: 800,
    gap: '400px',
  },
  list: {
    maxHeight: '150px',
    overflowY: 'auto',
  },
  codeContainer: {
    color: 'white',
    margin: '15px 0px',
    display: 'flex',
    padding: '10px',
    background: '#1b3670',
    fontWeight: 800,
    justifyContent: 'center',
    fontSize: 'large',
  },
  codeConfirm: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '10px 0px',
    fontWeight: 700,
  },
  icon: {
    display: 'flex',
  },
  itemInfo: {
    display: 'flex',
    gap: '8px',
  },
}));

export default useStyles;
