import React, { useMemo } from 'react';
import { getTenantNameFromStorage } from '@services/api';
import useTranslatedText from '@common/hooks/useTranslatedText';
import resources from '@constants/resources';
import Wrapper from './components/Wrapper';
import Hooks from './components/Hooks';
import Storage from './components/Storage';
import Apis from './components/Apis';
import useStyles from './styles';

const TenantStatistic = () => {
  const classes = useStyles();
  const { getText } = useTranslatedText(resources.tenantStatistic);
  const tenantName = useMemo(() => getTenantNameFromStorage(), []);
  return (
    <div className={classes.container}>
      <span className={classes.largeFont}>
        <span>{getText('tenant')}</span>
        <span>:&nbsp;</span>
        <span>{tenantName}</span>
      </span>
      <Wrapper label={getText('hooksUsage')}>
        <Hooks />
      </Wrapper>
      <Wrapper label={getText('storageUsage')}>
        <Storage />
      </Wrapper>
      <Wrapper label={getText('apiUsage')}>
        <Apis />
      </Wrapper>
    </div>
  );
};

export default TenantStatistic;
