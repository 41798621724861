import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import {
  sanitizeListRestProps,
  TopToolbar,
  useListContext,
  useTranslate,
} from 'react-admin';
import { Add } from '@material-ui/icons';
import ReusableButton from '@common/Button/Button';
import exportIcon from '@assets/icons/export.svg';
import { useHistory } from 'react-router-dom';
import { DATA_VIEW_CREATE } from '@constants/routes';
import useStyles from './styles';

const ListActions = props => {
  const classes = useStyles();
  const translate = useTranslate();
  const { push } = useHistory();
  const { className, maxResults, onRefresh, ...rest } = props;
  const {
    currentSort,
    resource,
    filterValues,
    total,
    basePath,
  } = useListContext();

  return (
    <TopToolbar
      className={cx(className, classes.toolBar)}
      {...sanitizeListRestProps(rest)}
    >
      <ReusableButton
        size="md"
        resource={resource}
        basePath={basePath}
        icon={null}
        onClick={() => {
          push(DATA_VIEW_CREATE);
        }}
        classNameWrapper={classes.buttonWrapper}
      >
        <>
          <Add className={classes.plusIcon} />
          {translate('common.create')}
        </>
      </ReusableButton>

      {onRefresh && (
        <ReusableButton
          buttonType="export"
          size="md"
          viewType="black"
          onClick={onRefresh}
          disabled={total === 0}
          resource={resource}
          sort={currentSort}
          filterValues={filterValues}
          maxResults={maxResults}
          icon={null}
          classNameWrapper={classes.buttonWrapper}
        >
          <>
            <img src={exportIcon} alt="export" />
            {translate('common.export')}
          </>
        </ReusableButton>
      )}
    </TopToolbar>
  );
};

ListActions.propTypes = {
  className: PropTypes.string,
  exporter: PropTypes.bool,
  filters: PropTypes.objectOf(PropTypes.any),
  maxResults: PropTypes.number,
  onRefresh: PropTypes.func,
  createPage: PropTypes.func,
};

export default ListActions;
