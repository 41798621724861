import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useStyles from '../dialogs/styles';

const Timer = ({ expired, setExpired, duration }) => {
  const classes = useStyles();

  const [time, setTime] = useState(`${duration}:00`);

  const startTimer = () => {
    let minute = duration;
    let sec = 0;
    const timer = setInterval(() => {
      if (minute === 0 && sec === 0) {
        clearInterval(timer);
        setExpired(true);
        return;
      }

      if (sec === 0) {
        minute--;
        sec = 60;
      }

      sec--;
      setTime(`${minute}:${sec < 10 ? `0${sec}` : sec}`);
    }, 1000);
  };

  useEffect(() => {
    startTimer();
  }, []);

  return (
    <div className={classes.textBlock}>
      {expired ? (
        <span className={classes.expirationMessage}>Code is expired</span>
      ) : (
        `Code expires in ${time} minutes`
      )}
    </div>
  );
};

Timer.propTypes = {
  expired: PropTypes.bool.isRequired,
  setExpired: PropTypes.func.isRequired,
  duration: PropTypes.number.isRequired,
};

export default Timer;
