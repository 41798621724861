import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import {
  sanitizeListRestProps,
  TopToolbar,
  useListContext,
  useTranslate,
} from 'react-admin';
import ReusableButton from '@common/Button/Button';
import exportIcon from '@assets/icons/export.svg';
import uploadIcon from '@assets/icons/upload_white.svg';
import refreshIcon from '@assets/icons/refresh.svg';
import useStyles from './styles';

const ListActions = props => {
  const classes = useStyles();
  const {
    className,
    exporter,
    filters,
    maxResults,
    onRefresh,
    onUploadOpen,
    ...rest
  } = props;
  const { currentSort, resource, filterValues, total } = useListContext();
  const translate = useTranslate();

  return (
    <TopToolbar
      className={cx(classes.toolBar, className)}
      {...sanitizeListRestProps(rest)}
    >
      <ReusableButton
        buttonType="export"
        size="md"
        viewType="black"
        onClick={onRefresh}
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
        icon={null}
        classNameWrapper={classes.buttonWrapper}
      >
        <>
          <img src={exportIcon} alt="export" />
          {translate('common.export')}
        </>
      </ReusableButton>
      <ReusableButton
        size="md"
        viewType="black"
        onClick={onUploadOpen}
        icon={null}
        classNameWrapper={classes.buttonWrapper}
      >
        <>
          <img src={uploadIcon} alt="upload" />
          {translate('common.upload')}
        </>
      </ReusableButton>
      <ReusableButton
        size="md"
        classNameWrapper={classes.buttonWrapper}
        onClick={onRefresh}
      >
        <>
          <img src={refreshIcon} alt="refresh" />
          {translate('common.refresh')}
        </>
      </ReusableButton>
    </TopToolbar>
  );
};

ListActions.propTypes = {
  className: PropTypes.string,
  exporter: PropTypes.bool,
  filters: PropTypes.objectOf(PropTypes.any),
  maxResults: PropTypes.number,
  onRefresh: PropTypes.func,
};

export default ListActions;
