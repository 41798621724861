import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useTranslate, useNotify } from 'react-admin';
import { Typography } from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import Input from '@common/Input/Input';
import ReusableButton from '@common/Button/Button';
import { getBillingEmail, updateEmail } from './helpers';
import useStyles from './styles';

const Invoices = ({ authKey }) => {
  const classes = useStyles();
  const notify = useNotify();
  const translate = useTranslate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    setLoading(true);
    getBillingEmail(authKey)
      .then(d => setData(d))
      .catch(e => notify(e.message || e, 'error'))
      .finally(() => setLoading(false));
  }, [authKey, notify]);

  const validate = values => {
    return {
      email: values.email ? undefined : 'required',
    };
  };

  const submit = values => {
    setLoading(true);
    updateEmail(authKey, { email: values.email })
      .then(d => setData(d))
      .catch(e => notify(e.message || e, 'error'))
      .finally(() => setLoading(false));
  };

  return (
    <div className={cx(classes.container, classes.font)}>
      <Typography className={classes.title}>
        {translate('resources.billing.invoices.content.header')}
      </Typography>
      <Typography className={classes.textPartTwo}>
        {translate('resources.billing.invoices.content.text')}
      </Typography>
      <div className={classes.block}>
        <Form
          onSubmit={submit}
          validate={validate}
          initialValues={{
            email: data.email ?? null,
          }}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} noValidate>
              <div>
                <Field
                  id="email"
                  name="email"
                  styleType="main"
                  inputView="text"
                  fullWidth
                  component={Input}
                  placeholder={translate('ra.registration.email')}
                  disabled={loading}
                />
              </div>
              <ReusableButton
                size="xl"
                viewType="black"
                type="submit"
                classNameWrapper={classes.buttonInvoice}
                disabled={loading}
                loading={loading}
                label="resources.billing.updateButton"
              />
            </form>
          )}
        />
      </div>
    </div>
  );
};

Invoices.propTypes = {
  authKey: PropTypes.string,
};

export default Invoices;
