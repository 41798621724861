import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Dialog, DialogContent, Tooltip } from '@material-ui/core';

import { ThunkGetCompanyVerificationSettings } from '@store/slices/verificationSettings/thunks';

import { ReactComponent as WarningIcon } from '@assets/icons/warnings.svg';
import ReusableButton from '@common/Button/Button';

import useSharedStyles from '@components/CrmTicket/sharedStyles';

import useStyles from '../styles';

const DisplayCompanyPolicies = ({ companyId = null, crmId = null }) => {
  const [loading, setLoading] = useState(true);
  const [showVerificationBanner, setShowVerificationBanner] = useState(false);
  const [bannerData, setBannerData] = useState(null);

  const dispatch = useDispatch();
  const classes = useStyles();
  const sharedClasses = useSharedStyles();

  const onMount = useCallback(() => {
    if (crmId && companyId !== null) {
      setLoading(true);
      dispatch(ThunkGetCompanyVerificationSettings({ crmId, companyId }))
        .unwrap()
        .then(res => {
          setBannerData(res);
          setShowVerificationBanner(res?.showVerificationBannerOnMount);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [companyId, crmId, dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  if (companyId === null || !crmId || loading) return null;
  if (!bannerData) return null;
  if (!bannerData?.enableVerificationBanner) return null;
  return (
    <div className={classes.companyPolicies}>
      <Tooltip title="Company Policies">
        <WarningIcon
          onClick={() => {
            setShowVerificationBanner(true);
          }}
        />
      </Tooltip>

      {showVerificationBanner && (
        <Dialog
          open={showVerificationBanner}
          onClose={() => {
            setShowVerificationBanner(false);
          }}
        >
          <DialogContent style={{ width: '400px' }}>
            {bannerData?.verificationBannerText}

            <div className={sharedClasses.dialogActionsContainer}>
              <ReusableButton
                label="Close"
                viewType="blue"
                onClick={() => {
                  setShowVerificationBanner(false);
                }}
              />
            </div>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};

DisplayCompanyPolicies.propTypes = {
  companyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  crmId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default DisplayCompanyPolicies;
