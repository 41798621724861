import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useNotify, useRedirect } from 'react-admin';
import InfoIcon from '@material-ui/icons/Info';

import {
  ThunkCreateIssueType,
  ThunkGetIssueType,
  ThunkUpdateIssueType,
} from '@store/slices/issueTypes/thunks';
import { SelectClientPortalConfigurationOptions } from '@store/slices/clientPortalAdmin/selectors';
import { ClientPortalDefaultsSelector } from '@store/slices/clientPortalDefaults';
import { ThunkGetClientPortalConfigurationOptions } from '@store/slices/clientPortalAdmin/thunks';

import { ISSUE_TYPE_ITEMS_NAME } from '@ui/pages/clientPortal/IssueType/constants';
import { ISSUE_TYPE, ISSUE_TYPES } from '@constants/routes';

import { reorder } from '@components/AuditViews/helpers/reorder';

import FieldWrapper from '@common/form/FieldWrapper';
import Input from '@components/Auth/Common/Input';
import IssueTypeButtonBar from '@ui/pages/clientPortal/IssueType/components/IssueTypeButtonBar';
import IssueTypeItem from '@ui/pages/clientPortal/IssueType/components/IssueTypeItem';
import Loading from '@common/Loading/Loading';
import InputWithAutocomplete from '@common/Input/InputWithAutocomplete';
import ActionButton from '@common/buttons/ActionButton/ActionButton';
import DropDown from '@components/Auth/Common/DropDown';
import { ThunkGetClientPortalDefaults } from '@store/slices/clientPortalDefaults/thunks';
import { validateRequired } from '@common/functions/validators';

import iconBack from '@assets/icons/arrowGrey.svg';

import { FormControlLabel } from '@material-ui/core';
import Checkbox from '@common/Checkbox/Checkbox';

import useStyles from './styles';

const IssueType = ({ match }) => {
  const [loading, setLoading] = useState(false);
  const [survey, setSurvey] = useState(null);
  const [processing, setProcessing] = useState(false);

  const dispatch = useDispatch();
  const redirect = useRedirect();
  const id = useMemo(() => match.params.id, [match]);
  const notify = useNotify();
  const clientPortalOptions = useSelector(
    SelectClientPortalConfigurationOptions,
  );
  const clientPortalDefaultsSelector = useSelector(
    ClientPortalDefaultsSelector,
  );

  // IF we have less than 2 client portal THAN hide selection
  // and leave blank field for clientPortals on form because
  // we don't want users to select client portal at all
  const hideClientPortalSelector = useMemo(
    () => clientPortalOptions.length < 2,
    [clientPortalOptions?.length],
  );

  const classes = useStyles();

  const onDragEnd = (list, form) => ({ destination, source }) => {
    // dropped outside the list
    if (!destination) return;

    const newRecord = reorder(list, source.index, destination.index);

    form.change(ISSUE_TYPE_ITEMS_NAME, newRecord);
  };

  const submit = useCallback(
    values => {
      if (Number(id)) {
        setProcessing(true);

        dispatch(ThunkUpdateIssueType({ id, payload: values }))
          .unwrap()
          .then(() => {
            notify('Successfully Updated', 'success');
          })
          .finally(() => {
            setProcessing(false);
          });
      } else {
        setProcessing(true);
        dispatch(
          ThunkCreateIssueType({
            payload: { ...values, type: 2, value: values.title },
          }),
        )
          .unwrap()
          .then(() => {
            redirect(ISSUE_TYPES);
            notify('Issue Type Created', 'success');
          })
          .finally(() => {
            setProcessing(false);
          });
      }
    },
    [dispatch, id, notify, redirect],
  );

  const onMount = useCallback(async () => {
    setLoading(true);
    await dispatch(ThunkGetClientPortalConfigurationOptions());
    await dispatch(ThunkGetClientPortalDefaults());
    if (Number(id)) {
      dispatch(ThunkGetIssueType({ id }))
        .unwrap()
        .then(d => {
          setSurvey(d);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    setLoading(false);
  }, [dispatch, id]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  const validate = values => {
    return {
      psaMappingId: validateRequired(values.psaMappingId),
    };
  };

  if (loading) return <Loading />;

  return (
    <div className={classes.container}>
      <ActionButton
        toolTip="Back"
        icon={<img src={iconBack} alt="iconBack" />}
        handler={() => redirect(ISSUE_TYPE.replace('/:id', ''))}
      />
      <Form
        onSubmit={submit}
        validate={validate}
        initialValues={
          Number(id)
            ? survey
            : {
                clientPortals: [],
                showConfigurationItemsDropdown: false,
                psaMappingId:
                  clientPortalDefaultsSelector.length === 1
                    ? clientPortalDefaultsSelector[0].id
                    : undefined,
              }
        }
        render={({ handleSubmit, values, form }) => (
          <form onSubmit={handleSubmit} className={classes.form}>
            <FieldWrapper
              label="Issue Type Name:"
              labelSize={12}
              contentSize={12}
              fullWidth
              content={<Field name="title" id="title" render={Input} />}
            />

            {!hideClientPortalSelector && (
              <div>
                <FieldWrapper
                  label="Client portals"
                  labelSize={12}
                  contentSize={12}
                  fullWidth
                  content={
                    <InputWithAutocomplete
                      name="clientPortals"
                      options={clientPortalOptions}
                      loading={processing}
                      disableCloseOnSelect
                      freeSolo={false}
                      getOptionValue={i => {
                        return typeof i === 'object'
                          ? i.value
                          : clientPortalOptions.find(m => m.value === i)?.value;
                      }}
                      getOptionLabel={i =>
                        typeof i === 'object'
                          ? i.label
                          : clientPortalOptions.find(m => m.value === i)?.label
                      }
                      getOptionSelected={(options, value) =>
                        options.value === value
                      }
                    />
                  }
                />
                <div className={classes.helper}>
                  <InfoIcon />
                  Leave it blank if you want to use this issue type on all
                  client portals
                </div>
              </div>
            )}
            <FieldWrapper
              label="PSA Mapping"
              labelSize={12}
              contentSize={12}
              isRequired
              content={
                <Field
                  id="psaMappingId"
                  name="psaMappingId"
                  size="small"
                  labelName="name"
                  valueName="id"
                  component={DropDown}
                  options={clientPortalDefaultsSelector}
                  disabled={processing}
                />
              }
            />
            <Field
              name="showConfigurationItemsDropdown"
              type="checkbox"
              render={({ input }) => (
                <FormControlLabel
                  className={classes.checkbox}
                  control={<Checkbox {...input} />}
                  label="Prompt users to associate their ticket with a Configuration Item"
                />
              )}
            />
            <DragDropContext
              onDragEnd={onDragEnd(values[ISSUE_TYPE_ITEMS_NAME], form)}
            >
              <Droppable droppableId="issueType-list">
                {provided => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className={classes.list}
                  >
                    {!!values[ISSUE_TYPE_ITEMS_NAME] &&
                      values[ISSUE_TYPE_ITEMS_NAME]?.map((i, index) => (
                        <IssueTypeItem
                          key={i.id || i.innerId}
                          {...i}
                          index={index}
                          disabled={processing}
                        />
                      ))}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <IssueTypeButtonBar disabled={processing} />
          </form>
        )}
      />
    </div>
  );
};

export default IssueType;
