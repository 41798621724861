import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { createTheme, ThemeProvider } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { useNotify, Notification } from 'react-admin';

import {
  ThunkCreateUserFromInvite,
  ThunkCheckUserInviteCode,
} from '@store/slices/usersInvite/thunks';
import { lightTheme } from '@services/themes/mainTheme';
import { requiredValidator } from '@utils/validators';
import { LOGIN_ROUTE } from '@constants/routes';

import Loading from '@common/Loading/Loading';
import FieldWrapper from '@common/form/FieldWrapper';
import Input from '@components/Auth/Common/Input';
import ReusableButton from '@common/Button/Button';

import useStyles from './styles';

const theme = createTheme(lightTheme());

const UserInvite = () => {
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(false);

  const dispatch = useDispatch();
  const classes = useStyles();
  const notify = useNotify();
  const { search } = useLocation();
  const { push } = useHistory();

  const urlParams = useMemo(() => new URLSearchParams(search), [search]);
  const code = useMemo(() => urlParams.get('code'), [urlParams]);

  const validate = values => {
    return {
      userName: requiredValidator(values.userName),
      password: requiredValidator(values.password),
      confirmPassword:
        values.confirmPassword === values.password
          ? undefined
          : 'Passwords don`t match',
    };
  };

  const submit = values => {
    setProcessing(true);
    const payload = {
      userName: values.userName,
      code,
      password: values.password,
    };

    dispatch(ThunkCreateUserFromInvite({ payload }))
      .unwrap()
      .then(() => {
        push(LOGIN_ROUTE);
        notify('User Created', 'success');
      })
      .catch(e => notify(e.message, 'error'))
      .finally(() => {
        setProcessing(false);
      });
  };

  const onMount = useCallback(() => {
    setLoading(true);
    dispatch(ThunkCheckUserInviteCode({ code }))
      .unwrap()
      .then(() => {})
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [code, dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  if (loading) return <Loading />;

  if (error) {
    return (
      <div style={{ padding: '30px', fontFamily: "'Nunito', sans-serif" }}>
        Something went wrong. Please check if url address is correct.
      </div>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Form
        onSubmit={submit}
        validate={validate}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className={classes.form}>
            <Typography variant="h6">
              <b>Create User</b>
            </Typography>
            <FieldWrapper
              label="Full Name"
              labelSize={12}
              contentSize={12}
              fullWidth
              isRequired
              disabled={processing}
              content={
                <Field
                  name="userName"
                  id="userName"
                  render={Input}
                  disabled={loading}
                />
              }
            />

            <FieldWrapper
              label="Password"
              labelSize={12}
              contentSize={12}
              fullWidth
              isRequired
              disabled={processing}
              content={
                <Field
                  name="password"
                  id="password"
                  type="password"
                  render={Input}
                  disabled={loading}
                />
              }
            />

            <FieldWrapper
              label="Confirm Password"
              labelSize={12}
              contentSize={12}
              fullWidth
              isRequired
              disabled={processing}
              content={
                <Field
                  name="confirmPassword"
                  id="confirmPassword"
                  type="password"
                  render={Input}
                  disabled={loading}
                />
              }
            />

            <div className={classes.buttonContainer}>
              <ReusableButton label="Create" type="submit" />
            </div>
          </form>
        )}
      />
      <Notification />
    </ThemeProvider>
  );
};

export default UserInvite;
