import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  CartesianGrid,
  Bar,
  BarChart,
  Tooltip,
  XAxis,
  YAxis,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { useTranslate } from 'react-admin';
import useAsync from '@services/api/common/useAsync';
import letterStatuses from '@constants/letterStatuses';
import { getMailBoxStat } from '../../helpers';

import useStyles from './styles';

const Dashboard = ({ mailBoxId, onClick }) => {
  const translate = useTranslate();
  const classes = useStyles();
  const [data, setData] = React.useState([]);
  const getData = useCallback(() => getMailBoxStat(mailBoxId), [mailBoxId]);
  useAsync(getData, setData);

  const getText = value =>
    translate('resources.mailBox.dashboard.'.concat(value));

  return (
    <div
      className={classes.chartContainer}
      style={{ width: 'auto', height: 400 }}
    >
      <ResponsiveContainer className={classes.chartWrapper}>
        <BarChart
          data={data}
          margin={{ top: 5, right: 20, left: 10, bottom: 50 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" interval={0} angle={-90} dx={-5} dy={40} />
          <YAxis />
          <Tooltip />
          <Legend
            verticalAlign="top"
            iconType="square"
            align="right"
            height={32}
          />
          {Object.keys(letterStatuses).map(i => (
            <Bar
              key={i}
              dataKey={i}
              name={getText(i)}
              stackId="a"
              fill={letterStatuses[i].hex}
              onClick={onClick}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

Dashboard.propTypes = {
  mailBoxId: PropTypes.string.isRequired,
};

export default Dashboard;
