import React, { useMemo } from 'react';
import { usePermissions } from 'react-admin';

import TabPanel from '@common/TabPanel/TabPanel';
import { basePermissions } from '@constants/permissions';
import useStyles from './styles';

import Usage from './components/Usage';
import Plan from './components/Plan';

const tabsData = [
  {
    label: 'resources.pricingPlan.usage.name',
    value: <Usage />,
  },
  {
    label: 'resources.pricingPlan.selectPlan.name',
    value: <Plan />,
  },
];

const PricingPlan = () => {
  const classes = useStyles();
  const { permissions } = usePermissions();

  const tabs = useMemo(
    () =>
      permissions?.pricingPlan &&
      permissions.pricingPlan[basePermissions.update]
        ? tabsData
        : [tabsData[0]],
    [permissions],
  );

  return (
    <div className={classes.container}>
      <TabPanel
        tabs={tabs}
        classNameWrapperTabs={classes.tabsWrapper}
        viewTypeLink="flooded"
      />
    </div>
  );
};

export default PricingPlan;
