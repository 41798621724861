import React from 'react';
import { useTranslate } from 'react-admin';
import PropTypes from 'prop-types';
import CustomCheckbox from '@common/Checkbox';
import { FormControlLabel } from '@material-ui/core';

const IncludeServiceInfoCheckBox = ({ checked, onChange }) => {
  const translate = useTranslate();
  return (
    <FormControlLabel
      control={
        <CustomCheckbox
          input={{
            checked,
            onChange,
          }}
        />
      }
      label={translate('resources.exporting.download.checkBoxLabel')}
    />
  );
};

IncludeServiceInfoCheckBox.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default IncludeServiceInfoCheckBox;
