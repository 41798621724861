import composeValidators from '@utils/validators/composeValidators';
import { requiredValidator } from '@utils/validators';

export const typeOptions = [
  { label: 'Histogram', value: 0 },
  { label: 'Pie Chart', value: 1 },
  { label: 'Count', value: 2 },
  { label: 'Horizontal Bar Chart', value: 3 },
  { label: 'Vertical Bar Chart', value: 4 },
];

export const chartTypes = {
  histogram: 0,
  pie: 1,
  count: 2,
  bar: 3,
  column: 4,
};

const initialData = {
  name: '',
  type: 0,
  barChartGroupBy: '',
  filters: {},
};

export const getInitData = data => {
  if (data && data.item) {
    return {
      name: data.title,
      type: data.item.type,
      auditViewId: data.item.auditViewId,
      filters: data.item.filters ? JSON.parse(data.item.filters) : {},
      barChartGroupBy: data.item.barChartGroupBy,
    };
  }
  return initialData;
};

export const formToValue = (tile, formValues, filters) => {
  const formData = { ...formValues };
  formData.filters = JSON.stringify(filters);
  return {
    ...tile,
    title: formData.name,
    item: formData,
    barChartGroupBy: formData.barChartGroupBy,
  };
};

export const groupRequired = [
  chartTypes.bar,
  chartTypes.column,
  chartTypes.pie,
];

export const validate = values => {
  return {
    name: composeValidators(requiredValidator)(values.name),
    barChartGroupBy:
      groupRequired.includes(values.type) && !values.barChartGroupBy
        ? 'ra.validation.required'
        : undefined,
  };
};
