import React from 'react';
import PropTypes from 'prop-types';
import { useNotify, useSafeSetState, useTranslate } from 'react-admin';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import { Dialog, DialogContent } from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import Input from '@common/Input/Input';
import ReusableButton from '@common/Button/Button';
import cx from 'classnames';
import { upsertDashboard } from '@components/Dashboard/helper';
import { useDispatch, useSelector } from 'react-redux';
import { dashboardOptions } from '@store/selectors/dashboard';
import { actions } from '@store/actions';
import useStyles from '../styles';

const init = {
  id: 0,
  name: '',
};

const DashboardForm = ({ onClose, open, data, setDashboardId }) => {
  const translate = useTranslate();
  const classes = useStyles();
  const notify = useNotify();
  const dispatch = useDispatch();
  const initialData = data || init;
  const options = useSelector(dashboardOptions);
  const headerText = data ? 'titleUpdate' : 'titleCreate';
  const [loading, setLoading] = useSafeSetState(false);

  const getText = value =>
    translate('resources.dashboard.createDialog.'.concat(value));

  const validate = values => {
    return {
      name: !values.name ? translate('ra.validation.required') : undefined,
    };
  };

  const submit = values => {
    setLoading(true);
    upsertDashboard(values)
      .then(d => {
        const newOptions = [...options];
        const idx = newOptions.findIndex(i => i.id === d.id);
        if (idx === -1) {
          newOptions.push(d);
          setDashboardId(d.id);
        } else {
          newOptions.splice(idx, 1, d);
        }
        dispatch(actions.dashboard_setOptions(newOptions));
      })
      .catch(e => notify(e.message, 'error'))
      .finally(() => {
        setLoading(false);
        onClose();
      });
  };

  return (
    <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">{getText(headerText)}</DialogTitle>
      <DialogContent>
        <Form
          onSubmit={submit}
          validate={validate}
          initialValues={initialData}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} noValidate>
              <div>
                <Field
                  autoFocus
                  id="name"
                  name="name"
                  fullWidth
                  component={Input}
                  styleType="main"
                  inputView="text"
                  placeholder={getText('label')}
                  disabled={loading}
                />
              </div>
              <div className={classes.modalButtonContainer}>
                <ReusableButton
                  label="resources.buttons.cancel"
                  onClick={onClose}
                  disabled={loading}
                  classNameWrapper={cx(
                    classes.button,
                    classes.modalCancelButtonContainer,
                  )}
                />
                <ReusableButton
                  viewType="black"
                  type="submit"
                  disabled={loading}
                  classNameWrapper={classes.button}
                  loading={loading}
                  label="resources.buttons.ok"
                />
              </div>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  );
};

DashboardForm.propTypes = {
  onClose: PropTypes.func,
  setDashboardId: PropTypes.func,
  open: PropTypes.bool,
  data: PropTypes.objectOf(PropTypes.any),
};

export default DashboardForm;
