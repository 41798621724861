import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  iconContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '10px',
    '& svg': {
      cursor: 'pointer',
    },
  },
  container: {
    fontFamily: '"Nunito",sans-serif',
    fontStyle: 'normal',
    padding: '20px',
  },
  logOutContainer: {
    position: 'absolute',
    top: '15px',
    right: '15px',
    zIndex: 15,
    cursor: 'pointer',
    background: '#e3e3e3',
    padding: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    transition: '.25s',
    '&:hover': {
      color: 'white',
      background: '#6a6a6a',
    },
  },
  tabsWrapper: {
    maxWidth: '750px',
    margin: '0 auto',
    justifyContent: 'space-evenly',
    backgroundColor: 'white',
    borderBottom: '0.0625rem solid #D9D5D5',
    display: 'flex',
  },
  tabTitle: {
    fontSize: '1rem',
    fontFamily: 'Montserrat',
    marginRight: 'unset',
  },
  tabContent: {
    overflow: 'hidden',
    // height: '100%',
    '& form': {
      height: '500px',
      overflowY: 'auto',
      overflowX: 'hidden',
    },
  },
  gap: {
    gap: '15px',
  },
}));

export default useStyles;
