import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuthProvider, useGetOne } from 'react-admin';
import PropTypes from 'prop-types';

import { currentUserDataSelector } from '@store/selectors';
import { actions } from '@store/actions';

import resources from '@constants/resources';

// import NotificationSetupForWizard from '@components/SetupWizard/userSetupComponents/NotificationSetupForWizard';
import ExternalLogin from '@components/SetupWizard/userSetupComponents/ExternalLogin';
import Mfa from '@components/SetupWizard/userSetupComponents/Mfa';
import TimeZone from '@components/SetupWizard/userSetupComponents/TimeZone';
import Loading from '@common/Loading/Loading';

import TenantLogo from '@components/SetupWizard/userSetupComponents/TenantLogo';
import { ThunkGetTenantSettings } from '@store/slices/common/thunks';
import useStyles from '../styles';

const UserSetup = React.memo(({ enableMoveForward, disableMoveForward }) => {
  const [processing, setProcessing] = useState(true);

  const classes = useStyles();
  const userData = useSelector(currentUserDataSelector);
  const dispatch = useDispatch();
  const authProvider = useAuthProvider();

  const id = authProvider.getUserId();
  const { data, refetch, loading } = useGetOne(resources.user, id);

  const onMount = useCallback(() => {
    setProcessing(true);
    dispatch(ThunkGetTenantSettings())
      .unwrap()
      .finally(() => {
        setProcessing(false);
      });
  }, [dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  useEffect(() => {
    dispatch(actions.setCurrentUser(data));
  }, [data, dispatch]);

  useEffect(() => {
    if (
      userData &&
      !userData.microsoftLinked &&
      !userData.googleLinked &&
      !userData.useMfa
    ) {
      disableMoveForward();
    } else {
      enableMoveForward();
    }
  }, [userData, disableMoveForward, enableMoveForward]);

  if (loading || processing) return <Loading />;
  return (
    <div className={classes.itemContainer}>
      <div className={classes.userSetup}>
        <div>You need to setup external login or mfa, to proceed</div>
        <div>{userData && <ExternalLogin refetch={refetch} />}</div>
        <div>{userData && <Mfa refetch={refetch} />}</div>
        <div>{userData?.roleId !== 3 && <TimeZone />}</div>
        <div>{userData?.roleId !== 3 && <TenantLogo />}</div>
      </div>
    </div>
  );
});

UserSetup.propTypes = {
  enableMoveForward: PropTypes.func,
  disableMoveForward: PropTypes.func,
};
export default UserSetup;
