import React from 'react';
import PropTypes from 'prop-types';
import { Paper } from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import ReusableButton from '@common/Button/Button';
import Input from '@common/Input/Input';
import useStyles from '../styles';

const HookCreator = ({ hooks, onSubmit }) => {
  const classes = useStyles();

  const validate = values => {
    const errors = { name: undefined, url: undefined };

    errors.url = values.url?.toLowerCase().startsWith('https')
      ? errors.url
      : 'Insecure';
    errors.name = values.name?.length > 50 ? '50 symbols limit' : errors.name;

    errors.name = hooks.some(i => i.name === values.name)
      ? 'Already exists'
      : errors.name;
    errors.url = hooks.some(i => i.url === values.url)
      ? 'Already exists'
      : errors.url;

    errors.name = values.name ? errors.name : 'Required';
    errors.url = values.url ? errors.url : 'Required';
    return errors.name || errors.url ? errors : undefined;
  };

  const submit = (values, form) => {
    onSubmit({ name: values.name, url: values.url });
    form.resetFieldState('name');
    form.resetFieldState('url');
    form.reset();
  };

  return (
    <Paper>
      <Form
        onSubmit={submit}
        validate={validate}
        render={({ handleSubmit, form }) => (
          <form onSubmit={values => handleSubmit(values, form)} noValidate>
            <div className={classes.smallPadding}>
              <Field
                id="name"
                name="name"
                styleType="main"
                inputView="text"
                fullWidth
                component={Input}
                placeholder="Input hook name"
              />
            </div>
            <div className={classes.smallPadding}>
              <Field
                id="url"
                name="url"
                styleType="main"
                inputView="text"
                fullWidth
                component={Input}
                placeholder="Input hook URL"
              />
            </div>
            <div className={classes.smallPadding}>
              <ReusableButton
                size="md"
                viewType="black"
                type="submit"
                label="common.add"
              />
            </div>
          </form>
        )}
      />
    </Paper>
  );
};

HookCreator.propTypes = {
  hooks: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  onSubmit: PropTypes.func.isRequired,
};

export default HookCreator;
