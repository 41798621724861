export const steps = [
  {
    id: 0,
    label: 'User Setup',
  },
  {
    id: 1,
    label: 'PSA Setup',
  },
  {
    id: 2,
    label: 'Messaging Setup',
  },
  {
    id: 3,
    label: 'Invite Users',
  },
  {
    id: 4,
    label: 'Verification Settings',
  },
];

export const stepsLength = steps.length;
export default {};
