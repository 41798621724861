import React, { useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog as MuiDialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  RadioGroup,
} from '@material-ui/core';
import FileUploader from '@common/FileUploader/FileUploader';
import { sendFormData } from '@services/api';
import appConfig from '@configs/appConfig';
import { GeneralContext } from '@components/Context/general';
import { useNotify } from 'react-admin';
import CustomRadio from '@common/Radio/Radio';
import fileFormat from '@common/FileUploader/fileFormats';
import getLetterBoxName from './utils';

const radioGroupValues = {
  file: 'file',
  folder: 'folder',
};

const UploadDialog = ({ open, setOpen }) => {
  const notify = useNotify();
  const [radioGroupValue, setRadioGroupValue] = useState(radioGroupValues.file);
  const { selectValue } = useContext(GeneralContext);

  const mailBoxName = useMemo(() => getLetterBoxName(selectValue), [
    selectValue,
  ]);

  const handleEvent = remove => (message, type) => {
    remove();
    notify(message, type);
  };

  const handleUpload = async (file, remove) => {
    const handleResponse = handleEvent(remove);
    try {
      const formData = new FormData();
      formData.append('file', file);
      const response = await sendFormData(
        `${appConfig.baseUrl}/eml-upload?mailBox=${mailBoxName}`,
        formData,
      );
      if (response.ok) {
        handleResponse(`File ${file.name} uploaded successfully`, 'info');
      } else {
        handleResponse(
          `File is damaged, you can't upload ${file.name} file`,
          'error',
        );
      }
    } catch (e) {
      handleResponse(e.message, 'error');
    }
  };

  const handleRadioChange = e => setRadioGroupValue(e.target.value);

  return (
    <>
      <MuiDialog
        fullWidth
        maxWidth="xs"
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogTitle>Upload files .eml format</DialogTitle>
        <DialogContent>
          <RadioGroup row value={radioGroupValue} onChange={handleRadioChange}>
            <FormControlLabel
              value={radioGroupValues.file}
              control={<CustomRadio />}
              label="files"
            />
            <FormControlLabel
              value={radioGroupValues.folder}
              control={<CustomRadio />}
              label="folder"
            />
          </RadioGroup>
          <FileUploader
            handleUpload={handleUpload}
            format={fileFormat.email}
            type={radioGroupValue}
            maxFiles={20}
            maxSizeBytes={10000000}
          />
        </DialogContent>
      </MuiDialog>
    </>
  );
};

UploadDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default UploadDialog;
