const maxLengthValidator = (value, length = 25, isRequired = true) => {
  if (isRequired && !value) {
    return 'ra.validation.required';
  }

  if (value && value.length > length) {
    return `field has max length ${length} symbols`;
  }

  return undefined;
};

export default maxLengthValidator;
