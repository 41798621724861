import React, { useCallback, useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import Loading from '@common/Loading/Loading';

import useAsync from '@services/api/common/useAsync';
import localStorage from '@services/localStorage';
import localStorageConst from '@constants/localStorage';

import ViewAutoComplete from '@common/ViewAutoComplete/ViewAutoComplete';
import { getAuditView, getAuditViewAutocomplete } from './helpers';
import GenericTable from './GenericTable';
import useStyles from './styles';

const AuditPage = ({ location }) => {
  const classes = useStyles();
  const [options, setOptions] = useState([]);
  const [currentView, setCurrentView] = useState({ label: '', value: 0 });
  const [currentFields, setCurrentFields] = useState({});
  const [selectedGroupValue, setSelectedGroupValue] = useState(null);

  const loading = useAsync(getAuditViewAutocomplete, setOptions);

  useEffect(() => {
    let persistentValue = localStorage.getItem(
      localStorageConst.SELECTED_AUDITVIEW,
    );
    const dataViewIdFromRoute =
      location.search && location.search.match(`\\?dataView=(\\d+)`)[1];
    if (dataViewIdFromRoute) {
      persistentValue = { value: dataViewIdFromRoute };
    }

    if (
      persistentValue &&
      options.some(i => i.value === +persistentValue.value)
    ) {
      const selectedAuditView = {
        label: options.find(i => i.value === +persistentValue.value).label,
        value: +persistentValue.value,
      };
      localStorage.setItem(
        localStorageConst.SELECTED_AUDITVIEW,
        selectedAuditView,
      );
      setCurrentView(selectedAuditView);
    }
  }, [options, location.search]);

  useEffect(() => {
    if (currentView.value) {
      window.history.pushState('', '', '/#/data');
      // eslint-disable-next-line no-param-reassign
      location.search = '';
    }
  }, [currentView, location.search]);

  const getData = useCallback(
    async () => (currentView.value ? getAuditView(currentView.value) : {}),
    [currentView],
  );
  useAsync(getData, setCurrentFields);

  const loadingView = currentFields && currentFields.data;

  const handleChange = value => {
    if (value) {
      setSelectedGroupValue(null);
      setCurrentView(value);
      localStorage.setItem(localStorageConst.SELECTED_AUDITVIEW, value);
    } else {
      setCurrentView({ label: '', value: 0 });
      localStorage.removeItem(localStorageConst.SELECTED_AUDITVIEW);
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className={classes.wrap}>
      <div className={classes.groupBy}>
        <ViewAutoComplete
          options={options}
          onChange={handleChange}
          loading={loading}
          currentView={currentView}
        />

        {loadingView ? (
          <ViewAutoComplete
            options={JSON.parse(currentFields.data)}
            onChange={setSelectedGroupValue}
            loading={loading}
            currentView={selectedGroupValue}
            optionLabel="name"
            textFieldLabel="Group by"
          />
        ) : null}
      </div>

      <div>
        {loadingView ? (
          <GenericTable
            item={currentFields}
            groupByValue={selectedGroupValue}
          />
        ) : null}
      </div>
    </div>
  );
};

export default AuditPage;
