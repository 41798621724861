import React, { useMemo, useContext, useState, useEffect } from 'react';
import {
  Menu,
  Typography,
  FormControlLabel,
  FormGroup,
} from '@material-ui/core';
import PropTypes from 'prop-types';

import CheckBox from '@common/Checkbox';
import ReusableButton from '@common/Button/Button';

import useStyles from './styles';
import { ColumnsContext } from '../context/columns';

const MenuHeader = React.forwardRef((props, ref) => {
  const { onReset } = props;
  const classes = useStyles();
  return (
    <div className={classes.headerContainer} ref={ref}>
      <ReusableButton
        label="common.toDefaults"
        onClick={onReset}
        classNameWrapper={classes.resetMenuButton}
      />
      <Typography className={classes.menuDescription} variant="h6" gutterBottom>
        ADJUST COLUMNS
      </Typography>
    </div>
  );
});

const MenuComponent = ({ anchorEl, setAnchorEl }) => {
  const { columns, isDraggable, setColumnsInLocalStorage } = useContext(
    ColumnsContext,
  );

  const [defaultColumns, setDefaultColumns] = useState([]);

  useEffect(() => {
    if (!isDraggable) {
      setDefaultColumns(columns);
    }
  }, [columns, isDraggable]);

  const open = Boolean(anchorEl);
  const classes = useStyles();

  const handleColumns = (event, column) => {
    const { checked } = event.target;
    const col = columns.find(c => c.key === column.key);
    const colDefault = defaultColumns.find(c => c.key === column.key);
    col.hidden = !checked;
    colDefault.hidden = !checked;

    setDefaultColumns([...defaultColumns]);
    setColumnsInLocalStorage([...columns]);
  };

  const isAllSelected = useMemo(() => columns.every(col => !col.hidden), [
    columns,
  ]);

  const toggleColumns = () => {
    const hidden = isAllSelected;
    setColumnsInLocalStorage(columns.map(c => ({ ...c, hidden })));
    setDefaultColumns(defaultColumns.map(c => ({ ...c, hidden })));
  };

  const onReset = () => {
    setColumnsInLocalStorage(
      defaultColumns.map(c => ({ ...c, hidden: false })),
    );
  };

  const getLabel = column => {
    if (typeof column.name === 'string') {
      return column.name;
    }
    return column.columnTextKey || column.key;
  };

  return (
    <Menu
      id="long-menu"
      anchorEl={anchorEl}
      keepMounted
      open={open}
      onClose={setAnchorEl}
      PaperProps={{
        className: classes.menuContainer,
      }}
    >
      <MenuHeader onReset={onReset} />
      <FormGroup classes={{ root: classes.formGroupContainer }}>
        <FormControlLabel
          control={
            <CheckBox onChange={toggleColumns} checked={isAllSelected} />
          }
          className={classes.formControl}
          label={isAllSelected ? 'Deselect All' : 'Select All'}
        />

        {defaultColumns.map(column => {
          const label = getLabel(column);
          return (
            <li key={crypto.randomUUID()}>
              <FormControlLabel
                control={
                  <CheckBox
                    checked={!column.hidden}
                    onChange={e => handleColumns(e, column)}
                  />
                }
                classes={{ root: classes.formCheckBox }}
                label={label}
              />
            </li>
          );
        })}
      </FormGroup>
    </Menu>
  );
};

MenuHeader.propTypes = {
  onReset: PropTypes.func,
};

MenuComponent.propTypes = {
  anchorEl: PropTypes.objectOf(PropTypes.any),
  setAnchorEl: PropTypes.func,
};

export default MenuComponent;
