import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useNotify } from 'react-admin';
import { Field, Form } from 'react-final-form';

import { ThunkSendSecureLink } from '@store/slices/secureData/thunks';
import { ThunkBeautifyPhoneNumber } from '@store/slices/common/thunks';
import Input from '@components/Auth/Common/Input';
import ReusableButton from '@common/Button/Button';

import { ReactComponent as LinkIcon } from '@assets/icons/link.svg';

import { secureDataSettingsInitialSelector } from '@store/slices/secureDataSettings/selectors';
import HtmlInput from '@common/HtmlInput';
import AutocompleteFormInput from '@common/AutocompleteFormInput/AutocompleteFormInput';
import { TypeLabelValue } from '@common/propTypes/common';
import { validateRequired } from '@common/functions/validators';
import { maxLengthProps } from '@utils/maxLengthProps';

import useStyles from '../styles';

const SendSecureDataLink = ({
  link,
  handleLinkChange,
  userPhone,
  userEmail,
  options,
}) => {
  const [processing, setProcessing] = useState(false);

  const classes = useStyles();
  const dispatch = useDispatch();
  const notify = useNotify();

  const initData = useSelector(secureDataSettingsInitialSelector);

  const [validationType, setValidationType] = useState(null);

  const initialIcon = useMemo(
    () => options.find(option => option.value === userPhone)?.icon,
    [userPhone, options],
  );

  const handleLinkCopyToClipboard = useCallback(() => {
    window.navigator.clipboard.writeText(link);
    notify('Copied to clipboard', 'success');
  }, [link, notify]);

  const handleLinkSend = useCallback(
    async (type, value, content) => {
      setValidationType(type);
      setProcessing(true);

      let phone = value;
      if (type === 'Phone') {
        await dispatch(ThunkBeautifyPhoneNumber(value))
          .unwrap()
          .then(res => {
            phone = res.data;
          });
      }

      const payload = {
        link,
        type,
        value: type === 'Phone' ? phone : value,
        content,
      };
      dispatch(ThunkSendSecureLink({ payload }))
        .unwrap()
        .then(() => {
          notify('Successfully sent', 'success');
          setValidationType(null);
        })
        .finally(() => {
          setProcessing(false);
        });
    },
    [dispatch, link, notify],
  );

  const submit = values => {
    handleLinkSend(
      validationType,
      validationType === 'Phone' ? values.userPhone : values.userEmail,
      values.smsText,
    );
  };

  const initialValues = useMemo(() => {
    return {
      userPhone,
      userEmail,
      // eslint-disable-next-line no-template-curly-in-string
      smsText: initData.smsTemplate?.replace('${link}', link),
      // eslint-disable-next-line no-template-curly-in-string
      emailText: initData.emailTemplate?.replace(
        // eslint-disable-next-line no-template-curly-in-string
        '${link}',
        `<a href='${link}'>here</a>`,
      ),
    };
  }, [
    initData.emailTemplate,
    initData.smsTemplate,
    link,
    userEmail,
    userPhone,
  ]);

  const validate = values => {
    const errors = {
      smsText: validateRequired(values.smsText),
    };

    if (validationType === 'Phone') {
      errors.userPhone = validateRequired(values.userPhone);
    } else if (validationType === 'Email') {
      errors.userEmail = validateRequired(values.userEmail);
    }

    return errors;
  };

  return (
    <div className={classes.form}>
      <div className={cx(classes.margins)}>
        <ReusableButton
          label="Back"
          onClick={() => {
            handleLinkChange(undefined);
          }}
        />
      </div>
      <Form
        // leave it empty just because it`s required prop for form
        onSubmit={submit}
        initialValues={initialValues}
        validate={validate}
        render={({ handleSubmit, values, form }) => (
          <form onSubmit={handleSubmit}>
            <div className={classes.margins}>
              {userPhone && (
                <>
                  <div className={classes.inputDiv}>
                    <div>Phone</div>
                    <Field
                      name="userPhone"
                      id="userPhone"
                      render={AutocompleteFormInput}
                      items={options}
                      isIconShow
                      disabled={processing}
                      initialIcon={initialIcon}
                    />
                  </div>
                  <div className={classes.inputDiv}>
                    <div>SMS Text</div>
                    <Field
                      name="smsText"
                      id="smsText"
                      render={Input}
                      {...maxLengthProps(values.smsText)}
                      fullWidth
                      multiline
                      minRows={4}
                      disabled={processing}
                    />
                  </div>
                </>
              )}
              {userEmail && (
                <>
                  <div className={classes.inputDiv}>
                    <div>Email:</div>
                    <Field
                      name="userEmail"
                      id="userEmail"
                      fullWidth
                      render={Input}
                      disabled={processing}
                    />
                  </div>

                  <div className={classes.inputDiv}>
                    <div>Email content</div>
                    <HtmlInput name="emailText" form={form} />
                  </div>
                </>
              )}
              <div className={cx(classes.margins, classes.align)}>
                <Input
                  disabled
                  input={{ value: link }}
                  meta={{ touched: false }}
                />
                <div>
                  <LinkIcon
                    className={classes.link}
                    onClick={handleLinkCopyToClipboard}
                  />
                </div>
              </div>

              <div className={cx(classes.margins, classes.align)}>
                {userPhone && (
                  <ReusableButton
                    label="Send via SMS"
                    type="submit"
                    onClick={() => {
                      setValidationType('Phone');
                    }}
                    disabled={processing}
                  />
                )}
                {userEmail && (
                  <ReusableButton
                    label="Send via Email"
                    type="submit"
                    onClick={() => {
                      setValidationType('Email');
                    }}
                    disabled={processing}
                  />
                )}
              </div>
            </div>
          </form>
        )}
      />
    </div>
  );
};

SendSecureDataLink.propTypes = {
  link: PropTypes.string.isRequired,
  handleLinkChange: PropTypes.func.isRequired,
  userPhone: PropTypes.string,
  userEmail: PropTypes.string,
  options: PropTypes.arrayOf(TypeLabelValue),
};
export default SendSecureDataLink;
