import React, { useEffect, useState } from 'react';
import { useNotify } from 'react-admin';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import DropDown from '@components/Auth/Common/DropDown';
import PipelineHelp from '@components/Ticketing/Notifications/TicketSettings/components/GenericPipelines/PipelineHelp';
import {
  getGenericCrmOptions,
  getGenericPipelineFields,
} from '@components/Ticketing/Notifications/TicketSettings/components/GenericPipelines/helpers';
import FieldWrapper from '@common/form/FieldWrapper';
import InputFields from '@components/Ticketing/Notifications/TicketSettings/components/GenericPipelines/InputFields';
import Grid from '@material-ui/core/Grid';
import { getFieldTransformers } from '@components/GenericSources/helpers';
import useStyles from './styles';

const PipelineWrapper = ({
  form,
  values,
  pipelineOptions,
  inputFields,
  setInputFields,
}) => {
  const classes = useStyles();
  const notify = useNotify();
  const [crmVariables, setCrmVariables] = useState([]);

  useEffect(() => {
    getGenericCrmOptions()
      .then(setCrmVariables)
      .catch(e => notify(e.message, 'error'));
  }, []);

  useEffect(() => {
    if (values.genericPipelineId) {
      getGenericPipelineFields(values.genericPipelineId)
        .then(d => {
          setInputFields(d.inputFields);
        })
        .catch(e => notify(e.message, 'error'));
    }
  }, [values.genericPipelineId]);

  useEffect(() => {
    if (values.genericPipelineId) {
      getFieldTransformers(values.id, values.genericPipelineId)
        .then(d => form.change('fieldTransformer', d.data))
        .catch(e => notify(e.message, 'error'));
    }
  }, [values.genericPipelineId]);

  return (
    <>
      <FieldWrapper
        label="Data source"
        classNameLabelOuter={classes.alignLeft}
        labelSize={2}
        contentSize={10}
        content={
          <Grid container spacing={1}>
            <Grid item xs={11}>
              <Field
                id="genericPipelineId"
                name="genericPipelineId"
                component={DropDown}
                options={pipelineOptions}
              />
            </Grid>
            <Grid item xs={1}>
              <PipelineHelp
                form={form}
                pipelineOptions={pipelineOptions}
                fieldName="genericPipelineId"
              />
            </Grid>
          </Grid>
        }
      />
      <InputFields
        inputFields={inputFields}
        form={form}
        inputOptions={[]}
        crmVariables={crmVariables}
        baseFieldName="inputData"
      />
    </>
  );
};

PipelineWrapper.propTypes = {
  form: PropTypes.objectOf(PropTypes.any),
};

export default PipelineWrapper;
