import React, { useEffect, useMemo, useState } from 'react';
import { useNotify } from 'react-admin';
import PropTypes from 'prop-types';
import useTranslatedText from '@common/hooks/useTranslatedText';
import TableLabel from '@common/TableLabel/TableLabel';
import MuiVirtualizedTable from '@common/MuiVirtualizedTable/MuiVirtualizedTable';
import { sortOrders } from '@constants/filters';
import CrmPanel from './CrmPanel';
import ActionField from './components/ActionField';
import { getGroupsOptions } from './helpers';

const fields = ['name', 'email', 'phone'];

const CrmUsers = ({ resource }) => {
  const notify = useNotify();
  const [groupOptions, setGroupOptions] = useState([]);
  const { getFieldText } = useTranslatedText(resource);

  useEffect(() => {
    getGroupsOptions()
      .then(d => setGroupOptions(d))
      .catch(e => notify(e.message, 'error'));
  }, []);

  const actions = useMemo(
    () => ({
      name: 'Actions',
      key: 'actions',
      accessor: rowData => (
        <ActionField label="Actions" record={rowData} options={groupOptions} />
      ),
      width: 150,
    }),
    [groupOptions],
  );

  const columns = useMemo(
    () =>
      fields
        .map(field => ({
          name: <TableLabel item={{ name: getFieldText(field), field }} />,
          key: field,
        }))
        .concat(actions),
    [getFieldText],
  );

  return (
    <MuiVirtualizedTable
      actions={null}
      columns={columns}
      resource={resource}
      defaultSort={{
        field: 'id',
        order: sortOrders.desc,
      }}
    >
      <CrmPanel />
    </MuiVirtualizedTable>
  );
};

CrmUsers.propTypes = {
  resource: PropTypes.string,
};

export default CrmUsers;
