import React, { useEffect, useState } from 'react';
import { useNotify } from 'react-admin';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import cx from 'classnames';
import {
  getMerakiOrganizationNetworks,
  getMerakiOrganizations,
} from '@components/DataSources/helpers';
import FullScreenLoading from '@common/Loading/FullScreenLoading/FullScreenLoading';
import TextField from '@material-ui/core/TextField';
import ReusableButton from '@common/Button/Button';

import OrgItem from './components/OrgItem';
import NetworkItem from './components/NetworkItem';

import useStyles from '../../styles';

const SelectNetworks = ({
  integrationId,
  handleBack,
  selectedNetworks,
  setSelectedNetworks,
  orgSelection,
  setOrgSelection,
  fullOrgsList,
  setFullOrgsList,
  orgs,
  setOrgs,
  handleForward,
}) => {
  const notify = useNotify();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [orgFilter, setOrgFilter] = useState('');
  const [networks, setNetworks] = useState([]);

  useEffect(() => {
    if (!fullOrgsList.length) {
      setLoading(true);
      getMerakiOrganizations(integrationId)
        .then(d => {
          const orgSelectionObject = d.reduce((obj, item) => {
            return {
              ...obj,
              [item.name]: { total: 0, selected: 0 },
            };
          }, {});
          setOrgSelection(orgSelectionObject);
          setFullOrgsList(d);
          setOrgs(d);
        })
        .catch(e => notify(e.message, 'error'))
        .finally(() => setLoading(false));
    }
  }, [integrationId]);

  const onOrgClick = (id, name, checkBoxClick, selected) => {
    setLoading(true);
    getMerakiOrganizationNetworks(integrationId, id)
      .then(d => {
        const newNetworks = d.map(n => ({ orgName: name, ...n }));
        setNetworks(newNetworks);
        const orgSelectionObject = {
          total: newNetworks.length,
          selected: orgSelection[name]?.selected ?? 0,
        };
        if (checkBoxClick) {
          if (selected) {
            const networkIds = newNetworks.map(i => i.id);
            const newSelectedNetworks = [...selectedNetworks].filter(
              i => !networkIds.includes(i.id),
            );
            setSelectedNetworks(newSelectedNetworks);
            orgSelectionObject.selected = 0;
          } else {
            const ids = newNetworks.map(n => ({
              id: n.id,
              name: n.name,
              orgName: n.orgName,
            }));
            const newSelectedNetworks = [...selectedNetworks].concat(ids);
            setSelectedNetworks(newSelectedNetworks);
            orgSelectionObject.selected = newNetworks.length;
          }
        }
        const newOrgSelection = { ...orgSelection };
        newOrgSelection[name] = orgSelectionObject;
        setOrgSelection(newOrgSelection);
      })
      .catch(e => notify(e.message, 'error'))
      .finally(() => setLoading(false));
  };

  const handleNetworkClick = (id, name, orgName, checked) => {
    if (checked) {
      const newArr = [...selectedNetworks].filter(i => i.id !== id);
      const newOrgSelection = { ...orgSelection };
      // eslint-disable-next-line no-plusplus
      newOrgSelection[orgName].selected--;
      setOrgSelection(newOrgSelection);
      setSelectedNetworks(newArr);
    } else {
      setSelectedNetworks([...selectedNetworks, { id, name, orgName }]);
      const newOrgSelection = { ...orgSelection };
      // eslint-disable-next-line no-plusplus
      newOrgSelection[orgName].selected++;
      setOrgSelection(newOrgSelection);
    }
  };

  const onFilterChanged = value => {
    setOrgFilter(value);
    if (!value) {
      setOrgs(fullOrgsList);
    } else {
      const filtered = fullOrgsList.filter(
        i => i.name.toUpperCase().indexOf(value.toUpperCase()) !== -1,
      );
      setOrgs(filtered);
    }
  };

  const onPressBack = () => {
    setSelectedNetworks([]);
    handleBack();
  };

  return (
    <div className={classes.font}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <div className={classes.applyTemplateFilter}>
            <TextField
              value={orgFilter}
              onChange={event => onFilterChanged(event.target.value)}
              variant="outlined"
              size="small"
            />
          </div>
          <div className={classes.column}>
            {orgs.map(org => (
              <OrgItem
                key={org.id}
                name={org.name}
                api={org.api}
                id={org.id}
                onClick={onOrgClick}
                selectionObject={orgSelection[org.name]}
              />
            ))}
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className={cx(classes.column, classes.networksMargin)}>
            {networks.map(nw => (
              <NetworkItem
                key={nw.id}
                name={nw.name}
                orgName={nw.orgName}
                id={nw.id}
                list={selectedNetworks}
                onClick={handleNetworkClick}
              />
            ))}
          </div>
        </Grid>
      </Grid>
      <div className={classes.buttonBlock}>
        <ReusableButton
          size="md"
          label="common.back"
          onClick={onPressBack}
          classNameWrapper={classes.buttonWrapper}
        />
        <ReusableButton
          size="md"
          viewType="black"
          label="common.next"
          onClick={handleForward}
          classNameWrapper={classes.buttonWrapper}
        />
      </div>
      <FullScreenLoading display={loading} />
    </div>
  );
};

SelectNetworks.propTypes = {
  integrationId: PropTypes.number.isRequired,
  handleBack: PropTypes.func.isRequired,
  selectedNetworks: PropTypes.arrayOf(PropTypes.any),
  setSelectedNetworks: PropTypes.func,
  handleForward: PropTypes.func,
};

export default SelectNetworks;
