import React, { useCallback, useState } from 'react';
import { useRedirect, useTranslate } from 'react-admin';
import { Grid } from '@material-ui/core';
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';

import Paper from '@common/Paper/Paper';
import ReusableButton from '@common/Button/Button';
import { INTEGRATIONS } from '@constants/routes';
import crmSources from '@constants/crmSources';
import Microsoft365Table from '@components/Ticketing/Integrations/pages/updateCrm/Table/Microsoft365Table/Microsoft365Table';
import { useHistory } from 'react-router-dom';
import UpdateForm from './UpdateForm';
import CrmDefaults from './CrmDefaults';

import useStyles from './styles';

const UpdateCrm = ({ match }) => {
  const [crmType, setCrmType] = useState();
  const [showCrmInfo, setShowCrmInfo] = useState(true);

  const classes = useStyles();
  const translate = useTranslate();
  const { push } = useHistory();

  const handleReturnClick = () => {
    push(INTEGRATIONS);
  };

  const handleShowCrmInfoFormChange = useCallback((state = true) => {
    setShowCrmInfo(state);
  }, []);

  return (
    <div className={classes.spacing}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ReusableButton
            classNameWrapper={classes.buttonWrapper}
            size="md"
            onClick={handleReturnClick}
          >
            <>
              <ArrowBackIcon className={classes.iconBlack} />
              {translate('resources.letter.back')}
            </>
          </ReusableButton>
        </Grid>

        {showCrmInfo && (
          <Grid item lg={4} xs={12}>
            <Paper classNameContainer={classes.defaultWidth}>
              <UpdateForm id={match.params.id} setCrmType={setCrmType} />
            </Paper>
          </Grid>
        )}
        {crmType !== undefined && (
          <Grid item lg={showCrmInfo ? 8 : 12} xs={12}>
            {crmType === crmSources.Microsoft.name ? (
              <Microsoft365Table crmId={match.params.id} />
            ) : (
              <CrmDefaults
                id={match.params.id}
                crmType={crmType}
                handleShowCrmInfoFormChange={handleShowCrmInfoFormChange}
              />
            )}
          </Grid>
        )}
      </Grid>
    </div>
  );
};

UpdateCrm.propTypes = {
  match: PropTypes.objectOf(PropTypes.any),
};

export default UpdateCrm;
