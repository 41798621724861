import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  ThunkCheckIfTicketHasChannel,
  ThunkGetConversationTemplates,
  ThunkGetMessagingChannel,
  ThunkGetMessagingConfiguration,
} from '@store/slices/messaging/thunks';
import { setTemplateText } from '@store/slices/messaging';

import crmSources from '@constants/crmSources';

import SearchableMenu from '@common/SearchableMenu';
import History from '@components/Messaging/MessagingChannel/components/History';
import CreateChatFromTicket from '@components/integrations/connectwise/components/CreateChatFromTicket';
import Loading from '@common/Loading/Loading';

import useStyles from '../../styles';

const MessagingComponent = ({
  data,
  channel,
  crmId,
  phoneNumber,
  ticketId,
  crmType = 0,
  psaType = crmSources.ConnectWise.label,
}) => {
  const [loading, setLoading] = useState(true);
  const [conversationTemplates, setConversationTemplates] = useState([]);
  const [hasChannel, setHasChannel] = useState(false);

  const dispatch = useDispatch();
  const classes = useStyles();
  const { search } = useLocation();

  const handleMessageSend = useCallback(
    item => {
      dispatch(setTemplateText(item.text));
    },
    [dispatch],
  );

  const checkIfChannelExist = useCallback(() => {
    if (Number(crmId) && ticketId) {
      setLoading(true);
      dispatch(
        ThunkCheckIfTicketHasChannel({
          crmId,
          ticketId,
        }),
      )
        .unwrap()
        .then(async res => {
          if (res.hasChannel) {
            await dispatch(ThunkGetMessagingChannel(res.channelId));
            await dispatch(ThunkGetMessagingConfiguration(res.configurationId));
            setHasChannel(true);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [crmId, ticketId, dispatch]);

  const onMount = useCallback(() => {
    setLoading(true);

    dispatch(ThunkGetConversationTemplates(channel?.id))
      .unwrap()
      .then(res => {
        setConversationTemplates(res);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [channel?.id, dispatch]);

  useEffect(() => {
    checkIfChannelExist();
  }, [checkIfChannelExist]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  if (loading) return <Loading />;
  return (
    <div>
      {channel && hasChannel && (
        <>
          <div
            style={{ padding: '5px 0px 0px 15px' }}
            className={classes.formContainer}
          >
            <SearchableMenu
              menuItems={conversationTemplates}
              onMenuItemClick={handleMessageSend}
              buttonLabel="Conversations"
              searchable
            />
          </div>
          <div className={classes.formContainer}>
            <History channelId={channel.id} hideExpandButton />
          </div>
        </>
      )}
      {!hasChannel && (
        <CreateChatFromTicket
          crmId={crmId}
          crmType={crmType}
          search={search}
          data={data}
          psaType={psaType}
          ticketId={ticketId}
          checkIfChannelExist={checkIfChannelExist}
          conversationTemplates={conversationTemplates}
          phoneNumber={phoneNumber}
        />
      )}
    </div>
  );
};

MessagingComponent.propTypes = {
  channel: PropTypes.objectOf(PropTypes.any),
  crmId: PropTypes.string,
  phoneNumber: PropTypes.string,
  ticketId: PropTypes.number,
};

export default MessagingComponent;
