import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  appLogo: {
    maxWidth: '60%',
    alignSelf: 'center',

    '@media (max-width: 850px)': {
      width: '60%',
      margin: '0 auto',
    },
  },
}));

export default useStyles;
