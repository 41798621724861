import React from 'react';
import PropTypes from 'prop-types';
import Circle from '@common/Circle/Circle';
import useStyles from './styles';

const AggergatedData = ({
  source,
  combCount,
  typeValues,
  record = { __data__: [] },
}) => {
  const classes = useStyles();
  const items = record.__data__.slice(combCount * -1).map(i => i[source]);
  return (
    <div>
      {items.map(i => {
        const style = typeValues.filter(tv => tv.id === i);
        const key = Math.random() * 100;
        return style && style[0] ? (
          <Circle
            classNameWrapper={classes.circleWrapper}
            key={key}
            bgColor={style[0].circleBgColor}
            borderColor="#E7E7E7"
          />
        ) : (
          <span key={key}>{i}</span>
        );
      })}
    </div>
  );
};

AggergatedData.propTypes = {
  record: PropTypes.objectOf(PropTypes.any),
  source: PropTypes.string.isRequired,
  combCount: PropTypes.number.isRequired,
  typeValues: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default AggergatedData;
