import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import signalRMessageTypes from '@constants/signalRMessageTypes';
import { addHistory, MessagingChannelSelector } from '@store/slices/messaging';
import { changeVerificationData } from '@store/slices/psaPods';
import eventBus from '@services/eventBus';
import { verificationEvents as events } from '@constants/events';
import { listSelector } from '@store/selectors';
import { actions } from '@store/actions';
import { useSignalREvent } from '../../../../../hooks/signalR';

const useSocketForHistory = (crmId, contactId) => {
  const dispatch = useDispatch();
  const channel = useSelector(MessagingChannelSelector);
  const list = useSelector(listSelector);
  const tenantId = localStorage.getItem('tenantId');

  const onPsaTechVerificationSmsRequest = useCallback(
    msg => {
      const {
        TenantId,
        Id,
        From,
        To,
        CreatedDate,
        Code,
        Type,
        CrmId,
        ContactName,
        IsVerified,
        ContactId,
        TicketId,
        TechAccountId,
        TechAccountName,
      } = JSON.parse(msg);
      if (Number(tenantId) === Number(TenantId)) {
        dispatch(
          actions.updateItemInList({
            id: Id,
            from: From,
            to: To,
            createdDate: CreatedDate,
            isVerified: IsVerified ?? undefined,
            code: Code ?? undefined,
            type: Type,
            crmId: CrmId ?? undefined,
            contactName: ContactName ?? undefined,
            contactId: ContactId ?? undefined,
            ticketId: TicketId ?? undefined,
            techAccountId: TechAccountId ?? undefined,
            techAccountName: TechAccountName ?? undefined,
          }),
        );
      }
    },
    [dispatch, tenantId],
  );

  const onVerificationEvent = useCallback(
    msg => {
      const {
        CrmId,
        ContactId,
        Value,
        Type,
        ValidationType,
        InspectorName,
        Note,
      } = JSON.parse(msg);
      if (
        Number(CrmId) === Number(crmId) &&
        Number(ContactId) === Number(contactId)
      ) {
        eventBus.dispatch(events.VerificationCompleted, null);
        dispatch(
          changeVerificationData({
            value: Value,
            type: Type,
            validationType: ValidationType,
            inspector: InspectorName,
            note: Note,
          }),
        );

        const item = list.find(i => i.id === contactId);
        if (item) {
          dispatch(actions.updateItemInList({ ...item, isVerified: true }));
        }
      }
    },
    [contactId, crmId, dispatch, list],
  );

  const onMessagingChannelMessage = useCallback(
    msg => {
      if (channel) {
        const { ChannelId, From, To, Message, MediaUrls = [] } = JSON.parse(
          msg,
        );

        if (channel.id === +ChannelId) {
          dispatch(
            addHistory({
              from: From,
              to: To,
              body: Message,
              dateTimeUtc: new Date().toISOString(),
              mediaUrls: MediaUrls || [],
              id: new Date().getTime(),
              messageSid: new Date().getTime(),
            }),
          );
        }
      }
    },
    [channel, dispatch],
  );

  useSignalREvent(
    signalRMessageTypes.messagingChannelMessage,
    onMessagingChannelMessage,
  );

  useSignalREvent(
    signalRMessageTypes.contactVerificationEvent,
    onVerificationEvent,
  );

  useSignalREvent(
    signalRMessageTypes.psaTechVerificationSmsRequestEvent,
    onPsaTechVerificationSmsRequest,
  );
};

export default useSocketForHistory;
