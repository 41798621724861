import React, { useEffect, useState } from 'react';
import CircularStatic from '@components/DataSources/Dashboards/CircularProgress';
import MerakiGrid from '@components/DataSources/Dashboards/Meraki/MerakiGrid';
import BagelsContainer from '@components/DataSources/Dashboards/Statistic/BagelsContainer';
import { integrationSources } from '@constants/integrationSources';
import statusColors from '@components/DataSources/Dashboards/statusColors';
import {
  getIntegrationOrganizations,
  getMerakiDeviceStatuses,
} from '../../helpers';

const items = {
  online: { title: 'ONLINE', color: statusColors.online, total: 0, count: 0 },
  offline: {
    title: 'OFFLINE',
    color: statusColors.offline,
  },
  alerting: {
    title: 'ALERTING',
    color: statusColors.alerting,
  },
  dormant: {
    title: 'DORMANT',
    color: statusColors.dormant,
  },
};

const MerakiDashboard = () => {
  const [data, setData] = useState([]);
  const [statusFilter, setStatusFilter] = useState(null);
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = React.useState(0);

  const adjustProgress = (current, total) => {
    setProgress((100 * current) / total);
  };

  useEffect(() => {
    getIntegrationOrganizations(integrationSources.Meraki.name)
      .then(i => {
        let dt = [];
        for (let s = 0, p = Promise.resolve(); s <= i.length; s += 1) {
          p = p
            .then(() =>
              s !== i.length
                ? getMerakiDeviceStatuses(
                    i[s].integrationId,
                    i[s].organizationId,
                  )
                : Promise.resolve(null),
            )
            // eslint-disable-next-line no-loop-func
            .then(rsp => {
              if (rsp === null) {
                setData(dt);
                setLoading(false);
                return;
              }
              dt = dt.concat(rsp);
            })
            .catch(e => console.log(e.message))
            .finally(() => adjustProgress(s + 1, i.length));
        }
      })
      .catch(e => console.log(e.message));
  }, []);

  const filteringData = () => {
    if (!statusFilter) return data;
    return data.filter(item => item.status === statusFilter.toLowerCase());
  };

  return !loading ? (
    <div>
      <BagelsContainer
        data={data}
        field="status"
        setStatusFilter={setStatusFilter}
        items={items}
      />
      <MerakiGrid data={filteringData()} />
    </div>
  ) : (
    <CircularStatic progress={progress} />
  );
};

export default MerakiDashboard;
