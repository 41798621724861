import React, { useState } from 'react';
import { FormControlLabel } from '@material-ui/core';

import Switch from '@common/FilterInputs/Switch';

import cx from 'classnames';
import { useTableContext } from '../../../../../hooks/table';

import useStyles from '../../styles';

const AutotaskTicketTableFilters = () => {
  const classes = useStyles();

  const [skipCompleted, setSkipCompleted] = useState(true);

  const { setFilteringObject, filtering } = useTableContext();

  return (
    <div className={cx(classes.filtersContainer, classes.alignRight)}>
      <FormControlLabel
        className={classes.switch}
        control={
          <Switch
            input={{
              onChange: (_, value) => {
                setSkipCompleted(value);
                setFilteringObject(filtering, 'skipCompleted', value);
              },
              value: skipCompleted,
            }}
            checked={skipCompleted}
          />
        }
        label="Skip Completed"
        labelPlacement="start"
      />
    </div>
  );
};

export default AutotaskTicketTableFilters;
