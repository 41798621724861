import { emailValidator } from '@utils/validators/index';

const emailListValidator = value => {
  if (!value || !Array.isArray(value) || !value.length) {
    return 'ra.validation.required';
  }
  const errorArray = value
    .map(item =>
      !emailValidator(item) || (item.startsWith('{$') && item.endsWith('}'))
        ? null
        : item,
    )
    .filter(item => !!item);

  return errorArray.length
    ? `Invalid email or template: ${errorArray.join(', ')}`
    : undefined;
};

export default emailListValidator;
