import React, { useEffect, useState } from 'react';
import { useNotify, useDataProvider, useRedirect } from 'react-admin';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import NotificationGroupForm from '@components/Settings/NotificationGroups/components/NotificationGroupForm';
import { EMAIL_GROUP_DETAILS } from '@constants/routes';
import { Typography } from '@material-ui/core';
import useStyles from '../styles';

const EditGroup = ({ id }) => {
  const redirect = useRedirect();
  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const [initData, setInitData] = useState(null);

  useEffect(() => {
    dataProvider
      .getOne('NotificationGroups', { id })
      .then(d => setInitData(d.data))
      .catch(e => notify(e.message, 'error'));
  }, [id]);

  return (
    <Paper className={classes.editForm}>
      <Typography align="center">Edit group name</Typography>
      <NotificationGroupForm
        onClose={() => redirect(`${EMAIL_GROUP_DETAILS.replace(':id', '')}`)}
        init={initData}
      />
    </Paper>
  );
};

EditGroup.propTypes = {
  id: PropTypes.string,
};

export default EditGroup;
