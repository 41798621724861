import React, { useMemo, useState } from 'react';
import {
  useDataProvider,
  useNotify,
  useRedirect,
  useTranslate,
} from 'react-admin';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import Input from '@components/Auth/Common/Input';
import ReusableButton from '@common/Button/Button';
import FormSwitch from '@common/FilterInputs/Switch';
import FieldWrapper from '@common/form/FieldWrapper';
import resources from '@constants/resources';
import { actions } from '@store/actions';
import { useDispatch } from 'react-redux';
import useAsync from '@services/api/common/useAsync';
import InputWithAutocomplete from '@common/Input/InputWithAutocomplete';
import ReactQueryBuilder from '@common/ReactQueryBuilder/ReactQueryBuilder';
import DropDown from '@components/Auth/Common/DropDown';
import { getQuery, setQuery } from '@common/ReactQueryBuilder/helpers';
import { TRIGGER } from '@constants/routes';
import { requiredListValidator, requiredValidator } from '@utils/validators';
import InfoBox from '@common/InfoBox/InfoBox';
import {
  createTrigger,
  getMailboxesOptions,
  getNotificationOptions,
  getTagsAutocomplete,
} from '../helpers';
import {
  getInitialValue,
  periodType,
  triggerOptions,
  triggerPriorities,
} from './helpers';
import useStyles from '../styles';

const TriggerForm = ({ item }) => {
  const classes = useStyles();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const dispatch = useDispatch();
  const translate = useTranslate();
  const [tagsOptions, setTagsOptions] = useState([]);
  useAsync(getTagsAutocomplete, setTagsOptions);
  const [loading, setLoading] = useState(false);
  const [mailBoxOptions, setMailBoxOptions] = useState([]);
  const mbOptionsLoading = useAsync(getMailboxesOptions, setMailBoxOptions);
  const [notificationOptions, setNotificationBoxOptions] = useState([]);
  const nOptionsLoading = useAsync(
    getNotificationOptions,
    setNotificationBoxOptions,
  );
  const init = useMemo(() => {
    const exprData = item?.identifierRules
      ? JSON.parse(item.identifierRules)
      : null;
    const setup = {
      ...getInitialValue(item),
      identifierRules: getQuery(exprData),
    };
    return setup;
  }, [item]);

  const optionsLoading = useMemo(() => mbOptionsLoading || nOptionsLoading, [
    mbOptionsLoading,
    nOptionsLoading,
  ]);

  const totalLoading = useMemo(() => loading || optionsLoading, [
    loading,
    optionsLoading,
  ]);

  const customizedOptions = useMemo(
    () => [{ value: 0, label: 'All Mailboxes' }, ...mailBoxOptions],
    [mailBoxOptions],
  );

  const validate = values => {
    return {
      name: requiredValidator(values.name),
      eventMessage: requiredValidator(values.name),
      mailBoxIds: requiredListValidator(values.mailBoxIds),
      periodSize:
        values.triggerRule === 2 && values.periodSize < 1
          ? 'Bad value'
          : undefined,
    };
  };

  const processResponse = (response, isEdit) => {
    setLoading(false);
    dispatch(actions.updateItemInList(response));
    notify(`Record ${isEdit ? 'updated' : 'created'}`);
  };

  const create = data => {
    createTrigger(data)
      .then(resp => {
        processResponse(resp);
      })
      .catch(error => {
        setLoading(false);
        notify(error.message, 'error');
      })
      .finally(() => redirect(TRIGGER));
  };

  const update = data => {
    dataProvider
      .update(resources.triggers, { id: data.id, data })
      .then(resp => processResponse(resp.data, true))
      .catch(error => {
        setLoading(false);
        notify(error.message, 'error');
      })
      .finally(() => redirect(TRIGGER));
  };

  const submit = payload => {
    setLoading(true);
    const data = {
      ...payload,
      identifierRules: JSON.stringify(setQuery(payload.identifierRules)),
    };
    if (!item?.id) {
      create(data);
    } else {
      update(data);
    }
  };

  const handleAllMailBoxesChange = values => {
    return form => {
      if (values.includes(0)) {
        const allIds = mailBoxOptions.map(mb => mb.value);
        form.change('mailBoxIds', allIds);
      }
    };
  };

  return (
    <Form
      onSubmit={submit}
      validate={validate}
      initialValues={init}
      render={({ handleSubmit, form, values }) => (
        <form onSubmit={handleSubmit} noValidate>
          <div className={classes.formContainer}>
            <FieldWrapper
              label="Enabled"
              labelSize={2}
              contentSize={10}
              content={
                <Field
                  id="enabled"
                  name="enabled"
                  type="checkbox"
                  component={FormSwitch}
                  disabled={totalLoading}
                />
              }
            />
            <FieldWrapper
              label="Trigger Name"
              isRequired
              labelSize={2}
              contentSize={10}
              content={
                <Field
                  id="name"
                  name="name"
                  fullWidth
                  component={Input}
                  placeholder="Name"
                  disabled={totalLoading}
                />
              }
            />
            <FieldWrapper
              label="Event Message"
              isRequired
              labelSize={2}
              contentSize={10}
              content={
                <Field
                  id="eventMessage"
                  name="eventMessage"
                  fullWidth
                  component={Input}
                  placeholder="Event message"
                  disabled={totalLoading}
                />
              }
            />
            <FieldWrapper
              label="Priority"
              isRequired
              labelSize={2}
              contentSize={10}
              content={
                <Field
                  id="priority"
                  name="priority"
                  options={triggerPriorities}
                  component={DropDown}
                  disabled={loading}
                />
              }
            />
            <FieldWrapper
              label="Query"
              isRequired
              labelSize={2}
              contentSize={10}
              content={
                <ReactQueryBuilder
                  data={form.getState()?.values?.identifierRules || null}
                  onChange={value => form.change('identifierRules', value)}
                />
              }
            />
            <FieldWrapper
              label="Create New Incident if Email subject is Unique"
              labelSize={2}
              contentSize={10}
              content={
                <div>
                  <Field
                    id="createIfSubjectIsUnique"
                    name="createIfSubjectIsUnique"
                    type="checkbox"
                    classNameWrapper={classes.switcher}
                    component={FormSwitch}
                    disabled={totalLoading}
                  />
                  <InfoBox
                    text={translate('resources.triggers.switchDescription')}
                  />
                </div>
              }
            />
            <FieldWrapper
              label="Tags"
              labelSize={2}
              contentSize={10}
              content={
                <InputWithAutocomplete
                  name="tags"
                  options={tagsOptions}
                  loading={totalLoading}
                  disableCloseOnSelect
                  freeSolo
                  limitTags={5}
                />
              }
            />
            <FieldWrapper
              label="Mail Boxes"
              labelSize={2}
              contentSize={10}
              content={
                <InputWithAutocomplete
                  name="mailBoxIds"
                  options={customizedOptions}
                  loading={loading}
                  disableCloseOnSelect
                  onChange={value => {
                    handleAllMailBoxesChange(value)(form);
                  }}
                  freeSolo={false}
                  getOptionValue={i => {
                    return typeof i === 'object'
                      ? i.value
                      : mailBoxOptions.find(m => m.value === i)?.value;
                  }}
                  getOptionLabel={i =>
                    typeof i === 'object'
                      ? i.label
                      : mailBoxOptions.find(m => m.value === i)?.label
                  }
                  getOptionSelected={(option, value) => {
                    return option.value === value;
                  }}
                />
              }
            />
            <FieldWrapper
              label="Notifications"
              labelSize={2}
              contentSize={10}
              content={
                <InputWithAutocomplete
                  name="notificationIds"
                  options={notificationOptions}
                  loading={loading}
                  disableCloseOnSelect
                  freeSolo={false}
                  getOptionValue={i => {
                    return typeof i === 'object'
                      ? i.value
                      : notificationOptions.find(m => m.value === i)?.value;
                  }}
                  getOptionLabel={i =>
                    typeof i === 'object'
                      ? i.label
                      : notificationOptions.find(m => m.value === i)?.label
                  }
                />
              }
            />
            <FieldWrapper
              label="Create incident"
              labelSize={2}
              contentSize={10}
              content={
                <div className={classes.ruleRegion}>
                  <Field
                    id="triggerRule"
                    name="triggerRule"
                    options={triggerOptions}
                    component={DropDown}
                    disabled={loading}
                  />
                  {values.triggerRule === triggerOptions[2].value && (
                    <>
                      <Field
                        id="periodSize"
                        name="periodSize"
                        type="number"
                        component={Input}
                        disabled={loading}
                      />
                      <Field
                        id="periodType"
                        name="periodType"
                        options={periodType}
                        component={DropDown}
                        disabled={loading}
                      />
                    </>
                  )}
                </div>
              }
            />
            <div className={classes.buttonGroup}>
              <ReusableButton
                size="md"
                label="resources.buttons.back"
                onClick={() => redirect(TRIGGER)}
                disabled={totalLoading}
              />
              <ReusableButton
                type="submit"
                viewType="black"
                size="md"
                label="resources.buttons.confirm"
                loading={loading}
                disabled={totalLoading}
              />
            </div>
          </div>
        </form>
      )}
    />
  );
};

TriggerForm.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    eventMessage: PropTypes.string,
    identifierRules: PropTypes.string,
    mailBoxIds: PropTypes.arrayOf(PropTypes.number),
    enabled: PropTypes.bool,
  }),
};

export default TriggerForm;
