import React from 'react';
import PropTypes from 'prop-types';
import { useNotify } from 'react-admin';
import cx from 'classnames';
import TemplateItem from '@components/WebHooks/components/TemplateItem';
import webHookTemplates from '@constants/webHookTemplates';
import { sendHook } from '@components/WebHooks/helpers';
import useStyles from '../styles';

const Templates = ({ url, setAwaitingHook }) => {
  const classes = useStyles();
  const notify = useNotify();

  const onSelect = value => {
    setAwaitingHook(true);
    sendHook(url, value)
      .then(() => notify('Hook sent'))
      .catch(e => notify(e.message, 'error'));
  };

  return (
    <div className={cx(classes.textBorder, classes.templatesContainer)}>
      {webHookTemplates.map(item => (
        <TemplateItem
          key={item.name}
          item={item}
          onSelect={onSelect}
          url={url}
        />
      ))}
    </div>
  );
};

Templates.propTypes = {
  url: PropTypes.string,
  setAwaitingHook: PropTypes.func,
};

export default Templates;
