import { useTranslate } from 'react-admin';

const useValidate = () => {
  const translate = useTranslate();

  const required = (value, msg) =>
    !value ? msg || translate('ra.validation.required') : undefined;

  const numberInRange = (value, min, max, msg) => {
    if (!value) {
      return msg || translate('ra.validation.required');
    }
    const num = +value;
    if (!num) {
      return 'Invalid value';
    }
    return num >= min && num <= max ? undefined : 'Invalid value';
  };

  const numberLength = (value, lng, msg) => {
    if (!value) {
      return msg || translate('ra.validation.required');
    }
    if (value.length !== lng || !+value) {
      return msg || 'Invalid value';
    }
    return undefined;
  };

  const numberLengthRange = (value, lngMin, lngMax, msg) => {
    if (!value) {
      return msg || translate('ra.validation.required');
    }

    if (value.length < lngMin || value.length > lngMax || !+value) {
      return msg || 'Invalid value';
    }
    return undefined;
  };

  const ifPredicate = (predicate, msg) =>
    predicate ? translate(msg) : undefined;

  const validateCreditCardNumber = (value, msg) => {
    if (!value) {
      return msg || translate('ra.validation.required');
    }

    if (numberLengthRange(value?.replace(/\s+/g, ''), 14, 16)) {
      return msg || translate('ra.registration.cardNumber');
    }

    return undefined;
  };

  return {
    required,
    numberInRange,
    numberLength,
    ifPredicate,
    numberLengthRange,
    validateCreditCardNumber,
  };
};

export default useValidate;
