import React, { useCallback, useEffect, useState } from 'react';
import { SECURITY_INTEGRATION } from '@constants/routes';
import { useNotify, useRedirect } from 'react-admin';

import { useDispatch } from 'react-redux';

import Loading from '@common/Loading/Loading';
import { ThunkGetSecurityIntegrationById } from '@store/slices/securityIntegrations/thunks';

import { securityIntegrationSources } from '@constants/integrationSources';
import MicrosoftAuthenticatorSettings from '@ui/pages/securityIntegrations/components/MicrosoftAuthenticatorSettings';
import BaseSettings from './components/BaseSettings';

const EditSecurityIntegrations = ({ match }) => {
  const redirect = useRedirect();
  const notify = useNotify();
  const dispatch = useDispatch();

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  const onMount = useCallback(async () => {
    await dispatch(ThunkGetSecurityIntegrationById(match.params.id))
      .unwrap()
      .then(d => {
        setData(d);
      })
      .catch(e => notify(e.message, 'error'))
      .finally(() => setLoading(false));
  }, [dispatch, notify, match]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  if (loading) {
    return <Loading />;
  }

  const handleBack = () => {
    redirect(SECURITY_INTEGRATION);
  };

  return (
    <div>
      {data.type === securityIntegrationSources.MicrosoftAuthenticator.name ? (
        <MicrosoftAuthenticatorSettings />
      ) : (
        <BaseSettings
          item={data}
          integrationType={data?.type}
          onBack={handleBack}
        />
      )}
    </div>
  );
};

export default EditSecurityIntegrations;
