import React, { useEffect, useState, useCallback } from 'react';
import { useNotify } from 'react-admin';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import ViewAutoComplete from '@common/ViewAutoComplete/ViewAutoComplete';
import iconPlus from '@assets/icons/plus.svg';
import iconEdit from '@assets/icons/edit.svg';
import iconDelete from '@assets/icons/delete_white.svg';
import iconMonitor from '@assets/icons/monitor.svg';
import {
  createTile,
  deleteDashboard,
  getDashboards,
} from '@components/Dashboard/helper';
import { actions } from '@store/actions';
import { dashboardOptions } from '@store/selectors/dashboard';
import useAsyncDispatch from '@services/api/common/useAsyncDispatch';
import DashboardForm from '@components/Dashboard/Modals/Dashboard/DashboardForm';
import useTranslatedText from '@common/hooks/useTranslatedText';
import ConfirmDialog from '@common/ConfirmDialog/ConfirmDialog';
import ReusableButton from '@common/Button/Button';

const DashboardHeader = ({
  onChange,
  loading,
  setLoading,
  currentDashboardId = 0,
  setDashboardId,
  handleFullScreen,
}) => {
  const notify = useNotify();
  const dispatch = useDispatch();
  const { getText } = useTranslatedText('dashboard.header');
  const [value, setValue] = useState(null);
  const [editedDb, setEditedDb] = useState(null);
  const [dbFormOpen, setDbFormOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const options = useSelector(dashboardOptions);
  const loadData = useAsyncDispatch(
    getDashboards,
    actions.dashboard_setOptions,
  );

  useEffect(() => {
    setValue(options.find(i => i.id === currentDashboardId));
  }, [currentDashboardId, options]);

  const handleClickFullScreen = useCallback(() => {
    if (handleFullScreen.active) {
      handleFullScreen.exit();
    } else {
      handleFullScreen.enter();
    }
  }, [handleFullScreen]);

  const handleChange = item => {
    setValue(item);
    onChange(item?.id ?? 0);
  };

  const handleAddTile = () => {
    setLoading(true);
    createTile(value.id)
      .then(d => dispatch(actions.dashboard_addTile(d)))
      .catch(e => notify(e.message, 'error'))
      .finally(() => setLoading(false));
  };

  const handleUpsertDashboard = isEdit => {
    const db = isEdit ? value : null;
    setEditedDb(db);
    setDbFormOpen(true);
  };

  const handleDeleteDashboard = () => {
    setLoading(true);
    deleteDashboard(currentDashboardId)
      .then(() => {
        const newOptions = [...options].filter(
          i => i.id !== currentDashboardId,
        );
        dispatch(actions.dashboard_setOptions(newOptions));
        setDashboardId(0);
      })
      .catch(e => notify(e.message, 'error'))
      .finally(setLoading(false));
  };

  return (
    <>
      <Grid container justify="space-between" wrap="nowrap">
        <Grid item container spacing={2}>
          <Grid item xs={12} sm={4} lg={3} xl={2}>
            <ViewAutoComplete
              options={options}
              onChange={handleChange}
              currentView={value}
              optionLabel="name"
              textFieldLabel="Dashboard"
              loading={loading || loadData}
            />
          </Grid>
          <Grid item xs={12} sm="auto">
            <ReusableButton
              onClick={() => handleUpsertDashboard(false)}
              disabled={loading}
            >
              <img src={iconPlus} alt="iconPlus" />
              <span>{getText('addDashboard')}</span>
            </ReusableButton>
          </Grid>
          <Grid item xs={12} sm="auto">
            <ReusableButton
              viewType="black"
              size="xl"
              onClick={handleAddTile}
              disabled={!value || loading}
            >
              <img src={iconPlus} alt="iconPlus" />
              <span>{getText('AddTile')}</span>
            </ReusableButton>
          </Grid>
          <Grid item xs={12} sm="auto">
            <ReusableButton
              onClick={() => handleUpsertDashboard(true)}
              disabled={!value || loading}
            >
              <img src={iconEdit} alt="iconEdit" />
              <span>{getText('editDashboard')}</span>
            </ReusableButton>
          </Grid>
          <Grid item xs={12} sm="auto">
            <ReusableButton
              viewType="red"
              onClick={() => setDeleteDialogOpen(true)}
              disabled={!value || loading}
            >
              <img src={iconDelete} alt="iconDelete" />
              <span>{getText('DeleteDashboard')}</span>
            </ReusableButton>
          </Grid>
          <Grid item xs={12} sm="auto">
            <ReusableButton
              onClick={handleClickFullScreen}
              disabled={!value || loading}
            >
              <img src={iconMonitor} alt="fullScreen" />
              <span>{getText('fullScreen')}</span>
            </ReusableButton>
          </Grid>
        </Grid>
      </Grid>
      <DashboardForm
        open={dbFormOpen}
        onClose={() => setDbFormOpen(false)}
        data={editedDb}
        setDashboardId={setDashboardId}
      />
      <ConfirmDialog
        open={!!deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        onAccept={() => {
          setDeleteDialogOpen(false);
          handleDeleteDashboard();
        }}
        onCancel={() => setDeleteDialogOpen(false)}
        title={getText('deleteDialogCaption')}
        content={getText('deleteDialogText')}
      />
    </>
  );
};

DashboardHeader.propTypes = {
  onChange: PropTypes.func.isRequired,
  currentDashboardId: PropTypes.number.isRequired,
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
  setDashboardId: PropTypes.func,
  handleFullScreen: PropTypes.objectOf(PropTypes.any),
};

export default DashboardHeader;
