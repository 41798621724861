import { makeStyles } from '@material-ui/core/styles/index';

const useStyles = makeStyles(() => ({
  container: {
    fontFamily: '"Nunito",sans-serif',
    fontStyle: 'normal',
    padding: '20px',
  },
  userHeader: {
    display: 'flex',
    paddingBottom: '1rem',
    borderBottom: '1px solid #cdc7c7',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  userName: {
    marginLeft: '1rem',
  },
  infoBox: {
    marginTop: '2rem',
  },
  infoItem: {
    display: 'flex',
    marginLeft: '1rem',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  infoItemButton: {
    marginLeft: '0.5rem',
  },
  infoItemText: {
    marginLeft: '5px',
  },
  refreshButton: {
    marginLeft: '1rem',
  },
  linkCursor: {
    cursor: 'pointer',
    background: 'transparent',
    border: 'none',
    color: 'purple',
    fontFamily: 'inherit',
    fontSize: 'inherit',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  formContainer: {
    maxWidth: '750px',
    margin: '0 auto',
  },
  buttonsContainer: {
    marginTop: '10px',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  contactButton: {
    display: 'flex',
  },
  optInArea: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '0.5rem',
    alignItems: 'center',
  },
  tabsWrapper: {
    maxWidth: '750px',
    margin: '0 auto',
    justifyContent: 'space-evenly',
    backgroundColor: 'white',
    borderBottom: '0.0625rem solid #D9D5D5',
    display: 'flex',
    '& *': {
      minWidth: 'unset',
      fontSize: '15px',
      gap: '30px !important',
    },
  },
  tabTitle: {
    fontSize: '1rem',
    fontFamily: 'Montserrat',
    marginRight: 'unset',
  },
  liveChatContainer: {
    maxWidth: '750px',
    margin: '0 auto',
  },
  tabContent: {
    overflow: 'hidden',
    maxWidth: '1100px',
    margin: '0 auto',
    // height: '100%',
    '& form': {
      height: '500px',
      overflowY: 'auto',
      overflowX: 'hidden',
    },
  },
  flex: {
    flexWrap: 'nowrap',
    gap: '10px',
  },
  logOutContainer: {
    position: 'absolute',
    top: '15px',
    right: '15px',
    zIndex: 15,
    cursor: 'pointer',
    background: '#e3e3e3',
    padding: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    transition: '.25s',
    '&:hover': {
      color: 'white',
      background: '#6a6a6a',
    },
  },
  companyPolicies: {
    position: 'absolute',
    top: '15px',
    left: '15px',
    zIndex: 15,
    cursor: 'pointer',
    background: '#e3e3e3',
    padding: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    transition: '.25s',
    '&:hover': {
      color: 'white',
      background: '#6a6a6a',
      '& svg': {
        '& path': {
          fill: 'white',
        },
      },
    },
  },
  templatesButton: {
    margin: '-40px 0 5px 6rem',
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '10px',
  },
  table: {
    '& *': {
      '&[role="grid"]': {
        height: '450px',
      },
    },
  },
}));

export default useStyles;
