import React, { useCallback, useEffect, useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { useDispatch } from 'react-redux';
import { useNotify } from 'react-admin';

import {
  ThunkGetIntegrationBoardStatuses,
  ThunkGetIntegrationBoardTypes,
  ThunkGetIntegrationPriorities,
  ThunkGetIntegrationServiceBoards,
  ThunkGetIntegrationTicketCategories,
} from '@store/slices/integration/thunks';
import {
  ThunkGetPsaContacts,
  ThunkGetKaseyaCompanies,
  ThunkCreateKaseyaTicket,
  ThunkGetKaseyaAssignees,
} from '@store/slices/createTicket/thunks';
import { ThunkGetKaseyaLocation } from '@store/slices/crmTicket/thunks';

import crmDefaultFields from '@components/Ticketing/Integrations/pages/updateCrm/CrmDefaults/constants';
import crmSources from '@constants/crmSources';

import AutocompleteFormInput from '@common/AutocompleteFormInput/AutocompleteFormInput';
import FieldWrapper from '@common/form/FieldWrapper';
import DropDown from '@components/Auth/Common/DropDown';
import Loading from '@ui/components/common/Loading/Loading';
import TicketCreationTemplates from '@components/CreateTicket/components/TicketCreationTemplates';
import TicketCreationInputsTemplates from '@components/CreateTicket/components/TicketCreationInputsTemplates';
import ReusableButton from '@common/Button/Button';
import Input from '@components/Auth/Common/Input';
import TicketLinkDialog from '@components/CreateTicket/dialogs/TicketLinkDialog';

import useSharedStyles from '@components/CrmTicket/sharedStyles';
import useStyles from '../styles';

const KaseyaCreateTicketForm = ({ psaId }) => {
  const [processing, setProcessing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [openTicketLinkDialog, setOpenTicketLinkDialog] = useState(false);
  const [link, setLink] = useState(undefined);
  const [ticketId, setTicketId] = useState(undefined);

  /// OnMount options
  const [ticketCategories, setTicketCategories] = useState([]);
  const [serviceBoards, setServiceBoards] = useState([]);
  const [priorities, setPriorities] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [locations, setLocations] = useState([]);
  const [assignees, setAssignees] = useState([]);

  // Board related options
  const [boardTypes, setBoardTypes] = useState([]);
  const [boardStatuses, setBoardStatuses] = useState([]);
  const [boardSubTypes, setSubTypes] = useState({});

  const classes = useStyles();
  const sharedClasses = useSharedStyles();
  const dispatch = useDispatch();
  const notify = useNotify();

  const submit = values => {
    setProcessing(true);
    dispatch(ThunkCreateKaseyaTicket({ psaId, payload: values }))
      .unwrap()
      .then(({ ticketLink, ticketId: createdTicketId }) => {
        setLink(ticketLink);
        setTicketId(createdTicketId);
        setOpenTicketLinkDialog(true);

        notify('Successfully created', 'success');
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  const handleCompanyChange = async value => {
    setProcessing(false);
    await Promise.all([
      new Promise(res =>
        res(
          dispatch(
            ThunkGetPsaContacts({
              psaId,
              companyId: value,
            }),
          ).unwrap(),
        ),
      ),
      new Promise(res =>
        res(
          dispatch(
            ThunkGetKaseyaLocation({
              psaId,
              companyId: value,
            }),
          ).unwrap(),
        ),
      ),
    ])
      .then(res => {
        setContacts(res[0]);
        setLocations(res[1]);
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  const handleBoardIdChange = useCallback(
    async (value, form, ignoreDrop = false) => {
      // Dropping related form values
      if (!ignoreDrop) {
        form.batch(() => {
          form.change(crmDefaultFields.typeId, '');
          form.change(crmDefaultFields.subTypeId, '');
          form.change(crmDefaultFields.openBoardStatusId, '');
          form.change(crmDefaultFields.acknowledgedBoardStatusId, '');
          form.change(crmDefaultFields.closedBoardStatusId, '');
          form.change(crmDefaultFields.smsReceivedBoardStatusId, '');
        });
      }

      setProcessing(true);
      await Promise.all([
        new Promise(res =>
          res(
            dispatch(
              ThunkGetIntegrationBoardStatuses({
                id: psaId,
                boardId: value,
              }),
            ).unwrap(),
          ),
        ),
        new Promise(res =>
          res(
            dispatch(
              ThunkGetIntegrationBoardTypes({
                id: psaId,
                boardId: value,
              }),
            ).unwrap(),
          ),
        ),
      ]).then(res => {
        setBoardStatuses(res[0]);
        setBoardTypes(res[1]);
        setSubTypes(
          res[1].reduce((acc, cur) => {
            acc[cur.id] = cur.boardSubTypes;
            return acc;
          }, {}),
        );
      });

      setProcessing(false);
    },
    [dispatch, psaId],
  );

  const onMount = useCallback(async () => {
    setLoading(true);
    await Promise.all([
      new Promise(res =>
        res(
          dispatch(ThunkGetIntegrationTicketCategories({ id: psaId })).unwrap(),
        ),
      ),
      new Promise(res =>
        res(dispatch(ThunkGetIntegrationServiceBoards({ id: psaId })).unwrap()),
      ),
      new Promise(res =>
        res(dispatch(ThunkGetIntegrationPriorities({ id: psaId })).unwrap()),
      ),
      new Promise(res =>
        res(dispatch(ThunkGetKaseyaCompanies({ psaId })).unwrap()),
      ),
      new Promise(res =>
        res(dispatch(ThunkGetKaseyaAssignees({ psaId })).unwrap()),
      ),
    ])
      .then(res => {
        setTicketCategories(res[0]);
        setServiceBoards(res[1]);
        setPriorities(res[2]);
        setCompanies(res[3]);
        setAssignees(res[4]);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch, psaId]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  const onSelect = async (d, form) => {
    setProcessing(true);
    await Promise.all([
      new Promise(res =>
        res(
          dispatch(ThunkGetIntegrationTicketCategories({ id: psaId })).unwrap(),
        ),
      ),
      new Promise(res =>
        res(dispatch(ThunkGetIntegrationServiceBoards({ id: psaId })).unwrap()),
      ),
      new Promise(res =>
        res(dispatch(ThunkGetIntegrationPriorities({ id: psaId })).unwrap()),
      ),
      new Promise(res =>
        res(dispatch(ThunkGetKaseyaCompanies({ psaId })).unwrap()),
      ),
      new Promise(res =>
        res(dispatch(ThunkGetKaseyaAssignees({ psaId })).unwrap()),
      ),
    ]).then(res => {
      setTicketCategories(res[0]);
      setServiceBoards(res[1]);
      setPriorities(res[2]);
      setCompanies(res[3]);
      setAssignees(res[4]);
    });
    if (d[crmDefaultFields.defaultBoardId]) {
      await Promise.all([
        new Promise(res =>
          res(
            dispatch(
              ThunkGetIntegrationBoardStatuses({
                id: psaId,
                boardId: d[crmDefaultFields.defaultBoardId],
              }),
            ).unwrap(),
          ),
        ),
        new Promise(res =>
          res(
            dispatch(
              ThunkGetIntegrationBoardTypes({
                id: psaId,
                boardId: d[crmDefaultFields.defaultBoardId],
              }),
            ).unwrap(),
          ),
        ),
      ]).then(res => {
        setBoardStatuses(res[0]);
        setBoardTypes(res[1]);
        setSubTypes(
          res[1].reduce((acc, cur) => {
            acc[cur.id] = cur.boardSubTypes;
            return acc;
          }, {}),
        );
      });

      if (d[crmDefaultFields.defaultBoardId]) {
        await handleCompanyChange(d[crmDefaultFields.defaultBoardId]);
      }

      const fields = form.getRegisteredFields();
      fields.forEach(field => {
        if (field === 'messagingTemplateId' && !d[field]) {
          return;
        }

        form.change(field, d[field] || '');
      });
      setProcessing(false);
    }
  };

  const handleDialogClose = () => {
    setOpenTicketLinkDialog(false);
    setLink(undefined);
    setTicketId(undefined);
    onMount();
  };

  if (loading) return <Loading />;
  return (
    <Form
      onSubmit={submit}
      render={({ handleSubmit, values, form }) => (
        <form onSubmit={handleSubmit}>
          {/* Templates */}
          <div className={cx(classes.templatesBox, classes.boxShadow)}>
            <TicketCreationInputsTemplates
              inputFields={{
                description: 'description',
                title: 'title',
              }}
            />

            <TicketCreationTemplates
              psaType={crmSources.Kaseya.name}
              onSelect={onSelect}
            />
          </div>

          <div className={cx(classes.formContainer, classes.boxShadow)}>
            <FieldWrapper
              label="Title"
              labelSize={3}
              contentSize={9}
              isRequired
              classNameLabelContainer={classes.removePadding}
              content={
                <Field
                  name="title"
                  id="title"
                  render={Input}
                  loading={processing}
                />
              }
            />
            <FieldWrapper
              label="Description"
              labelSize={3}
              contentSize={9}
              isRequired
              classNameLabelContainer={classes.removePadding}
              content={
                <Field
                  name="description"
                  id="description"
                  render={Input}
                  multiline
                  minRows={4}
                  loading={processing}
                />
              }
            />
            <FieldWrapper
              label="Company"
              labelSize={3}
              contentSize={9}
              isRequired
              classNameLabelContainer={classes.removePadding}
              content={
                <Field
                  id={crmDefaultFields.defaultCompany}
                  name={crmDefaultFields.defaultCompany}
                  size="small"
                  component={AutocompleteFormInput}
                  items={companies}
                  loading={processing}
                  suppressInlineLabel
                />
              }
            />
            <OnChange name={crmDefaultFields.defaultCompany}>
              {handleCompanyChange}
            </OnChange>

            <FieldWrapper
              label="Location"
              labelSize={3}
              contentSize={9}
              isRequired
              classNameLabelContainer={classes.removePadding}
              content={
                <Field
                  id="locationId"
                  name="locationId"
                  size="small"
                  component={AutocompleteFormInput}
                  items={locations}
                  loading={
                    processing || !values[crmDefaultFields.defaultCompany]
                  }
                  suppressInlineLabel
                />
              }
            />

            <FieldWrapper
              label="Contacts"
              labelSize={3}
              contentSize={9}
              isRequired
              classNameLabelContainer={classes.removePadding}
              content={
                <Field
                  id="contactId"
                  name="contactId"
                  size="small"
                  component={AutocompleteFormInput}
                  items={contacts}
                  loading={
                    processing || !values[crmDefaultFields.defaultCompany]
                  }
                  suppressInlineLabel
                />
              }
            />

            <FieldWrapper
              label="Board"
              labelSize={3}
              contentSize={9}
              isRequired
              classNameLabelContainer={classes.removePadding}
              content={
                <Field
                  id={crmDefaultFields.defaultBoardId}
                  name={crmDefaultFields.defaultBoardId}
                  size="small"
                  suppressInlineLabel
                  labelName="name"
                  valueName="id"
                  component={DropDown}
                  options={serviceBoards}
                  disabled={processing}
                />
              }
            />
            {form
              .getRegisteredFields()
              .some(item => item === crmDefaultFields.defaultBoardId) && (
              <OnChange name={crmDefaultFields.defaultBoardId}>
                {value => {
                  handleBoardIdChange(value, form);
                }}
              </OnChange>
            )}

            <FieldWrapper
              label="Type"
              labelSize={3}
              contentSize={9}
              isRequired
              classNameLabelContainer={classes.removePadding}
              content={
                <Field
                  id={crmDefaultFields.typeId}
                  name={crmDefaultFields.typeId}
                  size="small"
                  label="Ticket Type"
                  suppressInlineLabel
                  component={DropDown}
                  labelName="name"
                  valueName="id"
                  allowEmpty
                  options={boardTypes}
                  disabled={
                    processing || !values[crmDefaultFields.defaultBoardId]
                  }
                />
              }
            />

            <FieldWrapper
              label="Sub Type"
              labelSize={3}
              contentSize={9}
              isRequired
              classNameLabelContainer={classes.removePadding}
              content={
                <Field
                  id={crmDefaultFields.subTypeId}
                  name={crmDefaultFields.subTypeId}
                  size="small"
                  label="Ticket SubType"
                  labelName="name"
                  valueName="id"
                  suppressInlineLabel
                  component={DropDown}
                  allowEmpty
                  options={boardSubTypes[values[crmDefaultFields.typeId]] || []}
                  disabled={processing || !values[crmDefaultFields.typeId]}
                />
              }
            />

            <FieldWrapper
              label="New Ticket Status"
              labelSize={3}
              contentSize={9}
              isRequired
              classNameLabelContainer={classes.removePadding}
              content={
                <Field
                  id={crmDefaultFields.openBoardStatusId}
                  name={crmDefaultFields.openBoardStatusId}
                  size="small"
                  label="New Ticket Status"
                  suppressInlineLabel
                  component={DropDown}
                  options={boardStatuses}
                  labelName="name"
                  valueName="id"
                  disabled={
                    processing || !values[crmDefaultFields.defaultBoardId]
                  }
                />
              }
            />

            <FieldWrapper
              label="Category"
              labelSize={3}
              contentSize={9}
              isRequired
              classNameLabelContainer={classes.removePadding}
              content={
                <Field
                  id={crmDefaultFields.ticketCategoryId}
                  name={crmDefaultFields.ticketCategoryId}
                  size="small"
                  label="Ticket Category"
                  suppressInlineLabel
                  labelName="name"
                  valueName="id"
                  component={DropDown}
                  options={ticketCategories}
                  disabled={processing}
                />
              }
            />

            <FieldWrapper
              label="Priority"
              labelSize={3}
              contentSize={9}
              isRequired
              classNameLabelContainer={classes.removePadding}
              content={
                <Field
                  id={crmDefaultFields.ticketPriority}
                  name={crmDefaultFields.ticketPriority}
                  size="small"
                  suppressInlineLabel
                  labelName="name"
                  valueName="id"
                  component={DropDown}
                  options={priorities}
                  disabled={processing}
                />
              }
            />

            <FieldWrapper
              label="Assignee"
              labelSize={3}
              contentSize={9}
              isRequired
              classNameLabelContainer={classes.removePadding}
              content={
                <Field
                  id="assigneeId"
                  name="assigneeId"
                  size="small"
                  suppressInlineLabel
                  component={DropDown}
                  options={assignees}
                  disabled={processing}
                />
              }
            />
          </div>

          <div className={sharedClasses.dialogActionsContainer}>
            <ReusableButton
              viewType="white"
              buttonType="simple"
              label="Submit"
              type="submit"
              disabled={processing}
              loading={processing}
            />
          </div>

          {openTicketLinkDialog && (
            <TicketLinkDialog
              onClose={handleDialogClose}
              link={link}
              open={openTicketLinkDialog}
              ticketId={ticketId}
              channelCreationResponse={undefined}
            />
          )}
        </form>
      )}
    />
  );
};

KaseyaCreateTicketForm.propTypes = {
  psaId: PropTypes.number.isRequired,
};

export default KaseyaCreateTicketForm;
