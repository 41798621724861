import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import homePageItems from '@components/HomePage/homePageItems';
import CategoryItem from '@components/HomePage/components/CategoryItem';

import { currentUserDataSelector } from '@store/selectors';
import { AUTOTASK_POD, CONNECTWISE_POD, HALO_POD } from '@constants/routes';
import localStorage from '@services/localStorage';
import localStorageConst from '@constants/localStorage';

import SetupWizard from '../SetupWizard';
import useStyles from './styles';

const HomePage = () => {
  const classes = useStyles();
  const [pageItems, setPageItems] = useState([]);
  const [openWizard, setOpenWizard] = useState(false);
  const { isPodPageOpened, podData } = useSelector(state => state.pod);
  const { push } = useHistory();
  const userData = useSelector(currentUserDataSelector);

  useEffect(() => {
    const allowedResources = localStorage.tryGetItem(
      localStorageConst.ALLOWED_RESOURCES,
    );
    if (allowedResources) {
      homePageItems.forEach(chapter => {
        chapter.items = chapter.items.filter(i =>
          allowedResources.includes(i.permission),
        );
      });
    }
    setPageItems(homePageItems);
  }, []);

  useEffect(() => {
    if (isPodPageOpened) {
      if (podData.type === 'connectwise') {
        push(
          // eslint-disable-next-line prefer-template
          CONNECTWISE_POD.replace(':crmId', podData.crmId) +
            `?ticketId=${podData.ticketId}&screen=${podData.screen}`,
        );
      }
      if (podData.type === 'halo') {
        push(
          // eslint-disable-next-line prefer-template
          HALO_POD.replace(':crmId', podData.crmId) +
            `?ticketId=${podData.ticketId}`,
        );
      }
      if (podData.type === 'autotask') {
        push(
          // eslint-disable-next-line prefer-template
          AUTOTASK_POD.replace(':crmId', podData.crmId) +
            `?entityid=${podData.ticketId}`,
        );
      }
    }
  }, [isPodPageOpened, podData, push]);

  useEffect(() => {
    if (userData && userData?.displayStartupWizard) {
      setOpenWizard(true);
    }
  }, [userData]);

  return (
    <div className={classes.container}>
      {pageItems.map(item => (
        <CategoryItem key={item.label} item={item} />
      ))}

      {/* {openWizard && userData && userData.displayStartupWizard && (
        <SetupWizard open={openWizard} setOpen={setOpenWizard} />
      )} */}
      {openWizard && <SetupWizard open={openWizard} setOpen={setOpenWizard} />}
    </div>
  );
};

export default HomePage;
