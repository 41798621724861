import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useAuthProvider, useTranslate } from 'react-admin';
import Loading from '@common/Loading/Loading';
import lock from '@assets/lock.png';
import Banner from '@common/Banners/Banner';
import storage from '@constants/localStorage';
import ResetPasswordForm from './ResetPasswordForm';

import useStyles from './styles';

const ResetPassword = ({ oobCode }) => {
  const classes = useStyles();

  const auth = useAuthProvider();
  const translate = useTranslate();
  const logoUrl = sessionStorage.getItem(storage.BASE_APP_LOGO);

  const [isResetPasswordCodeValid, setResetPasswordCodeValid] = useState(true);
  const [loading, setLoading] = useState(true);
  const [errorText, setErrorText] = useState('');

  useEffect(() => {
    auth
      .verifyResetPasswordCode(oobCode)
      .then(() => {
        setResetPasswordCodeValid(true);
        setLoading(false);
      })
      .catch(e => {
        setErrorText(e.message);
        setResetPasswordCodeValid(false);
        setLoading(false);
      });
    // there is not necessary to handle changing of obbCode, cz it won't change when page is opened
    // eslint-disable-line react-hooks/exhaustive-deps
  }, [auth, oobCode]);

  if (loading) {
    return <Loading />;
  }

  const resetPwdForm = <ResetPasswordForm token={oobCode} />;

  const errorMsg = (
    <div className={classes.font}>
      <div className={classes.bold}>
        {translate('pages.resetPassword.errorTitle')}
      </div>
      <div>{translate('pages.resetPassword.errors.unexpectedError')}</div>
      <pre>
        <code>{errorText}</code>
      </pre>
    </div>
  );

  return (
    <div className={classes.card}>
      <div className={classes.contentWrapper}>
        <img alt="logo" src={logoUrl} className={classes.logoStyle} />
        {isResetPasswordCodeValid ? resetPwdForm : errorMsg}
      </div>
      <Banner text="Reset password" icon={lock} />
    </div>
  );
};

ResetPassword.propTypes = {
  oobCode: PropTypes.string,
};

export default ResetPassword;
