import React, { useEffect } from 'react';

import appConfig from '@configs/appConfig';

import UnauthorizedProvider from '@ui/common/UnauthorizedProvider/UnauthorizedProvider';
import Loading from '@common/Loading/Loading';

const AutotaskPodWidget = () => {
  const openInTab = () => {
    window.location.replace(
      `${appConfig.podUrl}${window.location.hash.replace('#', '')}`,
    );
  };

  useEffect(() => {
    openInTab();
  }, []);

  return (
    <UnauthorizedProvider>
      <Loading />
    </UnauthorizedProvider>
  );
};

export default AutotaskPodWidget;
