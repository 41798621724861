import polyglotI18nProvider from 'ra-i18n-polyglot';
import { defaultLanguage } from '@constants/languages';
import { getLanguageFromStorage } from '../api';

const locales = {
  en: () => import('./translations/en').then(m => m.default),
  zh: () => import('./translations/zh').then(m => m.default),
  ua: () => import('./translations/ua').then(m => m.default),
};

const getDefaultLocale = () => getLanguageFromStorage() || defaultLanguage;

export default async () => {
  const defaultLocale = getDefaultLocale();
  const defaultMessages = await locales[defaultLocale]();

  return polyglotI18nProvider(
    locale => {
      if (locale === defaultLocale) {
        return defaultMessages;
      }

      return locales[locale]();
    },
    defaultLocale,
    { allowMissing: true },
  );
};
