import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ThunkGetClientPortalPusherSettings } from '@store/slices/clientPortalAdmin/thunks';
import { SelectClientPortalPusherSettings } from '@store/slices/clientPortalAdmin/selectors';

import ClientPortalChat from '@components/ClientPortalChat';
import ReusableButton from '@common/Button/Button';
import { ReactComponent as LockIcon } from '@assets/icons/lock.svg';
import ClientPortalNotifications from '@components/Layout/AppBar/UserMenu/ClientPortalNotifications/ClientPortalNotifications';
import Loading from '@common/Loading/Loading';

import useStyles from './styles';

const ClientChatsWrapper = ({ clientPortalChats }) => {
  const [loading, setLoading] = useState(true);
  const [selectedChat, setSelectedChat] = useState(undefined);

  const classes = useStyles();
  const dispatch = useDispatch();
  const pusherConfig = useSelector(SelectClientPortalPusherSettings);

  const content = useMemo(() => {
    switch (selectedChat) {
      case Number(selectedChat):
        return (
          <div>
            <div className={classes.clientChatActions}>
              <ReusableButton
                label="Back"
                onClick={() => {
                  setSelectedChat(undefined);
                }}
              />
            </div>
            <ClientPortalChat chatId={selectedChat} fromPodPage />
          </div>
        );
      default:
        return (
          <div className={classes.clientChatsList}>
            {clientPortalChats.length >= 1 &&
              clientPortalChats.map(i => (
                <div
                  key={i.Id}
                  className={classes.clientChatItem}
                  onClick={() => {
                    setSelectedChat(i.Id);
                  }}
                  role="presentation"
                >
                  <div className={classes.clientChatItemName}>
                    <LockIcon
                      style={{
                        visibility: i.IsClosed ? 'inherit' : 'hidden',
                        marginRight: '10px',
                      }}
                    />
                    <span>{i.Name}</span>
                  </div>
                  <div className={classes.link}>{`#${i.TicketId}`}</div>
                </div>
              ))}
          </div>
        );
    }
  }, [classes.clientChatItem, classes.link, clientPortalChats, selectedChat]);

  const onMount = useCallback(async () => {
    setLoading(true);
    await dispatch(ThunkGetClientPortalPusherSettings())
      .unwrap()
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    onMount();
  }, []);

  if (loading) return <Loading />;
  return (
    <>
      {content}
      {!!pusherConfig && !!Number(selectedChat) && (
        <ClientPortalNotifications
          pusherConfig={pusherConfig}
          selectedChat={selectedChat}
        />
      )}
    </>
  );
};

export default ClientChatsWrapper;
