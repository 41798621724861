import React from 'react';
import cx from 'classnames';
import ReusableButton from '@common/Button/Button';
import PropTypes from 'prop-types';
import useStyles from './styles';

const TestArea = ({ classNameWrapper, loading, onClick, testOutput }) => {
  const classes = useStyles({ testOutput });
  return (
    <div className={cx(classes.testArea, classNameWrapper)}>
      <ReusableButton
        size="md"
        viewType="orange"
        type="button"
        label="resources.buttons.test"
        classNameWrapper={classes.button}
        disabled={loading}
        onClick={onClick}
      />
      <div className={cx(classes.testOutput, classes.testDynamicArea)}>
        {testOutput?.message}
      </div>
    </div>
  );
};

TestArea.propTypes = {
  onClick: PropTypes.func.isRequired,
  testOutput: PropTypes.shape({
    isOk: PropTypes.bool,
    message: PropTypes.string,
  }),
  loading: PropTypes.bool.isRequired,
  classNameWrapper: PropTypes.string,
};

export default TestArea;
