import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNotify } from 'react-admin';

import { ThunkDeleteOptIn } from '@store/slices/optIn/thunks';
import { actions } from '@store/actions';

import DeleteIcon from '@assets/icons/delete.svg';

import { crmSourcesEnum, crmSourcesOptions } from '@constants/crmSources';
import BooleanAccessor from '@common/MuiVirtualizedTable/components/accessors/BooleanAccessor';
import ActionFieldItem from '@ui/components/common/ActionButtons/ActionFieldItem';
import ConfirmDialog from '@ui/components/common/ConfirmDialog/ConfirmDialog';
import Table from '@components/Table';

import { TableTypes } from '@components/Table/constants';
import useStyles from './styles';

const OptIn = () => {
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [processing, setProcessing] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const notify = useNotify();

  const handleDelete = item => {
    setProcessing(true);
    dispatch(ThunkDeleteOptIn({ id: item.id }))
      .unwrap()
      .then(id => {
        dispatch(actions.removeItemFromList({ id }));
        notify('Successfully deleted', 'success');
        setSelectedItem(null);
        setOpenDeleteDialog(false);
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  const columns = useMemo(
    () => [
      {
        name: 'Contact Name',
        key: 'contactName',
        sortable: true,
        searchable: true,
      },
      {
        name: 'Contact Phone',
        key: 'contactPhoneNumber',
        sortable: true,
        searchable: true,
        type: TableTypes.phoneNumber,
      },
      {
        name: 'CRM Type',
        key: 'crmType',
        sortable: true,
        searchable: true,
        type: TableTypes.dropdown,
        dropDownValues: crmSourcesOptions,
        filterByAutocomplete: 'crmType',
        isCustomAccessor: true,
        accessor: row => crmSourcesEnum[row.crmType],
      },
      {
        name: 'Allow messaging',
        key: 'allowMessaging',
        isCustomAccessor: true,
        width: 150,
        accessor: row => (
          <BooleanAccessor value={row.allowMessaging} useMultiColor />
        ),
      },
      {
        name: 'Actions',
        key: TableTypes.actions,
        type: TableTypes.actions,
        width: 100,
        accessor: record => (
          <>
            <ActionFieldItem
              icon={DeleteIcon}
              alt="delete"
              handler={() => {
                setOpenDeleteDialog(true);
                setSelectedItem(record);
              }}
              toolTip="Delete"
            />
          </>
        ),
      },
    ],
    [],
  );

  return (
    <div className={classes.container}>
      <Table
        columns={columns}
        resource="OptIn"
        defaultSort={{ fieldName: 'contactName', order: 'ASC' }}
      />

      {openDeleteDialog && (
        <ConfirmDialog
          open={openDeleteDialog}
          setOpen={setOpenDeleteDialog}
          onCancel={() => {
            setOpenDeleteDialog(false);
          }}
          onAccept={() => {
            handleDelete(selectedItem);
          }}
          content="Are you sure you want to delete this item?"
          title="Confirm action"
          loading={processing}
        />
      )}
    </div>
  );
};

export default OptIn;
