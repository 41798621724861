import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useNotify } from 'react-admin';

import crmSources from '@constants/crmSources';
import ConnectWiseCreateTicketForm from '@components/CreateTicket/components/ConnectWiseCreateTicketForm';
import AutotaskCreateTicketForm from '@components/CreateTicket/components/AutotaskCreateTicketForm';
import KaseyaCreateTicketForm from '@components/CreateTicket/components/KaseyaCreateTicketForm';
import { ThunkAttachTicketToChat } from '@store/slices/liveChats/thunks';
import { LIVE_CHAT } from '@constants/routes';

import UserMessages from '@components/LiveChat/components/UserMessages';
import useStyles from '../styles';

const CreateTicketFromLiveChatDialog = ({ match }) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const { chatId, psaType, psaId } = match.params;
  const { push } = useHistory();
  const notify = useNotify();

  const handleTicketAttach = async ({ ticketId, ticketLink }) => {
    await dispatch(
      ThunkAttachTicketToChat({
        chatId,
        payload: {
          ticketLink,
          ticketId,
        },
      }),
    ).then(() => {
      notify('Ticket created and attached to live chat', 'success');
      push(LIVE_CHAT);
    });
  };

  const form = useMemo(() => {
    switch (Number(psaType)) {
      case crmSources.ConnectWise.idx:
        return (
          <ConnectWiseCreateTicketForm
            psaId={psaId}
            ticketCreationSuccessCallback={handleTicketAttach}
          />
        );
      case crmSources.Autotask.idx:
        return <AutotaskCreateTicketForm psaId={psaId} />;
      case crmSources.Kaseya.idx:
        return <KaseyaCreateTicketForm psaId={psaId} />;
      default:
        return <></>;
    }
  }, [psaType]);

  return (
    <div className={classes.createTicketContainer}>
      <div className={classes.item} style={{ flex: '70%' }}>
        {form}
      </div>
      <div className={classes.item} style={{ flex: '30%' }}>
        <UserMessages chatId={chatId} />
      </div>
    </div>
  );
};

export default CreateTicketFromLiveChatDialog;
