import { useTranslate } from 'react-admin';

const useTranslation = resource => {
  const translate = useTranslate();
  const getTranslation = (src, prefix) =>
    translate(`resources.${resource}.${prefix}.${src}`);
  const getLabel = src => getTranslation(src, 'fields');
  const getText = src => getTranslation(src, 'deleteDialog');
  const getRaText = src => translate(`ra.${resource}.${src}`);
  return {
    getLabel,
    getText,
    getRaText,
  };
};

export default useTranslation;
