import React, { useState } from 'react';
import PropType from 'prop-types';
import { Avatar, Menu, MenuItem } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import { useNotify } from 'react-admin';

import normalizeDate from '@utils/getFormattedDate';
import { ThunkDeleteIncidentNote } from '@store/slices/incident/thunks';

import ConfirmDialog from '@ui/components/common/ConfirmDialog/ConfirmDialog';
import AddNotesDialog from '../dialogs/AddNotesDialog';

import useStyles from '../../styles';

const NoteItem = ({
  note,
  userName,
  userImage,
  id,
  noteDateTime,
  incidentId,
}) => {
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const dispatch = useDispatch();
  const notify = useNotify();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditClick = () => {
    setAnchorEl(null);
    setOpenEditDialog(true);
  };

  const handleDeleteClick = () => {
    setAnchorEl(null);
    setOpenDeleteDialog(true);
  };

  const handleDelete = () => {
    setAnchorEl(null);
    dispatch(ThunkDeleteIncidentNote(id))
      .unwrap()
      .then(() => {
        notify('Successfully delete', 'success');
      });
  };

  const classes = useStyles();
  return (
    <div className={classes.noteItem}>
      <div>
        <Avatar src={userImage} />
      </div>
      <div className={classes.noteItemText}>
        <div className={classes.noteItemUserName}>{userName}</div>
        <div>{note}</div>
        <div className={classes.noteItemDate}>
          {normalizeDate(noteDateTime)}
        </div>
      </div>
      <div className={classes.noteItemActions}>
        <MoreVert
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
        />
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleEditClick}>Edit</MenuItem>
          <MenuItem onClick={handleDeleteClick}>Delete</MenuItem>
        </Menu>
      </div>

      {openEditDialog && (
        <AddNotesDialog
          open={openEditDialog}
          setOpen={setOpenEditDialog}
          incidentId={incidentId}
          noteId={id}
          note={note}
        />
      )}

      {openDeleteDialog && (
        <ConfirmDialog
          open={openDeleteDialog}
          setOpen={setOpenDeleteDialog}
          onAccept={handleDelete}
          onCancel={() => {
            setOpenDeleteDialog(false);
          }}
          title="Confirm action"
          content="Are you sure you want to delete this note?"
        />
      )}
    </div>
  );
};

NoteItem.propTypes = {
  note: PropType.string,
  userName: PropType.string,
  userImage: PropType.string,
  id: PropType.number,
  noteDateTime: PropType.string,
  incidentId: PropType.number,
};
export default NoteItem;
