import React, { useCallback, useMemo, useState } from 'react';
import { Step, StepLabel, Stepper } from '@material-ui/core';
import PropTypes from 'prop-types';

import VerificationSettings from '@components/Settings/VerificationSettings/VerificationSettings';

import { steps, stepsLength } from '../constants/steps';

import StepperHeader from './StepperHeader';
import PsaDefaultSetup from '../steps/PsaDefaultSetup';
import UserSetup from '../steps/UserSetup';
import MessagingSetup from '../steps/MessagingSetup';
import InviteUsers from '../steps/InviteUsers';

import useStyles from '../styles';

const CustomStepper = ({ handleClose }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [moveForwardIsDisabled, setDisabledMoveForward] = useState(false);

  const classes = useStyles();

  const handleForward = useCallback(() => {
    if (activeStep === stepsLength - 1) {
      handleClose();
    } else {
      setActiveStep(activeStep + 1);
    }
  }, [activeStep, handleClose]);

  const handleBackward = useCallback(() => {
    setActiveStep(activeStep - 1);
  }, [activeStep]);

  const disableMoveForward = useCallback(() => {
    setDisabledMoveForward(true);
  }, []);

  const enableMoveForward = useCallback(() => {
    setDisabledMoveForward(false);
  }, []);

  const content = useMemo(() => {
    switch (activeStep) {
      case 0:
        return (
          <UserSetup
            disableMoveForward={disableMoveForward}
            enableMoveForward={enableMoveForward}
          />
        );
      case 1:
        return <PsaDefaultSetup handleForward={handleForward} />;
      case 2:
        return <MessagingSetup handleForward={handleForward} />;
      case 3:
        return <InviteUsers handleForward={handleForward} />;
      case 4:
        return (
          <VerificationSettings
            skipBorders
            customClass={classes.verificationSettingsWrapper}
          />
        );
      default:
        return <></>;
    }
  }, [
    activeStep,
    disableMoveForward,
    enableMoveForward,
    handleForward,
    classes.verificationSettingsWrapper,
  ]);

  return (
    <>
      <div className={classes.stepperContainer}>
        <Stepper activeStep={activeStep} className={classes.stepper}>
          {steps.map(({ label }) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <StepperHeader
          handleForward={handleForward}
          handleBackward={handleBackward}
          handleClose={handleClose}
          activeStep={activeStep}
          disableMoveForward={moveForwardIsDisabled}
        />
      </div>
      <div>{content}</div>
    </>
  );
};

CustomStepper.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default CustomStepper;
