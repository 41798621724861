import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { Field, Form, useForm } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { useNotify } from 'react-admin';
import _ from 'lodash';

import { ThunkCreateTicketCreationTemplate } from '@store/slices/ticketCreationTemplates/thunks';

import FieldWrapper from '@common/form/FieldWrapper';
import Input from '@components/Auth/Common/Input';
import ReusableButton from '@common/Button/Button';

import useSharedStyles from '@components/CrmTicket/sharedStyles';

const CreateTicketCreationTemplateDialog = ({ open, setOpen, psaType }) => {
  const [processing, setProcessing] = useState(false);

  const form = useForm();
  const dispatch = useDispatch();
  const notify = useNotify();
  const sharedClasses = useSharedStyles();

  const handleSaveClick = useCallback(
    async ({ templateName }) => {
      setProcessing(true);
      const { values } = form.getState();
      if (_.isEmpty(values)) {
        notify('Please fill at least one field to save template');
        return;
      }
      await dispatch(
        ThunkCreateTicketCreationTemplate({
          psaType,
          payload: { name: templateName, data: values },
        }),
      )
        .unwrap()
        .then(() => {
          notify('Template created', 'success');
          setOpen(false);
        })
        .finally(() => {
          setProcessing(false);
        });
    },
    [setOpen, dispatch, notify, psaType, form],
  );

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogTitle>Create Ticket Template</DialogTitle>
      <DialogContent>
        <Form
          onSubmit={handleSaveClick}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <FieldWrapper
                label="Template Name"
                labelSize={12}
                contentSize={12}
                fullWidth
                content={
                  <Field
                    name="templateName"
                    id="templateName"
                    render={Input}
                    disabled={processing}
                  />
                }
              />

              <div className={sharedClasses.dialogActionsContainer}>
                <ReusableButton
                  viewType="white"
                  buttonType="simple"
                  label="Close"
                  onClick={() => {
                    setOpen(false);
                  }}
                />

                <ReusableButton
                  viewType="white"
                  buttonType="simple"
                  label="Save"
                  type="submit"
                />
              </div>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  );
};

CreateTicketCreationTemplateDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  psaType: PropTypes.string.isRequired,
};

export default CreateTicketCreationTemplateDialog;
