import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'start',
    padding: '0px',
    [theme.breakpoints.up('sm')]: {
      margin: '0 80px',
    },
    overflowY: 'auto',
  },
  defaultWidth: {
    width: 'inherit',
    margin: 'unset',
  },
  spacing: {
    margin: '20px',
  },
  block: {
    textAlign: 'center',
    margin: '25px 0',
    width: '95%',
  },
  buttonsWrapper: {
    marginTop: '0.875rem',
  },
  buttonCancel: {
    [theme.breakpoints.up('sm')]: {
      marginRight: '0.4rem',
    },
  },
  buttonStyle: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  iconBlack: {
    fill: theme.palette.info.main,
  },
  inputLabel: {
    fontWeight: 'bold',
    marginTop: '0.5rem',
    margin: '0.5rem 0',
    textAlign: 'left',
    fontSize: '0.875rem',
  },
  inputWrapper: {
    marginBottom: '0.625rem',
  },
  buttonWrapper: {
    margin: '0',
    padding: '0',
    border: 'none',
    justifyContent: 'flex-start',
    width: 'max-content',
    backgroundColor: 'transparent',

    '&:hover': {
      backgroundColor: 'transparent',
    },

    '&:last-child': {
      marginLeft: '0',
    },
    '& span': {
      textTransform: 'uppercase',
    },
  },
  header: {
    textAlign: 'center',
    background: '#EC6A4E',
    boxShadow: '0,10,15,0 #AAADCB26',
    color: '#FFFFFF',
    borderRadius: '5px 5px 0 0',
    marginBottom: '5px',
    minHeight: '25px',

    fontStyle: 'normal',
    paddingTop: '4px',
  },
  root: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: '0 0 5px 0',
    width: '100%',
  },
  chip: {
    margin: '8px 3px',
  },
  mainBlock: {
    textAlign: 'left',
  },
  label: {
    fontSize: '14px',
    margin: '0.5rem 0',
    fontWeight: 'bold',
  },
  dialogInfo: {
    marginBottom: '2rem',
    textAlign: 'center',
  },
  dialogWidth: {
    width: '30rem',
  },
  refreshIcon: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  form: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '0px 70px',
  },
  dateWrapper: { textAlign: 'left' },
}));

export default useStyles;
