import React from 'react';
import PropTypes from 'prop-types';
import { Button, CreateButton, ExportButton, useTranslate } from 'react-admin';
import cx from 'classnames';
import { CircularProgress } from '@material-ui/core';
import useStyles from './styles';
import sanitizeProps from './helpers';

const buttons = {
  simple: Button,
  export: ExportButton,
  create: CreateButton,
};

const ReusableButton = ({
  viewType = 'white',
  size,
  label = '',
  buttonType = 'simple',
  classNameWrapper,
  children,
  loading = false,
  textSize = 'normal',
  ...rest
}) => {
  const classes = useStyles();

  const translate = useTranslate();

  const buttonClasses = {
    white: classes.whiteButton,
    black: classes.blackButton,
    orange: classes.orangeButton,
    red: classes.redButton,
    blue: classes.blueButton,
  };

  const buttonSizeClasses = {
    sm: classes.smallButton,
    md: classes.mediumButton,
    lg: classes.largeButton,
    xl: classes.bigButton,
  };

  const textSizeClasses = {
    normal: classes.normalText,
    mini: classes.miniText,
  };

  const ButtonComponent = buttons[buttonType];

  return (
    <ButtonComponent
      {...sanitizeProps(rest)}
      label={null}
      className={cx(
        classes.button,
        classes.buttonWrapper,
        buttonClasses[viewType],
        buttonSizeClasses[size],
        textSizeClasses[textSize],
        classNameWrapper,
      )}
      icon={buttonType === 'export' ? children : null}
    >
      <>
        {loading && <CircularProgress size={18} thickness={2} />}
        {children || translate(label)}
      </>
    </ButtonComponent>
  );
};

ReusableButton.propTypes = {
  viewType: PropTypes.oneOf(['white', 'black', 'orange', 'red', 'blue']),
  size: PropTypes.oneOf(['sm', 'md', 'xl', 'lg']),
  label: PropTypes.string,
  buttonType: PropTypes.oneOf(['simple', 'create', 'export']),
  classNameWrapper: PropTypes.string,
  children: PropTypes.node,
  loading: PropTypes.bool,
  textSize: PropTypes.oneOf(['normal', 'mini']),
};

export default ReusableButton;
