import React, { useState } from 'react';
import { useTranslate, useNotify } from 'react-admin';
import Loading from '@common/Loading/Loading';
import { useDispatch, useSelector } from 'react-redux';
import useAsync from '@services/api/common/useAsync';
import ConfirmDialog from '@common/ConfirmDialog/ConfirmDialog';

import { pricePlanInfo } from '@store/selectors';
import { actions } from '@store/actions';

import useStyles from './styles';

import PlanToggleButton from '../PlanToggleButton/index';
import baseColors from '../baseColors';
import { getPlans, scaleUp } from '../../../helpers';
import planStatuses from '../planStatuses';

const PlanToggleButtonGroup = () => {
  const plan = useSelector(pricePlanInfo);
  const [data, setData] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [loading, setLoading] = useState(false);
  const loadingPlans = useAsync(getPlans, setData);
  const classes = useStyles();
  const translate = useTranslate();
  const notify = useNotify();
  const dispatch = useDispatch();

  const colours = [...new Set(data.map(i => i.name))].map((item, index) => ({
    name: item,
    gamma: baseColors[index],
  }));

  const getPlanStatus = (selPlan, currentPlan) => {
    if (selPlan.isDemoSubscription) return planStatuses.deny;
    if (selPlan.id === currentPlan.id) return planStatuses.selected;

    return planStatuses.allow;
    // return selPlan.basePriceCents > currentPlan.basePriceCents
    //   ? planStatuses.deny
    //   : planStatuses.allow;
  };

  const handleSelection = id => {
    if (getPlanStatus(plan, id) === planStatuses.allow) {
      setSelectedPlan(id);
    }
  };

  const onSubmit = () => {
    setLoading(true);
    scaleUp(selectedPlan.id)
      .then(() => dispatch(actions.setPricePlan(selectedPlan)))
      .catch(() => {
        notify('Unable to scale plan', 'error');
      })
      .finally(() => {
        setLoading(false);
        setSelectedPlan(null);
      });
  };

  if (loadingPlans) {
    return <Loading />;
  }

  return (
    <div className={classes.container}>
      <div className={classes.groupHeader}>
        {translate('resources.pricingPlan.chargeMonthly')}
      </div>
      <div className={classes.cardBlock}>
        {data.map(option => (
          <PlanToggleButton
            key={option.id}
            {...option}
            value={getPlanStatus(plan, option)}
            onChange={handleSelection}
            palette={colours.find(i => i.name === option.name).gamma}
          />
        ))}
      </div>
      <ConfirmDialog
        open={!!selectedPlan}
        setOpen={setSelectedPlan}
        onAccept={onSubmit}
        title={translate('pages.pricingPlan.changePlan')}
        content={translate('pages.pricingPlan.cannotUndo')}
        loading={loading}
      />
    </div>
  );
};

export default PlanToggleButtonGroup;
