import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Tooltip } from '@material-ui/core';
import { useNotify } from 'react-admin';

import {
  ThunkCloneMessagingTemplate,
  ThunkDeleteMessagingTemplate,
  ThunkGetMessagingTemplates,
} from '@store/slices/messaging/thunks';
import { MessagingTemplatesSelector } from '@store/slices/messaging';

import { ReactComponent as DeleteIcon } from '@assets/icons/delete.svg';
import { ReactComponent as EditIcon } from '@assets/icons/edit.svg';
import { ReactComponent as CloneIcon } from '@assets/icons/copy_blue.svg';

import ReusableButton from '@ui/components/common/Button/Button';
import Loading from '@ui/components/common/Loading/Loading';
import SimpleTable from '@ui/components/Triggers/pages/Incident/components/SimpleTable';

import ConfirmDialog from '@ui/components/common/ConfirmDialog/ConfirmDialog';
import OptInForm from '@components/Messaging/MessagingTemplates/components/OptInForm';
import TemplateDialog from '@components/Messaging/MessagingTemplates/dialogs/TemplateDialog';
import ResourcesForm from '@components/Messaging/MessagingTemplates/components/ResourcesForm';
import AddMessagingTemplate from './dialogs/AddMessagingTemplate';

import useStyles from './styles';

const MessagingTemplates = () => {
  const [loading, setLoading] = useState(true);
  const [openAddTemplateDialog, setOpenAddTemplateDialog] = useState(false);
  const [openDeleteTemplateDialog, setOpenDeleteTemplateDialog] = useState(
    false,
  );

  const [selectedItem, setSelectedItem] = useState(undefined);

  const [optInFormOpen, setOptInFormOpen] = useState(false);
  const [resourceFormOpen, setResourceFormOpen] = useState(false);

  const classes = useStyles();
  const dispatch = useDispatch();
  const notify = useNotify();

  const handleDelete = useCallback(
    ({ id }) => {
      dispatch(ThunkDeleteMessagingTemplate(id))
        .unwrap()
        .then(() => {
          notify('Successfully Deleted', 'success');
          setOpenDeleteTemplateDialog(false);
          setSelectedItem(undefined);
        });
    },
    [dispatch, notify],
  );

  const handleClone = useCallback(
    ({ id }) => {
      dispatch(ThunkCloneMessagingTemplate({ templateId: id }))
        .unwrap()
        .then(() => {
          notify('Successfully Cloned', 'success');
          setSelectedItem(undefined);
        });
    },
    [dispatch, notify],
  );

  const columns = [
    {
      label: 'Subject',
      value: 'subject',
    },
    {
      label: 'Description',
      value: 'description',
    },
    {
      label: 'Actions',
      handler: item => (
        <div className={classes.tableActions}>
          <Tooltip title="Edit" style={{ cursor: 'pointer' }}>
            <EditIcon
              onClick={() => {
                setSelectedItem(item);
                setOpenAddTemplateDialog(true);
              }}
            />
          </Tooltip>

          <Tooltip title="Clone" style={{ cursor: 'pointer' }}>
            <CloneIcon
              onClick={() => {
                setSelectedItem(item);
                handleClone(item);
              }}
            />
          </Tooltip>

          <Tooltip title="Delete" style={{ cursor: 'pointer' }}>
            <DeleteIcon
              onClick={() => {
                setSelectedItem(item);
                setOpenDeleteTemplateDialog(true);
              }}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  const onMount = useCallback(async () => {
    setLoading(true);
    await dispatch(ThunkGetMessagingTemplates());
    setLoading(false);
  }, [dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  if (loading) return <Loading />;
  return (
    <div className={classes.container}>
      <div className={classes.templatesTableActions}>
        <ReusableButton
          label="Opt-in template"
          onClick={() => {
            setOptInFormOpen(true);
          }}
        />

        <ReusableButton
          label="Resource template"
          onClick={() => {
            setResourceFormOpen(true);
          }}
        />

        <ReusableButton
          label="Add Template"
          onClick={() => {
            setOpenAddTemplateDialog(true);
          }}
        />
      </div>

      <SimpleTable columns={columns} selector={MessagingTemplatesSelector} />

      {optInFormOpen && (
        <TemplateDialog
          open={optInFormOpen}
          setOpen={setOptInFormOpen}
          content={<OptInForm onSubmit={() => setOptInFormOpen(false)} />}
          title="Opt-in template form"
        />
      )}

      {resourceFormOpen && (
        <TemplateDialog
          open={resourceFormOpen}
          setOpen={setResourceFormOpen}
          content={
            <ResourcesForm onSubmit={() => setResourceFormOpen(false)} />
          }
          title="Assigned resource template form"
        />
      )}

      {openAddTemplateDialog && (
        <AddMessagingTemplate
          open={openAddTemplateDialog}
          setOpen={setOpenAddTemplateDialog}
          item={selectedItem}
          setItem={setSelectedItem}
        />
      )}

      {openDeleteTemplateDialog && (
        <ConfirmDialog
          open={openDeleteTemplateDialog}
          setOpen={setOpenDeleteTemplateDialog}
          onCancel={() => {
            setOpenDeleteTemplateDialog(false);
            setSelectedItem(undefined);
          }}
          onAccept={() => {
            handleDelete(selectedItem);
          }}
          title="Confirm Action"
          content="Are you sure you want to delete this template?"
        />
      )}
    </div>
  );
};

MessagingTemplates.propTypes = {};

export default MessagingTemplates;
