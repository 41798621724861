import React from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import { useTranslate } from 'react-admin';
import { Field, Form } from 'react-final-form';
import Loading from '@common/Loading/Loading';
import FieldWrapper from '@common/form/FieldWrapper';
import Input from '@common/Input/Input';
import ReusableButton from '@common/Button/Button';
import Paper from '@common/Paper/Paper';
import { webHookSettings } from '@store/selectors/notifications';
import { actions } from '@store/actions';
import iconArrow from '@assets/icons/arrowGrey.svg';
import { basePermissions } from '@constants/permissions';
import useDisabled from '@components/Hooks/useDisabled';
import DropDown from '@components/Auth/Common/DropDown';
import httpRequestMethods from '@constants/httpRequestMethods';
import TestArea from '@common/TestArea/TestArea';
import useTest from '@components/Hooks/useTest';
import {
  getWebHookSettings,
  postWebHookSettings,
  testWebHook,
} from './helpers';

import useStyles from './styles';
import useTicketSetting from '../../hooks/useTicketSetting';
import { webHookSettingsValidator } from '../../validators';

const WebHookDMSettings = ({ match }) => {
  const classes = useStyles();
  const translate = useTranslate();

  const storedData = useSelector(webHookSettings);

  const { submit, loading, setLoading, handleBack, data } = useTicketSetting({
    storedData,
    paramsId: match.params.id,
    action: actions.saveWebHookSetting,
    tileKey: 'isWebHook',
    apiPostRequest: postWebHookSettings,
    apiGetSetting: getWebHookSettings,
  });

  const { handleTestClick, testOutput } = useTest({
    setLoading,
    apiRequest: testWebHook,
  });

  const { editMode, disabled } = useDisabled(
    loading,
    'notificationSettings',
    basePermissions.update,
  );

  const dataLoaded = typeof data.id !== 'undefined';

  return dataLoaded ? (
    <Paper>
      <Form
        onSubmit={submit}
        validate={webHookSettingsValidator}
        initialValues={data}
        render={({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit} noValidate>
            <div>
              <FieldWrapper
                label="Url"
                labelSize={3}
                content={
                  <Field
                    id="url"
                    name="url"
                    styleType="main"
                    inputView="text"
                    fullWidth
                    component={Input}
                    disabled={disabled}
                  />
                }
              />
              <FieldWrapper
                label="Request type"
                labelSize={3}
                content={
                  <Field
                    id="httpRequestMethod"
                    name="httpRequestMethod"
                    options={httpRequestMethods}
                    component={DropDown}
                    disabled={disabled}
                  />
                }
              />
              {/* <FieldWrapper */}
              {/*  label="Payload" */}
              {/*  labelSize={3} */}
              {/*  content={ */}
              {/*    <div className={classes.ticketSubject}> */}
              {/*      <Field */}
              {/*        id="payload" */}
              {/*        name="payload" */}
              {/*        styleType="main" */}
              {/*        inputView="text" */}
              {/*        fullWidth */}
              {/*        component={Input} */}
              {/*        disabled={disabled} */}
              {/*      /> */}
              {/*    </div> */}
              {/*  } */}
              {/* /> */}
              {/* <FieldWrapper */}
              {/*  label="Recovery Payload" */}
              {/*  labelSize={3} */}
              {/*  content={ */}
              {/*    <div className={classes.ticketSubject}> */}
              {/*      <Field */}
              {/*        id="recoveryPayload" */}
              {/*        name="recoveryPayload" */}
              {/*        styleType="main" */}
              {/*        inputView="text" */}
              {/*        fullWidth */}
              {/*        component={Input} */}
              {/*        disabled={disabled} */}
              {/*      /> */}
              {/*    </div> */}
              {/*  } */}
              {/* /> */}
              <TestArea
                onClick={() =>
                  handleTestClick({
                    url: form.getFieldState('url').value,
                    method: form.getFieldState('httpRequestMethod').value,
                  })
                }
                loading={loading}
                testOutput={testOutput}
              />
            </div>
            <div className={classes.buttonWrapper}>
              <ReusableButton
                size="md"
                type="button"
                classNameWrapper={classes.button}
                disabled={loading}
                onClick={handleBack}
              >
                <>
                  <img src={iconArrow} alt="iconArrow" />
                  {translate('resources.buttons.back')}
                </>
              </ReusableButton>
              {editMode && (
                <ReusableButton
                  size="md"
                  viewType="black"
                  type="submit"
                  classNameWrapper={classes.button}
                  disabled={loading}
                  loading={loading}
                >
                  {translate('resources.billing.updateButton')}
                </ReusableButton>
              )}
            </div>
          </form>
        )}
      />
    </Paper>
  ) : (
    <Loading />
  );
};

webHookSettings.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.string }),
  }),
};

export default WebHookDMSettings;
