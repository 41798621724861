/* eslint-disable no-restricted-syntax,import/no-named-default */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TextField, useNotify, useRedirect } from 'react-admin';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  TextField as TField,
  Grid,
  Paper,
} from '@material-ui/core';

import { mailBodyView } from '@components/Letter/params';
import TabPanel from '@common/TabPanel/TabPanel';
import {
  getClearName,
  getSelectionSource,
  oneClickElements,
} from '@constants/selectionSource';
import eventBus from '@services/eventBus';
import CustomRichTextField from '@common/RichTetField';
import { regionSelectionEvents as events } from '@constants/events';
import { actions } from '@store/actions';
import ReusableButton from '@common/Button/Button';
import Loading from '@common/Loading/Loading';
import useCurrentBody from '@common/hooks/useCurrentBody';
import getItemName from '@components/Helpers/fileNameHelpers';
import SimpleDialog from '@common/Dialog/SimpleDialog';
import {
  selectedExpressionNames,
  selectedViewType,
} from '@store/selectors/pasers';
import getAttachmentBody from './attachments/attachmentsProcessor';
import { getOnlyLetterNumberText, getRegexExpression } from '../helpers';
import Metadata from './Metadata';
import Attachments from './attachments/Attachments';
import {
  displayAsHtml,
  doesTypeSupports,
  prepareHeaders,
} from './attachments/helper';

import { sourceOptions } from '../DataExpression/options';
import {
  commonAncestor,
  getAttachmentFileName,
  hasSameFileTypes,
  getBadge,
  getCaretCharacterOffsetWithin,
  getDomPath,
  getLetterDetails,
  getUnclosedTags,
  prepareValue,
  uuidv4,
  getCustomFields,
  postAddCustomField,
  getHeaderName,
} from './helpers';
import './styles.css';
import useStyles from './styles';

const textViewType = 1;
const htmlTagRegex = /<([a-z]+)(?=[\s>/])(?:[^>=]|='[^']*'|="[^"]*"|=[^'"\s]*)*?\s?\/?>/g;

const MailView = ({
  bodyIndex,
  body,
  htmlViewerId,
  onMouseDown,
  onMouseUp,
  key,
  useHtml,
}) => {
  const classes = useStyles();
  const currentBody = useCurrentBody(body);

  return (
    <Paper className={classes.mailBody} key={key}>
      {[0, 3].includes(bodyIndex) || useHtml ? (
        // // Another way to display email HTML (could be useful in case of issues)
        // <div
        //     dangerouslySetInnerHTML={{__html: body.body}}
        // />
        <CustomRichTextField
          id={htmlViewerId}
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
          onFocus={() => null}
          source="body"
          record={currentBody}
          component="div"
          className={classes.textStyle}
        />
      ) : (
        <TextField
          id={htmlViewerId}
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
          onFocus={() => null}
          source="body"
          record={currentBody}
          component="pre"
          className={cx(classes.textStyle, classes.jsonTextStyle)}
        />
      )}
    </Paper>
  );
};

const HtmlAreaId = 'displayEmailElement';

const DisplayEmail = ({
  letterId,
  mailBoxId,
  setCurrentLetter,
  selectableMode,
  setSelectableSelMode,
  current,
}) => {
  const htmlViewerId = 'HtmlViewer_id';
  const amIdAttr = 'alert-manager-id';

  const classes = useStyles();
  const notify = useNotify();
  const redirect = useRedirect();
  const dispatch = useDispatch();
  const [modalText, setModalText] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [regexpBuilderResponse, setRegexpBuilderResponse] = useState({});
  const [dataViewKey, setDataViewKey] = useState(Math.random());
  const [selectedElementBody, setSelectedElementBody] = useState([]);
  const [data, setData] = useState({});
  const [disableBack, setDisableBack] = useState(false);
  const [metaDataKey, setMetaDataKey] = useState(Math.random());
  const warningPageIndexes = [];
  const [displayWarning, setDisplayWarning] = useState(false);
  const [customFields, setCustomFields] = useState([]);

  const selectedExpressions = useSelector(selectedExpressionNames);
  const viewType = useSelector(selectedViewType);

  useEffect(() => {
    getLetterDetails(letterId)
      .then(response => {
        setData(response);
        dispatch(actions.setViewType(0));
      })
      .catch(error => {
        notify(error.message, 'warning');
        redirect(`/mailbox/${mailBoxId}/documents`);
      });
  }, [dispatch, notify, mailBoxId, redirect, letterId]);

  const mapToFields = useCallback(
    fields => fields.models.map(i => ({ label: i.name, value: i.value })),
    [],
  );

  useEffect(() => {
    getCustomFields()
      .then(d => {
        setCustomFields(mapToFields(d));
      })
      .catch(error => notify(error.message, 'warning'));
  }, [mapToFields, notify]);

  const clearSelectionHandler = () => {
    setSelectedElementBody([]);
    setDataViewKey(Math.random());
    setMetaDataKey(Math.random());
  };

  useEffect(() => {
    eventBus.on(events.ClearSelection, clearSelectionHandler);
    return () => {
      eventBus.remove(events.ClearSelection, clearSelectionHandler);
    };
  }, [current]);

  const dataLoaded = data && data.letterContent;
  if (dataLoaded) {
    setCurrentLetter(data.letterContent);
  }

  const headers = useMemo(() => prepareHeaders(data?.letterContent?.headers), [
    data?.letterContent?.headers,
  ]);

  const getBody = bodyView => {
    if (!dataLoaded) {
      return { body: '' };
    }
    let content;
    switch (bodyView) {
      case mailBodyView[0].value:
        if (!data.letterContent.htmlBody) {
          content = '';
        } else {
          content = data.letterContent.htmlBody;
        }
        break;
      case mailBodyView[1].value:
        content = data.letterContent.textBody;
        break;
      case mailBodyView[2].value:
        content = JSON.stringify(data.letterContent, null, 2);
        break;
      case mailBodyView[3].value:
        content = headers;
        break;
      default:
        content = '';
    }
    return { body: content };
  };

  const onMouseOver = event => {
    if (selectableMode && viewType !== textViewType) {
      const element = document.elementFromPoint(
        event.pageX - window.pageXOffset,
        event.pageY - window.pageYOffset,
      );

      if (element.nodeName === 'TD' || element.parentNode?.nodeName === 'TD') {
        try {
          if (element.classList) {
            element.classList.add('elementHovered');
          } else {
            element.className += ' elementHovered';
          }
          element.onmouseout = () => element.classList.remove('elementHovered');
        } catch (e) {
          console.log(e);
        }
      }
    }
  };

  const onMouseDown = event => {
    if (selectableMode) {
      const newElementId = `Badgificator-${uuidv4()}`;
      const element = document.elementFromPoint(
        event.pageX - window.pageXOffset,
        event.pageY - window.pageYOffset,
      );
      if (!element) {
        notify('Unable to define element', 'warning');
        return;
      }
      element.setAttribute(amIdAttr, newElementId);

      const newRecord = {
        id: newElementId,
        body: element.innerHTML,
      };
      setSelectedElementBody([...selectedElementBody, newRecord]);
    }
  };

  const getRequestBody = async (source, attName) => {
    switch (source.value) {
      case 'TextBody':
        return data.letterContent.textBody;
      case 'From':
        return data.letterContent.from;
      case 'FromName':
        return data.letterContent.fromName;
      case 'Subject':
        return data.letterContent.subject;
      case 'HtmlBody':
        return data.letterContent.htmlBody;
      case 'To':
        return data.letterContent.to;
      case 'Date':
        return data.letterContent.date;
      case 'Attachment':
        return getAttachmentBody(
          data.letterContent.attachments.find(p => p.name === attName),
        ).body;
      case 'Headers':
        return prepareHeaders(data.letterContent.headers);
      default:
        return data.letterContent.htmlBody;
    }
  };

  const escapeCharacters = value =>
    value.replace(/[.*+?^${}()|[\]\\/]/g, '\\$&');

  const onDateTimeMouseUp = event => {
    if (!selectableMode) {
      return;
    }
    const el = event.target;
    el.classList.add('selectedBadge');
    const requestData = {
      sourceType: 'Date',
      value: el.innerText,
      path: [],
      start: 0,
      source: data.letterContent.date,
    };
    // get regexp data from backEnd and store to state
    setDisableBack(true);
    getRegexExpression(requestData)
      .then(resp => {
        setRegexpBuilderResponse({
          sourceType: requestData.sourceType,
          expression: resp.expression,
          elementPath: resp.tableInformation?.elementPath,
          headerContent: resp.tableInformation?.headerContent,
          headerPosition: resp.tableInformation?.headerPosition,
        });
        setModalText(getOnlyLetterNumberText(resp.name));
        setModalOpen(true);
      })
      .catch(e => {
        notify(e.message, 'error');
      })
      .finally(() => {
        setDisableBack(false);
        setSelectableSelMode(false);
      });
  };

  const onMouseUp = async bodyView => {
    if (!selectableMode) {
      return;
    }

    const selection = document.getSelection();

    // The document/window.getSelection method works differently in Google Chrome, Safari and Internet Explorer than in Firefox and Opera
    if (navigator.userAgent.indexOf('Firefox') > 0) {
      selection.baseNode = selection.anchorNode;
      selection.extentNode = selection.anchorNode;
    }

    // get ancestor of selected nodes (start, end)
    const element = commonAncestor(
      selection.baseNode.parentElement,
      selection.extentNode.parentElement,
    );

    const elementName = element?.nodeName;
    const parentElementName = element?.parentElement?.nodeName;
    const parentElementContentEqualCurrent =
      element?.innerText &&
      element?.innerText === element?.parentElement?.innerText;
    const elementRole = element?.attributes['am-element-role']?.value;
    const clickMetadataItem = oneClickElements.includes(elementRole);

    if (
      elementName !== 'TD' &&
      elementName !== 'TH' &&
      !clickMetadataItem &&
      (!selection || selection.toString().length === 0) &&
      !(parentElementName === 'TD' && parentElementContentEqualCurrent)
    ) {
      return;
    }

    let startPos = 0;
    let selectedText = '';
    let content = null;

    // process click on the table cell or metadata
    if (
      (elementName === 'TD' ||
        elementName === 'TH' ||
        clickMetadataItem ||
        (parentElementName === 'TD' && parentElementContentEqualCurrent)) &&
      (!selection || selection.toString().length === 0)
    ) {
      // count inner tags in the element
      const innerTagsLength =
        element.innerHTML.match(htmlTagRegex)?.length || 0;
      if (innerTagsLength > 20) {
        notify('Selected text contains too much inner elements', 'error');
        setSelectableSelMode(false);
        return;
      }

      selectedText = element.innerText;
      content = document.createElement('div');
      const text = document.createTextNode(selectedText);
      content.appendChild(text);
    } else {
      const selection2 = window.getSelection().getRangeAt(0);
      const fragment = selection2.cloneContents();
      content = document.createElement('div');
      content.appendChild(fragment);
      // calculate correct offset of cursor
      startPos = getCaretCharacterOffsetWithin(element);
      selectedText = selection.toString();
    }

    // prepare and display selected text in a badge
    const selectedValue = prepareValue(content.innerHTML);

    // try to replace selected text with the badge
    const newInnerHTML =
      element.innerHTML.substring(0, startPos) +
      element.innerHTML
        .substring(startPos)
        .replace(
          selectedValue.trim(),
          getUnclosedTags(selectedValue) + getBadge(selectedText),
        );
    if (newInnerHTML === element.innerHTML) {
      const newSelectedText = escapeCharacters(selectedValue)
        .replace('\n', '')
        .replace(/(\\.|.)/gs, '(?>(?:<[^<]*>)*(?:\\s)*)+$1');

      element.innerHTML =
        element.innerHTML.substring(0, startPos) +
        element.innerHTML
          .substring(startPos)
          .replace(
            new RegExp(newSelectedText),
            getUnclosedTags(selectedValue) + getBadge(selectedText),
          );
    } else {
      element.innerHTML = newInnerHTML;
    }

    const selectionSource = getSelectionSource(element.className, bodyView);
    const isHeaders = selectionSource === sourceOptions[13];
    const preferredName = clickMetadataItem
      ? getClearName(elementRole)
      : isHeaders
      ? getHeaderName(element)
      : null;
    const path = isHeaders
      ? [getHeaderName(element)]
      : getDomPath(element, htmlViewerId);
    const source = isHeaders
      ? element.innerText
      : await getRequestBody(selectionSource, getAttachmentFileName(bodyView));
    const requestData = {
      sourceType: selectionSource.value,
      value: prepareValue(content.innerHTML),
      path,
      start: startPos,
      source,
      preferredName,
    };

    // get regexp data from backEnd and store to state
    setDisableBack(true);
    getRegexExpression(requestData)
      .then(resp => {
        setRegexpBuilderResponse({
          sourceType: requestData.sourceType,
          expression: resp.expression,
          elementPath: resp.tableInformation?.elementPath,
          headerContent: resp.tableInformation?.headerContent,
          headerPosition: resp.tableInformation?.headerPosition,
          attachmentFileName: getAttachmentFileName(bodyView),
          useFullAttachmentName: hasSameFileTypes(
            bodyView,
            data?.letterContent?.attachments,
          ),
          regexOptions: resp.regexOptions,
        });
        setModalText(getOnlyLetterNumberText(resp.name));
        setModalOpen(true);
      })
      .catch(e => {
        notify(e.message, 'error');
      })
      .finally(() => {
        setDisableBack(false);
        setSelectableSelMode(false);
      });
  };

  const mailViewProps = { onMouseOver, onMouseDown, htmlViewerId };
  const tabs = mailBodyView.map((item, index) => ({
    label: item.label,
    value: (
      <MailView
        {...mailViewProps}
        body={getBody(item.value)}
        bodyIndex={index}
        onMouseUp={() => onMouseUp(item.value)}
        key={dataViewKey}
      />
    ),
  }));

  // Show tabs with attachments
  if (data.letterContent?.attachments?.length) {
    data.letterContent.attachments.forEach(e => {
      if (doesTypeSupports(e.name)) {
        if (e.name.endsWith('.pdf')) {
          warningPageIndexes.push(tabs.length);
        }
        const item = {
          label: getItemName(e.name),
          value: (
            <MailView
              {...mailViewProps}
              body={getAttachmentBody(e)}
              bodyIndex={tabs.length + 1}
              onMouseUp={() => onMouseUp(`attachment_${e.name}`)}
              key={dataViewKey}
              useHtml={displayAsHtml(e.name)}
            />
          ),
        };
        tabs.push(item);
      }
    });
  }

  const triggerEvent = () => {
    setModalOpen(false);
    const object = {
      ...regexpBuilderResponse,
      expressionText: modalText,
      expression: regexpBuilderResponse.expression.replace('{0}', modalText),
    };
    eventBus.dispatch(events.Selection, object);
    setModalText('');
  };

  const handleEnterKeyPressed = event => {
    if (event.keyCode === 13 && event.target.value) {
      triggerEvent();
    }
  };

  // prevent add spaces and prior digit to the group name
  const handleModalTextEntered = event =>
    setModalText(getOnlyLetterNumberText(event.target.value));

  const handleModalConfirmed = () => {
    if (!modalText) {
      return;
    }
    if (!customFields.find(f => f.value === modalText)) {
      postAddCustomField(modalText)
        .then(() => {
          getCustomFields()
            .then(d => {
              setCustomFields(mapToFields(d));
            })
            .catch(error => notify(error.message, 'warning'));
        })
        .catch(error => notify(error.message, 'warning'));
    }
    triggerEvent();
  };

  const handleValueChanged = newValue => {
    if (warningPageIndexes.includes(newValue)) {
      setDisplayWarning(true);
    }
    dispatch(actions.setViewType(newValue));
  };

  const handleSelectName = value => {
    setModalText(value);
  };

  return dataLoaded ? (
    <div id={HtmlAreaId}>
      {disableBack && (
        <div className={classes.modalContext}>
          <Loading classNameWrapper={classes.loading} />
        </div>
      )}
      <Grid container className={classes.paddingHorizontal}>
        <Metadata
          content={data.letterContent}
          onMouseDown={onMouseDown}
          onMouseOver={onMouseOver}
          onMouseUp={onMouseUp}
          onDateMouseUp={onDateTimeMouseUp}
          key={metaDataKey}
        />
        <Attachments attachments={data.letterContent.attachments} />
        <Grid item xs={12}>
          <Paper className={classes.tabsWrapper}>
            <TabPanel
              classNameWrapperTabs={classes.tabsHeaderWrapper}
              classFlexContainer={classes.flexWrap}
              tabs={tabs}
              viewTypeLink="flooded"
              onValueChanged={handleValueChanged}
            />
          </Paper>
        </Grid>
      </Grid>
      <Dialog
        onClose={() => setModalOpen(false)}
        aria-labelledby="simple-dialog-title"
        open={modalOpen}
      >
        <DialogContent>
          <div className={classes.wrapper}>
            {!!customFields.length &&
              customFields.map(i => (
                <ReusableButton
                  key={i.value}
                  viewType="black"
                  classNameWrapper={classes.customFieldButton}
                  onClick={() => handleSelectName(i.value)}
                  label={i.label}
                  disabled={selectedExpressions?.find(
                    items => items === i.value,
                  )}
                />
              ))}
          </div>
          <div>
            <TField
              placeholder="Name of field"
              variant="outlined"
              className={classes.textField}
              size="small"
              value={modalText}
              onChange={handleModalTextEntered}
              onKeyDown={handleEnterKeyPressed}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <ReusableButton
            label="resources.buttons.cancel"
            onClick={() => setModalOpen(false)}
          />
          <ReusableButton
            viewType="black"
            label="resources.buttons.confirm"
            classNameWrapper={classes.submitButton}
            onClick={handleModalConfirmed}
          />
        </DialogActions>
      </Dialog>
      <SimpleDialog
        open={displayWarning}
        setOpen={setDisplayWarning}
        title="Pdf parsing warning"
      >
        <div className={classes.warningDialogContainer}>
          <span>We removed all unnecessary data from your PDF file</span>
          <span>and left only text to simplify parsing process</span>
          <span className={classes.warningDialogRedWord}> Warning!</span>
          <span>PDF parsing mechanism still is under construction.</span>
          <span>We do not recommend to use it now for regular issues</span>
        </div>
      </SimpleDialog>
    </div>
  ) : (
    <div>
      <Loading />
    </div>
  );
};

DisplayEmail.propTypes = {
  letterId: PropTypes.string.isRequired,
  mailBoxId: PropTypes.string.isRequired,
  setCurrentLetter: PropTypes.func,
  setSelectableSelMode: PropTypes.func.isRequired,
  selectableMode: PropTypes.bool.isRequired,
};

MailView.propTypes = {
  bodyIndex: PropTypes.number,
  body: PropTypes.objectOf(PropTypes.any),
  htmlViewerId: PropTypes.string,
  onMouseOver: PropTypes.func,
  onMouseDown: PropTypes.func,
  onMouseUp: PropTypes.func,
};

export default DisplayEmail;
