import React from 'react';
import { useTranslate } from 'react-admin';
import PropTypes from 'prop-types';
import { Grid, Box } from '@material-ui/core';
import InfoBox from '@common/InfoBox/InfoBox';
import MessageIgnoreExpression from './Components/MessageIgnoreExpression';
import DangerZone from './Components/DangerZone';

import useStyles from './styles';

const Settings = ({ mailBoxId }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const getText = key =>
    translate('resources.mailBox.settings.ignoreSpoiler.'.concat(key));
  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12}>
        <span className={classes.heading}>{getText('header')}</span>
        <Box className={classes.secondaryHeadingWrapper}>
          <span className={classes.secondaryHeading}>
            {getText('secondHeader')}
          </span>
          <InfoBox
            text={translate('resources.mailBox.settings.ignore.leftText')}
          />
        </Box>
        <MessageIgnoreExpression mailBoxId={mailBoxId} />
      </Grid>
      <Grid item xs={12}>
        <DangerZone mailBoxId={mailBoxId} />
      </Grid>
    </Grid>
  );
};

Settings.propTypes = {
  mailBoxId: PropTypes.string.isRequired,
};

export default Settings;
