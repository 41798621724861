import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useHistory } from 'react-router-dom';
import { useNotify } from 'react-admin';
import { Dialog } from '@material-ui/core';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';

import Input from '@ui/components/Auth/Common/Input';
import ReusableButton from '@ui/components/common/Button/Button';
import FieldWrapper from '@ui/components/common/form/FieldWrapper';
import StringArraySelector from '@common/form/StringArraySelector/StringArraySelector';
import Loading from '@ui/components/common/Loading/Loading';
import ConfirmDialog from '@ui/components/common/ConfirmDialog/ConfirmDialog';
import ViewAutoComplete from '@common/ViewAutoComplete/ViewAutoComplete';

import { convertDurationToTime } from '@utils/convertDurationToTime';
import { getTimeDiff } from '@utils/getDuration';
import { EDIT_SCHEDULE, SCHEDULE_GROUP } from '@constants/routes';
import { monthShortNames } from '@constants/monthShortNames';
import { daysOfWeekArray } from '@constants/daysOfWeek';
import { initial, validate } from './form';
import {
  cloneScheduleRule,
  createScheduleRule,
  deleteScheduleRule,
  getNotificationGroupAutocomplete,
  getNotificationGroupUsers,
  getScheduleRule,
  prepareDate,
  testScheduleRule,
  updateScheduleRule,
} from '../helper';
import SchedulerItem from '../Schedule/components/SchedulerItem';

import useStyles from '../styles';

const EditScheduleRule = ({ match }) => {
  const [loading, setLoading] = useState(true);
  const [editMode, setPageMode] = useState(false);
  const [initialValues, setInitialValues] = useState(initial);
  const [users, setUsers] = useState([]);
  const [groups, setGroups] = useState([]);
  const [testEvents, setTestEvents] = useState([]);
  const [showTestCalendar, setShowTestCalendar] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [showCloneDialog, setCloneDialog] = useState(false);

  const classes = useStyles();
  const { goBack, push } = useHistory();
  const notify = useNotify();

  const submit = ({ timeTo, timeFrom, groupName, ...rest }) => {
    const payload = {
      ...initialValues,
      ...rest,
      timeFrom,
      periodMinutes: getTimeDiff(timeFrom, timeTo),
      weekStart: rest.weekNumber !== 1 ? rest.weekStart : null,
      dayStartWeek: rest.daysNumber !== 1 ? rest.dayStartWeek : null,
    };
    if (editMode) {
      updateScheduleRule(initialValues.id, payload)
        .then(() => {
          notify('Successfully updated', 'success');
          push(SCHEDULE_GROUP.replace(':groupId', match.params.groupId));
        })
        .catch(err => {
          notify(err.message, 'error');
        });
    } else {
      createScheduleRule(payload)
        .then(() => {
          notify('Successfully created', 'success');
          push(SCHEDULE_GROUP.replace(':groupId', match.params.groupId));
        })
        .catch(err => {
          notify(err.message, 'error');
        });
    }
  };

  const onMount = () => {
    setLoading(true);
    getNotificationGroupUsers(match.params.groupId)
      .then(res => {
        setUsers(res);
      })
      .catch(err => {
        notify(err.message, 'error');
        goBack();
      });
    getNotificationGroupAutocomplete()
      .then(res => {
        setGroups(res);
      })
      .catch(e => {
        notify(e.message, 'error');
      });
    if (match.params.ruleId === 'scheduleCreate') {
      setPageMode(false);
      setLoading(false);
    } else if (!isNaN(match.params.ruleId)) {
      setPageMode(true);
      getScheduleRule(match.params.ruleId)
        .then(res => {
          setInitialValues({
            ...res,
            startDate: prepareDate(res.startDate),
            endDate: prepareDate(res.endDate),
            timeTo: convertDurationToTime(res.timeFrom, res.periodMinutes),
            dayStartWeek: res.dayStartWeek
              ? prepareDate(res.dayStartWeek)
              : initial.dayStartWeek,
            weekStart: res.weekStart
              ? prepareDate(res.weekStart)
              : initial.weekStart,
          });
          setSelectedUser({ value: res.userId, label: res.userName });
        })
        .catch(() => {
          goBack();
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      goBack();
    }
  };

  const handleDelete = ruleId => {
    deleteScheduleRule(ruleId)
      .then(() => {
        notify('Successfully deleted ', 'success');
        push(SCHEDULE_GROUP.replace(':groupId', match.params.groupId));
      })
      .catch(err => {
        notify(err.message, 'error');
      });
  };

  const handleTestClick = ({ timeTo, timeFrom, ...rest }) => {
    const validationResult = validate({ timeFrom, timeTo, ...rest });
    if (Object.values(validationResult).some(i => i === 'Required')) {
      notify('Fill all fields before testing', 'info');
    } else {
      const payload = {
        ...initialValues,
        ...rest,
        timeFrom,
        periodMinutes: getTimeDiff(timeFrom, timeTo),
        weekStart: rest.weekNumber !== '1' ? rest.weekStart : null,
        dayStartWeek: rest.daysNumber !== '1' ? rest.dayStartWeek : null,
      };

      testScheduleRule(payload)
        .then(res => {
          const events = [];
          res.periods.map(period => {
            events.push({
              start: period.start,
              end: period.end,
              color: res.color,
              title: res.title,
              userId: res.userId,
              userName: res.userName,
            });
          });
          setTestEvents(events);
        })
        .catch(err => {
          notify(err.message, 'error');
        })
        .finally(() => {
          setShowTestCalendar(true);
        });
    }
  };

  const handleCloneClick = ruleId => {
    cloneScheduleRule(ruleId)
      .then(res => {
        push(
          EDIT_SCHEDULE.replace(':groupId', match.params.groupId).replace(
            ':ruleId',
            res.id,
          ),
        );
      })
      .catch(err => {
        notify(err.message, 'error');
      })
      .finally(() => {
        setCloneDialog(false);
      });
  };

  useEffect(() => {
    onMount();
  }, [match]);

  if (loading) return <Loading />;
  return (
    <>
      <Form
        onSubmit={submit}
        initialValues={initialValues}
        validate={validate}
        render={({ handleSubmit, values, form }) => (
          <form onSubmit={handleSubmit} className={classes.formContainer}>
            <FieldWrapper
              label="Group"
              labelSize={2}
              contentSize={10}
              content={
                <Field
                  id="groupName"
                  name="groupName"
                  showLabel
                  component={Input}
                  disabled
                  initialValue={
                    groups.find(i => i.value === Number(match.params.groupId))
                      ?.label
                  }
                />
              }
            />

            <FieldWrapper
              label="User"
              labelSize={2}
              contentSize={10}
              content={
                <Field
                  id="userId"
                  name="userId"
                  showLabel
                  component={ViewAutoComplete}
                  options={users}
                  disabled={!isNaN(match.params.ruleId)}
                  textFieldLabel=""
                  onChange={item => {
                    setSelectedUser(item);
                    form.change('userId', item?.value);
                  }}
                  currentView={selectedUser}
                  formControlClassNameWrapper={classes.dropDownSelector}
                />
              }
            />

            <FieldWrapper
              label="Title"
              labelSize={2}
              contentSize={10}
              content={
                <Field id="title" name="title" showLabel component={Input} />
              }
            />

            <FieldWrapper
              label="Color"
              labelSize={2}
              contentSize={2}
              content={
                <Field
                  id="color"
                  name="color"
                  showLabel
                  type="color"
                  component={Input}
                />
              }
            />

            <FieldWrapper
              label="Rule range"
              labelSize={2}
              contentSize={5}
              classNameContentContainer={classes.doubledContainer}
              content={
                <>
                  <Field
                    id="startDate"
                    name="startDate"
                    showLabel
                    type="date"
                    dateProps={{ max: values?.dateTo }}
                    component={Input}
                  />
                  <Field
                    id="endDate"
                    name="endDate"
                    showLabel
                    type="date"
                    dateProps={{ min: values?.dateFrom }}
                    component={Input}
                  />
                </>
              }
            />

            <FieldWrapper
              label="Work time"
              labelSize={2}
              contentSize={5}
              classNameContentContainer={classes.doubledContainer}
              content={
                <>
                  <Field
                    id="timeFrom"
                    name="timeFrom"
                    showLabel
                    type="time"
                    component={Input}
                  />
                  <Field
                    id="timeTo"
                    name="timeTo"
                    showLabel
                    type="time"
                    component={Input}
                  />
                </>
              }
            />
            <FieldWrapper
              label="Months"
              labelSize={2}
              contentSize={10}
              content={
                <Field
                  id="months"
                  name="months"
                  component={StringArraySelector}
                  itemsList={monthShortNames}
                />
              }
            />
            <FieldWrapper
              label="Days of week"
              labelSize={2}
              contentSize={5}
              content={
                <Field
                  id="daysOfWeek"
                  name="daysOfWeek"
                  component={StringArraySelector}
                  itemsList={daysOfWeekArray}
                />
              }
            />

            <FieldWrapper
              label=""
              labelSize={0}
              contentSize={12}
              classNameContentContainer={classes.intervalsContainer}
              showLabel={false}
              content={
                <>
                  <p>Repeat Every</p>
                  <div className={classes.numberContainer}>
                    <Field
                      id="weekNumber"
                      name="weekNumber"
                      component={Input}
                      type="number"
                      initialValue={1}
                      dateProps={{ min: 1 }}
                    />
                  </div>
                  <p className={classes.fixedWidth}>Week(s) Starting</p>
                  <div className={classes.dateContainer}>
                    <Field
                      id="weekStart"
                      name="weekStart"
                      component={Input}
                      type="date"
                      disabled={Number(values.weekNumber) === 1}
                    />
                  </div>
                  <p>With Period Length</p>
                  <div className={classes.numberContainer}>
                    <Field
                      id="numberOfWeeks"
                      name="numberOfWeeks"
                      component={Input}
                      type="number"
                      initialValue={1}
                      dateProps={{ min: 1 }}
                    />
                  </div>
                </>
              }
            />

            <FieldWrapper
              label=""
              labelSize={0}
              contentSize={12}
              classNameContentContainer={classes.intervalsContainer}
              showLabel={false}
              content={
                <>
                  <p>Repeat Every</p>
                  <div className={classes.numberContainer}>
                    <Field
                      id="daysNumber"
                      name="daysNumber"
                      component={Input}
                      type="number"
                      initialValue={1}
                      dateProps={{ min: 1 }}
                    />
                  </div>
                  <p className={classes.fixedWidth}>Day(s) Starting</p>
                  <div className={classes.dateContainer}>
                    <Field
                      id="dayStartWeek"
                      name="dayStartWeek"
                      component={Input}
                      type="date"
                      disabled={Number(values.daysNumber) === 1}
                    />
                  </div>
                  <p>With Period Length</p>
                  <div className={classes.numberContainer}>
                    <Field
                      id="numberOfDays"
                      name="numberOfDays"
                      component={Input}
                      type="number"
                      initialValue={1}
                      dateProps={{ min: 1 }}
                    />
                  </div>
                </>
              }
            />

            <div className={classes.buttonsContainer}>
              <ReusableButton
                label="Cancel"
                size="md"
                onClick={() => {
                  push(
                    SCHEDULE_GROUP.replace(':groupId', match.params.groupId),
                  );
                }}
              />
              <ReusableButton
                label="Test"
                size="md"
                onClick={() => {
                  handleTestClick(values);
                }}
              />
              <ReusableButton
                label={editMode ? 'Update' : 'Create'}
                type="submit"
                size="md"
              />
              {editMode && (
                <>
                  <ReusableButton
                    label="Clone"
                    size="md"
                    onClick={() => {
                      setCloneDialog(true);
                    }}
                  />
                  <ReusableButton
                    viewType="red"
                    label="Delete"
                    size="md"
                    onClick={() => {
                      setShowDeleteDialog(true);
                    }}
                  />
                </>
              )}
            </div>
          </form>
        )}
      />
      {showTestCalendar && (
        <Dialog
          open={showTestCalendar}
          onClose={() => {
            setShowTestCalendar(false);
          }}
          fullWidth
        >
          <div className={classes.dialog}>
            <div className={classes.testCalendarWrapper}>
              <FullCalendar
                height="95%"
                viewHeight="95%"
                nowIndicator
                plugins={[timeGridPlugin, dayGridPlugin]}
                events={testEvents}
                initialView="dayGridMonth"
                headerToolbar={{
                  left: 'title',
                  right:
                    'today prev timeGridDay timeGridWeek dayGridMonth next',
                }}
                eventContent={({ event }) => (
                  <SchedulerItem event={event} testEvents />
                )}
              />
            </div>
            <div className={classes.dialogButtonContainer}>
              <ReusableButton
                label="Back"
                size="md"
                onClick={() => {
                  setShowTestCalendar(false);
                }}
              />
            </div>
          </div>
        </Dialog>
      )}

      {showDeleteDialog && (
        <ConfirmDialog
          open={showDeleteDialog}
          onAccept={() => {
            handleDelete(initialValues.id);
          }}
          onCancel={() => {
            setShowDeleteDialog(false);
          }}
          title="Please confirm this action"
          content="Are you sure you want to delete this rule?"
        />
      )}

      {showCloneDialog && (
        <ConfirmDialog
          open={showCloneDialog}
          onAccept={() => {
            handleCloneClick(initialValues.id);
          }}
          onCancel={() => {
            setCloneDialog(false);
          }}
          title="Please confirm this action"
          content="Are you sure you want to clone this rule?"
        />
      )}
    </>
  );
};

export default EditScheduleRule;
