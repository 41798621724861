import React from 'react';
import { useSelector } from 'react-redux';

import getFormattedDate from '@utils/getFormattedDate';

import useStyles from './styles';

const VerificationHistory = () => {
  const classes = useStyles();

  const data = useSelector(state => state.pod.data);

  return (
    <div className={classes.verificationContainer}>
      <div>
        <b>Verification history</b>
      </div>

      <div
        style={{
          height: '100%',
          overflow: 'auto',
          paddingRight: '15px',
        }}
      >
        {!!data?.history?.length &&
          data?.history.map(i => (
            <div
              key={i.Date || i.date}
              style={{
                display: 'flex',
                flexDirection: 'column',
                margin: '15px 0',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  fontWeight: 'bold',
                }}
              >
                <div>{getFormattedDate(i.Date || i.date)}</div>
                <div>{i.Type || i.type}</div>
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <div style={{ marginRight: '5px' }}>
                  {i.Inspector || i.inspector}
                </div>
                <div>{i.Item || i.item}</div>
              </div>

              <div>
                <b>{i?.Notes || i.notes}</b>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default VerificationHistory;
