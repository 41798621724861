import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import ReusableButton from '@common/Button/Button';
import EndpointDetails from '@components/DataSources/Integrations/pages/Sophos/EndpointDetails';

const DetailsDialog = ({ open, onClose, item }) => {
  const { data } = item;

  return (
    <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogContent>
        <EndpointDetails item={data} />
      </DialogContent>
      <DialogActions>
        <ReusableButton
          viewType="black"
          label="resources.buttons.ok"
          onClick={onClose}
        />
      </DialogActions>
    </Dialog>
  );
};

DetailsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DetailsDialog;
