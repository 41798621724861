import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { compact } from 'lodash/array';
import TableLabel from '@common/TableLabel/TableLabel';
import TableDropDown from '@common/TableDropDown/TableDropDown';
import HyperlinkAccessor from '@common/MuiVirtualizedTable/components/accessors/HyperlinkAccessor';
import TitledValueAccessor from '@common/MuiVirtualizedTable/components/accessors/TitledValueAccessor';
import DetailedViewAccessor from '@common/MuiVirtualizedTable/components/accessors/DetailedViewAccessor';
import DateAccessor from '@common/MuiVirtualizedTable/components/accessors/DateAccessor';
import { MuiVirtualizedListView } from '@common/MuiVirtualizedTable';
import { statuses, tableKeys } from '@common/MuiVirtualizedTable/constants';
import statusColors from '../statusColors';

const circleStyle = value => {
  return value
    ? {
        width: '10px',
        height: '10px',
        display: 'inline-block',
        margin: '0 10px',
        borderRadius: '50%',
        backgroundColor: statusColors[value],
      }
    : null;
};

const valueHandlerStatus = key => statuses[key].name;

const columns = [
  {
    key: 'organizationName',
    name: (
      <TableLabel item={{ name: 'Organization', field: 'organizationName' }} />
    ),
    width: 200,
    accessor: row => (
      <HyperlinkAccessor
        href={row.organizationUrl}
        value={row.organizationName}
      />
    ),
  },
  {
    key: 'networkName',
    name: <TableLabel item={{ name: 'Network', field: 'networkName' }} />,
    width: 200,
    accessor: row => (
      <HyperlinkAccessor href={row.networkUrl} value={row.networkName} />
    ),
  },
  {
    key: 'name',
    name: <TableLabel item={{ name: 'Device Name', field: 'name' }} />,
    width: 200,
  },
  {
    key: 'model',
    name: <TableLabel item={{ name: 'Model', field: 'model' }} />,
    width: 100,
  },
  {
    key: 'serial',
    name: <TableLabel item={{ name: 'Serial Number', field: 'serial' }} />,
    width: 200,
  },
  {
    key: 'mac',
    name: <TableLabel item={{ name: 'MAC address', field: 'mac' }} />,
    width: 150,
  },
  {
    key: 'status',
    name: (
      <TableDropDown
        item={{ field: 'status', name: 'Device Status' }}
        options={statuses}
        labelHandler={valueHandlerStatus}
      />
    ),
    width: 150,
    accessor: row => {
      return (
        <>
          <div style={circleStyle(row.status)} />
          <span>{row.status}</span>
        </>
      );
    },
  },
  {
    key: 'lastReportedAt',
    name: (
      <TableLabel
        item={{ name: 'Last Reported Date', field: 'lastReportedAt' }}
      />
    ),
    width: 210,
    accessor: row => <DateAccessor value={row.lastReportedAt} />,
  },
  {
    key: 'productType',
    name: <TableLabel item={{ name: 'Product type', field: 'productType' }} />,
    width: 100,
  },
  {
    key: 'tags',
    name: <TableLabel item={{ name: 'Tags', field: 'tags' }} />,
    width: 150,
    accessor: row => <TitledValueAccessor value={row.tags} />,
  },
  {
    key: 'lanIp',
    name: <TableLabel item={{ name: 'Lan IP', field: 'lanIp' }} />,
    width: 150,
  },
  {
    key: 'wan',
    name: <TableLabel item={{ name: 'Wan IPs', field: 'wan' }} />,
    width: 150,
    accessor: row => <DetailedViewAccessor value={row.wan} />,
  },
  {
    key: 'wanGw',
    name: <TableLabel item={{ name: 'Wan Gateways', field: 'wanGw' }} />,
    width: 150,
    accessor: row => <DetailedViewAccessor value={row.wanGw} />,
  },
];

const MerakiGrid = ({ data }) => {
  const rowsData = useMemo(() => {
    return data.map((i, idx) => {
      return {
        id: idx,
        wan: compact([i.publicIp, i.wan1Ip, i.wan2Ip]),
        wanGw: compact([i.wan1Gateway, i.wan2Gateway]),
        ...i,
      };
    });
  }, [data]);

  return (
    <div style={{ height: 650, width: '100%' }}>
      <MuiVirtualizedListView
        tableKey={tableKeys.merakiDashboards}
        data={rowsData}
        columns={columns}
      />
    </div>
  );
};

MerakiGrid.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
};

export default MerakiGrid;
