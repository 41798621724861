import React, { useState } from 'react';
import useAsync from '@services/api/common/useAsync';
import { apisUsage } from '@components/Settings/TenantStatistic/helpers';
import Loading from '@common/Loading/Loading';
import SourceItemValue from '@components/Settings/TenantStatistic/components/SourceItemValue';
import useTranslatedText from '@common/hooks/useTranslatedText';
import resources from '@constants/resources';

const Apis = () => {
  const { getText } = useTranslatedText(resources.tenantStatistic);
  const [data, setData] = useState({});
  const loading = useAsync(apisUsage, setData);

  if (loading) return <Loading />;
  return (
    <>
      <SourceItemValue label={getText('apiTotal')} value={data?.totalCount} />
      <SourceItemValue
        label={getText('apiGeneric')}
        value={data?.genericCount}
      />
      <SourceItemValue label={getText('apiMeraki')} value={data?.merakiCount} />
      <SourceItemValue label={getText('apiSophos')} value={data?.sophosCount} />
    </>
  );
};

export default Apis;
