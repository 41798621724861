import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useStyles from '@components/Audit/styles';
import { useNotify } from 'react-admin';
import { getTicketLink } from '@components/Audit/helpers';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell/TableCell';
import StatusesAccessor from '@common/MuiVirtualizedTable/components/accessors/StatusesAccessor';
import crmTicketStatuses from '@constants/crmTicketStatuses';
import { crmSourcesEnum } from '@constants/crmSources';
import ActionButton from '@common/buttons/ActionButton/ActionButton';
import iconLink from '@assets/icons/link.svg';
import Loading from '@common/Loading/Loading';
import LinearProgress from '@material-ui/core/LinearProgress/LinearProgress';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';

const TicketList = ({ identifier, handler }) => {
  const classes = useStyles();
  const notify = useNotify();

  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [linkLoading, setLinkLoading] = useState(false);

  useEffect(() => {
    if (Array.isArray(identifier) ? identifier?.length : !!identifier) {
      setLoading(true);
      handler(identifier)
        .then(d => setTickets(d))
        .catch(e => notify(e.message, 'error'))
        .finally(() => setLoading(false));
    }
  }, [identifier, handler, notify]);

  const getLink = useCallback(
    (id, crmDefaultId) => {
      setLinkLoading(true);
      getTicketLink(id, crmDefaultId)
        .then(d => window.open(d.data, '_blank'))
        .catch(e => notify(e.message, 'error'))
        .finally(() => setLinkLoading(false));
    },
    [identifier],
  );

  const getRow = dataRow => {
    return (
      <TableRow key={dataRow.id}>
        <TableCell>{dataRow.ticketId}</TableCell>
        <TableCell>
          <StatusesAccessor
            item={dataRow.ticketStatus}
            array={crmTicketStatuses}
          />
        </TableCell>
        <TableCell>{crmSourcesEnum[dataRow.crmType]}</TableCell>
        <TableCell>
          {dataRow.crmDefaultId ? (
            <ActionButton
              icon={<img src={iconLink} alt="openTicket" />}
              handler={() => getLink(dataRow.ticketId, dataRow.crmDefaultId)}
              toolTip="Open ticket"
              disabled={linkLoading}
            />
          ) : (
            <div>no crm</div>
          )}
        </TableCell>
      </TableRow>
    );
  };

  const getContent = () => {
    if (loading) {
      return <Loading />;
    }
    if (!tickets.length) {
      return <div>No content</div>;
    }
    return (
      <>
        {linkLoading && <LinearProgress />}
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>PSA</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>{tickets.map(t => getRow(t))}</TableBody>
          </Table>
        </TableContainer>
      </>
    );
  };

  return <div className={classes.ticketsContainer}>{getContent()}</div>;
};

TicketList.propTypes = {
  identifier: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.number,
  ]).isRequired,
  handler: PropTypes.func.isRequired,
};

export default TicketList;
