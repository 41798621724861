import React, { useState } from 'react';
import { Tooltip } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useNotify } from 'react-admin';

import { ThunkAssignTicketToTechVerification } from '@store/slices/psaTechVerification/thunks/assignTicketToTechVerification';

import { PSA_TECH_VERIFICATION } from '@constants/routes';

import PropTypes from 'prop-types';
import ReusableButton from '@common/Button/Button';

const Actions = ({ psaId, itemId, item }) => {
  const [processing, setProcessing] = useState(false);

  const notify = useNotify();
  const dispatch = useDispatch();
  const { push } = useHistory();

  const handleAssign = payload => {
    setProcessing(true);
    dispatch(ThunkAssignTicketToTechVerification(payload))
      .unwrap()
      .then(() => {
        push(PSA_TECH_VERIFICATION);
        notify('Linked Successfully', 'success');
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      <Tooltip title="Assign" style={{ cursor: 'pointer' }}>
        <ReusableButton
          label="Assign"
          viewType="blue"
          disabled={processing}
          onClick={() => {
            handleAssign({
              crmId: psaId,
              ticketId: String(item.id),
              contactId: item.contactId ?? null,
              itemId: +itemId,
              contactName: item.contact,
            });
          }}
        />
      </Tooltip>
    </div>
  );
};

Actions.propTypes = {
  psaId: PropTypes.number,
  itemId: PropTypes.number,
  item: PropTypes.shape({
    id: PropTypes.number,
    contactId: PropTypes.number,
    contact: PropTypes.string,
  }),
};

export default Actions;
