import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useNotify, useSafeSetState } from 'react-admin';
import { Field, Form, FormSpy } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import Input from '@common/Input/Input';
import ReusableButton from '@common/Button/Button';
import useTranslatedText from '@common/hooks/useTranslatedText';
import {
  getDataViewOptions,
  getTileItemData,
  updateTile,
} from '@components/Dashboard/helper';
import AuditViewForm from '@components/Ticketing/Notifications/TicketSettings/AuditViewForm';
import DropDown from '@components/Auth/Common/DropDown';
import {
  dashboardCurrentTiles,
  dashboardDataViewOptions,
} from '@store/selectors/dashboard';
import { actions } from '@store/actions';
import FieldWrapper from '@common/form/FieldWrapper';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Accordion from '@material-ui/core/Accordion';
import useAsyncDispatch from '@services/api/common/useAsyncDispatch';
import {
  formToValue,
  getInitData,
  groupRequired,
  typeOptions,
  validate,
} from './form';
import useStyles from '../styles';

const TileForm = ({ onClose, open, data }) => {
  const initialData = useMemo(() => getInitData(data), [data]);

  const classes = useStyles();
  const notify = useNotify();
  const dispatch = useDispatch();
  const tiles = useSelector(dashboardCurrentTiles);
  const dataViewOptions = useSelector(dashboardDataViewOptions);
  const { getText } = useTranslatedText('dashboard.tileForm');
  const [loading, setLoading] = useSafeSetState(false);
  const [filters, setFilters] = useState({});
  const [groupingOptions, setGroupingOptions] = useState([]);
  const [formValues, setFormValues] = useState({});
  useAsyncDispatch(getDataViewOptions, actions.dashboard_setDatViewOptions);

  const submit = values => {
    setLoading(true);
    updateTile(formToValue(data, values, filters))
      .then(response => {
        const newTiles = [...tiles];
        const idx = newTiles.findIndex(p => p.id === response.id);
        newTiles.splice(idx, 1, response);
        dispatch(actions.dashboard_setTiles(newTiles));
      })
      .then(() => {
        if (data.dashboardItemId) {
          getTileItemData(data.dashboardItemId).then(response =>
            dispatch(actions.dashboard_addItem(response)),
          );
        }
      })
      .catch(e => notify(e.message, 'error'))
      .finally(() => {
        setLoading(false);
        onClose();
      });
  };

  const onDataViewDataChanged = (values, form) => {
    const groupOptions = values.data
      ? JSON.parse(values.data).map(i => ({
          label: i.name,
          value: i.field,
        }))
      : [];
    const currentValue = form.getFieldState('barChartGroupBy')?.value;
    if (currentValue && !groupOptions.find(i => i.value === currentValue)) {
      form.change('barChartGroupBy', null);
    }
    setGroupingOptions(groupOptions);
  };

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      classes={{
        paper: classes.container,
      }}
    >
      <DialogTitle id="simple-dialog-title">
        {getText(data && data.item ? 'headerTextUpdate' : 'headerTextCreate')}
      </DialogTitle>
      <DialogContent>
        <Form
          onSubmit={submit}
          validate={validate}
          initialValues={initialData}
          render={({ handleSubmit, form }) => (
            <form onSubmit={handleSubmit} noValidate>
              <FieldWrapper
                label={getText('nameLabel')}
                labelSize={3}
                contentSize={9}
                content={
                  <Field
                    autoFocus
                    id="name"
                    name="name"
                    fullWidth
                    component={Input}
                    styleType="main"
                    inputView="text"
                    disabled={loading}
                  />
                }
              />
              <FieldWrapper
                label={getText('typeLabel')}
                labelSize={3}
                contentSize={9}
                content={
                  <Field
                    id="type"
                    name="type"
                    component={DropDown}
                    options={typeOptions}
                    disabled={loading}
                  />
                }
              />
              <FieldWrapper
                label={getText('dataViewLabel')}
                labelSize={3}
                contentSize={9}
                content={
                  <Field
                    id="auditViewId"
                    name="auditViewId"
                    component={DropDown}
                    options={dataViewOptions}
                    disabled={loading}
                  />
                }
              />
              {groupRequired.includes(formValues.type) && (
                <FieldWrapper
                  label={getText('groupingLabel')}
                  labelSize={3}
                  contentSize={9}
                  content={
                    <Field
                      id="barChartGroupBy"
                      name="barChartGroupBy"
                      component={DropDown}
                      options={groupingOptions}
                      disabled={loading}
                    />
                  }
                />
              )}
              <Accordion classes={{ rounded: classes.rounded }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  {getText('accordionLabel')}
                </AccordionSummary>
                <AccordionDetails>
                  <AuditViewForm
                    id={formValues.auditViewId}
                    onDataChange={values => setFilters(values)}
                    initData={initialData.filters}
                    onDataChanged={d => onDataViewDataChanged(d, form)}
                  />
                </AccordionDetails>
              </Accordion>
              <div>
                <Grid container justify="flex-end" spacing={2}>
                  <Grid item>
                    <ReusableButton
                      label="resources.buttons.cancel"
                      onClick={onClose}
                      disabled={loading}
                    />
                  </Grid>
                  <Grid item>
                    <ReusableButton
                      viewType="black"
                      type="submit"
                      disabled={loading}
                      loading={loading}
                      label="resources.buttons.ok"
                    />
                  </Grid>
                </Grid>
              </div>
              <FormSpy onChange={values => setFormValues(values.values)} />
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  );
};

TileForm.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  data: PropTypes.objectOf(PropTypes.any),
};

export default TileForm;
