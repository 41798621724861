import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form } from 'react-final-form';
import { Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';

import { ThunkCreateUser, ThunkUpdateUser } from '@store/slices/user/thunks';
import Paper from '@common/Paper/Paper';
import Input from '@common/Input/Input';
import ReusableButton from '@common/Button/Button';
import {
  areValuesTheSame,
  isEmail,
  isRequiredValidation,
} from '@components/Helpers/valudationHelpers';
import FormSwitch from '@common/FilterInputs/Switch';
import FieldWrapper from '@common/form/FieldWrapper';
import DropDown from '@components/Auth/Common/DropDown';
import { getUserId } from '@services/api';
import { currentUserDataSelector } from '@store/selectors';

import roles from '@constants/roles';
import { USERS } from '@constants/routes';
import useStyles from './styles';

const getInitData = data => {
  return {
    id: data && data.id ? data.id : 0,
    username: data ? data.username : null,
    fieldEmail: data ? data.email : null,
    password: data ? data.password : null,
    passwordConfirmation: data ? data.passwordConfirmation : null,
    useMfa: data ? data.useMfa : false,
    allowAccessToMainApp: data ? data.allowAccessToMainApp : false,
    imageUri: data ? data.imageUri : null,
    roleId: data ? data.roleId : 3,
    customLocation: data ? data.customLocation : null,
  };
};

const UserForm = ({ user, rolesOptions }) => {
  const { push } = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentUserId = getUserId();
  const currentUser = useSelector(currentUserDataSelector);
  const initData = getInitData(user);
  const { enqueueSnackbar } = useSnackbar();

  const isUpdate = initData.id !== 0;

  const [loading, setLoading] = useState(false);

  const handleBack = () => push(USERS);

  const create = useCallback(
    values => {
      setLoading(true);
      dispatch(ThunkCreateUser(values))
        .unwrap()
        .finally(() => {
          setLoading(false);
        });
    },
    [dispatch],
  );

  const update = useCallback(
    payload => {
      setLoading(true);
      dispatch(ThunkUpdateUser({ id: payload.id, payload }))
        .unwrap()
        .then(() => {
          enqueueSnackbar('User updated successfully', { variant: 'success' });
          handleBack();
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [dispatch],
  );

  const submit = payload => {
    const { fieldEmail, ...values } = payload;
    setLoading(true);
    if (!isUpdate) {
      create({ ...values, email: fieldEmail });
    } else {
      update({ ...values, email: fieldEmail });
    }
  };

  const validate = values => ({
    username: isRequiredValidation(values.username),
    fieldEmail:
      isRequiredValidation(values.fieldEmail) || isEmail(values.fieldEmail),
    passwordConfirmation: areValuesTheSame(
      values.password,
      values.passwordConfirmation,
    ),
  });

  const formFields = [
    {
      label: 'Email',
      field: 'fieldEmail',
      inputView: 'text',
      styleType: 'main',
      type: Input,
      autoComplete: 'email-off',
    },
    {
      label: 'Full Name',
      field: 'username',
      inputView: 'text',
      styleType: 'main',
      isRequired: true,
      type: Input,
      autoComplete: 'off',
    },
    {
      label: 'Location',
      field: 'customLocation',
      inputView: 'text',
      styleType: 'main',
      isRequired: false,
      type: Input,
      autoComplete: 'off',
    },
    {
      label: 'Password',
      field: 'password',
      inputView: 'text',
      styleType: 'main',
      btnType: 'password',
      isRequired: true,
      type: Input,
      autoComplete: 'off',
    },
    {
      label: 'Password Confirmation',
      field: 'passwordConfirmation',
      inputView: 'text',
      styleType: 'main',
      btnType: 'password',
      isRequired: true,
      type: Input,
      autoComplete: 'new-password',
    },
  ];

  if (
    currentUser?.roleId !== roles.SUPER_ADMIN &&
    currentUser?.roleId !== roles.TENANT_ADMIN
  ) {
    return <></>;
  }

  return (
    <Paper>
      <Form
        onSubmit={submit}
        validate={validate}
        initialValues={initData}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} noValidate>
            {formFields.map(field => (
              <div className={classes.inputWrapper} key={field.field}>
                <Typography className={classes.inputLabel}>
                  {field.label}
                  <span>:</span>
                </Typography>
                <Field
                  id={field.field}
                  name={field.field}
                  inputView={field.inputView}
                  styleType={field.styleType}
                  placeholder={field.label}
                  type={field.btnType}
                  classNameWrapper={classes.inputWrapper}
                  component={field.type}
                  disabled={loading}
                  fullWidth
                  autoComplete={field.autoComplete}
                />
              </div>
            ))}
            {initData.roleId !== roles.SUPER_ADMIN &&
              initData.id !== currentUserId && (
                <div className={classes.inputWrapper} key="roleId">
                  <Typography className={classes.inputLabel}>
                    Role assignment:
                  </Typography>
                  <Field
                    id="roleId"
                    name="roleId"
                    classNameWrapper={classes.inputWrapper}
                    component={DropDown}
                    options={rolesOptions}
                    disabled={loading}
                  />
                </div>
              )}
            <div className={classes.displayFlex}>
              <FieldWrapper
                label="Use MFA"
                labelSize={3}
                classNameLabelOuter={cx(
                  classes.inputLabel,
                  classes.inputSwitch,
                )}
                content={
                  <Field
                    id="useMfa"
                    name="useMfa"
                    type="checkbox"
                    component={FormSwitch}
                    disabled={loading}
                  />
                }
              />

              <FieldWrapper
                label="Allow Access to UI"
                labelSize={5}
                classNameLabelOuter={cx(
                  classes.inputLabel,
                  classes.inputSwitch,
                )}
                content={
                  <Field
                    id="allowAccessToMainApp"
                    name="allowAccessToMainApp"
                    type="checkbox"
                    component={FormSwitch}
                    disabled={loading}
                  />
                }
              />
            </div>
            <div className={classes.buttonWrapper}>
              <ReusableButton
                size="md"
                type="button"
                classNameWrapper={classes.button}
                disabled={loading}
                onClick={handleBack}
                label="resources.buttons.cancel"
              />
              <ReusableButton
                size="md"
                viewType="black"
                type="submit"
                classNameWrapper={classes.button}
                disabled={loading}
                loading={loading}
                label="resources.buttons.submit"
              />
            </div>
          </form>
        )}
      />
    </Paper>
  );
};

UserForm.propTypes = {
  user: PropTypes.objectOf({
    id: PropTypes.number,
    username: PropTypes.string,
    email: PropTypes.string,
    password: PropTypes.string,
    passwordConfirmation: PropTypes.string,
    useMfa: PropTypes.bool,
    allowAccessToMainApp: PropTypes.bool,
    imageUri: PropTypes.string,
    roleId: PropTypes.number,
    customLocation: PropTypes.string,
  }),
  rolesOptions: PropTypes.arrayOf(
    PropTypes.objectOf({
      value: PropTypes.number,
      label: PropTypes.string,
    }),
  ),
};

export default UserForm;
