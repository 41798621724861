import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Tooltip } from '@material-ui/core';

import { ReactComponent as WarningIcon } from '@assets/icons/warning.svg';
import { ReactComponent as CheckIcon } from '@assets/icons/check.svg';
import iconPlus from '@assets/icons/refreshItem.svg';

import ActionButton from '@common/buttons/ActionButton/ActionButton';
import getFormattedDate from '@utils/getFormattedDate';

import VerificationHistory from '@components/integrations/connectwise/components/VerificationHistory';
import useStyles from './styles';

const ContactPhoneEmails = ({
  handleVerificationClick,
  handleRefresh = null,
}) => {
  const classes = useStyles();

  const data = useSelector(state => state.pod.contactData);

  return (
    <>
      <div className={classes.verificationBlock}>
        <ActionButton
          handler={handleRefresh}
          icon={<img src={iconPlus} alt="iconPlus" />}
          toolTip="refresh"
        />
        {data?.length &&
          data.map(i => (
            <div key={i.Value} className={classes.verificationItem}>
              <div className={classes.verificationItemTitle}>
                <b>{`${i.Type}:`}</b>
              </div>
              <div>{i.Value}</div>

              <div className={classes.verificationIcon}>
                {i.IsVerified ? (
                  <Tooltip
                    title={`${getFormattedDate(i.DateTime)}${
                      i.Note ? ` - ${i.Note}` : ''
                    }`}
                  >
                    <CheckIcon
                      className={classes.checkIcon}
                      onClick={() => {
                        handleVerificationClick({
                          type: i.Type,
                          value: i.Value,
                        });
                      }}
                    />
                  </Tooltip>
                ) : (
                  <WarningIcon
                    onClick={() => {
                      handleVerificationClick({ type: i.Type, value: i.Value });
                    }}
                  />
                )}
              </div>
            </div>
          ))}
      </div>
      <div>
        <VerificationHistory />
      </div>
    </>
  );
};

ContactPhoneEmails.propTypes = {
  handleVerificationClick: PropTypes.func,
  handleRefresh: PropTypes.func,
};

export default ContactPhoneEmails;
