import React from 'react';
import PropTypes from 'prop-types';
import { BooleanInput } from 'react-admin';

import cx from 'classnames';
import useStyles from './styles';

const BooleanComponent = ({ classNameWrapper, ...props }) => {
  const classes = useStyles();
  return (
    <BooleanInput
      className={cx(classes.switcher, classNameWrapper)}
      {...props}
    />
  );
};

BooleanComponent.propTypes = {
  classNameWrapper: PropTypes.string,
};

export default BooleanComponent;
