import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';

import { ThunkGetSecureLink } from '@store/slices/secureData/thunks';

import { ReactComponent as KeyIcon } from '@assets/icons/key.svg';
import ReusableButton from '@common/Button/Button';
import FieldWrapper from '@common/form/FieldWrapper';
import Input from '@components/Auth/Common/Input';
import DropDown from '@components/Auth/Common/DropDown';
import Checkbox from '@common/Checkbox/Checkbox';
import PasswordGenerator from '@components/integrations/secureLink/components/PasswordGenerator';

import expirationPeriodOptions from '@constants/expirationPeriodOptions';

import { requiredValidator } from '@utils/validators';
import { NEVER_EXPIRED } from '@constants/commonConstants';

import { secureDataSettingsInitialSelector } from '@store/slices/secureDataSettings/selectors';
import { ThunkGetSecureDataSettings } from '@store/slices/secureDataSettings/thunks';
import Loading from '@common/Loading/Loading';
import useStyles from '../styles';

const initialDate = new Date(moment().add(1, 'd'));

const getExpirationDateUtc = value => {
  switch (value) {
    case expirationPeriodOptions.day.value:
      return initialDate;
    case expirationPeriodOptions.tenDays.value:
      return new Date(moment().add(10, 'd'));
    case expirationPeriodOptions.sevenDays.value:
      return new Date(moment().add(7, 'd'));
    case expirationPeriodOptions.month.value:
      return new Date(moment().add(1, 'M'));
    case expirationPeriodOptions.year.value:
      return new Date(moment().add(1, 'y'));
    case expirationPeriodOptions.custom.value:
      return null;
    case expirationPeriodOptions.never.value:
      return new Date(moment().set({ year: NEVER_EXPIRED, month: 1, day: 1 }));
    default:
      return initialDate;
  }
};

const GetSecureDataLink = ({
  handleLinkChange,
  psaContactId,
  psaId,
  localTicketId = null,
}) => {
  const [processing, setProcessing] = useState(false);
  const [openGenerationSetup, setOpenGenerationSetup] = useState(false);
  const [loading, setLoading] = useState(false);

  const initData = useSelector(secureDataSettingsInitialSelector);

  const dispatch = useDispatch();
  const classes = useStyles();
  const { search } = useLocation();

  const ticketId = useMemo(() => {
    const urlParams = new URLSearchParams(search);

    if (urlParams.get('screen') === 'ticket') {
      return urlParams.get('ticketId');
    }

    if (localTicketId) return localTicketId;
    return undefined;
  }, [localTicketId, search]);

  const submit = values => {
    setProcessing(true);
    const payload = {
      ...values,
      crmId: psaId,
      psaContactId,
      psaTicketId: ticketId,
      expirationDateUtc:
        getExpirationDateUtc(values.period) ?? values.expirationDateUtc,
    };
    dispatch(ThunkGetSecureLink({ payload }))
      .unwrap()
      .then(res => {
        handleLinkChange(res.data);
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  const validate = values => {
    return {
      value: requiredValidator(values.value),
      expirationDateUtc: requiredValidator(values.expirationDateUtc),
    };
  };

  const onMount = useCallback(async () => {
    setLoading(true);
    await dispatch(ThunkGetSecureDataSettings());
    setLoading(false);
  }, [dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  if (loading) return <Loading />;

  return (
    <Form
      onSubmit={submit}
      validate={validate}
      initialValues={{
        ...initData,
        expirationDateUtc: initialDate,
      }}
      render={({ handleSubmit, form, values }) => (
        <form onSubmit={handleSubmit} className={classes.form}>
          <FieldWrapper
            label="Password / Secure Data"
            labelSize={12}
            contentSize={12}
            fullWidth
            content={
              <Field
                id="value"
                name="value"
                fullWidth
                dateProps={{ maxLength: 122 }}
                render={props => (
                  <div>
                    <div className={classes.align}>
                      <Input {...props} />
                      <Tooltip title="Generate Password">
                        <KeyIcon
                          onClick={() => {
                            setOpenGenerationSetup(!openGenerationSetup);
                          }}
                          style={{
                            cursor: 'pointer',
                            width: '30px',
                            height: '30px',
                          }}
                        />
                      </Tooltip>
                    </div>
                    {openGenerationSetup && (
                      <PasswordGenerator
                        handleClose={() => [setOpenGenerationSetup(false)]}
                      />
                    )}
                  </div>
                )}
                disabled={processing}
              />
            }
          />

          <div className={classes.marginTop}>
            {initData.lockOptions && (
              <span style={{ fontFamily: 'Monospace' }}>Locked by admin</span>
            )}
          </div>

          <FieldWrapper
            label="Period"
            labelSize={3}
            contentSize={9}
            fullWidth
            content={
              <Field
                id="period"
                name="period"
                fullWidth
                render={props => (
                  <div className={classes.align}>
                    <DropDown {...props} />
                    <DatePicker
                      selected={values?.expirationDateUtc}
                      disabled={initData.lockOptions}
                      onChange={value => {
                        form.change('expirationDateUtc', value);
                        if (
                          values?.period !==
                          expirationPeriodOptions.custom.value
                        ) {
                          form.change(
                            'period',
                            expirationPeriodOptions.custom.value,
                          );
                        }
                      }}
                    />
                  </div>
                )}
                options={Object.values(expirationPeriodOptions)}
                disabled={processing || initData.lockOptions}
              />
            }
          />

          <FieldWrapper
            label="Single Use Link"
            labelSize={11}
            contentSize={1}
            fullWidth
            classNameContainer={classes.noWrap}
            content={
              <Field
                id="removeAfterView"
                name="removeAfterView"
                fullWidth
                type="checkbox"
                component={Checkbox}
                disabled={processing || initData.lockOptions}
              />
            }
          />

          <FieldWrapper
            label="Save logs to ticket/contact"
            labelSize={11}
            contentSize={1}
            fullWidth
            classNameContainer={classes.noWrap}
            content={
              <Field
                id="saveClientNote"
                name="saveClientNote"
                fullWidth
                component={Checkbox}
                type="checkbox"
                disabled={processing || initData.lockOptions}
              />
            }
          />

          <FieldWrapper
            label="Save logs to Internal Note"
            labelSize={11}
            contentSize={1}
            fullWidth
            classNameContainer={classes.noWrap}
            content={
              <Field
                id="saveLogsInternalNote"
                name="saveLogsInternalNote"
                fullWidth
                component={Checkbox}
                type="checkbox"
                disabled={processing || initData.lockOptions}
              />
            }
          />

          <FieldWrapper
            label="Save data to Internal Note"
            labelSize={11}
            contentSize={1}
            fullWidth
            classNameContainer={classes.noWrap}
            content={
              <Field
                id="saveDataInternalNote"
                name="saveDataInternalNote"
                fullWidth
                component={Checkbox}
                type="checkbox"
                disabled={processing || initData.lockOptions}
              />
            }
          />

          <div>
            <ReusableButton
              label="Get Link"
              disabled={processing}
              type="submit"
            />
          </div>
        </form>
      )}
    />
  );
};

GetSecureDataLink.propTypes = {
  handleLinkChange: PropTypes.func.isRequired,
  psaContactId: PropTypes.number,
  psaId: PropTypes.number,
  localTicketId: PropTypes.string,
};
export default GetSecureDataLink;
