import React from 'react';
import { useRedirect } from 'react-admin';
import cx from 'classnames';
/* import AddIcon from '@material-ui/icons/Add'; */
import AddIcon from '@assets/icons/AddPlus.svg';
import { CRM_CREATE } from '@constants/routes';
import ReusableButton from '@common/Button/Button';
import useStyles from '../styles';

const AddCrmCard = () => {
  const classes = useStyles();
  const redirect = useRedirect();
  const handleAddClick = () => redirect(CRM_CREATE);
  return (
    <div
      className={cx(classes.container, classes.addCrmCardContainer)}
      role="presentation"
      style={{ cursor: 'pointer' }}
      onClick={handleAddClick}
    >
      <div className={classes.headerContainer}>
        <span>Add new PSA</span>
      </div>
      <div className={classes.block}>
        <div className={classes.iconWrapper}>
          <img src={AddIcon} alt="addIcon" />
        </div>
      </div>
      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <ReusableButton
          classNameWrapper={classes.btnWrapper}
          size="md"
          viewType="red"
          label="resources.integrations.create.addBtn"
        />
      </div>
    </div>
  );
};

export default AddCrmCard;
