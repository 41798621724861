import React from 'react';
import { useTranslate } from 'react-admin';
import cx from 'classnames';
import PropTypes from 'prop-types';
import CustomCheckbox from '@common/Checkbox';
import useStyles from './styles';

const CreatorCheckBox = ({ checked, setChecked, label }) => {
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <div className={cx(classes.displayFlex, classes.alignCenter)}>
      <CustomCheckbox
        input={{
          checked,
          onChange: event => setChecked(event.target.checked),
        }}
      />
      <span>{translate(label)}</span>
    </div>
  );
};

CreatorCheckBox.propTypes = {
  checked: PropTypes.bool.isRequired,
  setChecked: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

export default CreatorCheckBox;
