import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Loading from '@common/Loading/Loading';
import { Notification } from 'react-admin';
import ErrorIcon from '@assets/icons/dangerTriangle.svg';
import { createTheme, ThemeProvider } from '@material-ui/core';
import { lightTheme } from '@services/themes/mainTheme';
import Index from './Pages/Index';
import useStyles from './styles';

const theme = createTheme(lightTheme);

const SharedBillingLayout = ({ location }) => {
  const classes = useStyles();

  const authKey = useMemo(() => {
    const { search } = location;
    const urlParams = new URLSearchParams(search);
    return urlParams.get('auth-key');
  }, [location]);

  if (!authKey) {
    return (
      <Loading
        customHeader="Error 404"
        customText="Document not found"
        customIcon={ErrorIcon}
        classNameWrapper={classes.center}
      />
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <div>
        <Index authKey={authKey} />
        <Notification />
      </div>
    </ThemeProvider>
  );
};

SharedBillingLayout.propTypes = {
  location: PropTypes.objectOf(PropTypes.any),
};

export default SharedBillingLayout;
