import React from 'react';
import { useHistory } from 'react-router-dom';
import { sanitizeListRestProps, TopToolbar, useTranslate } from 'react-admin';
import PropTypes from 'prop-types';
import cx from 'classnames';
import ReusableButton from '@common/Button/Button';
import exportIcon from '@assets/icons/export.svg';
import refreshIcon from '@assets/icons/refresh.svg';
import { DATA_EXPORT, DATA_VIEW_EDIT } from '@constants/routes';

import useStyles from './styles';

const ListActions = ({ className, onRefresh, dataViewId, ...rest }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const history = useHistory();

  return (
    <TopToolbar
      classes={{ root: classes.root }}
      className={cx(className, classes.toolBar)}
      {...sanitizeListRestProps(rest)}
    >
      <ReusableButton
        label="Edit DataView"
        onClick={() => {
          history.push(DATA_VIEW_EDIT.replace(':dataViewId', dataViewId));
        }}
      />
      <ReusableButton
        size="md"
        viewType="black"
        onClick={() => history.push(DATA_EXPORT)}
        icon={null}
        label="common.export"
        classNameWrapper={classes.buttonWrapper}
      >
        <>
          <img src={exportIcon} alt="export" />
          {translate('common.export')}
        </>
      </ReusableButton>
      <ReusableButton
        size="md"
        onClick={onRefresh}
        classNameWrapper={classes.buttonWrapper}
      >
        <>
          <img src={refreshIcon} alt="refresh" />
          {translate('common.refresh')}
        </>
      </ReusableButton>
    </TopToolbar>
  );
};

ListActions.propTypes = {
  onRefresh: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default ListActions;
