import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  form: {
    background: 'white',
    padding: '30px',
    boxSizing: 'border-box',
    margin: '10px',
    maxHeight: '80vh',
    overflow: 'auto',
  },
  margins: {
    margin: '10px 0px',
  },
  align: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    '& .react-datepicker-wrapper': {
      width: 'unset',
      '& input': {
        padding: '10px',
        background: '#3f51b5',
        border: 'unset',
        borderRadius: '5px',
        color: 'white',
        cursor: 'pointer',
        fontWeight: 700,
        fontFamily: "'Nunito', sans-serif",
      },
    },
  },
  link: {
    cursor: 'pointer',
  },
  noWrap: {
    flexWrap: 'nowrap !important',
    '& .MuiGrid-item': {
      display: 'flex',
      justifyContent: 'flex-start',
    },
  },
  formContainer: {
    height: 'unset !important',
    padding: '10px 30px',
  },
  fullWidthInput: {
    width: '50%',
  },
  marginTop: {
    marginTop: '1rem',
  },
  inputDiv: {
    gap: '10px',
    display: 'grid',
    alignItems: 'center',
    marginRight: '10px',
    marginBottom: '24px',
    gridTemplateColumns: '100px 1fr',
  },
}));

export default useStyles;
