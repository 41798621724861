import React from 'react';
import { Notification } from 'react-admin';
import PropTypes from 'prop-types';
import cx from 'classnames';

import useStyles from './styles';

const UnauthorizedLayout = ({
  children,
  extraClasses,
  baseColorA = '#CFCFCF',
  baseColorB = '#545B6B',
  ...rest
}) => {
  const classes = useStyles({ baseColorA, baseColorB });

  return (
    <div className={cx(classes.root, extraClasses)} {...rest}>
      <div>{children}</div>
      <Notification />
    </div>
  );
};

UnauthorizedLayout.propTypes = {
  children: PropTypes.node,
};

export default UnauthorizedLayout;
