import React from 'react';
import { useTranslate } from 'react-admin';
import PropTypes from 'prop-types';
import xlsIcon from '@assets/exports/xls.svg';
import csvIcon from '@assets/exports/csv.svg';
import jsonIcon from '@assets/exports/json.svg';
import Card from '@common/CardDownload/Card';

import useStyles from './styles';

const Download = ({ resource }) => {
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <div className={classes.tabBase}>
      <div className={classes.marginLeft}>
        {translate('resources.exporting.download.header')}
      </div>
      <div className={classes.displayFlex}>
        <Card
          data={resource?.xlsx}
          icon={<img src={xlsIcon} alt="xls" className={classes.iconWidth} />}
        />
        <Card
          data={resource?.csv}
          icon={<img src={csvIcon} alt="csv" className={classes.iconWidth} />}
        />
        <Card
          data={resource?.json}
          icon={<img src={jsonIcon} alt="json" className={classes.iconWidth} />}
        />
      </div>
    </div>
  );
};

Download.propTypes = {
  resource: PropTypes.objectOf(PropTypes.any),
};

export default Download;
