import phoneNumberValidator from '@utils/validators/phoneNumberValidator';

const requiredPhoneListValidator = value => {
  if (!value || !Array.isArray(value) || !value.length) {
    return 'ra.validation.required';
  }
  const errorArray = value
    .map(item =>
      !phoneNumberValidator(item) ||
      (item.startsWith('{$') && item.endsWith('}'))
        ? null
        : item,
    )
    .filter(item => !!item);

  return errorArray.length
    ? `Invalid phone or template: ${errorArray.join(', ')}`
    : undefined;
};

export default requiredPhoneListValidator;
