import React from 'react';
import PropTypes from 'prop-types';
import { useNotify } from 'react-admin';
import CopyToClipboard from '@common/Copy/CopyToClipboard';
import useStyles from './styles';

const InfoSpan = ({
  onMouseOver,
  onMouseDown,
  onMouseUp,
  text,
  className,
  name = null,
  copyable = false,
}) => {
  const classes = useStyles();
  const notify = useNotify();
  return (
    <>
      {name && (
        <span className={classes.metaCaption}>
          {name}
          :&nbsp;
        </span>
      )}
      {/* eslint-disable-next-line jsx-a11y/mouse-events-have-key-events */}
      <span
        am-element-role={className}
        className={className}
        role="presentation"
        onMouseOver={onMouseOver}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
      >
        {text}
      </span>
      {copyable && (
        <CopyToClipboard text={text} onCopy={() => notify('common.copied')} />
      )}
    </>
  );
};

InfoSpan.propTypes = {
  onMouseOver: PropTypes.func,
  onMouseDown: PropTypes.func,
  onMouseUp: PropTypes.func,
  text: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  copyable: PropTypes.bool,
};

export default InfoSpan;
