import React from 'react';
import PropTypes from 'prop-types';
import { SimpleForm, TextInput, useTranslate } from 'react-admin';

import {
  Dialog as MuiDialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';

import { LOGIN_ROUTE } from '@constants/routes';
import Toolbar from './Toolbar';

const Dialog = ({ open, onToggle, onSave, ...props }) => {
  const translate = useTranslate();

  return (
    <MuiDialog open={open} onClose={onToggle} fullWidth maxWidth="sm">
      <DialogTitle>{translate('pages.profile.areYouSure')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {translate('pages.profile.cannotUndoAction')}
        </DialogContentText>
        <SimpleForm
          {...props}
          toolbar={<Toolbar onToggle={onToggle} />}
          redirect={LOGIN_ROUTE}
        >
          <TextInput
            autoFocus
            fullWidth
            variant="outlined"
            type="password"
            source="confirmPassword"
          />
        </SimpleForm>
      </DialogContent>
    </MuiDialog>
  );
};

Dialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  onSave: PropTypes.func,
};

export default Dialog;
