import React from 'react';
import { useTranslate } from 'react-admin';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { getFullEmail } from '@services/helpers';
import ActionButton from '@common/buttons/ActionButton/ActionButton';
import copyText from '@utils/copy';
import { ReactComponent as IconCopy } from '@assets/icons/copyWhite.svg';
import { ReactComponent as IconDashboard } from '@assets/icons/dashboard.svg';
import { ReactComponent as IconDocument } from '@assets/icons/document.svg';
import { ReactComponent as IconParsers } from '@assets/icons/parsers.svg';
import { ReactComponent as IconSetting } from '@assets/icons/setting.svg';
import useStyles from './styles';
import iconTypes from './iconTypes';
import MiniBox from './MailBoxMiniCardItem';
import MailBoxCartItem from './MailBoxCartdtem';

const makeFirstLetterCapital = value =>
  value.charAt(0).toUpperCase() + value.slice(1);

const MailBoxCard = ({ item }) => {
  const classes = useStyles();
  const translate = useTranslate();

  const getText = value =>
    translate('resources.home.mailBoxCard.'.concat(value));
  const getDocumentCaption = value =>
    value === 1 ? getText('document') : getText('documents');

  const goDashboard = () => {
    window.location.href = '#/mailbox/'.concat(item.id).concat('/dashboard');
  };

  const goDocs = () => {
    window.location.href = '#/mailbox/'.concat(item.id).concat('/documents');
  };

  const goParsers = () => {
    window.location.href = '#/mailbox/'.concat(item.id).concat('/parsers');
  };

  const goSettings = () => {
    window.location.href = '#/mailbox/'.concat(item.id).concat('/settings');
  };

  return (
    <div className={classes.container} role="presentation">
      <div className={classes.titleWrapper}>
        <span className={classes.title}>
          {makeFirstLetterCapital(item.name)}
        </span>
        <Box className={classes.emailWrapper}>
          <span className={classes.titleMail}>{getFullEmail(item.name)}</span>
          <ActionButton
            icon={<IconCopy alt="iconCopy" />}
            handler={() => copyText(getFullEmail(item.name))}
            toolTip="Copy email"
            classNameWrapper={classes.buttonCopy}
          />
        </Box>
      </div>
      <div className={classes.body}>
        <MailBoxCartItem icon={<IconDashboard alt="iconDashboard" />}>
          <span
            className={classes.link}
            onClick={goDashboard}
            role="presentation"
          >
            {getText('dashboard')}
          </span>
        </MailBoxCartItem>
        <MailBoxCartItem icon={<IconDocument alt="iconDocument" />}>
          <span className={classes.link} onClick={goDocs} role="presentation">
            {item.total}
            &nbsp;
            {getDocumentCaption(item.total)}
          </span>
        </MailBoxCartItem>
        <div className={classes.iconsWrapper}>
          <MiniBox
            data={item.attentionRequired}
            label={getText('parsed')}
            iconType={iconTypes.check}
          />
          <MiniBox
            data={item.processed}
            label={getText('processed')}
            iconType={iconTypes.add}
          />
          <MiniBox
            data={item.failed}
            label={getText('failed')}
            iconType={iconTypes.reportProblem}
          />
          <MiniBox
            data={item.quotaExceeded}
            label={getText('quotaExceeded')}
            iconType={iconTypes.error}
          />
          <MiniBox
            data={item.ignored}
            label={getText('ignored')}
            iconType={iconTypes.ignored}
          />
        </div>
        <MailBoxCartItem icon={<IconParsers alt="iconParsers" />}>
          <span
            className={classes.link}
            onClick={goParsers}
            role="presentation"
          >
            {item.parsersCount}
            &nbsp; of &nbsp;
            {item.totalParsersCount}
            &nbsp;
            {getText('parsers')}
          </span>
        </MailBoxCartItem>
        <MailBoxCartItem icon={<IconSetting alt="iconSetting" />}>
          <span
            className={classes.link}
            onClick={goSettings}
            role="presentation"
          >
            {getText('settings')}
          </span>
        </MailBoxCartItem>
      </div>
    </div>
  );
};

MailBoxCard.defaultProps = {
  item: {
    id: 1,
    name: 'test',
    total: 2,
    parsed: 0,
    processed: 1,
    failed: 2,
    quotaExceeded: 0,
    parsersCount: 1,
  },
};

MailBoxCard.propTypes = {
  item: PropTypes.objectOf(PropTypes.any),
};

export default MailBoxCard;
