import React, { useEffect, useState } from 'react';
import BaseSettings from '@components/DataSources/Integrations/pages/create/BaseSettings';
import { DATA_SOURCES_INTEGRATION } from '@constants/routes';
import { useNotify, useRedirect } from 'react-admin';
import Loading from '@common/Loading/Loading';
import { getIntegrationById } from '@components/DataSources/helpers';

const Edit = ({ match }) => {
  const redirect = useRedirect();
  const notify = useNotify();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getIntegrationById(match.params.id)
      .then(d => {
        setData(d);
      })
      .catch(e => notify(e.message, 'error'))
      .finally(() => setLoading(false));
  }, [match, notify]);

  if (loading) {
    return <Loading />;
  }

  const handleBack = () => {
    redirect(DATA_SOURCES_INTEGRATION);
  };

  return (
    <div>
      <BaseSettings
        item={data}
        integrationType={data?.type}
        onBack={handleBack}
      />
    </div>
  );
};

export default Edit;
