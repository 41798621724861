import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import ReusableButton from '@common/Button/Button';
import FieldWrapper from '@common/form/FieldWrapper';
import Input from '@common/Input/Input';
import { ftpUrl, validateRequired } from '@common/functions/validators';
import TestArea from '@common/TestArea/TestArea';
import { testConnection } from './helpers';
import useStyles from './styles';

const initData = {
  name: '',
  url: '',
  userName: '',
  password: '',
};

const fields = [
  { name: 'name', label: 'Name', type: 'text', autoCompete: 'off' },
  { name: 'url', label: 'URL', type: 'text', autoCompete: 'off' },
  { name: 'userName', label: 'User Name', type: 'text', autoCompete: 'off' },
  {
    name: 'password',
    label: 'Password',
    type: 'password',
    autoCompete: 'new-password',
  },
];

const FormDialog = ({ open, onClose, item, onSubmit, loading }) => {
  const classes = useStyles();
  const initial = item || initData;

  const [testOutput, setTestOutput] = useState(null);
  const [localLoading, setLocalLoading] = useState(false);

  const handleCancel = () => {
    setTestOutput(null);
    onClose();
  };

  const validate = values => {
    return {
      name: validateRequired(values.name),
      url: validateRequired(values.url) || ftpUrl(values.url),
      userName: validateRequired(values.userName),
      password: validateRequired(values.password),
    };
  };

  const handleTestClick = (values, formInvalid) => {
    if (!formInvalid) {
      setLocalLoading(true);
      testConnection(values)
        .then(() => setTestOutput({ isOk: true, message: 'OK' }))
        .catch(e => setTestOutput({ isOk: false, message: e.message }))
        .finally(() => setLocalLoading(false));
    }
  };

  return (
    <div className={classes.contentFormWrapper}>
      <Dialog
        onClose={onClose}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <Form
          onSubmit={onSubmit}
          validate={validate}
          initialValues={initial}
          render={({ handleSubmit, values, form }) => (
            <form onSubmit={handleSubmit} noValidate className={classes.form}>
              <DialogTitle id="simple-dialog-title">
                Setup FTP connection
              </DialogTitle>
              <DialogContent>
                <div className={classes.textCenter}>
                  <span className={classes.monospace}>
                    {`format: ftp://{server address}:{port}`}
                  </span>
                </div>
                {fields.map(field => (
                  <FieldWrapper
                    key={field.name}
                    label={field.label}
                    labelSize={4}
                    classNameContainer={classes.formItem}
                    content={
                      <Field
                        id={field.name}
                        name={field.name}
                        styleType="main"
                        inputView="text"
                        type={field.type}
                        component={Input}
                        autoCompete={field.autoCompete}
                        fullWidth
                        disabled={loading}
                      />
                    }
                  />
                ))}
                <TestArea
                  onClick={() =>
                    handleTestClick(values, form.getState().invalid)
                  }
                  loading={loading || localLoading}
                  testOutput={testOutput}
                  classNameWrapper={classes.noMargin}
                />
              </DialogContent>
              <DialogActions>
                <ReusableButton
                  type="button"
                  label="resources.buttons.cancel"
                  onClick={handleCancel}
                  disabled={loading}
                />
                <ReusableButton
                  viewType="black"
                  label="resources.buttons.confirm"
                  type="submit"
                  disabled={loading}
                  loading={loading}
                />
              </DialogActions>
            </form>
          )}
        />
      </Dialog>
    </div>
  );
};

FormDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  item: PropTypes.objectOf(PropTypes.any),
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default FormDialog;
