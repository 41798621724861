import React from 'react';
import {
  useNotify,
  useRedirect,
  useSafeSetState,
  useTranslate,
} from 'react-admin';
import { Field, Form } from 'react-final-form';
import { Button, CircularProgress } from '@material-ui/core';
import { ArrowForward as ArrowForwardIcon } from '@material-ui/icons';
import cx from 'classnames';

import { getHeaders } from '@utils/transfered';

import Input from '@common/Input/Input';
import { checkMailBoxName } from '@components/MailBox/helpers';

import appConfig from '@configs/appConfig';
import useStyles from './styles';

const FirstMailBox = () => {
  const [loading, setLoading] = useSafeSetState(false);
  const classes = useStyles();
  const notify = useNotify();
  const redirect = useRedirect();
  const translate = useTranslate();

  const validate = async values => {
    const name = values.name ? values.name : ' ';
    const errors = {
      name: undefined,
    };

    const checkName = await checkMailBoxName(name);
    errors.name = checkName.data !== 'Allow' ? checkName.data : undefined;

    if (!/^([A-Za-z0-9_]*)$/.test(name)) {
      errors.name = 'Name contains incorrect symbols';
    }

    if (!values.name) {
      errors.name = translate('ra.validation.required');
    }
    return errors;
  };

  const submit = async values => {
    setLoading(true);
    const response = await fetch(appConfig.baseUrl.concat('/MailBoxes'), {
      method: 'POST',
      body: JSON.stringify(values),
      headers: getHeaders(true),
    }).catch(e => {
      const message = e.message || response.statusText;
      notify(message);
    });

    if (response.status === 401 || response.status === 403) {
      const json = await response.json();
      notify(json.message);
    } else if (response.status < 200 || response.status >= 300) {
      notify('Server error');
    } else {
      notify(translate('validation.success'));
      redirect('/home');
    }
    setLoading(false);
  };

  return (
    <div className={classes.container}>
      <div className={classes.element}>
        <span className={cx(classes.baseFont, classes.header)}>
          {translate('pages.firstMailBox.welcome')}
        </span>
      </div>
      <div className={classes.element}>
        <span className={cx(classes.baseFont, classes.subHeader)}>
          {translate('pages.firstMailBox.create')}
        </span>
      </div>
      <div className={classes.element}>
        <span className={cx(classes.baseFont, classes.subHeader2)}>
          {translate('pages.firstMailBox.action')}
        </span>
      </div>
      <div className={classes.element}>
        <Form
          onSubmit={submit}
          validate={validate}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} noValidate className={classes.form}>
              <div>
                <Field
                  autoFocus
                  id="name"
                  name="name"
                  component={Input}
                  styleType="main"
                  inputView="text"
                  classNameWrapper={classes.fieldWrapper}
                  placeholder={translate('pages.firstMailBox.name')}
                  disabled={loading}
                />
                <p className={cx(classes.baseFont, classes.domain)}>
                  {'@'.concat(appConfig.mailBoxDomain)}
                </p>
              </div>
              <div className={cx(classes.element, classes.elementSpace)}>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  disabled={loading}
                  className={classes.button}
                >
                  {loading && <CircularProgress size={18} thickness={2} />}
                  <div className={classes.buttonContainer}>
                    <span className={classes.buttonContainerText}>
                      {' '}
                      {translate('pages.firstMailBox.buttonCaption')}
                    </span>
                    <ArrowForwardIcon className={classes.buttonContainerIcon} />
                  </div>
                </Button>
              </div>
            </form>
          )}
        />
      </div>
    </div>
  );
};

export default FirstMailBox;
