import { useTranslate } from 'react-admin';

const useTranslatedText = resource => {
  const translate = useTranslate();
  const getText = src => translate(`resources.${resource}.${src}`);
  const getFieldText = src => translate(`resources.${resource}.fields.${src}`);
  return { getText, getFieldText };
};

export default useTranslatedText;
