import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNotify } from 'react-admin';

import ThunkGetCountries from '@store/slices/countries/thunks';
import { ThunkGetTenantCountry } from '@store/slices/tenant/thunks';
import { ThunkAddUsersToSmsCampaignGroup } from '@store/slices/smsCampaigns/thunks';
import { actions } from '@store/actions';
import { countryCodesListSelector } from '@store/slices/countries';

import maxLengthValidator from '@utils/validators/maxLengthValidator';
import phoneWithCharactersValidator from '@utils/validators/phoneWithCharactersValidator';
import { SMS_CAMPAIGN_USER_TYPES } from '@constants/smsCampaign';
import { requiredValidator } from '@utils/validators';

import FieldWrapper from '@common/form/FieldWrapper';
import Input from '@components/Auth/Common/Input';
import ReusableButton from '@common/Button/Button';
import PhoneField from '@common/PhoneField';
import Loading from '@common/Loading/Loading';

import useSharedStyles from '@components/CrmTicket/sharedStyles';
import { usePhoneInitialData } from '../../../../../hooks/usePhoneInitialData';

const SmsCampaignManualUsers = ({ open, onClose, smsCampaignGroupId }) => {
  const [loading, setLoading] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [defaultTenantCodes, setDefaultTenantCodes] = useState({
    tenantCountryCode: '',
    tenantDialCode: '',
  });

  const dispatch = useDispatch();
  const sharedStyles = useSharedStyles();
  const notify = useNotify();

  const submit = values => {
    setProcessing(true);

    const payload = {
      type: SMS_CAMPAIGN_USER_TYPES.MANUAL,
      smsCampaignGroupId,
      contacts: [
        {
          name: values.name,
          phone: values.countryCode.split(' ')[1] + values.phone,
        },
      ],
    };

    dispatch(ThunkAddUsersToSmsCampaignGroup({ payload }))
      .unwrap()
      .then(res => {
        dispatch(actions.updateItemInList(res[0]));
        onClose();
        notify('Successfully added', 'success');
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  const validate = values => {
    return {
      name: maxLengthValidator(values.name, 250),
      phone: phoneWithCharactersValidator(values.phone, 9, true),
      countryCode: requiredValidator(values.countryCode),
    };
  };

  const { countries } = useSelector(countryCodesListSelector);

  const countryCodesOptions = useMemo(() => {
    return countries.map(list => ({
      value: `${list.code} ${list.dialCode}`,
      label: `${list.code} ${list.dialCode}`,
      icon: (
        <img
          alt={list.code}
          src={`https://flagcdn.com/w20/${list.code.toLowerCase()}.png`}
        />
      ),
    }));
  }, [countries]);

  const {
    initialIcon,
    countryCodeFormValue,
    nationalNumber,
  } = usePhoneInitialData({
    phone: '',
    countries,
    defaultTenantCodes,
  });

  const onMount = () => {
    setLoading(true);
    Promise.all([
      dispatch(ThunkGetCountries()).unwrap(),
      dispatch(ThunkGetTenantCountry()).unwrap(),
    ])
      .then(([countriesAutocomplete, res]) => {
        const tenantCountry = countriesAutocomplete.find(
          country => country.code === res.data,
        );

        setDefaultTenantCodes({
          tenantCountryCode: tenantCountry?.code ?? '',
          tenantDialCode: tenantCountry?.dialCode ?? '',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    onMount();
  }, []);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add User</DialogTitle>
      <DialogContent>
        <Form
          onSubmit={submit}
          validate={validate}
          initialValues={{
            name: '',
            phone: nationalNumber,
            countryCode: countryCodeFormValue,
          }}
          render={({ handleSubmit }) =>
            loading ? (
              <Loading />
            ) : (
              <form onSubmit={handleSubmit}>
                <FieldWrapper
                  label="Full Name"
                  labelSize={12}
                  contentSize={12}
                  fullWidth
                  isRequired
                  content={
                    <Field
                      name="name"
                      id="name"
                      render={Input}
                      disabled={processing}
                    />
                  }
                />

                <PhoneField
                  isRequired
                  phoneName="phone"
                  phoneCodeName="countryCode"
                  label="Phone Number"
                  countryCodesOptions={countryCodesOptions}
                  initialIcon={initialIcon}
                />

                <div className={sharedStyles.dialogActionsContainer}>
                  <ReusableButton
                    label="Cancel"
                    onClick={onClose}
                    disabled={processing}
                  />
                  <ReusableButton
                    label="Submit"
                    type="submit"
                    disabled={processing}
                    loading={processing}
                  />
                </div>
              </form>
            )
          }
        />
      </DialogContent>
    </Dialog>
  );
};

SmsCampaignManualUsers.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  smsCampaignGroupId: PropTypes.number.isRequired,
};

export default SmsCampaignManualUsers;
