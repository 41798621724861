/* eslint-disable no-cond-assign */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReusableButton from '@common/Button/Button';
import FieldTransformerRecord from './FieldTransformerRecord';
import { selectionTypeOptions } from '../DataExpression/options';
import CustomTransformerRecord from './CustomTransformerRecord';
import LastResultField from './LastResultField';

import useStyles from './styles';

const pattern = /\(\?<(\w+)>.+?\)/g;

const FieldTransformer = ({
  fld: allTransformers,
  setFld: setAllTransformers,
  expressions,
  typesOptions,
}) => {
  const classes = useStyles();
  const [transformers, setTransformers] = useState([]);
  const [customTransformers, setCustomTransformers] = useState([]);
  const [lastResult, setLastResult] = useState('');

  const syncFld = (tr, ctr) => {
    const prettyTr = tr.map(i => {
      return {
        name: i.name,
        path: i.path,
        identifier: i.identifier,
        type: i.type,
        lastField: !!i.lastField,
      };
    });
    setAllTransformers(prettyTr.concat(ctr));
  };

  useEffect(() => {
    const matches = [];

    expressions.forEach(item => {
      let match;
      while ((match = pattern.exec(item.value)) != null) {
        let i = match[1];
        if (item.selectionType === selectionTypeOptions[1].value) {
          i = `${item.name}[].${match[1]}`;
        }
        if (item.selectionType === selectionTypeOptions[0].value && item.name) {
          i = `${item.name}.${match[1]}`;
        }

        matches.push(i);
      }
    }, []);

    // field transformers based on data expressions
    const tr = matches.map(match => {
      const arr = match.split('[].');
      const ex = allTransformers.find(i => i.path === match);
      return {
        checked: true,
        name: !ex ? [...arr].slice(-1)[0] : ex.name,
        path: match,
        identifier: !ex ? false : ex.identifier,
        type: ex ? ex.type || 0 : 0,
      };
    });
    const uniqueTr = tr.filter(
      (elem, index) => tr.findIndex(obj => obj.name === elem.name) === index,
    );
    setTransformers(uniqueTr);

    const cTr = allTransformers
      .filter(i => i.path.startsWith('@'))
      .map(i => {
        return {
          id: i.id,
          name: i.name,
          path: i.path,
          identifier: !i.identifier ? false : i.identifier,
          type: !i.type ? 0 : i.type,
        };
      });

    syncFld(
      tr.filter(i => i.checked),
      cTr,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expressions]);

  // custom field transformers
  useEffect(() => {
    const cTr = allTransformers
      .filter(i => i.path.startsWith('@'))
      .map(i => {
        return {
          id: i.id,
          name: i.name,
          path: i.path,
          identifier: !i.identifier ? false : i.identifier,
          type: !i.type ? 0 : i.type,
        };
      });
    setCustomTransformers(cTr);

    // set last field value
    allTransformers.forEach(i => {
      if (i.lastField) {
        setLastResult(i.name);
      }
    });
  }, [allTransformers]);

  const handleFieldTransformerChange = useCallback(
    item => {
      const newTransformers = [...transformers];
      const idx = newTransformers.findIndex(i => i.path === item.path);
      newTransformers[idx] = item;
      setTransformers(newTransformers);
      const groomedTransformers = newTransformers.map(i => {
        return {
          name: i.name,
          path: i.path,
          identifier: i.identifier,
          type: i.type,
        };
      });
      setLastResult('');
      const all = groomedTransformers.concat(customTransformers);
      setAllTransformers(all);
    },
    [customTransformers, setAllTransformers, transformers],
  );

  const handleCustomFieldTransformerChange = item => {
    const flds = [...allTransformers];
    const index = flds.findIndex(i => i.id === item.id);
    if (index > -1) {
      flds[index] = item;
      setAllTransformers(flds);
    }
  };

  const handleAddNewCustomTransformer = () => {
    const flds = [...allTransformers];
    flds.push({
      id: Math.floor(Math.random() * 10000),
      name: '',
      path: '@',
    });
    setAllTransformers(flds);
  };

  const handleDeleteCustomFieldTransformer = id => {
    const flds = [...allTransformers];
    const index = flds.findIndex(i => i.id === id);
    if (index > -1) {
      flds.splice(index, 1);
      setAllTransformers(flds);
    }
  };

  const handleLastResultChanged = event => {
    const { value } = event.target;
    const flds = allTransformers.map(i => {
      if (i.name === value && i.path !== '@') {
        i.lastField = true;
      } else {
        delete i.lastField;
      }
      return i;
    });
    setAllTransformers(flds);
    setLastResult(value);
  };

  return (
    <div>
      {transformers.map(t => (
        <FieldTransformerRecord
          key={t.path}
          item={t}
          onChange={handleFieldTransformerChange}
          typesOptions={typesOptions}
        />
      ))}
      {customTransformers.map(t => (
        <CustomTransformerRecord
          key={t.id}
          item={t}
          onChange={handleCustomFieldTransformerChange}
          onDelete={handleDeleteCustomFieldTransformer}
          typesOptions={typesOptions}
        />
      ))}
      <LastResultField
        options={transformers
          .filter(i => !!i.checked && !i.identifier)
          .map(i => ({ label: i.name, value: i.name }))}
        value={lastResult}
        onChange={handleLastResultChanged}
      />
      <ReusableButton
        size="md"
        label="common.create"
        icon={null}
        classNameWrapper={classes.buttonWrapper}
        onClick={handleAddNewCustomTransformer}
      />
    </div>
  );
};
FieldTransformer.propTypes = {
  fld: PropTypes.arrayOf(PropTypes.object).isRequired,
  expressions: PropTypes.arrayOf(PropTypes.object).isRequired,
  setFld: PropTypes.func.isRequired,
  typesOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
};
export default FieldTransformer;
