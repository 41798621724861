import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Input from '@components/Auth/Common/Input';
import { useTranslate } from 'react-admin';
import useStyles from './styles';

const CreatorInput = ({ input, setValue, label }) => {
  const classes = useStyles();
  const translate = useTranslate();
  return (
    <div className={cx(classes.displayFlex, classes.alignCenter)}>
      <span>
        {translate(label)}
        :&nbsp;
      </span>
      <Input
        meta={{ touched: input.touched, error: input.error }}
        input={{
          value: input.value,
          onChange: event => setValue(event.target.value),
        }}
      />
    </div>
  );
};

CreatorInput.propTypes = {
  input: PropTypes.objectOf(PropTypes.any).isRequired,
  setValue: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

export default CreatorInput;
