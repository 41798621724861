import React from 'react';
import { useTranslate } from 'react-admin';
import { useSelector } from 'react-redux';
import { Field, Form } from 'react-final-form';
import Loading from '@common/Loading/Loading';
import PropTypes from 'prop-types';
import FieldWrapper from '@common/form/FieldWrapper';
import Input from '@common/Input/Input';
import Paper from '@common/Paper/Paper';
import ReusableButton from '@common/Button/Button';
import { emailSettings } from '@store/selectors/notifications';
import { actions } from '@store/actions';
import iconArrow from '@assets/icons/arrowGrey.svg';
import useDisabled from '@components/Hooks/useDisabled';
import { basePermissions } from '@constants/permissions';
import ActionButton from '@common/buttons/ActionButton/ActionButton';
import iconPlus from '@assets/icons/plusGrey.svg';
import useTemplateVariables from '@components/Ticketing/Notifications/hooks/useTemplateVariables';
import { getTemplates } from '@components/Ticketing/Notifications/DmSettings/helpers';
import TemplateMenu from '@components/Ticketing/Notifications/components/TemplateMenu';
import { EmailInput } from '@components/Ticketing/Notifications/TicketSettings/components/subComponents';
import useStyles from './styles';
import { getEmailSettings, postEmailSettings } from './helpers';
import useTicketSetting from '../../hooks/useTicketSetting';
import { emailSettingsValidator } from '../../validators';

const EmailDMSettings = ({ match }) => {
  const classes = useStyles();
  const translate = useTranslate();

  const storedData = useSelector(emailSettings);

  const {
    submit,
    loading,
    handleBack,
    data,
    anchorEl,
    setAnchorEl,
  } = useTicketSetting({
    storedData,
    paramsId: match.params.id,
    action: actions.saveEmailSetting,
    tileKey: 'isEmail',
    apiPostRequest: postEmailSettings,
    apiGetSetting: getEmailSettings,
  });

  const { templateVariables } = useTemplateVariables({ func: getTemplates });

  const { editMode, disabled } = useDisabled(
    loading,
    'notificationSettings',
    basePermissions.update,
  );

  const dataLoaded = typeof data.id !== 'undefined';

  return dataLoaded ? (
    <Paper>
      <Form
        onSubmit={submit}
        validate={emailSettingsValidator}
        initialValues={data}
        render={({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit} noValidate className={classes.form}>
            <div className={classes.contentFormWrapper}>
              <FieldWrapper
                label="Email"
                labelSize={3}
                content={
                  <div className={classes.ticketSubject}>
                    <EmailInput
                      form={form}
                      disabled={disabled}
                      templateVariables={templateVariables}
                    />
                  </div>
                }
              />
              <FieldWrapper
                label="Subject"
                labelSize={3}
                content={
                  <div className={classes.ticketSubject}>
                    <ActionButton
                      handler={event => setAnchorEl(event.currentTarget)}
                      icon={<img src={iconPlus} alt="iconPlus" />}
                      toolTip="Add template variable"
                      disabled={disabled}
                    />
                    <Field
                      id="subject"
                      name="subject"
                      styleType="main"
                      inputView="text"
                      fullWidth
                      component={Input}
                      disabled={disabled}
                    />
                  </div>
                }
              />
            </div>
            <div className={classes.buttonWrapper}>
              <ReusableButton
                size="md"
                type="button"
                classNameWrapper={classes.button}
                disabled={loading}
                onClick={handleBack}
              >
                <>
                  <img src={iconArrow} alt="iconArrow" />
                  {translate('resources.buttons.back')}
                </>
              </ReusableButton>
              {editMode && (
                <ReusableButton
                  size="md"
                  viewType="black"
                  type="submit"
                  classNameWrapper={classes.button}
                  disabled={loading}
                  loading={loading}
                >
                  {translate('resources.billing.updateButton')}
                </ReusableButton>
              )}
            </div>
            <TemplateMenu
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              form={form}
              name="subject"
              templateVariables={templateVariables}
            />
          </form>
        )}
      />
    </Paper>
  ) : (
    <Loading />
  );
};

EmailDMSettings.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.string }),
  }),
};

export default EmailDMSettings;
