import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCurrentContactData } from '@services/requests/psaPods';
import { VERIFICATION_SETTINGS } from '@constants/routes';

export const ThunkGetCurrentContactData = createAsyncThunk(
  `${VERIFICATION_SETTINGS}/getCurrentContactData`,
  async ({ crmId, contactId, psaType }) =>
    getCurrentContactData(crmId, contactId, psaType),
);

export default {};
