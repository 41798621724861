import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Field, Form } from 'react-final-form';
import { useListContext, TextInput } from 'react-admin';
import { Button } from '@material-ui/core';
import { ArrowUpward } from '@material-ui/icons';
import { sortOrders, COUNT_PRODUCT_PER_PAGE } from '@constants/filters';
import { customTypeIdMeasure } from '@constants/numericConstants';
import DropDown from '@components/Auth/Common/DropDown';
import useStyles from './styles';

const TableLabel = ({ item: { field, name, type }, typeValues }) => {
  const classes = useStyles();
  const {
    setFilters,
    filterValues,
    currentSort,
    setSort,
    setPerPage: setCountData,
    basePath,
  } = useListContext();

  const types = useMemo(
    () => [
      { value: '', label: 'All' },
      ...typeValues
        .filter(item => item.typeId === type)
        .map(item => ({ value: item.id, label: item.name })),
    ],
    [type, typeValues],
  );

  const isCurrentFieldSorted = currentSort.field === field;
  const filters = JSON.parse(sessionStorage.getItem(basePath)) || {};
  const [inputValue, setInputValue] = useState(
    filterValues[field] || filters[field] || '',
  );

  const onChange = e => {
    const { value } = e.target;
    setCountData(COUNT_PRODUCT_PER_PAGE);
    setInputValue(value);
    setFilters({ [field]: value }, filterValues, true);

    filters[field] = value;
    sessionStorage.setItem(basePath, JSON.stringify(filters));
  };

  const setSorting = () => {
    setCountData(COUNT_PRODUCT_PER_PAGE);
    setSort(field);
    setFilters({}, filterValues, true);
  };

  const classNameForButtonIcon = cx(classes.tableHeaderButtonIcon, {
    [classes.tableHeaderButtonIconRotate]:
      isCurrentFieldSorted && currentSort.order === sortOrders.asc,
  });

  useEffect(() => {
    setFilters(filters, filterValues, true);
  }, []);

  return (
    <Form onSubmit={() => {}}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} className={classes.tableHeaderForm}>
          <Button onClick={setSorting} className={classes.tableHeaderButton}>
            <ArrowUpward className={classNameForButtonIcon} />
          </Button>
          {type >= customTypeIdMeasure ? (
            <Field
              id={field}
              name={field}
              label={name}
              fullWidth
              component={({ input, ...rest }) => (
                <DropDown
                  {...rest}
                  input={{
                    value: input.value,
                    onChange: e => {
                      input.onChange(e);
                      onChange(e);
                    },
                  }}
                />
              )}
              classNameWrapper={classes.inputSmallWrapper}
              options={types}
            />
          ) : (
            <TextInput
              className={classes.tableHeaderInput}
              source={field}
              label=""
              defaultValue={inputValue}
              placeholder={name}
              onChange={onChange}
            />
          )}
        </form>
      )}
    </Form>
  );
};

TableLabel.propTypes = {
  item: PropTypes.shape({
    field: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.number,
  }),
  typeValues: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ),
};

export default TableLabel;
