import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { Typography } from '@material-ui/core';
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import { useTranslate } from 'react-admin';

import {
  ThunkGetIntegrationCompanies,
  ThunkGetSuperOpsIntegrationStatuses,
  ThunkGetSuperOpsIntegrationTypes,
} from '@store/slices/integration/thunks';
import crmSources from '@constants/crmSources';

import Input from '@common/Input/Input';
import DropDown from '@components/Auth/Common/DropDown';
import ReusableButton from '@common/Button/Button';
import AutocompleteFormInput from '@common/AutocompleteFormInput/AutocompleteFormInput';
import Loading from '@ui/components/common/Loading/Loading';

import { baseFieldName } from '@components/Ticketing/Integrations/pages/updateCrm/CrmDefaults/SubForms/helper';
import crmDefaultFields from '../updateCrm/CrmDefaults/constants';
import {
  getInitFormData,
  validateFormData,
} from '../updateCrm/CrmDefaults/formData';

import useStyles from '../updateCrm/styles';

const SuperOpsDefaultForm = ({ onSubmit, onBack, crmId, data }) => {
  const [loading, setLoading] = useState(true);

  /// OnMount options
  const [companies, setCompanies] = useState([]);
  const [ticketTypes, setTicketTypes] = useState([]);
  const [statuses, setStatuses] = useState([]);
  // Board related options

  const classes = useStyles();
  const dispatch = useDispatch();
  const translate = useTranslate();

  const submit = values => {
    const payload = {
      id: data ? data.id : 0,
      name: values.name,
      typeId: values.typeId,
      openBoardStatusId: values.openBoardStatusId,
      smsReceivedBoardStatusId: values.smsReceivedBoardStatusId,
      closedBoardStatusId: values.closedBoardStatusId,
      defaultBoard: 'Support',
      defaultCompany: companies.find(
        cp =>
          cp.value === values.defaultCompany ||
          cp.label === values.defaultCompany,
      )?.label,
      crmSpecificSettings: values.crmSpecificSettings,
    };

    onSubmit(payload);
  };

  const onMount = useCallback(async () => {
    setLoading(true);
    await Promise.all([
      new Promise(res =>
        res(dispatch(ThunkGetIntegrationCompanies({ id: crmId })).unwrap()),
      ),
      new Promise(res =>
        res(dispatch(ThunkGetSuperOpsIntegrationTypes({ crmId })).unwrap()),
      ),
      new Promise(res =>
        res(dispatch(ThunkGetSuperOpsIntegrationStatuses({ crmId })).unwrap()),
      ),
    ])
      .then(res => {
        setCompanies(res[0]);
        setTicketTypes(res[1]);
        setStatuses(res[2]);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch, crmId, data]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  if (loading) return <Loading />;
  return (
    <div className={classes.container}>
      <div className={classes.block}>
        <Form
          onSubmit={submit}
          initialValues={data ? getInitFormData(data) : {}}
          validate={values =>
            validateFormData(values, translate, crmSources.Autotask.name)
          }
          render={({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit}>
              <div className={classes.form}>
                <div>
                  <Typography className={classes.inputLabel}>Name:</Typography>
                  <Field
                    id={crmDefaultFields.name}
                    name={crmDefaultFields.name}
                    styleType="main"
                    inputView="text"
                    component={Input}
                    placeholder="Name"
                    fullWidth
                  />
                </div>

                <div>
                  <Typography className={classes.inputLabel}>
                    Default Company:
                  </Typography>
                  <Field
                    id={crmDefaultFields.defaultCompany}
                    name={crmDefaultFields.defaultCompany}
                    size="small"
                    component={AutocompleteFormInput}
                    items={companies}
                    suppressInlineLabel
                  />
                </div>

                <div>
                  <Typography className={classes.inputLabel}>
                    Ticket Type:
                  </Typography>
                  <Field
                    id={crmDefaultFields.typeId}
                    name={crmDefaultFields.typeId}
                    size="small"
                    component={DropDown}
                    labelName="label"
                    valueName="value"
                    allowEmpty
                    options={ticketTypes}
                  />
                </div>

                <div>
                  <Typography className={classes.inputLabel}>
                    New Ticket Status:
                  </Typography>
                  <Field
                    id={crmDefaultFields.openBoardStatusId}
                    name={crmDefaultFields.openBoardStatusId}
                    size="small"
                    labelName="label"
                    valueName="value"
                    component={DropDown}
                    options={statuses}
                  />
                </div>

                <div>
                  <Typography className={classes.inputLabel}>
                    SMS received Status:
                  </Typography>
                  <Field
                    id={crmDefaultFields.smsReceivedBoardStatusId}
                    name={crmDefaultFields.smsReceivedBoardStatusId}
                    size="small"
                    labelName="label"
                    valueName="value"
                    component={DropDown}
                    options={[{ value: undefined, label: '-' }, ...statuses]}
                  />
                </div>

                <div>
                  <Typography className={classes.inputLabel}>
                    Closed Ticket Status:
                  </Typography>
                  <Field
                    id={crmDefaultFields.closedBoardStatusId}
                    name={crmDefaultFields.closedBoardStatusId}
                    size="small"
                    labelName="label"
                    valueName="value"
                    component={DropDown}
                    options={statuses}
                  />
                </div>
                <div>
                  <Typography className={classes.inputLabel}>
                    Custom Field for Preferred Contact Method:
                  </Typography>
                  <Field
                    id={
                      baseFieldName +
                      crmDefaultFields.preferredContactMethodFieldName
                    }
                    name={
                      baseFieldName +
                      crmDefaultFields.preferredContactMethodFieldName
                    }
                    styleType="main"
                    inputView="text"
                    component={Input}
                    placeholder="Enter the label of the custom field"
                    fullWidth
                  />
                </div>
              </div>
              <div className={classes.buttonsWrapper}>
                <ReusableButton
                  size="md"
                  type="button"
                  label="Cancel"
                  classNameWrapper={cx(
                    classes.buttonCancel,
                    classes.buttonStyle,
                  )}
                  onClick={onBack}
                />
                <ReusableButton
                  size="md"
                  classNameWrapper={classes.buttonStyle}
                  viewType="black"
                  type="submit"
                  onClick={() => submit(values)}
                  label="resources.buttons.submit"
                />
              </div>
            </form>
          )}
        />
      </div>
    </div>
  );
};

SuperOpsDefaultForm.propTypes = {
  onSubmit: PropTypes.func,
  onBack: PropTypes.func,
  crmId: PropTypes.number,
  data: PropTypes.shape({
    defaultBoardId: PropTypes.number,
    id: PropTypes.string,
  }),
};

export default SuperOpsDefaultForm;
