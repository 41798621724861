import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import ReusableButton from '@common/Button/Button';

const SimpleDialog = ({ open, setOpen, title, children }) => {
  return (
    <MuiDialog open={open} maxWidth="sm">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {children}
        <DialogActions>
          <ReusableButton
            label="resources.buttons.gotIt"
            viewType="black"
            onClick={() => setOpen(false)}
            size="md"
          />
        </DialogActions>
      </DialogContent>
    </MuiDialog>
  );
};

SimpleDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default SimpleDialog;
