import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import TextMask from '@components/Auth/Common/creditCardInput';
import Input from '@components/Auth/Common/Input';

import useTranslation from '@common/hooks/useTranslation';

const CreditCardForm = ({ loading }) => {
  const { getRaText } = useTranslation('creditCard');
  return (
    <div
      style={{
        margin: '10px 0px',
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
      }}
    >
      <Field
        id="cardHolder"
        name="cardHolder"
        styleType="main"
        inputView="text"
        fullWidth
        component={Input}
        placeholder={getRaText('name')}
        disabled={loading}
      />
      <Field
        id="cardNumber"
        name="cardNumber"
        inputView="text"
        styleType="main"
        fullWidth
        component={Input}
        InputProps={{ inputComponent: TextMask }}
        placeholder={getRaText('number')}
        disabled={loading}
      />
      <div style={{ display: 'flex' }}>
        <Field
          id="cardExpMonth"
          name="cardExpMonth"
          styleType="main"
          inputView="text"
          fullWidth
          component={Input}
          placeholder={getRaText('month')}
          disabled={loading}
        />
        <Field
          id="cardExpYear"
          name="cardExpYear"
          styleType="main"
          inputView="text"
          fullWidth
          component={Input}
          placeholder={getRaText('year')}
          disabled={loading}
        />
        <Field
          id="cardCvc"
          name="cardCvc"
          styleType="main"
          inputView="text"
          fullWidth
          component={Input}
          placeholder={getRaText('cvv')}
          disabled={loading}
        />
      </div>
      <Field
        id="billingEmail"
        name="billingEmail"
        styleType="main"
        inputView="text"
        fullWidth
        component={Input}
        placeholder={getRaText('billingEmail')}
        disabled={loading}
      />
    </div>
  );
};

CreditCardForm.propTypes = {
  loading: PropTypes.objectOf(PropTypes.any),
};

export default CreditCardForm;
