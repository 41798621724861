import React, { useCallback, useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { OnChange } from 'react-final-form-listeners';

import {
  ThunkGetAutotaskConfigurationItems,
  ThunkGetAutotaskPsaCompanies,
  ThunkUploadAutotaskFile,
} from '@store/slices/fileUpload/thunks';
import { FileUploadOptionsSelector } from '@store/slices/fileUpload';

import useSharedStyles from '@components/CrmTicket/sharedStyles';

import Loading from '@common/Loading/Loading';
import FieldWrapper from '@common/form/FieldWrapper';
import AutocompleteFormInput from '@common/AutocompleteFormInput/AutocompleteFormInput';
import ReusableButton from '@common/Button/Button';
import FileUploader from '@common/FileUploader/FileUploader';
import fileFormats from '@common/FileUploader/fileFormats';
import { useNotify } from 'react-admin';
import SimpleTable from '@components/Triggers/pages/Incident/components/SimpleTable';
import { FILE_UPLOAD } from '@store/slices/resources';

const AutotaskFileUploadForm = ({ psaId }) => {
  const [loading, setLoading] = useState(true);
  const [file, setFile] = useState();
  const [selectedItem, setSelectedItem] = useState(undefined);

  const sharedStyles = useSharedStyles();
  const dispatch = useDispatch();
  const { companies } = useSelector(FileUploadOptionsSelector);
  const notify = useNotify();

  const columns = [
    { value: 'name', label: 'Name' },
    { value: 'productName', label: 'Product Name' },
    { value: 'referenceNumber', label: 'Reference Number' },
    { value: 'referenceTitle', label: 'Reference Title' },
    { value: 'serialNumber', label: 'Serial Number' },
    {
      label: 'Actions',
      handler: item => (
        <ReusableButton
          viewType="white"
          buttonType="simple"
          label="Select"
          onClick={() => {
            setSelectedItem(item);
          }}
        />
      ),
    },
  ];

  const submit = () => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      dispatch(
        ThunkUploadAutotaskFile({
          formData,
          psaId,
          configurationItemsId: selectedItem?.id,
        }),
      );
    } catch (e) {
      notify(e.message(), 'error');
    }
  };

  const handleCompanyChange = useCallback(
    value => {
      dispatch(ThunkGetAutotaskConfigurationItems({ psaId, companyId: value }));
    },
    [psaId, dispatch],
  );

  const handleFileUpload = useCallback(uploadFile => {
    setFile(uploadFile);
  }, []);

  const onMount = useCallback(async () => {
    setLoading(true);
    await dispatch(ThunkGetAutotaskPsaCompanies({ psaId }));
    setLoading(false);
  }, [dispatch, psaId]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  if (loading) return <Loading />;
  return (
    <Form
      onSubmit={submit}
      render={({ handleSubmit }) => (
        <form
          onSubmit={handleSubmit}
          style={{
            padding: '10px',
            gap: '15px',
            display: 'flex',
            flexDirection: 'column',
            width: '40%',
            margin: '0 auto',
          }}
        >
          <FieldWrapper
            label="Company"
            contentSize={12}
            labelSize={12}
            content={
              <Field
                id="companyId"
                name="companyId"
                component={AutocompleteFormInput}
                items={companies}
              />
            }
          />

          <OnChange name="companyId">{handleCompanyChange}</OnChange>

          <div style={{ maxHeight: '30%', height: '30vh', display: 'flex' }}>
            <SimpleTable
              selector={state => state[FILE_UPLOAD].items}
              columns={columns}
              selectedId={selectedItem?.id}
            />
          </div>

          <FileUploader
            handleUpload={handleFileUpload}
            type="file"
            format={fileFormats.all}
            disabled={!selectedItem}
          />

          <div className={sharedStyles.dialogActionsContainer}>
            <ReusableButton
              viewType="white"
              buttonType="simple"
              label="Submit"
              type="submit"
              disabled={!file && !selectedItem}
            />
          </div>
        </form>
      )}
    />
  );
};

AutotaskFileUploadForm.propTypes = {
  psaId: PropTypes.number.isRequired,
};

export default AutotaskFileUploadForm;
