import React from 'react';
import { Field, Form } from 'react-final-form';

import ReusableButton from '@common/Button/Button';

import FormColorPickerField from '@components/Auth/Common/FormColorPickerField';
import { getRoleIdFromStorage } from '@services/api';
import roles from '@constants/roles';
import {
  defaultPalette,
  getPalette,
  setPalette,
} from '@services/themes/mainTheme';
import { HOME_ROUTE } from '@constants/routes';
import { useRedirect } from 'react-admin';
import Template from '@components/Settings/CustomPalette/components/Tamplate';
import ItemGroup from '@components/DataSources/AlertForm/AlertList/components/ItemGroup';
import { postTenantPalettes } from '@components/Settings/CustomPalette/helpers';
import useStyles from '../../TenantSettings/styles';

const TenantSettings = () => {
  const classes = useStyles();
  const redirect = useRedirect();
  const readOnlyMode = getRoleIdFromStorage() === roles.READ_ONLY;

  const submit = async values => {
    setPalette(values);
    postTenantPalettes({ palette: JSON.stringify(values) }).catch(e =>
      console.error(e.message),
    );
    redirect(HOME_ROUTE);
  };

  const fields = {
    Content: [{ label: 'Background', name: 'contentBackground' }],
    AppBar: [
      { label: 'Background', name: 'appBarBackground' },
      { label: 'Text', name: 'appBarText' },
      { label: 'Icon', name: 'appBarIcon' },
    ],
    SideBar: [
      { label: 'Background', name: 'sideBarBackground' },
      { label: 'Selected', name: 'sideBarSelected' },
      { label: 'Text', name: 'sideBarText' },
      { label: 'Icon', name: 'sideBarIcon' },
    ],
    ListView: [
      { label: 'Background', name: 'listViewBackground' },
      { label: 'HeaderText', name: 'listViewHeaderText' },
      { label: 'Text', name: 'listViewText' },
    ],
    MailBoxCard: [
      { label: 'Background', name: 'mailBoxCardBackground' },
      { label: 'Text', name: 'mailBoxCardText' },
      { label: 'CopyIcon', name: 'mailBoxCardCopyIcon' },
      { label: 'Icon', name: 'mailBoxCardIcon' },
    ],
    Card: [
      { label: 'Background', name: 'cardBackground' },
      { label: 'HoverBackground', name: 'cardHoverBackground' },
      { label: 'Text', name: 'cardText' },
    ],
  };

  const setDefault = () => {
    setPalette(defaultPalette);
    postTenantPalettes({ palette: JSON.stringify(defaultPalette) }).catch(e =>
      console.error(e.message),
    );
    redirect(HOME_ROUTE);
  };

  const generateFields = () =>
    Object.keys(fields).map(field => (
      <>
        <ItemGroup
          name={field}
          key={field}
          gridTemplateColumns="1fr 1fr 1fr 1fr 1fr"
        >
          {fields[field].map(i => (
            <Field
              key={i.name}
              name={i.name}
              label={`${i.label}`}
              component={FormColorPickerField}
            />
          ))}
        </ItemGroup>
        <hr />
      </>
    ));

  return (
    <div className={classes.container}>
      <div className={classes.block}>
        <Form
          onSubmit={submit}
          initialValues={getPalette()}
          render={({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit} noValidate>
              {generateFields()}

              <div className={classes.buttonWrapper}>
                {readOnlyMode || (
                  <>
                    <ReusableButton
                      size="md"
                      viewType="black"
                      onClick={setDefault}
                      label="Default"
                    />
                    <ReusableButton
                      size="md"
                      viewType="black"
                      type="submit"
                      label="resources.billing.updateButton"
                    />
                  </>
                )}
              </div>
              <Template colors={values} />
            </form>
          )}
        />
      </div>
    </div>
  );
};

export default TenantSettings;
