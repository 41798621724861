function findFirstItem(arr, priorityNames) {
  // Find the index of the first matching priority name
  const index = arr.findIndex(
    item => item.Type === 'phone' && priorityNames.includes(item.Name),
  );
  // Return the item if found, otherwise return the first item in the array
  return index !== -1
    ? arr[index]?.Value
    : arr.find(item => item.Type === 'phone')?.Value;
}

export const getPodPhoneNumber = data => {
  if (data?.Data && data?.Data.length > 0) {
    const phonesData = data?.Data.filter(
      i => i.Type === 'phone' && i.IsVerified,
    );
    if (phonesData?.length > 0) {
      return phonesData.pop()?.Value;
    }
    return data?.ContactPhone;
  }
  if (data?.communicationItems) {
    const priorityNames = [
      'Cell',
      'Mobile',
      'Direct',
      'Mobile Phone',
      'Mobile Phone 2',
    ];
    return findFirstItem(data.communicationItems, priorityNames);
  }
  return data?.ContactPhone;
};

export default getPodPhoneNumber;
