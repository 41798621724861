import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Notification, useGetOne, useLogout } from 'react-admin';
import { createTheme, ThemeProvider, Tooltip } from '@material-ui/core';
import { ExitToAppRounded } from '@material-ui/icons';

import {
  ThunkGetConnectWisePodDataByContactId,
  ThunkGetConnectwiseTicketInfo,
} from '@store/slices/psaPods/thunks';
import { MessagingChannelSelector } from '@store/slices/messaging';

import { actions } from '@store/actions';
import resources from '@constants/resources';
import useSocketForHistory from '@components/integrations/connectwise/hooks/useSocketForHistory';
import { changeStateOfPodPageFlag, setPodData } from '@store/slices/psaPods';
import { lightTheme } from '@services/themes/mainTheme';

import ClientChatsWrapper from '@components/integrations/connectwise/components/ClientChatsWrapper';
import EndUserVerification from '@components/integrations/connectwise/tabs/EndUserVerification';
import Loading from '@common/Loading/Loading';
import MessagingComponent from '@components/integrations/connectwise/components/MessagingComponent';
import TabPanel from '@common/TabPanel/TabPanel';
import CreateTicketWrapper from '@components/integrations/connectwise/wrappers/CreateTicketWrapper';
import SecureLink from '@components/integrations/secureLink';
import PsaPodTechVerification from '@components/integrations/connectwise/tabs/PsaPodTechVerification';

import DisplayCompanyPolicies from '@components/integrations/components/DisplayCompanyPolicies';
import crmSources from '@constants/crmSources';
import { parseJwtToken } from '@services/api';
import LocalStorage from '@constants/localStorage';
import useStyles from '../styles';
import { getPodPhoneNumber } from '../helpers';
import { checkParams } from './helpers/errorsLoggers';

const theme = createTheme(lightTheme);

const ConnectwisePod = ({ location: { search }, match }) => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [ticketId, setTicketId] = useState(0);
  const [screenType, setScreenType] = useState('');

  const classes = useStyles();
  const dispatch = useDispatch();
  const channel = useSelector(MessagingChannelSelector);
  const data = useSelector(state => state.pod.data);

  const phoneNumber = useMemo(() => {
    return getPodPhoneNumber(data);
  }, [data]);

  const handleRefresh = () => {
    onMount();
  };

  const ticketTabsData = [
    {
      label: 'End User ID',
      value: (
        <EndUserVerification
          data={data}
          match={match}
          onRefresh={handleRefresh}
        />
      ),
    },
    {
      label: 'SMS',
      value: (
        <MessagingComponent
          data={data}
          crmId={match.params.crmId}
          ticketId={ticketId}
          phoneNumber={phoneNumber}
          channel={channel}
        />
      ),
    },
    data?.clientPortalChats?.length >= 1 && {
      label: 'Chat',
      value: <ClientChatsWrapper clientPortalChats={data?.clientPortalChats} />,
    },
    {
      label: 'Send Secure Data',
      value: (
        <SecureLink
          userPhone={phoneNumber}
          userEmail={data.ContactEmail}
          psaContactId={data.ContactId}
          psaId={match.params.crmId}
          psaType={crmSources.ConnectWise.label}
        />
      ),
    },
    {
      label: 'Tech Verification',
      value: <PsaPodTechVerification crmId={match.params.crmId} data={data} />,
    },
  ];

  const contactPodTabs = [
    {
      label: 'End User ID',
      value: (
        <EndUserVerification
          data={data}
          match={match}
          onRefresh={handleRefresh}
        />
      ),
    },
    {
      label: 'Chat',
      value: <ClientChatsWrapper clientPortalChats={data?.clientPortalChats} />,
    },
    {
      label: 'Quick Ticket',
      value: (
        <CreateTicketWrapper psaId={+match.params.crmId} contactInfo={data} />
      ),
    },
    {
      label: 'Send Secure Data',
      value: (
        <SecureLink
          userPhone={phoneNumber}
          userEmail={data.ContactEmail}
          psaContactId={data.ContactId}
          psaId={match.params.crmId}
          psaType={crmSources.ConnectWise.label}
        />
      ),
    },
  ];

  const content = useMemo(() => {
    switch (screenType) {
      case 'ticket':
        return (
          <TabPanel
            tabs={ticketTabsData.filter(i => i) || []}
            viewTypeLink="main"
            classNameWrapperTabs={classes.tabsWrapper}
            isTransparent
            classNameTabTitle={classes.tabTitle}
            classNameContentWrapper={classes.tabContent}
            classFlexContainer={classes.flex}
          />
        );
      case 'contact':
        return (
          <TabPanel
            tabs={contactPodTabs.filter(i => i) || []}
            viewTypeLink="main"
            classNameWrapperTabs={classes.tabsWrapper}
            isTransparent
            classNameTabTitle={classes.tabTitle}
            classNameContentWrapper={classes.tabContent}
          />
        );
      default:
        return <Loading />;
    }
  }, [data, match, screenType, ticketTabsData]);

  const onMount = useCallback(async () => {
    setLoading(true);
    const urlParams = new URLSearchParams(search);
    const screen = urlParams.get('screen');
    // logger function to track issues
    checkParams(urlParams.get('ticketId'), urlParams.get('screen'), search);

    const ticketIdParam = urlParams.get('ticketId');
    if (
      (urlParams.get('screen') !== 'ticket' &&
        urlParams.get('screen') !== 'contact') ||
      !+ticketIdParam
    ) {
      setError(true);
      return;
    }

    setTicketId(+ticketIdParam);
    setScreenType(screen);

    if (Number(ticketIdParam) && Number(match.params.crmId)) {
      if (screen === 'ticket') {
        dispatch(
          ThunkGetConnectwiseTicketInfo({
            crmId: match.params.crmId,
            ticketId: +ticketIdParam,
          }),
        )
          .unwrap()
          .catch(() => {
            setError(true);
          })
          .finally(() => setLoading(false));
      }
      if (screen === 'contact') {
        dispatch(
          ThunkGetConnectWisePodDataByContactId({
            crmId: match.params.crmId,
            contactId: +ticketIdParam,
          }),
        )
          .unwrap()
          .catch(() => {
            setError(true);
          })
          .finally(() => setLoading(false));
      }
    }
  }, []);

  useEffect(() => {
    if (match) onMount();
  }, []);

  useEffect(() => {
    // first load setting properties for pod in store
    const urlParams = new URLSearchParams(search);
    const ticketIdParam = urlParams.get('ticketId');
    const screen = urlParams.get('screen');
    dispatch(changeStateOfPodPageFlag(true));
    if (match.params.crmId !== 'crmId' && Number(match.params.crmId)) {
      dispatch(
        setPodData({
          crmId: match.params.crmId,
          ticketId: ticketIdParam,
          screen,
          type: 'connectwise',
        }),
      );
    }
  }, [dispatch, search, match]);

  const { data: userData } = useGetOne(
    resources.user,
    localStorage.getItem('userId'),
  );

  useEffect(() => {
    if (userData) {
      dispatch(actions.setCurrentUser(userData));
      localStorage.setItem('tenantId', userData.tenantId);
    }
  }, [userData, dispatch]);

  //* Add this custom for creating socket connection for this pod page
  useSocketForHistory(match.params.crmId, data.ContactId);
  const logout = useLogout();

  const token = localStorage.getItem(LocalStorage.ACCESS_TOKEN);

  useEffect(() => {
    if (userData?.id && token) {
      const claims = parseJwtToken(token);

      window.pendo.initialize({
        visitor: {
          id: +userData.id,
          email: userData.email,
          fullName: userData.username,
        },
        account: {
          id: userData.tenantId,
          tenantName: claims.tenantAlias,
        },
      });
    }
  }, [userData?.id, token]);

  if (error) return <div>Error on page</div>;
  if (loading && !error) return <Loading />;
  return (
    <ThemeProvider theme={theme}>
      <DisplayCompanyPolicies
        crmId={match.params.crmId}
        companyId={data?.CompanyId}
      />

      <div className={classes.container}>{content}</div>

      <div
        className={classes.logOutContainer}
        onClick={logout}
        role="presentation"
      >
        <Tooltip title="Log Out">
          <ExitToAppRounded />
        </Tooltip>
      </div>
      <Notification />
    </ThemeProvider>
  );
};

ConnectwisePod.propTypes = {
  location: PropTypes.shape({ search: PropTypes.string }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      crmId: PropTypes.string,
      defaultId: PropTypes.string,
    }),
  }),
};

export default ConnectwisePod;
