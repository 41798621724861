import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useTranslate, useRedirect } from 'react-admin';
import Box from '@material-ui/core/Box';
import TabPanel from '@common/TabPanel/TabPanel';
import ActionButton from '@common/buttons/ActionButton/ActionButton';
import { getFullEmail } from '@services/helpers';
import copyText from '@utils/copy';
import resources from '@constants/resources';
import iconCopy from '@assets/icons/copyGrey.svg';
import DropDown from '@components/Auth/Common/DropDown';
import GeneralContextProvider from '@components/Context/general';
import { dashboardDataTransformer } from '@components/MailBox/helpers';
import Parsers from './components/Parsers/Parsers';
import Settings from './components/Settings/Settings';
import Ignored from './components/Ignored/Ignored';
import Documents from './components/Documents/Documents';

import Dashboard from './components/Dashboard/Dashboard';
import useStyles from './styles';

const MailBox = ({ match }) => {
  const classes = useStyles();
  const [selectValue, setSelectValue] = useState('');
  const translate = useTranslate();
  const redirect = useRedirect();

  const onDashboardClick = data => {
    const date = dashboardDataTransformer(data.date);
    redirect(`documents?date=${date}`);
  };

  const tabs = [
    {
      id: 'dashboard',
      label: 'resources.mailBox.layout.dashboard',
      value: (
        <Dashboard mailBoxId={match.params.id} onClick={onDashboardClick} />
      ),
    },
    {
      id: 'emails',
      label: 'Emails',
      value: <Documents mailBoxId={match.params.id} />,
    },
    {
      id: 'parsers',
      label: 'resources.mailBox.layout.parsers',
      value: <Parsers mailBoxId={match.params.id} />,
    },
    {
      id: 'settings',
      label: 'resources.mailBox.layout.settings',
      value: <Settings mailBoxId={match.params.id} />,
    },
    {
      id: 'ignored',
      label: 'resources.mailBox.layout.ignored',
      value: <Ignored mailBoxId={match.params.id} />,
    },
  ];

  const { data: mailBoxes = [] } = useQuery({
    type: 'getList',
    resource: resources.mailBoxes,
    payload: {
      pagination: { page: 1, perPage: 1000 },
      sort: {},
      filter: {},
    },
  });

  const onChangeSelect = ({ target: { value } }) => {
    const findMailBox = mailBoxes.find(
      ({ name }) => getFullEmail(name) === value,
    );
    const newUrl = match.url.replace(/\d+/, findMailBox.id);
    redirect(newUrl);
    setSelectValue(value);
  };

  useEffect(() => {
    if (mailBoxes.length) {
      const findMailBox = mailBoxes.find(({ id }) => id === +match.params.id);
      setSelectValue(getFullEmail(findMailBox?.name || ''));
    }
    localStorage.setItem('mailBoxId', match.params.id);
  }, [mailBoxes, match.params.id]);

  const mailBoxesForSelect = mailBoxes.map(({ name }) => ({
    label: getFullEmail(name),
    value: getFullEmail(name),
  }));

  return (
    <>
      <Box className={classes.headerWrapper}>
        {!selectValue ? (
          translate('resources.messages.loading')
        ) : (
          <Box className={classes.emailWrapper}>
            <span>Select Mailbox</span>
            <div className={classes.dropDownWrapper}>
              <DropDown
                options={mailBoxesForSelect}
                classNameWrapper={classes.selectWrapper}
                input={{
                  value: selectValue,
                  onChange: onChangeSelect,
                }}
              />
              <ActionButton
                icon={<img src={iconCopy} alt="iconCopy" />}
                handler={() => copyText(selectValue)}
                toolTip="Copy email"
              />
            </div>
          </Box>
        )}
      </Box>
      <GeneralContextProvider selectValue={selectValue}>
        <TabPanel
          classNameWrapperTabs={classes.tabsWrapper}
          tabs={tabs}
          match={match}
          viewTypeLink="flooded"
        />
      </GeneralContextProvider>
    </>
  );
};

MailBox.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
    url: PropTypes.string,
  }),
};

export default MailBox;
