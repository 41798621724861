import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNotify } from 'react-admin';

import PropTypes from 'prop-types';
import { ThunkGetMessagingConfigurations } from '@store/slices/messaging/thunks';
import { ThunkCreateMessagingChannelFromTicketByCrmId } from '@store/slices/crmTicket/thunks';

import { MessagingConfigurationsSelector } from '@store/slices/messaging';
import crmSources from '@constants/crmSources';
import { requiredValidator } from '@utils/validators';

import phoneValidation from '@constants/phoneValidation';
import Loading from '@ui/components/common/Loading/Loading';
import FieldWrapper from '@ui/components/common/form/FieldWrapper';
import Input from '@ui/components/Auth/Common/Input';
import ReusableButton from '@ui/components/common/Button/Button';

import DropDown from '@ui/components/Auth/Common/DropDown';
import ActionButtonLight from '@common/buttons/ActionButton/ActionButtonLight';
import iconVariable from '@assets/icons/variable.svg';
import iconTemplate from '@assets/icons/template.svg';
import TemplateMenu from '@components/Ticketing/Notifications/components/TemplateMenu';

import { ThunkBeautifyPhoneNumber } from '@store/slices/common/thunks';
import { ThunkPodSaveToContact } from '@store/slices/psaPods/thunks';
import usePodSaveContactRoute from '@ui/components/Hooks/usePodSaveContactRoute';

import sharedStyles from '../../../CrmTicket/sharedStyles';
import useStyles from '../../styles';

const templateVariables = [
  { value: '{$ticketId}', label: 'Ticket Number' },
  { value: '{$companyName}', label: 'Company Name' },
  { value: '{$contactName}', label: 'Contact Name' },
];

const CreateChatFromTicket = React.memo(
  ({
    data,
    crmId,
    search,
    checkIfChannelExist,
    conversationTemplates,
    phoneNumber = '',
    crmType,
    psaType,
    ticketId,
  }) => {
    const [loading, setLoading] = useState(true);
    const [processing, setProcessing] = useState(false);
    const [variableAnchorEl, setVariableAnchorEl] = useState(null);
    const [templateAnchorEl, setTemplateAnchorEl] = useState(null);
    const [communicationItem, setCommunicationItem] = useState(null);

    const templates = useMemo(() => {
      return conversationTemplates.map(i => ({ label: i.name, value: i.text }));
    }, [conversationTemplates]);

    const sharedClasses = sharedStyles();
    const classes = useStyles();
    const notify = useNotify();
    const dispatch = useDispatch();
    const configurations = useSelector(MessagingConfigurationsSelector);

    const configurationAutocomplete = useMemo(
      () =>
        configurations
          .filter(i => i.crmType === crmType)
          .map(i => ({ label: i.name, value: i.id })),
      [configurations],
    );

    const prePopulatedConfigurationId = useMemo(() => {
      if (configurationAutocomplete.length > 1) {
        return undefined;
      }
      return configurationAutocomplete[0]?.value;
    }, [configurationAutocomplete]);

    const submit = useCallback(
      async ({ userPhone, ...values }) => {
        let phone = userPhone;

        await dispatch(ThunkBeautifyPhoneNumber(userPhone))
          .unwrap()
          .then(res => {
            phone = res.data;
          });

        const urlParams = new URLSearchParams(search);
        const ticketIdParam = urlParams.get('ticketId') || ticketId;
        const payload = {
          ...values,
          userPhone: phone,
          ticketId: ticketIdParam,
          createTriage: false,
        };
        setProcessing(true);

        dispatch(
          ThunkCreateMessagingChannelFromTicketByCrmId({
            crmId,
            payload,
          }),
        )
          .unwrap()
          .then(() => {
            checkIfChannelExist(ticketIdParam);
          })
          .catch(e => notify(e.message, 'error'))
          .finally(() => setProcessing(false));
      },
      [crmId, dispatch, search, checkIfChannelExist],
    );

    const validate = values => {
      return {
        messagingConfigurationId: requiredValidator(
          values.messagingConfigurationId,
        ),
        userPhone: requiredValidator(values.userPhone),
        smsMessage: requiredValidator(values.smsMessage),
      };
    };

    const saveContactRoute = usePodSaveContactRoute({ psaType });

    useEffect(() => {
      const item = data.communicationItems.find(x => x.Value === phoneNumber);
      setCommunicationItem(item);
    }, [data.communicationItems, phoneNumber]);

    const saveValueToContact = values => {
      setProcessing(true);
      dispatch(
        ThunkPodSaveToContact({
          route: saveContactRoute,
          type: communicationItem.Type,
          value: values.userPhone,
          crmId: +crmId,
          contactId: data.ContactId,
          itemName: communicationItem.Name,
          ticketId,
        }),
      )
        .unwrap()
        .then(() => notify('Successfully updated', 'success'))
        .catch(e => notify(e.message, 'error'))
        .finally(() => setProcessing(false));
    };

    const onMount = useCallback(async () => {
      setLoading(true);
      await dispatch(ThunkGetMessagingConfigurations());
      setLoading(false);
    }, [dispatch]);

    useEffect(() => {
      onMount();
    }, [onMount]);

    if (loading) return <Loading />;
    return (
      <div className={classes.formContainer}>
        <Form
          onSubmit={submit}
          validate={validate}
          initialValues={{
            userPhone: phoneNumber,
            messagingConfigurationId: prePopulatedConfigurationId,
          }}
          render={({ handleSubmit, form, values }) => (
            <form onSubmit={handleSubmit}>
              <FieldWrapper
                label="Messaging Configuration"
                labelSize={12}
                contentSize={12}
                showLabel
                isRequired
                classNameLabelInner={sharedClasses.alignLeft}
                content={
                  <Field
                    name="messagingConfigurationId"
                    id="messagingConfigurationId"
                    render={DropDown}
                    options={configurationAutocomplete}
                    disabled={processing}
                  />
                }
              />

              <FieldWrapper
                label="Phone"
                labelSize={12}
                contentSize={12}
                showLabel
                isRequired
                classNameLabelInner={sharedClasses.alignLeft}
                content={
                  <Field
                    name="userPhone"
                    id="userPhone"
                    render={Input}
                    disabled={processing}
                  />
                }
              />

              <FieldWrapper
                label="Message"
                labelSize={12}
                contentSize={12}
                showLabel
                isRequired
                classNameLabelInner={sharedClasses.alignLeft}
                content={
                  <>
                    <div className={classes.templatesButton}>
                      <ActionButtonLight
                        handler={event =>
                          setVariableAnchorEl(event.currentTarget)
                        }
                        toolTip="Insert variable"
                        icon={<img src={iconVariable} alt="iconAddVariable" />}
                      />
                      <ActionButtonLight
                        handler={event =>
                          setTemplateAnchorEl(event.currentTarget)
                        }
                        toolTip="Insert template"
                        icon={<img src={iconTemplate} alt="iconAddTemplate" />}
                      />
                    </div>
                    <Field
                      name="smsMessage"
                      id="smsMessage"
                      render={Input}
                      multiline
                      minRows={3}
                      disabled={processing}
                    />
                  </>
                }
              />

              <div className={classes.buttonsContainer}>
                <ReusableButton
                  onClick={() => saveValueToContact(values)}
                  size="xl"
                  viewType="black"
                  classNameWrapper={classes.contactButton}
                  disabled={processing}
                  loading={processing}
                >
                  Save to Contact
                </ReusableButton>

                <ReusableButton
                  label="Submit"
                  type="submit"
                  disabled={processing}
                  loading={processing}
                />
              </div>
              <TemplateMenu
                anchorEl={variableAnchorEl}
                setAnchorEl={setVariableAnchorEl}
                form={form}
                name="smsMessage"
                templateVariables={templateVariables}
              />
              <TemplateMenu
                anchorEl={templateAnchorEl}
                setAnchorEl={setTemplateAnchorEl}
                form={form}
                name="smsMessage"
                templateVariables={templates}
              />
            </form>
          )}
        />
      </div>
    );
  },
);

CreateChatFromTicket.propTypes = {
  search: PropTypes.string.isRequired,
  crmId: PropTypes.string.isRequired,
  crmType: PropTypes.number.isRequired,
  checkIfChannelExist: PropTypes.func.isRequired,
  phoneNumber: PropTypes.string,
  conversationTemplates: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    text: PropTypes.string,
  }),
};

export default CreateChatFromTicket;
