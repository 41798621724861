import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNotify, useTranslate, useRefresh } from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmDialog from '@common/ConfirmDialog/ConfirmDialog';
import { actions } from '@store/actions';
import { listSelector } from '@store/selectors';
import ParsersList from './Components/ParsersList';
import LettersList from './Components/LettersList';
import { deleteParser } from '../../helpers';

const Parsers = ({ mailBoxId }) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const translate = useTranslate();
  const dispatch = useDispatch();

  const list = useSelector(listSelector);

  const [currentParserId, setCurrentParserId] = useState(0);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const onParserSelect = e => setCurrentParserId(e.id);
  const goBack = () => setCurrentParserId(0);

  const onDelete = value => {
    setDeleteId(value.id);
    setDeleteDialogOpen(true);
  };

  const onCancelDeleteHandler = () => setDeleteDialogOpen(false);

  const onDeleteHandler = async () => {
    const result = await deleteParser(deleteId);
    if (!result) {
      notify(translate('resources.parsers.deleteDialog.error'), 'warning');
    } else {
      const newList = list.filter(item => item.id !== deleteId);
      dispatch(actions.saveList(newList));
      refresh();
    }
    setDeleteDialogOpen(false);
    setDeleteId(null);
  };

  return (
    <div>
      {currentParserId === 0 ? (
        <ParsersList
          onParserSelect={onParserSelect}
          onDelete={onDelete}
          mailBoxId={mailBoxId}
        />
      ) : (
        <LettersList
          parserId={currentParserId}
          goBack={goBack}
          mailBoxId={mailBoxId}
        />
      )}
      <ConfirmDialog
        open={!!deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        onAccept={onDeleteHandler}
        onCancel={onCancelDeleteHandler}
        title={translate('resources.parsers.deleteDialog.head')}
        content={translate('resources.parsers.deleteDialog.body')}
      />
    </div>
  );
};

Parsers.propTypes = {
  mailBoxId: PropTypes.string.isRequired,
};

export default Parsers;
