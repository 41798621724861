import React, { useState } from 'react';
import { useTranslate } from 'react-admin';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import FormControl from '@material-ui/core/FormControl';
import { FormControlLabel } from '@material-ui/core';

import Checkbox from '@common/Checkbox';
import ActionButton from '@common/buttons/ActionButton/ActionButton';
import iconDelete from '@assets/icons/delete.svg';
import Input from '@common/Input/Input';
import DropDown from '@components/Auth/Common/DropDown';
import { customTypeIdMeasure } from '@constants/numericConstants';

import useStyles from './styles';

const CustomTransformerRecord = ({
  item,
  onChange,
  onDelete,
  typesOptions,
}) => {
  const classes = useStyles();
  const translate = useTranslate();
  const [name, setName] = useState(item.name);
  const [path, setPath] = useState(item.path.substring(1));
  const [idChecked, setIdChecked] = useState(item.identifier);
  const [type, setType] = React.useState(item.type);

  const handleChange = (newName, newPath, newIdentifier, newType) => {
    onChange({
      id: item.id,
      name: newName,
      path: '@'.concat(newPath),
      identifier: newIdentifier,
      type: newType,
    });
  };

  const handleNameChange = event => {
    setName(event.target.value);
    handleChange(event.target.value, path, idChecked, type);
  };

  const handlePathChange = event => {
    setPath(event.target.value);
    handleChange(name, event.target.value, idChecked, type);
  };

  const handleCheckboxChange = event => {
    setIdChecked(event.target.checked);
    handleChange(name, path, event.target.checked, type);
  };

  const handleTypeChange = event => {
    setType(event.target.value);
    handleChange(name, path, idChecked, event.target.value);
  };

  const getValueInput = () => {
    if (type < customTypeIdMeasure) {
      return (
        <Field
          name="value"
          placeholder="value"
          styleType="main"
          inputView="text"
          classNameWrapper={classes.inputWrapper}
          input={{
            value: path,
            onChange: handlePathChange,
          }}
          component={Input}
        />
      );
    }
    const options = typesOptions.find(p => p.value === type)?.info ?? [];
    return (
      <div className={classes.typeSelector}>
        <DropDown
          input={{
            value: path,
            onChange: handlePathChange,
          }}
          options={options}
        />
      </div>
    );
  };

  return (
    <div className={classes.container} style={{ margin: '10px 0px 10px 42px' }}>
      <Form onSubmit={() => {}}>
        {() => (
          <>
            <Field
              name="name"
              placeholder="name"
              styleType="main"
              inputView="text"
              style={{ marginRight: '15px' }}
              input={{
                value: name,
                onChange: handleNameChange,
              }}
              component={Input}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={idChecked}
                  onChange={handleCheckboxChange}
                  name="checkedB"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              }
              label={translate('resources.parsers.dialog.id')}
            />
            <div className={classes.typeSelector}>
              <FormControl variant="outlined" size="small" fullWidth>
                <DropDown
                  id="demo-simple-select"
                  input={{
                    value: type,
                    onChange: handleTypeChange,
                  }}
                  style={{ width: '120px' }}
                  options={typesOptions}
                />
              </FormControl>
            </div>
            {getValueInput()}
            <ActionButton
              icon={<img src={iconDelete} alt="iconDelete" />}
              handler={() => onDelete(item.id)}
              toolTip="Delete"
            />
          </>
        )}
      </Form>
    </div>
  );
};

CustomTransformerRecord.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  typesOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default CustomTransformerRecord;
