import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNotify } from 'react-admin';
import { Paper } from '@material-ui/core';
import FullScreenLoading from '@common/Loading/FullScreenLoading/FullScreenLoading';
import TemplateEditor from '@components/DataSources/AlertForm/components/TemplateEditor';
import {
  convertArrayToObject,
  createTemplateData,
  getTemplateData,
  updateAlertSettings,
  updateTemplateData,
} from '@components/DataSources/helpers';
import AlertHookForm from '@components/DataSources/AlertForm/AlertHookForm';
import marakiAlertFormInitial from './formInitial';
import useStyles from './styles';
import { useTableContext } from '../../../../hooks/table';

const AlertHookTemplate = ({ onCancel, integrationId }) => {
  const classes = useStyles();
  const notify = useNotify();
  const [emails, setEmails] = useState([]);
  const [formInit, setFormInit] = useState(marakiAlertFormInitial);
  const [hooks, setHooks] = useState([]);
  const [selectedHooks, setSelectedHooks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [templateName, setTemplateName] = useState(null);
  const [formValues, setFormValues] = useState(marakiAlertFormInitial);
  const [alerts, setAlerts] = useState({});

  const { selectedIds: networkIds } = useTableContext();

  const onHooksSubmit = value => {
    const newHooks = [...hooks];
    newHooks.push(value);
    setHooks(newHooks.map((i, k) => ({ ...i, id: k })));
  };

  const onHookDelete = id => {
    const newHooks = [...hooks];
    setHooks(newHooks.filter(i => i.id !== id));
  };

  const onSubmit = () => {
    const webHookServers = hooks
      .filter(i => selectedHooks.includes(i.name))
      .map(i => ({ name: i.name, url: i.url }));
    const httpServerIds = webHookServers.map(i => i.url);
    const payload = {
      networkIds,
      emailsTemplateBehaviour: formValues.emailsTemplateBehaviour,
      hooksTemplateBehaviour: formValues.hooksTemplateBehaviour,
      payload: {
        emails,
        snmp: formValues.snmp,
        allAdmins: formValues.allAdmins,
        httpServerIds,
      },
      webHookServers,
      alerts: Object.keys(alerts).map(key => alerts[key]),
    };
    setLoading(true);
    updateAlertSettings(integrationId, payload)
      .then(() => {
        notify('Alert settings updated');
        onCancel();
      })
      .catch(e => notify(e.message, 'error'))
      .finally(() => setLoading(false));
  };

  const onTemplateLoad = templateId => {
    setLoading(true);
    getTemplateData(templateId)
      .then(data => {
        setEmails(data.emails);
        setFormInit({
          snmp: data.snmp,
          allAdmins: data.allAdmins,
          emailsTemplateBehaviour: data.emailsTemplateBehaviour,
          hooksTemplateBehaviour: data.hooksTemplateBehaviour,
        });
        const newHooks = data.hookEntities.map((i, idx) => ({
          id: idx,
          ...i,
        }));
        setHooks(newHooks);
        setSelectedHooks(data.httpServerIds);
        setTemplateName(data.name);
        const alertsObject = data.alerts
          ? convertArrayToObject(data.alerts, 'type')
          : {};
        setAlerts(alertsObject);
      })
      .catch(e => notify(e.message, 'error'))
      .finally(() => setLoading(false));
  };

  const onTemplateUpdate = id => {
    setLoading(true);
    const payload = {
      id,
      name: templateName,
      emails,
      snmp: formValues.snmp,
      allAdmins: formValues.allAdmins,
      emailsTemplateBehaviour: formValues.emailsTemplateBehaviour,
      hooksTemplateBehaviour: formValues.hooksTemplateBehaviour,
      httpServerIds: selectedHooks,
      hookEntities: hooks,
      alerts: Object.keys(alerts).map(key => alerts[key]),
    };
    updateTemplateData(id, payload)
      .then(() => notify('Template updated'))
      .catch(e => notify(e.message, 'error'))
      .finally(() => setLoading(false));
  };

  const onTemplateReset = () => {
    setTemplateName(null);
    setEmails([]);
    setHooks([]);
    setSelectedHooks([]);
    setFormInit(formInit);
  };

  const onCreateTemplate = ({ name }) => {
    setTemplateName(null);
    setLoading(true);
    const payload = {
      name,
      emails,
      emailsTemplateBehaviour: formValues.emailsTemplateBehaviour,
      hooksTemplateBehaviour: formValues.hooksTemplateBehaviour,
      snmp: formValues.snmp,
      allAdmins: formValues.allAdmins,
      httpServerIds: selectedHooks,
      hookEntities: hooks,
    };
    createTemplateData(payload)
      .then(() => notify('Template created'))
      .catch(e => notify(e.message, 'error'))
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Paper className={classes.paperContainer}>
        <Paper className={classes.paperRoot}>
          <span>
            Networks selected: &nbsp;
            {networkIds.length}
          </span>
        </Paper>
        <Paper className={classes.paperRoot}>
          <TemplateEditor
            loadSettings={onTemplateLoad}
            updateSettings={onTemplateUpdate}
            resetSettings={onTemplateReset}
            createSettings={onCreateTemplate}
          />
        </Paper>
        <AlertHookForm
          hooks={hooks}
          onSubmit={onSubmit}
          emails={emails}
          formInit={formInit}
          onCancel={onCancel}
          onHookDelete={onHookDelete}
          onHooksSubmit={onHooksSubmit}
          selectedHooks={selectedHooks}
          setEmails={setEmails}
          setFormValues={setFormValues}
          setSelectedHooks={setSelectedHooks}
          alerts={alerts}
          setAlerts={setAlerts}
        />
      </Paper>
      <FullScreenLoading display={loading} />
    </>
  );
};

AlertHookTemplate.propTypes = {
  integrationId: PropTypes.string.isRequired,
  onCancel: PropTypes.func,
};

export default AlertHookTemplate;
