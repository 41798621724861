import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { ThunkGetCurrentContactData } from '@store/slices/psaPods/thunks/getCurrentContactData';

import GetSecureDataLink from '@components/integrations/secureLink/components/GetSecureDataLink';
import SendSecureDataLink from '@components/integrations/secureLink/components/SendSecureDataLink';

const SecureLink = ({
  userPhone,
  userEmail,
  psaContactId,
  psaId,
  localTicketId = null,
  psaType,
}) => {
  const [link, setLink] = useState(undefined);
  const [options, setOptions] = useState([]);

  const handleLinkChange = value => {
    setLink(value);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    if (psaId && psaContactId) {
      dispatch(
        ThunkGetCurrentContactData({
          crmId: psaId,
          contactId: psaContactId,
          psaType,
        }),
      ).then(({ payload }) => {
        if (payload?.communicationItems) {
          setOptions(
            payload?.communicationItems.reduce((acc, item) => {
              if (item.type === 'phone' && item.value) {
                acc.push({
                  value: item.value,
                  label: item.value,
                  icon: `[${item.name}]`,
                });
              }
              return acc;
            }, []),
          );
        }
      });
    }
  }, [dispatch, psaContactId, psaId, psaType, userPhone]);

  return (
    <div>
      {!link ? (
        <GetSecureDataLink
          handleLinkChange={handleLinkChange}
          psaContactId={psaContactId}
          psaId={psaId}
          localTicketId={localTicketId}
        />
      ) : (
        <SendSecureDataLink
          link={link}
          options={options}
          handleLinkChange={handleLinkChange}
          userPhone={userPhone}
          userEmail={userEmail}
        />
      )}
    </div>
  );
};

SecureLink.propTypes = {
  userEmail: PropTypes.string,
  userPhone: PropTypes.string,
  psaContactId: PropTypes.string,
  psaId: PropTypes.number,
  psaType: PropTypes.string,
  localTicketId: PropTypes.string,
};

export default SecureLink;
