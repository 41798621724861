import React, { useState } from 'react';
import { useTranslate } from 'react-admin';
import deleteIcon from '@assets/icons/deleteWhite.svg';
import ReusableButton from '@common/Button/Button';
import ConfirmDialog from '@common/ConfirmDialog/ConfirmDialog';
import useBulkDelete from '@components/Hooks/useBulkDelete';
import { removeNotificationSettings } from '../helpers';

const BulkActionButtons = () => {
  const translate = useTranslate();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const onClickDelete = useBulkDelete(
    removeNotificationSettings,
    setDeleteDialogOpen,
  );
  return (
    <>
      <ReusableButton
        onClick={() => setDeleteDialogOpen(true)}
        viewType="red"
        size="xl"
      >
        <>
          <img src={deleteIcon} alt="deleteIcon" />
          {translate('resources.data.bulkActions.deleteSelected')}
        </>
      </ReusableButton>
      <ConfirmDialog
        open={!!deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        onAccept={onClickDelete}
        onCancel={() => setDeleteDialogOpen(false)}
        title={translate('common.deleteDialogHeader')}
        content={translate('common.deleteDialogText')}
      />
    </>
  );
};

export default BulkActionButtons;
