import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { CircularProgress, Typography } from '@material-ui/core';
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import { OnChange } from 'react-final-form-listeners';
import { useTranslate } from 'react-admin';

import {
  ThunkGetIntegrationPriorities,
  ThunkGetIntegrationServiceBoards,
  ThunkGetIntegrationSources,
  ThunkGetIntegrationTicketCategories,
  ThunkGetIntegrationCompanies,
  ThunkGetIntegrationBoardStatuses,
  ThunkGetIntegrationBoardTypes,
  ThunkClearAutotaskCache,
  ThunkGetIntegrationResources,
} from '@store/slices/integration/thunks';
import crmSources, { crmSourcesByType } from '@constants/crmSources';

import RefreshIcon from '@assets/icons/refreshItem.svg';

import { baseFieldName } from '@components/Ticketing/Integrations/pages/updateCrm/CrmDefaults/SubForms/helper';
import Input from '@common/Input/Input';
import DropDown from '@components/Auth/Common/DropDown';
import ReusableButton from '@common/Button/Button';
import AutocompleteFormInput from '@common/AutocompleteFormInput/AutocompleteFormInput';
import Loading from '@ui/components/common/Loading/Loading';
import MultiSelectDialog from '@common/MultiSelectDialog/MultiSelectDialog';
import ActionFieldItem from '@ui/components/common/ActionButtons/ActionFieldItem';
import { ThunkGetTimeZones } from '@store/slices/common/thunks';
import AutotaskSubForm from '../updateCrm/CrmDefaults/SubForms/AutotaskSubForm';

import crmDefaultFields from '../updateCrm/CrmDefaults/constants';
import {
  getInitFormData,
  validateFormData,
} from '../updateCrm/CrmDefaults/formData';
import useStyles from '../updateCrm/styles';

const AutotaskDefaultForm = ({ onSubmit, onBack, crmId, data }) => {
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);

  /// OnMount options
  const [ticketCategories, setTicketCategories] = useState([]);
  const [sources, setSources] = useState([]);
  const [serviceBoards, setServiceBoards] = useState([]);
  const [priorities, setPriorities] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [resources, setResources] = useState([]);

  // Board related options
  const [boardTypes, setBoardTypes] = useState([]);
  const [boardStatuses, setBoardStatuses] = useState([]);
  const [boardSubTypes, setSubTypes] = useState({});

  const [ignoredValues, setIgnoredValues] = useState([]);
  const [timeZones, setTimeZones] = useState([]);

  const classes = useStyles();
  const dispatch = useDispatch();
  const translate = useTranslate();

  const handleBoardIdChange = useCallback(
    async (value, form, ignoreDrop = false) => {
      // Dropping related form values
      if (!ignoreDrop) {
        form.batch(() => {
          form.change(crmDefaultFields.typeId, '');
          form.change(crmDefaultFields.subTypeId, '');
          form.change(crmDefaultFields.openBoardStatusId, '');
          form.change(crmDefaultFields.acknowledgedBoardStatusId, '');
          form.change(crmDefaultFields.closedBoardStatusId, '');
          form.change(crmDefaultFields.smsReceivedBoardStatusId, '');
        });
        setIgnoredValues([]);
      }

      setProcessing(true);
      if (value) {
        await Promise.all([
          new Promise(res =>
            res(
              dispatch(
                ThunkGetIntegrationBoardTypes({
                  id: crmId,
                  boardId: value,
                }),
              ).unwrap(),
            ),
          ),
        ]).then(res => {
          setBoardTypes(res[0]);
          setSubTypes(
            res[0].reduce((acc, cur) => {
              // eslint-disable-next-line no-param-reassign
              acc[cur.id] = cur.boardSubTypes;
              return acc;
            }, {}),
          );
        });
      }

      setProcessing(false);
    },
    [dispatch, crmId],
  );

  const submit = values => {
    const defaultBoard = serviceBoards.find(
      board => +board.id === +values.defaultBoardId,
    );
    const payload = {
      id: data ? data.id : 0,
      name: values.name,
      defaultCompany: values.defaultCompany,
      ticketPriority: values.ticketPriority,
      defaultBoardId: values.defaultBoardId,
      defaultBoard: defaultBoard ? defaultBoard?.name : 'Support',
      sourceId: values.sourceId,
      typeId: values.typeId,
      subTypeId: values.subTypeId,
      openBoardStatusId: values.openBoardStatusId,
      acknowledgedBoardStatusId: values.acknowledgedBoardStatusId,
      smsReceivedBoardStatusId: values.smsReceivedBoardStatusId,
      closedBoardStatusId: values.closedBoardStatusId,
      ignoreStatusIds: ignoredValues.map(i => i.value),
      ticketCategoryId: values.ticketCategoryId,
      crmSpecificSettings: values.crmSpecificSettings,
      ticketTimeZone: values.ticketTimeZone,
    };

    onSubmit(payload);
  };

  const onMount = useCallback(async () => {
    setLoading(true);
    if (data && data.defaultBoardId) {
      await handleBoardIdChange(data.defaultBoardId, {}, true);
    }
    await Promise.all([
      new Promise(res =>
        res(
          dispatch(ThunkGetIntegrationTicketCategories({ id: crmId })).unwrap(),
        ),
      ),
      new Promise(res =>
        res(
          dispatch(
            ThunkGetIntegrationSources({
              id: crmId,
            }),
          ).unwrap(),
        ),
      ),
      new Promise(res =>
        res(dispatch(ThunkGetIntegrationServiceBoards({ id: crmId })).unwrap()),
      ),
      new Promise(res =>
        res(dispatch(ThunkGetIntegrationPriorities({ id: crmId })).unwrap()),
      ),
      new Promise(res =>
        res(dispatch(ThunkGetIntegrationCompanies({ id: crmId })).unwrap()),
      ),
      new Promise(res => res(dispatch(ThunkGetTimeZones()).unwrap())),
      new Promise(res =>
        res(
          dispatch(
            ThunkGetIntegrationResources({
              id: crmId,
              type: crmSourcesByType.Autotask.name,
            }),
          ).unwrap(),
        ),
      ),
      new Promise(res =>
        res(
          dispatch(
            ThunkGetIntegrationBoardStatuses({
              id: crmId,
              boardId: 0,
            }),
          ).unwrap(),
        ),
      ),
    ])
      .then(res => {
        setTicketCategories(res[0]);
        setSources(res[1]);
        setServiceBoards(res[2]);
        setPriorities(res[3]);
        setCompanies(res[4]);
        setTimeZones(res[5]);
        setResources(res[6]);
        setBoardStatuses(
          res[7].map(({ id, name }) => ({
            label: name,
            value: id,
          })),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch, crmId, data, handleBoardIdChange]);

  const handleRefresh = async () => {
    await dispatch(ThunkClearAutotaskCache({ crmId })).unwrap();
    onMount();
  };

  useEffect(() => {
    onMount();
  }, [onMount]);

  if (loading) return <Loading />;
  return (
    <div className={classes.container}>
      <div className={classes.block}>
        <Form
          onSubmit={submit}
          initialValues={data ? getInitFormData(data) : {}}
          validate={values =>
            validateFormData(values, translate, crmSources.Autotask.name)
          }
          render={({ handleSubmit, values, form }) => (
            <form onSubmit={handleSubmit}>
              {data && (
                <div className={classes.refreshIcon}>
                  <ActionFieldItem
                    toolTip="Clear Cache"
                    alt="Refresh"
                    icon={RefreshIcon}
                    handler={() => {
                      handleRefresh();
                    }}
                  />
                </div>
              )}
              <div className={classes.form}>
                <div>
                  <Typography className={classes.inputLabel}>Name:</Typography>
                  <Field
                    id="name"
                    name="name"
                    styleType="main"
                    inputView="text"
                    component={Input}
                    placeholder="Name"
                    fullWidth
                    disabled={processing}
                  />
                </div>

                <div>
                  <Typography className={classes.inputLabel}>
                    Catchall Account:
                  </Typography>
                  <Field
                    id={crmDefaultFields.defaultCompany}
                    name={crmDefaultFields.defaultCompany}
                    size="small"
                    component={AutocompleteFormInput}
                    items={companies}
                    disabled={processing}
                  />
                </div>

                <div>
                  <Typography className={classes.inputLabel}>
                    Default Queue:
                  </Typography>
                  <Field
                    id={crmDefaultFields.defaultBoardId}
                    name={crmDefaultFields.defaultBoardId}
                    size="small"
                    labelName="name"
                    valueName="id"
                    allowEmpty
                    component={DropDown}
                    options={serviceBoards}
                    disabled={processing}
                  />
                  {form
                    .getRegisteredFields()
                    .some(item => item === crmDefaultFields.defaultBoardId) && (
                    <OnChange name={crmDefaultFields.defaultBoardId}>
                      {value => {
                        handleBoardIdChange(value, form);
                      }}
                    </OnChange>
                  )}
                </div>

                <div>
                  <Typography className={classes.inputLabel}>
                    Primary Resource:
                  </Typography>
                  <Field
                    id={baseFieldName + crmDefaultFields.assignedResourceId}
                    name={baseFieldName + crmDefaultFields.assignedResourceId}
                    size="small"
                    component={DropDown}
                    allowEmpty
                    options={resources}
                    disabled={processing}
                  />
                </div>

                <div>
                  <Typography className={classes.inputLabel}>
                    Issue Type:
                  </Typography>
                  <Field
                    id={crmDefaultFields.typeId}
                    name={crmDefaultFields.typeId}
                    size="small"
                    component={DropDown}
                    labelName="name"
                    valueName="id"
                    allowEmpty
                    options={boardTypes}
                    disabled={
                      processing || !values[crmDefaultFields.defaultBoardId]
                    }
                  />
                </div>

                <div>
                  <Typography className={classes.inputLabel}>
                    Sub-Issue Type:
                  </Typography>
                  <Field
                    id={crmDefaultFields.subTypeId}
                    name={crmDefaultFields.subTypeId}
                    size="small"
                    labelName="name"
                    valueName="id"
                    component={DropDown}
                    allowEmpty
                    options={
                      boardSubTypes[values[crmDefaultFields.typeId]] || []
                    }
                    disabled={processing || !values[crmDefaultFields.typeId]}
                  />
                </div>

                <div>
                  <Typography className={classes.inputLabel}>
                    New Ticket Status:
                  </Typography>
                  <Field
                    id={crmDefaultFields.openBoardStatusId}
                    name={crmDefaultFields.openBoardStatusId}
                    size="small"
                    component={DropDown}
                    options={boardStatuses}
                    disabled={processing}
                  />
                </div>

                <div>
                  <Typography className={classes.inputLabel}>
                    Acknowledged Status:
                  </Typography>
                  <Field
                    id={crmDefaultFields.acknowledgedBoardStatusId}
                    name={crmDefaultFields.acknowledgedBoardStatusId}
                    size="small"
                    component={DropDown}
                    options={boardStatuses}
                    disabled={processing}
                  />
                </div>

                <div>
                  <Typography className={classes.inputLabel}>
                    Closed Ticket Status:
                  </Typography>
                  <Field
                    id={crmDefaultFields.closedBoardStatusId}
                    name={crmDefaultFields.closedBoardStatusId}
                    size="small"
                    component={DropDown}
                    options={boardStatuses}
                    disabled={processing}
                  />
                </div>

                <div>
                  <Typography className={classes.inputLabel}>
                    SMS received Status:
                  </Typography>
                  <Field
                    id={crmDefaultFields.smsReceivedBoardStatusId}
                    name={crmDefaultFields.smsReceivedBoardStatusId}
                    size="small"
                    component={DropDown}
                    options={[
                      { value: undefined, label: '-' },
                      ...boardStatuses,
                    ]}
                    disabled={processing}
                  />
                </div>

                <div>
                  <MultiSelectDialog
                    title="Ignored statuses"
                    values={ignoredValues}
                    setValues={v => setIgnoredValues(v)}
                    fullArray={boardStatuses}
                    disabled={processing}
                  />
                </div>

                <div>
                  <Typography className={classes.inputLabel}>
                    Ticket Category:
                  </Typography>
                  <Field
                    id={crmDefaultFields.ticketCategoryId}
                    name={crmDefaultFields.ticketCategoryId}
                    size="small"
                    labelName="name"
                    valueName="id"
                    component={DropDown}
                    options={ticketCategories}
                    disabled={processing}
                  />
                </div>

                <div>
                  <Typography className={classes.inputLabel}>
                    Ticket Priority:
                  </Typography>
                  <Field
                    id={crmDefaultFields.ticketPriority}
                    name={crmDefaultFields.ticketPriority}
                    size="small"
                    labelName="name"
                    valueName="name"
                    component={DropDown}
                    options={priorities}
                    disabled={processing}
                  />
                </div>

                <div>
                  <Typography className={classes.inputLabel}>
                    Ticket Source:
                  </Typography>
                  <Field
                    id={crmDefaultFields.sourceId}
                    name={crmDefaultFields.sourceId}
                    size="small"
                    labelName="name"
                    valueName="id"
                    component={DropDown}
                    options={sources}
                    disabled={processing}
                  />
                </div>

                <div>
                  <Typography className={classes.inputLabel}>
                    Ticketing Time Zone:
                  </Typography>
                  <Field
                    id={crmDefaultFields.ticketTimeZone}
                    name={crmDefaultFields.ticketTimeZone}
                    size="small"
                    component={DropDown}
                    options={timeZones}
                    disabled={processing}
                  />
                </div>

                <AutotaskSubForm crmId={crmId} />
              </div>

              {processing ? (
                <CircularProgress size={18} thickness={2} />
              ) : (
                <div className={classes.buttonsWrapper}>
                  <ReusableButton
                    size="md"
                    type="button"
                    label="Cancel"
                    classNameWrapper={cx(
                      classes.buttonCancel,
                      classes.buttonStyle,
                    )}
                    onClick={onBack}
                  />
                  <ReusableButton
                    size="md"
                    classNameWrapper={classes.buttonStyle}
                    viewType="black"
                    type="submit"
                    label="resources.buttons.submit"
                  />
                </div>
              )}
            </form>
          )}
        />
      </div>
    </div>
  );
};

AutotaskDefaultForm.propTypes = {
  onSubmit: PropTypes.func,
  onBack: PropTypes.func,
  crmId: PropTypes.number,
  data: PropTypes.shape({
    defaultBoardId: PropTypes.number,
    id: PropTypes.string,
  }),
};

export default AutotaskDefaultForm;
