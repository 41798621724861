import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { createTheme, ThemeProvider, Tooltip } from '@material-ui/core';
import { ReactComponent as EmailIcon } from '@assets/icons/email_active.svg';
import { ReactComponent as PhoneIcon } from '@assets/icons/phone.svg';

import { lightTheme } from '@services/themes/mainTheme';

import { ReactComponent as CheckIcon } from '@assets/icons/shield_green.svg';
import { ReactComponent as WarningIcon } from '@assets/icons/shield_red.svg';
import { ReactComponent as UserVerified } from '@assets/icons/userVerified.svg';
import { ReactComponent as UserNotVerified } from '@assets/icons/userNotVerified.svg';

import useStyles from './styles';

const theme = createTheme(lightTheme());

const getItemName = item => item?.Name || item?.name;

const ContactPsaInfo = ({ handleRedirectToVerification }) => {
  const classes = useStyles();
  const data = useSelector(state => state.pod.data);

  const getValue = useCallback(i => {
    const val = i?.Value || i?.value;

    if (val && val?.length > 20) {
      return (
        <Tooltip title={val || ''}>
          <div>{`${val.slice(0, 20)}...`}</div>
        </Tooltip>
      );
    }
    return val;
  }, []);

  const getIcon = useCallback(
    i => {
      const verified = i?.isVerified || i?.IsVerified;

      if (verified) {
        return (
          <CheckIcon
            onClick={() => {
              handleRedirectToVerification({
                type: i?.Type || i?.type,
                value: i?.Value || i?.value,
                name: getItemName(i),
              });
            }}
            style={{ cursor: 'pointer' }}
          />
        );
      }
      return (
        <WarningIcon
          onClick={() => {
            handleRedirectToVerification({
              type: i?.Type || i?.type,
              value: i?.Value || i?.value,
              name: getItemName(i),
            });
          }}
          style={{ cursor: 'pointer' }}
        />
      );
    },
    [handleRedirectToVerification],
  );

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.contactInfoContainer}>
        <div className={classes.contactData}>
          <div className={classes.contactInitials}>
            <div className={classes.contactInitialsCircle}>
              {data?.isVerified ? <UserVerified /> : <UserNotVerified />}
            </div>
          </div>

          <div>
            <div>
              <b>{data?.ContactName}</b>
            </div>

            <div className={classes.contactInfoItem}>
              <div>Company:</div>
              <div>
                <b>{data?.CompanyName}</b>
              </div>
            </div>
          </div>
        </div>

        <div className={classes.communicationInfoContainer}>
          <div className={classes.bold}>PSA Contact Communication</div>
          <div>
            <EmailIcon className={classes.lowMargin} />
            {!!data?.communicationItems?.length &&
              data?.communicationItems
                .filter(p => p?.type === 'email' || p?.Type === 'email')
                .map(i => (
                  <div
                    key={i?.Value}
                    className={classes.communicationItem}
                    role="presentation"
                  >
                    <div className={classes.itemInfo}>
                      <b>{getItemName(i)}</b>
                      <p>{getValue(i)}</p>
                    </div>
                    <div className={classes.icon}>{getIcon(i)}</div>
                  </div>
                ))}
            <PhoneIcon className={classes.lowMargin} />
            {!!data?.communicationItems?.length &&
              data?.communicationItems
                .filter(p => p?.type === 'phone' || p?.Type === 'phone')
                .map(i => (
                  <div
                    key={i?.Value}
                    className={classes.communicationItem}
                    role="presentation"
                  >
                    <div className={classes.itemInfo}>
                      <b>{getItemName(i)}</b>
                      <p>{getValue(i)}</p>
                    </div>
                    <div>{getIcon(i)}</div>
                  </div>
                ))}
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

ContactPsaInfo.propTypes = {};

export default ContactPsaInfo;
