import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNotify } from 'react-admin';
import moment from 'moment';

import {
  ThunkAddConnectWiseTicketTimeEntry,
  ThunkEditConnectWiseTicketTimeEntry,
  ThunkGetConnectWiseTicketAgreements,
  ThunkGetConnectWiseTicketMembers,
  ThunkGetConnectWiseTicketTimeEntry,
  ThunkGetConnectWiseTicketWorkRoles,
  ThunkGetConnectWiseTicketWorkTypes,
} from '@store/slices/connectWiseTicket/thunks';
import { ThunkGetTicketInfo } from '@store/slices/crmTicket/thunks';
import { requiredValidator } from '@utils/validators';
import { currentUserDataSelector } from '@store/selectors';

import FieldWrapper from '@ui/components/common/form/FieldWrapper';
import Input from '@ui/components/Auth/Common/Input';
import ReusableButton from '@ui/components/common/Button/Button';
import Loading from '@ui/components/common/Loading/Loading';
import AutocompleteFormInput from '@ui/components/common/AutocompleteFormInput/AutocompleteFormInput';
import DropDown from '@ui/components/Auth/Common/DropDown';
import { OnChange } from 'react-final-form-listeners';
import Switch from '@common/FilterInputs/Switch';
import useStyles from '../sharedStyles';

const AddCWTicketTimeEntryDialog = ({
  open,
  setOpen,
  crmDefaultId,
  ticketId,
  timeEntryId = undefined,
  setSelectedItem = () => {},
  initialStartEndTime = null,
}) => {
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [timeEntry, setTimeEntry] = useState(undefined);

  const dispatch = useDispatch();
  const notify = useNotify();
  const classes = useStyles();
  const { connectwiseLinkedUser } = useSelector(currentUserDataSelector);

  //   * form options
  const [members, setMembers] = useState([]);
  const [ticketInfo, setTicketInfo] = useState([]);
  const [agreements, setAgreements] = useState([]);
  const [workRoles, setWorkRoles] = useState([]);
  const [workTypes, setWorkTypes] = useState([]);

  const getMemberId = () =>
    +crmDefaultId === connectwiseLinkedUser?.crmDefaultId
      ? connectwiseLinkedUser?.userId
      : '';

  const initialFormData = {
    startDateTime: initialStartEndTime
      ? moment
          .utc(initialStartEndTime.start)
          .local()
          .format('YYYY-MM-DDTHH:mm')
      : moment().format('YYYY-MM-DDTHH:mm'),
    endDateTime: initialStartEndTime
      ? moment(initialStartEndTime.end).format('YYYY-MM-DDTHH:mm')
      : moment()
          .add(30, 'm')
          .format('YYYY-MM-DDTHH:mm'),
    memberId: getMemberId(),
    workRoleId: members?.find(m => m.value === getMemberId())?.info,
  };

  const submit = async values => {
    setProcessing(true);
    const payload = {
      ...values,
      ticketId,
      companyId: ticketInfo.companyId,
      startDateTime: moment(values.startDateTime)
        .utc()
        .toISOString(),
      endDateTime: moment(values.endDateTime)
        .utc()
        .toISOString(),
    };

    if (timeEntryId) {
      // * EDIT
      await dispatch(
        ThunkEditConnectWiseTicketTimeEntry({
          crmId: crmDefaultId,
          payload,
          timeEntryId,
        }),
      )
        .unwrap()
        .then(() => {
          notify('Successfully edited', 'success');
          setOpen(false);
        })
        .finally(() => {
          setProcessing(false);
        });
    } else {
      // * ADD
      await dispatch(
        ThunkAddConnectWiseTicketTimeEntry({ crmId: crmDefaultId, payload }),
      )
        .unwrap()
        .then(() => {
          notify('Successfully added', 'success');
          setOpen(false);
        })
        .finally(() => {
          setProcessing(false);
        });
    }
  };

  const validate = values => {
    return {
      memberId: requiredValidator(values.memberId),
      summaryNotes: requiredValidator(values.summaryNotes),
      startDateTime: requiredValidator(values.startDateTime),
      endDateTime: requiredValidator(values.endDateTime),
    };
  };

  const onMount = useCallback(async () => {
    setLoading(true);

    const info = await dispatch(
      ThunkGetTicketInfo({ crmId: crmDefaultId, ticketId }),
    ).unwrap();
    setTicketInfo(info);

    if (timeEntryId) {
      const timeEntryData = await dispatch(
        ThunkGetConnectWiseTicketTimeEntry({
          crmId: crmDefaultId,
          timeEntryId,
        }),
      ).unwrap();
      setTimeEntry(timeEntryData);
    }

    await Promise.all([
      new Promise(res =>
        res(dispatch(ThunkGetConnectWiseTicketMembers(crmDefaultId)).unwrap()),
      ),
      new Promise(res =>
        res(
          dispatch(
            ThunkGetConnectWiseTicketAgreements({
              crmId: crmDefaultId,
              companyId: info.companyId,
            }),
          ).unwrap(),
        ),
      ),
      new Promise(res =>
        res(
          dispatch(ThunkGetConnectWiseTicketWorkRoles(crmDefaultId)).unwrap(),
        ),
      ),
      new Promise(res =>
        res(
          dispatch(ThunkGetConnectWiseTicketWorkTypes(crmDefaultId)).unwrap(),
        ),
      ),
    ]).then(res => {
      setMembers(res[0]);
      setAgreements(res[1]);
      setWorkRoles(res[2]);
      setWorkTypes(res[3]);
    });

    setLoading(false);
  }, [dispatch, crmDefaultId, ticketId, timeEntryId]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  const handleMemberChange = (value, form) => {
    const selectedMemberRoleId = members.find(m => m.value === value)?.info;
    form.change('workRoleId', selectedMemberRoleId);
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
        setSelectedItem(undefined);
      }}
    >
      <DialogTitle>
        {timeEntryId ? 'Edit ' : 'Add '}
        Time Entry
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <Loading />
        ) : (
          <Form
            onSubmit={submit}
            validate={validate}
            initialValues={timeEntryId ? timeEntry : initialFormData}
            render={({ handleSubmit, form }) => (
              <form onSubmit={handleSubmit}>
                <FieldWrapper
                  label="Agreements"
                  labelSize={12}
                  contentSize={12}
                  showLabel
                  classNameLabelInner={classes.alignLeft}
                  content={
                    <Field
                      name="agreementId"
                      id="agreementId"
                      render={AutocompleteFormInput}
                      items={agreements}
                      disabled={processing}
                    />
                  }
                />

                <FieldWrapper
                  label="Member"
                  labelSize={12}
                  contentSize={12}
                  showLabel
                  isRequired
                  classNameLabelInner={classes.alignLeft}
                  content={
                    <Field
                      name="memberId"
                      id="memberId"
                      render={AutocompleteFormInput}
                      items={members}
                      disabled={processing}
                    />
                  }
                />

                <OnChange name="memberId">
                  {value => {
                    handleMemberChange(value, form);
                  }}
                </OnChange>

                <FieldWrapper
                  label="Work Roles"
                  labelSize={12}
                  contentSize={12}
                  showLabel
                  classNameLabelInner={classes.alignLeft}
                  isRequired
                  content={
                    <Field
                      name="workRoleId"
                      id="workRoleId"
                      render={DropDown}
                      options={workRoles}
                      disabled={processing}
                    />
                  }
                />

                <FieldWrapper
                  label="Work Types"
                  labelSize={12}
                  contentSize={12}
                  showLabel
                  classNameLabelInner={classes.alignLeft}
                  content={
                    <Field
                      name="workTypeId"
                      id="workTypeId"
                      render={DropDown}
                      options={workTypes}
                      disabled={processing}
                    />
                  }
                />

                <FieldWrapper
                  label="Notes"
                  labelSize={12}
                  contentSize={12}
                  showLabel
                  isRequired
                  classNameLabelInner={classes.alignLeft}
                  content={
                    <Field
                      name="summaryNotes"
                      id="summaryNotes"
                      render={Input}
                      multiline
                      minRows={3}
                      disabled={processing}
                    />
                  }
                />

                <div className={classes.switchRow}>
                  <div>
                    <span>Description</span>
                    <Field
                      name="addToDetailDescriptionFlag"
                      id="addToDetailDescriptionFlag"
                      type="checkbox"
                      render={Switch}
                      disabled={processing}
                    />
                  </div>
                  <div>
                    <span>Internal</span>
                    <Field
                      name="addToInternalAnalysisFlag"
                      id="addToInternalAnalysisFlag"
                      type="checkbox"
                      render={Switch}
                      disabled={processing}
                    />
                  </div>
                  <div>
                    <span>Resolution</span>
                    <Field
                      name="addToResolutionFlag"
                      id="addToResolutionFlag"
                      type="checkbox"
                      render={Switch}
                      disabled={processing}
                    />
                  </div>
                </div>

                <FieldWrapper
                  label="Start Time"
                  labelSize={12}
                  contentSize={12}
                  showLabel
                  isRequired
                  classNameLabelInner={classes.alignLeft}
                  content={
                    <Field
                      name="startDateTime"
                      id="startDateTime"
                      render={Input}
                      disabled={processing}
                      type="datetime-local"
                    />
                  }
                />

                <FieldWrapper
                  label="End Time"
                  labelSize={12}
                  contentSize={12}
                  showLabel
                  isRequired
                  classNameLabelInner={classes.alignLeft}
                  content={
                    <Field
                      name="endDateTime"
                      id="endDateTime"
                      render={Input}
                      disabled={processing}
                      type="datetime-local"
                    />
                  }
                />

                {/* <FieldWrapper
                  label="Email to Resource"
                  labelSize={12}
                  contentSize={12}
                  showLabel
                  classNameLabelInner={classes.alignLeft}
                  content={
                    <Field
                      name="emailResourceFlag"
                      id="emailResourceFlag"
                      render={FormSwitch}
                      disabled={processing}
                    />
                  }
                />

                <FieldWrapper
                  label="Email to Contact"
                  labelSize={12}
                  contentSize={12}
                  showLabel
                  classNameLabelInner={classes.alignLeft}
                  content={
                    <Field
                      name="emailContactFlag"
                      id="emailContactFlag"
                      render={FormSwitch}
                      disabled={processing}
                    />
                  }
                />

                <FieldWrapper
                  label="Email to CC"
                  labelSize={12}
                  contentSize={12}
                  showLabel
                  classNameLabelInner={classes.alignLeft}
                  content={
                    <Field
                      name="emailCcFlag"
                      id="emailCcFlag"
                      render={FormSwitch}
                      disabled={processing}
                    />
                  }
                /> */}

                <div className={classes.dialogActionsContainer}>
                  <ReusableButton
                    label="Cancel"
                    onClick={() => {
                      setOpen(false);
                      setSelectedItem(undefined);
                    }}
                    disabled={processing}
                  />

                  <ReusableButton
                    label="Submit"
                    type="submit"
                    disabled={processing}
                    loading={processing}
                  />
                </div>
              </form>
            )}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

AddCWTicketTimeEntryDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  crmDefaultId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ticketId: PropTypes.string,
  timeEntryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setSelectedItem: PropTypes.func,
  initialStartEndTime: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
  }),
};

export default AddCWTicketTimeEntryDialog;
