import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'react-admin';
import { Paper } from '@material-ui/core';
import copyText from '@utils/copy';
import ActionButton from '@common/buttons/ActionButton/ActionButton';
import iconCopy from '@assets/icons/copyGrey.svg';
import dangerIcon from '@assets/icons/dangerTriangle.svg';

import useStyles from './styles';

const GoogleSheets = ({ resource }) => {
  const classes = useStyles();
  const translate = useTranslate();

  const getLink = () =>
    resource ? `=IMPORTDATA("${resource.csv.value}")` : '';

  const getText = key => `resources.exporting.googleSheets.${key}`;

  return (
    <div className={classes.tabBase}>
      <div className={classes.marginLeft}>
        <div>{translate(getText('header'))}</div>
        <div className={classes.marginTop}>
          {translate(getText('text'))}
          &nbsp;
          <a
            href="https://docs.google.com/spreadsheets/u/0/"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
          >
            Google Sheets
          </a>
        </div>
      </div>
      <div className={classes.bold}>
        <span>Document link</span>
      </div>
      <div className={classes.displayFlex}>
        <ActionButton
          icon={<img src={iconCopy} alt="iconCopy" />}
          handler={() => copyText(getLink())}
          toolTip="Copy link"
        />

        <div className={classes.linkWrapper}>
          <pre className={classes.code}>
            <code className={classes.darkBrown}>{getLink()}</code>
          </pre>
        </div>
      </div>
      <Paper className={classes.paperWrapper}>
        <div className={classes.displayFlex}>
          <img src={dangerIcon} alt="dangerIcon" />
          <span className={classes.bold}>
            &nbsp;
            {translate(getText('limitationCaption'))}
          </span>
        </div>
        <div>
          <ul>
            <li className={classes.liMargin}>{translate(getText('limit1'))}</li>
            <li className={classes.liMargin}>{translate(getText('limit2'))}</li>
          </ul>
        </div>
      </Paper>
    </div>
  );
};

GoogleSheets.propTypes = {
  resource: PropTypes.objectOf(PropTypes.any),
  setResource: PropTypes.func,
  includeServiceInfo: PropTypes.bool,
  setIncludeServiceInfo: PropTypes.func,
};

export default GoogleSheets;
