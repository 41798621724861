import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { useNotify } from 'react-admin';

import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';

import FieldWrapper from '@common/form/FieldWrapper';
import { actions } from '@store/actions';
import Input from '@components/Auth/Common/Input';
import ReusableButton from '@common/Button/Button';
import { ThunkAddSmsBlackListNumber } from '@store/slices/smsBlackList/thunks/addSmsBlackListNumber';
import phoneNumberValidator from '@utils/validators/phoneNumberValidator';

import useStyles from '../styles';

const AddBlackListNumberDialog = ({ open, setOpen }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const notify = useNotify();

  const [processing, setProcessing] = useState(false);

  const submit = values => {
    setProcessing(true);
    dispatch(ThunkAddSmsBlackListNumber(values.phoneNumber))
      .unwrap()
      .then(data => {
        dispatch(actions.updateItemInList(data));
        notify('Successfully added', 'success');
      })
      .catch(e => notify(e.message, 'error'))
      .finally(() => {
        setProcessing(false);
        setOpen(false);
      });
  };

  const validate = values => {
    return {
      phoneNumber: phoneNumberValidator(values.phoneNumber),
    };
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogTitle>Opt-out a Recipient</DialogTitle>
      <DialogContent style={{ width: '25rem' }}>
        <Form
          onSubmit={submit}
          validate={validate}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <FieldWrapper
                label="Phone number"
                labelSize={4}
                contentSize={8}
                showLabel
                isRequired
                classNameLabelInner={classes.alignLeft}
                content={
                  <Field
                    name="phoneNumber"
                    id="phoneNumber"
                    render={Input}
                    disabled={processing}
                  />
                }
              />

              <div className={classes.actionButtons}>
                <ReusableButton
                  label="Cancel"
                  onClick={() => {
                    setOpen(false);
                  }}
                  disabled={processing}
                />

                <ReusableButton
                  viewType="black"
                  label="Submit"
                  type="submit"
                  disabled={processing}
                  loading={processing}
                />
              </div>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  );
};

AddBlackListNumberDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

export default AddBlackListNumberDialog;
