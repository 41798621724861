import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import { useNotify } from 'react-admin';
import { OnChange } from 'react-final-form-listeners';
import { useDispatch } from 'react-redux';

import DropDown from '@ui/components/Auth/Common/DropDown';
import FieldWrapper from '@ui/components/common/form/FieldWrapper';
import Input from '@ui/components/common/Input/Input';
import ReusableButton from '@ui/components/common/Button/Button';

import { actions } from '@store/actions';
import { getDefaultsAutocomplete } from '@components/Ticketing/Notifications/helpers';
import { requiredValidator } from '@utils/validators';
import { createTicketForIncident } from '@ui/components/Triggers/helpers';
import crmSources from '@constants/crmSources';

import useStyles from '../../../styles';

const CreateTicket = ({ open, onClose, incidentId }) => {
  const [loading, setLoading] = useState(false);
  const [allOptions, setAllOptions] = useState([]);
  const [defaultsOptions, setDefaultsOptions] = useState([]);
  const notify = useNotify();
  const dispatch = useDispatch();
  const classes = useStyles();

  const crmOptions = Object.values(crmSources).map((i, idx) => ({
    label: i.label,
    value: idx + 1,
  }));

  const handleCrmChanged = v => {
    setDefaultsOptions(allOptions.filter(o => o.info === v));
  };

  const submit = values => {
    setLoading(true);
    const payload = { ...values, incidentId };
    createTicketForIncident(payload)
      .then(res => {
        dispatch(actions.updateItemInList(res));
      })
      .catch(err => {
        notify(err.message, 'error');
      })
      .finally(() => {
        setLoading(false);
        onClose();
      });
  };

  useEffect(() => {
    setLoading(true);
    getDefaultsAutocomplete()
      .then(res => {
        setAllOptions(res);
      })
      .catch(err => {
        notify(err.message, 'error');
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const validate = values => {
    return {
      ticketSubject: requiredValidator(values.ticketSubject),
      crm: requiredValidator(values.crm),
      crmTicketDefaultId: requiredValidator(values.crmTicketDefaultId),
      ticketBody: requiredValidator(values.ticketBody),
    };
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle>Create Ticket</DialogTitle>
      <DialogContent>
        <Form
          validate={validate}
          onSubmit={submit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Grid container className={classes.createTicketContainer}>
                <FieldWrapper
                  label="PSA"
                  labelSize={2}
                  contentSize={10}
                  content={
                    <>
                      <Field
                        id="crm"
                        name="crm"
                        component={DropDown}
                        options={crmOptions}
                      />
                      <OnChange name="crm">
                        {value => {
                          handleCrmChanged(value - 1);
                        }}
                      </OnChange>
                    </>
                  }
                />
                <FieldWrapper
                  label="PSA Defaults"
                  labelSize={2}
                  contentSize={10}
                  content={
                    <Field
                      id="crmTicketDefaultId"
                      name="crmTicketDefaultId"
                      component={DropDown}
                      options={defaultsOptions}
                      disabled={loading || !defaultsOptions.length}
                    />
                  }
                />

                <FieldWrapper
                  label="Ticket Subject"
                  labelSize={2}
                  contentSize={10}
                  content={
                    <Field
                      id="ticketSubject"
                      name="ticketSubject"
                      styleType="main"
                      inputView="text"
                      fullWidth
                      component={Input}
                      disabled={loading}
                    />
                  }
                />

                <FieldWrapper
                  label="Body"
                  labelSize={2}
                  contentSize={10}
                  content={
                    <Field
                      id="ticketBody"
                      name="ticketBody"
                      styleType="main"
                      inputView="text"
                      fullWidth
                      component={Input}
                      disabled={loading}
                      multiline
                      rows={4}
                      variant="outlined"
                    />
                  }
                />
              </Grid>
              <DialogActions>
                <ReusableButton
                  size="md"
                  viewType="red"
                  label="Close"
                  onClick={onClose}
                  disabled={loading}
                />
                <ReusableButton
                  size="md"
                  label="Create"
                  type="submit"
                  loading={loading}
                  disabled={loading}
                />
              </DialogActions>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  );
};

export default CreateTicket;
