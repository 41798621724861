import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import { useNotify } from 'react-admin';

import DeleteIcon from '@assets/icons/delete.svg';

import ActionFieldItem from '@common/ActionButtons/ActionFieldItem';
import ReusableButton from '@common/Button/Button';
import ImportFromPsa from '@ui/pages/smsCampaigns/SmsBroadcast/dialogs/ImportFromPsa';
import ImportFromSmsGroup from '@ui/pages/smsCampaigns/SmsBroadcast/dialogs/ImportFromSmsGroup';
import AddManuallyUser from '@ui/pages/smsCampaigns/SmsBroadcast/dialogs/AddManuallyUser';
import CreateNewSmsGroup from '@ui/pages/smsCampaigns/SmsBroadcast/dialogs/CreateNewSmsGroup';

import { ThunkSendGroupMessage } from '@store/slices/smsCampaigns/thunks';

import useStyles from '../styles';

const SelectRecipients = ({ message }) => {
  const [recipients, setRecipients] = useState([]);

  const [openFromPsa, setOpenFromPsa] = useState(false);
  const [openFromSmsGroup, setOpenFromSmsGroup] = useState(false);
  const [openAddManually, setOpenAddManually] = useState(false);
  const [openSaveAsNewCampaignGroup, setOpenSaveAsNewCampaignGroup] = useState(
    false,
  );

  const dispatch = useDispatch();
  const classes = useStyles();
  const notify = useNotify();

  const handleAddRecipients = setter => contacts => {
    setter(false);
    setRecipients(recipients.length ? [...recipients, ...contacts] : contacts);
  };

  const handleDeleteRecipients = recipientId => {
    setRecipients(recipients.filter(i => i.id !== recipientId));
  };

  const sendMessages = () => {
    const payload = { message, phoneNumbers: recipients.map(i => i.phone) };
    dispatch(ThunkSendGroupMessage({ payload }))
      .unwrap()
      .then(() => {
        notify('Messages sent', 'success');
      });
  };

  return (
    <div className={classes.recipients}>
      <div className={classes.flexContainer}>
        <div className={classes.header}>Imports</div>

        <div className={classes.buttons}>
          <div className={classes.align}>
            <ReusableButton
              label="From PSA"
              onClick={() => setOpenFromPsa(true)}
            />
            <ReusableButton
              label="From Messaging Groups"
              onClick={() => setOpenFromSmsGroup(true)}
            />
          </div>

          <div>
            <ReusableButton
              label="Add Manually"
              onClick={() => {
                setOpenAddManually(true);
              }}
            />
          </div>
        </div>
      </div>

      <div className={classes.overflow}>
        <div className={classes.headerExtended}>Recipients</div>

        {!!recipients.length && (
          <div>
            <div className={cx(classes.tableRow, classes.bold)}>
              <div>Name</div>
              <div>Phone</div>
              <div>Type</div>
              <div>Actions</div>
            </div>

            {recipients.map(i => (
              <div key={i.id} className={classes.tableRow}>
                <div>{i.name}</div>
                <div>{i.phone}</div>
                <div>{i.type}</div>

                <div>
                  <ActionFieldItem
                    handler={() => {
                      handleDeleteRecipients(i.id);
                    }}
                    icon={DeleteIcon}
                    classNameWrapper={classes.alignCenter}
                    alt="Remove"
                    toolTip="Remove"
                  />
                </div>
              </div>
            ))}
          </div>
        )}

        {recipients.length === 0 && <div>Add at least one recipient.</div>}
      </div>

      <div className={classes.spaceBetween}>
        <div>
          <ReusableButton
            label="Add as new Messaging Group"
            onClick={() => {
              setOpenSaveAsNewCampaignGroup(true);
            }}
          />
        </div>

        <div>
          <ReusableButton
            label="Send"
            viewType="blue"
            disabled={!recipients?.length}
            onClick={sendMessages}
          />
        </div>
      </div>

      {openFromPsa && (
        <ImportFromPsa
          open={openFromPsa}
          onClose={() => {
            setOpenFromPsa(false);
          }}
          onAdd={handleAddRecipients(setOpenFromPsa)}
        />
      )}

      {openFromSmsGroup && (
        <ImportFromSmsGroup
          open={openFromSmsGroup}
          onClose={() => {
            setOpenFromSmsGroup(false);
          }}
          onAdd={handleAddRecipients(setOpenFromSmsGroup)}
        />
      )}

      {openAddManually && (
        <AddManuallyUser
          open={openAddManually}
          onClose={() => {
            setOpenAddManually(false);
          }}
          onAdd={handleAddRecipients(setOpenAddManually)}
        />
      )}

      {openSaveAsNewCampaignGroup && (
        <CreateNewSmsGroup
          open={openSaveAsNewCampaignGroup}
          onClose={() => {
            setOpenSaveAsNewCampaignGroup(false);
          }}
          items={recipients}
        />
      )}
    </div>
  );
};

SelectRecipients.propTypes = {
  message: PropTypes.string.isRequired,
};

export default SelectRecipients;
