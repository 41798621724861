import React, { useState } from 'react';
import useAsync from '@services/api/common/useAsync';
import Loading from '@common/Loading/Loading';
import linkIcon from '@assets/icons/link.svg';
import { Link } from '@material-ui/core';
import useTranslatedText from '@common/hooks/useTranslatedText';
import resources from '@constants/resources';
import SourceItemValue from './SourceItemValue';
import { hooksUsage } from '../helpers';
import useStyles from '../styles';

const HookSourceItem = ({ total, processed, hookSourceId, hookSourceName }) => {
  const classes = useStyles();
  const { getText } = useTranslatedText(resources.tenantStatistic);
  return (
    <div className={classes.item}>
      <SourceItemValue label={getText('hookName')} value={hookSourceName} />
      <SourceItemValue label={getText('hookTotal')} value={total} />
      <SourceItemValue label={getText('hookProcessed')} value={processed} />
      <Link
        href={`#/webHooks/${hookSourceId}`}
        onClick={e => e.stopPropagation()}
      >
        <img src={linkIcon} alt="linkIcon" className={classes.iconButton} />
      </Link>
    </div>
  );
};

const Hooks = () => {
  const { getText } = useTranslatedText(resources.tenantStatistic);
  const [data, setData] = useState({});
  const loading = useAsync(hooksUsage, setData);

  if (loading) return <Loading />;
  return (
    <>
      <SourceItemValue label={getText('totalHooks')} value={data.total} />
      <SourceItemValue label={getText('successHooks')} value={data.processed} />
      <div>
        {data?.hookSourceStatistic?.map(item => (
          <HookSourceItem
            key={item.hookSourceId}
            total={item.total}
            hookSourceId={item.hookSourceId}
            hookSourceName={item.hookSourceName}
            processed={item.processed}
          />
        ))}
      </div>
    </>
  );
};

export default Hooks;
