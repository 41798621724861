import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRedirect, useTranslate } from 'react-admin';
import { Field, Form } from 'react-final-form';
import PropTypes from 'prop-types';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import { enqueueSnackbar } from 'notistack';

import {
  TEXT_CREATE_SUCESS,
  TEXT_UPDATE_SUCESS,
} from '@constants/texts/common';
import Input from '@common/Input/Input';
import useAsync from '@services/api/common/useAsync';
import DropDown from '@components/Auth/Common/DropDown';
import { NOTIFICATIONS_DATA } from '@constants/routes';
import FieldWrapper from '@common/form/FieldWrapper';
import ReusableButton from '@common/Button/Button';
import Paper from '@common/Paper/Paper';
import SwitchInput from '@common/FilterInputs/Switch';
import iconArrow from '@assets/icons/arrowGrey.svg';
import { actions } from '@store/actions';
import {
  emailSettings,
  ftpSettings,
  notificationSettings,
  slackSettings,
  smsSettings,
  teamsSettings,
  ticketSettings,
  voiceSettings,
  webHookSettings,
} from '@store/selectors/notifications';
import Checkbox from '@common/Checkbox/FormControlCheckBox';
import SettingsTile from '@components/Ticketing/Notifications/components/SettingsTile';
import ConfirmDialog from '@common/ConfirmDialog/ConfirmDialog';
import useAllowUpdate from '@components/Ticketing/Notifications/hooks/useAllowUpdate';
import notificationSources from '@components/Ticketing/Notifications/TicketSettings/notificationSources';
import useTranslation from '@common/hooks/useTranslation';
import InputWithAutocomplete from '@common/Input/InputWithAutocomplete';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import AuditViewForm from '../AuditViewForm';
import GenericPipelines from '../components/GenericPipelines';
import {
  createNotificationSetting,
  filtersNotEmpty,
  getAuditViewsAutocomplete,
  getTriggersAutocomplete,
  postEmailSettings,
  postFtpSettings,
  postSlackSettings,
  postSmsSettings,
  postTeamsSettings,
  postTicketSettings,
  postVoiceSettings,
  postWebHooksSettings,
  updateNotificationSetting,
} from '../helpers';
import useStyles from './stylesNotificationSettingsForm';
import tileData from '../../utils';
import CrmTreeView from '../components/CrmTreeView';

const loadData = data => {
  return {
    id: data && data.id ? data.id : 0,
    name: data ? data.name : null,
    enabled: data ? data.enabled : true,
    detailedDescription: data ? data.detailedDescription : null,
    filters: data && data.filters ? JSON.parse(data.filters) : {},
    isTicket: data ? data.isTicket : false,
    isEmail: data ? data.isEmail : false,
    isSms: data ? data.isSms : false,
    isWebHook: data ? data.isWebHook : false,
    isSlack: data ? data.isSlack : false,
    isTeams: data ? data.isTeams : false,
    isFtp: data ? data.isFtp : false,
    isVoice: data ? data.isVoice : false,
    auditViewId: data ? data.auditViewId : 0,
    ignoreAcknowledged: data ? !!data.ignoreAcknowledged : true,
    useGenericPipelines: data ? !!data.useGenericPipelines : false,
    pipelineId: data ? data.pipelineId : undefined,
    input: data && data.input ? data.input : {},
    notificationSettingSource:
      data && data.notificationSettingSource
        ? data.notificationSettingSource
        : 0,
    triggers: data && data.triggers ? data.triggers : [],
    isDelayNotification:
      data && data.isDelayNotification ? data.isDelayNotification : false,
    eventForRepeatField:
      data && data.eventForRepeatField ? data.eventForRepeatField : '',
    delayMinutes: data && data.delayMinutes ? Math.abs(+data.delayMinutes) : 0,
    shiftDirection: data && data.delayMinutes && data.delayMinutes < 0 ? -1 : 1,
    skipInitialNotification:
      data && data.skipInitialNotification
        ? data.skipInitialNotification
        : false,
  };
};

const shiftDirectionOptions = [
  { label: 'before', value: 1 },
  { label: 'after', value: -1 },
];

const processDelayOnSave = values => {
  if (values.shiftDirection === -1) {
    values.delayMinutes *= -1;
  }
};

const flexibleDataLoader = (loadedData, storedData) => {
  const data = Object.keys(storedData).length ? storedData : loadedData;
  return loadData(data);
};

const NotificationSettingsForm = ({ data }) => {
  const inMemorySetting = useSelector(notificationSettings);
  const inMemoryTicketSetting = useSelector(ticketSettings);
  const inMemoryEmailSetting = useSelector(emailSettings);
  const inMemorySmsSetting = useSelector(smsSettings);
  const inMemoryWebHookSetting = useSelector(webHookSettings);
  const inMemorySlackSetting = useSelector(slackSettings);
  const inMemoryTeamsSetting = useSelector(teamsSettings);
  const inMemoryFtpSetting = useSelector(ftpSettings);
  const inMemoryVoiceSettings = useSelector(voiceSettings);

  const initData = useMemo(() => flexibleDataLoader(data, inMemorySetting), [
    inMemorySetting,
    data,
  ]);

  const dispatch = useDispatch();
  const classes = useStyles();
  const translate = useTranslate();
  const redirect = useRedirect();
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(initData.enabled);
  const [auditViews, setAuditViews] = useState([]);
  const [auditFormData, setAuditFormData] = useState(initData.filters);
  const [preSavedModel, setPreSavedModel] = useState({});
  const [warningDialogOpen, setWarningDialogOpen] = useState(false);
  const [inputFields, setInputFields] = useState([]);
  useAsync(getAuditViewsAutocomplete, setAuditViews);
  const [triggersOptions, setTriggersOptions] = useState([]);
  useAsync(getTriggersAutocomplete, setTriggersOptions);
  const { getLabel } = useTranslation('users');

  const isAllowedUpdate = useAllowUpdate();

  const sourceOptions = useMemo(
    () =>
      Object.keys(notificationSources).map(p => ({
        label: notificationSources[p].name,
        value: +p,
      })),
    [notificationSources],
  );

  const dataViewFieldNamesOptions = useMemo(
    () => Object.keys(auditFormData).map(i => ({ label: i, value: i })),
    [auditFormData],
  );

  const validate = values => {
    const errors = {};
    errors.input = {};

    if (!values.name) {
      errors.name = translate('ra.validation.required');
    }

    if (!values.auditViewId && values.notificationSettingSource === 0) {
      errors.auditViewId = translate('ra.validation.required');
    }

    if (!values.triggers.length && values.notificationSettingSource === 1) {
      errors.triggers = translate('ra.validation.required');
    }

    if (values.useGenericPipelines && values.pipelineId) {
      inputFields.forEach(el => {
        if (!values.input || !values.input[el.name]) {
          errors.input[el.name] = translate('ra.validation.required');
        }
      });
    }
    return errors;
  };

  const isAllow = setting => setting && Object.keys(setting).length;

  const create = val => {
    return createNotificationSetting(val)
      .then(model => {
        const promises = [
          isAllow(inMemoryTicketSetting) && {
            tickets: postTicketSettings(model.id, {
              ...inMemoryTicketSetting,
              id: model.id,
            }),
          },
          isAllow(inMemoryEmailSetting) && {
            tickets: postEmailSettings(model.id, {
              ...inMemoryEmailSetting,
              id: model.id,
            }),
          },
          isAllow(inMemorySmsSetting) && {
            tickets: postSmsSettings(model.id, {
              ...inMemorySmsSetting,
              id: model.id,
            }),
          },
          isAllow(inMemoryWebHookSetting) && {
            tickets: postWebHooksSettings(model.id, {
              ...inMemoryWebHookSetting,
              id: model.id,
            }),
          },
          isAllow(inMemorySlackSetting) && {
            tickets: postSlackSettings(model.id, {
              ...inMemorySlackSetting,
              id: model.id,
            }),
          },
          isAllow(inMemoryTeamsSetting) && {
            tickets: postTeamsSettings(model.id, {
              ...inMemoryTeamsSetting,
              id: model.id,
            }),
          },
          isAllow(inMemoryFtpSetting) && {
            tickets: postFtpSettings(model.id, {
              ...inMemoryFtpSetting,
              id: model.id,
            }),
          },
          isAllow(inMemoryVoiceSettings) && {
            tickets: postVoiceSettings(model.id, {
              ...inMemoryFtpSetting,
              id: model.id,
            }),
          },
        ]
          .filter(i => i)
          .map(i => i.tickets);

        return Promise.all(promises).then(() =>
          enqueueSnackbar(TEXT_CREATE_SUCESS, { variant: 'success' }),
        );
      })
      .catch(() => {
        enqueueSnackbar('Unable to create ticket default', {
          variant: 'error',
        });
      });
  };

  const update = val => {
    return updateNotificationSetting(val.id, val)
      .then(() => enqueueSnackbar(TEXT_UPDATE_SUCESS, { variant: 'success' }))
      .catch(() =>
        enqueueSnackbar('Unable to update ticket default', {
          variant: 'error',
        }),
      );
  };

  const handleBack = () => redirect(NOTIFICATIONS_DATA);

  const getPayload = values => {
    return {
      ...values,
      id: initData.id,
      enabled: active,
      filters: JSON.stringify(auditFormData),
    };
  };

  const storeData = values => {
    const payload = getPayload(values);
    processDelayOnSave(payload);
    let promise;
    setLoading(true);
    if (!payload.id) {
      promise = create(payload);
    } else {
      promise = update(payload);
    }
    promise.then(() => {
      setLoading(false);
      handleBack();
    });
  };

  const submit = values => {
    if (
      values.notificationSettingSource === 1 ||
      filtersNotEmpty(auditFormData)
    ) {
      storeData(values);
    } else {
      setPreSavedModel(values);
      setWarningDialogOpen(true);
    }
  };

  const onCancelWarningHandler = () => {
    setPreSavedModel({});
    setWarningDialogOpen(false);
  };

  const onWarningHandler = () => {
    onCancelWarningHandler();
    storeData(preSavedModel);
  };

  const handleActiveChange = event => setActive(event.target.checked);

  const handleAuditFormDataChange = useCallback(
    val => setAuditFormData(val),
    [],
  );

  const storeDataToRedux = formData =>
    dispatch(actions.saveSetting(getPayload(formData)));

  const onDragEnd = ({ draggableId, destination: { droppableId } }) => {
    if (
      !auditFormData[droppableId].includes(String(draggableId.split('/')[1]))
    ) {
      const updatedAuditFormData = {
        ...auditFormData,
        [droppableId]: auditFormData[droppableId]?.length
          ? [...auditFormData[droppableId], String(draggableId.split('/')[1])]
          : [String(draggableId.split('/')[1])],
      };
      setAuditFormData(updatedAuditFormData);
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="filter-form">
        {provided => (
          <div
            className={classes.droppableContainer}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            <Paper classNameContainer={classes.formContainer}>
              <Form
                onSubmit={submit}
                validate={validate}
                initialValues={initData}
                render={({ handleSubmit, values, form }) => (
                  <form
                    onSubmit={handleSubmit}
                    noValidate
                    className={classes.formWrapper}
                  >
                    <div className={classes.contentFormWrapper}>
                      <FieldWrapper
                        label={getLabel('isActive')}
                        labelSize={2}
                        content={
                          <SwitchInput
                            checked={active}
                            onChange={handleActiveChange}
                            disabled={loading || !isAllowedUpdate}
                          />
                        }
                      />
                      <FieldWrapper
                        label={getLabel('source')}
                        labelSize={12}
                        contentSize={6}
                        content={
                          <Field
                            id="notificationSettingSource"
                            name="notificationSettingSource"
                            component={DropDown}
                            options={sourceOptions}
                            disabled={loading || !isAllowedUpdate}
                          />
                        }
                        classNameContent={classes.sourceField}
                      />
                      <Field
                        id="name"
                        name="name"
                        placeholder="Name"
                        styleType="main"
                        inputView="text"
                        fullWidth
                        classNameWrapper={classes.inputWrapper}
                        component={Input}
                        disabled={loading || !isAllowedUpdate}
                      />
                      {values.notificationSettingSource === 0 && (
                        <>
                          <Accordion
                            classes={{
                              rounded: classes.rounded,
                            }}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              Data view
                            </AccordionSummary>
                            <AccordionDetails>
                              <Field
                                id="auditViewId"
                                name="auditViewId"
                                label="Data View"
                                classNameWrapper={classes.inputWrapper}
                                options={auditViews}
                                disabled={loading || !isAllowedUpdate}
                              >
                                {props => {
                                  return (
                                    <DropDown
                                      {...props}
                                      onInputChange={v =>
                                        dispatch(actions.saveDataViewId(v))
                                      }
                                    />
                                  );
                                }}
                              </Field>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion
                            classes={{
                              rounded: classes.rounded,
                            }}
                            style={{ marginTop: '1rem' }}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              Filters
                            </AccordionSummary>
                            <AccordionDetails>
                              <div className={classes.fullWidth}>
                                <div className={classes.textAlignLeft}>
                                  <Field
                                    id="ignoreAcknowledged"
                                    name="ignoreAcknowledged"
                                    label="Ignore Acknowledged"
                                    type="checkbox"
                                    component={Checkbox}
                                    disabled={loading || !isAllowedUpdate}
                                  />
                                </div>
                                <AuditViewForm
                                  id={values.auditViewId}
                                  onDataChange={handleAuditFormDataChange}
                                  initData={initData.filters}
                                  auditFormData={auditFormData}
                                />
                              </div>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion
                            classes={{
                              rounded: classes.rounded,
                            }}
                            style={{ marginTop: '1rem' }}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              Post-Processing Steps
                            </AccordionSummary>
                            <AccordionDetails>
                              <div className={classes.genericPipelines}>
                                <GenericPipelines
                                  form={form}
                                  setInputFields={setInputFields}
                                  dataViewId={values.auditViewId}
                                />
                              </div>
                            </AccordionDetails>
                          </Accordion>
                        </>
                      )}
                      {values.notificationSettingSource === 1 && (
                        <Accordion
                          classes={{
                            rounded: classes.rounded,
                          }}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            Triggers
                          </AccordionSummary>
                          <AccordionDetails>
                            <InputWithAutocomplete
                              name="triggers"
                              options={triggersOptions}
                              loading={loading}
                              disableCloseOnSelect
                              freeSolo={false}
                              getOptionValue={i => {
                                return typeof i === 'object'
                                  ? i.value
                                  : triggersOptions.find(m => m.value === i)
                                      ?.value;
                              }}
                              getOptionLabel={i =>
                                typeof i === 'object'
                                  ? i.label
                                  : triggersOptions.find(m => m.value === i)
                                      ?.label
                              }
                            />
                          </AccordionDetails>
                        </Accordion>
                      )}
                      <Accordion
                        classes={{
                          rounded: classes.rounded,
                        }}
                        style={{ marginTop: '1rem' }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          Repeat Notifications
                        </AccordionSummary>
                        <AccordionDetails>
                          <div style={{ width: '100%' }}>
                            <FieldWrapper
                              label="Repeat notifications"
                              labelSize={3}
                              content={
                                <Field
                                  id="isDelayNotification"
                                  name="isDelayNotification"
                                  type="checkbox"
                                  component={SwitchInput}
                                />
                              }
                            />
                            <FieldWrapper
                              label="Event date field"
                              labelSize={3}
                              content={
                                <Field
                                  id="eventForRepeatField"
                                  name="eventForRepeatField"
                                  component={DropDown}
                                  options={dataViewFieldNamesOptions}
                                  disabled={!values.isDelayNotification}
                                />
                              }
                            />
                            <FieldWrapper
                              label="Notify"
                              labelSize={3}
                              content={
                                <div className={classes.horizontalAlignment}>
                                  <Field
                                    id="delayMinutes"
                                    name="delayMinutes"
                                    placeholder="minutes"
                                    component={Input}
                                    styleType="main"
                                    type="number"
                                    inputView="number"
                                    min={0}
                                    max={100}
                                    disabled={!values.isDelayNotification}
                                  />
                                  <span className={classes.marginLeft}>
                                    minutes
                                  </span>
                                  <Field
                                    id="shiftDirection"
                                    name="shiftDirection"
                                    component={DropDown}
                                    options={shiftDirectionOptions}
                                    disabled={!values.isDelayNotification}
                                  />
                                </div>
                              }
                            />
                            <FieldWrapper
                              label="Ignore initial"
                              labelSize={3}
                              content={
                                <>
                                  <Field
                                    id="skipInitialNotification"
                                    name="skipInitialNotification"
                                    type="checkbox"
                                    component={SwitchInput}
                                  />
                                  <span>
                                    {values.skipInitialNotification
                                      ? ' Skip initial notification'
                                      : ' Duplicate initial notification'}
                                  </span>
                                </>
                              }
                            />
                          </div>
                        </AccordionDetails>
                      </Accordion>
                      <div className={classes.tilesSpace}>
                        {tileData.map(i => (
                          <Field
                            key={i.id}
                            type="checkbox"
                            id={i.id}
                            name={i.id}
                            component={SettingsTile}
                            disabled={loading}
                            label={i.label}
                            ticketSettingId={initData.id}
                            notificationType={i.notifyType}
                            dataViewSelected={
                              values.notificationSettingSource === 1 ||
                              values.auditViewId !== 0
                            }
                            additionalHandler={() => storeDataToRedux(values)}
                            readOnly={!isAllowedUpdate}
                            icon={i.icon}
                          />
                        ))}
                      </div>
                    </div>
                    <div className={classes.buttonWrapper}>
                      <ReusableButton
                        size="md"
                        type="button"
                        classNameWrapper={classes.button}
                        disabled={loading}
                        onClick={handleBack}
                      >
                        <>
                          <img src={iconArrow} alt="iconArrow" />
                          {translate('resources.buttons.back')}
                        </>
                      </ReusableButton>
                      {isAllowedUpdate && (
                        <ReusableButton
                          size="md"
                          viewType="black"
                          type="submit"
                          classNameWrapper={classes.button}
                          disabled={loading}
                          loading={loading}
                        >
                          {translate(
                            data
                              ? 'resources.billing.updateButton'
                              : 'pages.role.permissions.create',
                          )}
                        </ReusableButton>
                      )}
                    </div>
                  </form>
                )}
              />
              <ConfirmDialog
                open={!!warningDialogOpen}
                setOpen={setWarningDialogOpen}
                onAccept={onWarningHandler}
                onCancel={onCancelWarningHandler}
                title={translate(
                  'resources.notificationSettings.emptyFilterTitle',
                )}
                content={translate(
                  'resources.notificationSettings.emptyFilterContent',
                )}
              />
            </Paper>

            <div className={classes.crmTreeWrapper}>
              <CrmTreeView />
            </div>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

NotificationSettingsForm.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
};

export default NotificationSettingsForm;
