/* eslint-disable no-console */
export const checkParams = (ticketId, screen, search) => {
  console.log(`POD: url search ${search}`);
  if (!ticketId) console.log('POD: ticketId param not provided');
  if (!screen) console.log('POD: screen param not provided');

  if (!ticketId || !screen) return;

  if (Number.isNaN(Number(ticketId)))
    console.log('POD: ticketId is not number', `ticketId=${ticketId}`);
  if (screen !== 'POD: ticket' && screen !== 'contact')
    console.log('POD: screen not equal "ticket"', `screen=${screen}`);
};

export default {};
