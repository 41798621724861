import React, { useCallback, useState } from 'react';
import { IconButton, Menu, MenuItem, Tooltip } from '@material-ui/core';
import { Help as HelpIcon } from '@material-ui/icons';
import { ReactComponent as CopyIcon } from '@assets/icons/copy_blue.svg';
import { useNotify } from 'react-admin';
import useStyles from './styles';

const supportEmail = 'help@support.mspprocess.com';

const Support = () => {
  const classes = useStyles();
  const notify = useNotify();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleNavigateToKnowledgeBase = useCallback(() => {
    window.open('https://kb.mspprocess.com', '_blank');
  }, []);

  const handleMailToSupport = useCallback(e => {
    window.location.href = `mailto:${supportEmail}`;
    e.preventDefault();
  }, []);

  const handleMenuItemClick = useCallback((event, handler) => {
    setAnchorEl(null);
    handler(event);
  }, []);

  const handleCopyEmail = useCallback(e => {
    e.stopPropagation();
    window.navigator.clipboard.writeText(supportEmail);
    notify('Copied to clipboard', 'success');
    e.preventDefault();
  }, []);

  return (
    <div className={classes.container}>
      <IconButton
        alt="help"
        onClick={event => setAnchorEl(event.currentTarget)}
      >
        <Tooltip title="Support">
          <HelpIcon />
        </Tooltip>
      </IconButton>
      <span />
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        getContentAnchorEl={null}
      >
        <MenuItem
          key="kb"
          onClick={e => handleMenuItemClick(e, handleNavigateToKnowledgeBase)}
        >
          <div>
            <span>Knowledge base</span>
          </div>
        </MenuItem>
        <MenuItem
          key="mail"
          onClick={e => handleMenuItemClick(e, handleMailToSupport)}
        >
          <div className={classes.sendEmailItem}>
            <span>Support</span>
            <Tooltip title="Copy to clipboard">
              <CopyIcon
                onClick={e => handleMenuItemClick(e, handleCopyEmail)}
              />
            </Tooltip>
          </div>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default Support;
