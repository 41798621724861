import React from 'react';
import PropTypes from 'prop-types';
import { Filter } from 'react-admin';

import BooleanInputComponent from '@common/FilterInputs/Boolean';
import useStyles from './styles';

const Filters = ({ hasList, hasEdit, hasShow, ...props }) => {
  const classes = useStyles();

  return (
    <Filter {...props} classes={{ form: classes.switcherForm }}>
      <BooleanInputComponent
        alwaysOn
        source="hideAcknowledged"
        classNameWrapper={classes.switcher}
      />
    </Filter>
  );
};

Filters.propTypes = {
  hasEdit: PropTypes.bool,
  hasList: PropTypes.bool,
  hasShow: PropTypes.bool,
};

export default Filters;
