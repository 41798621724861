import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNotify } from 'react-admin';

import {
  ThunkAssignKaseyaTicketContact,
  ThunkGetKaseyaAccounts,
  ThunkGetKaseyaContacts,
} from '@store/slices/kaseyaTicket/thunks';
import { KaseyaTicketOptionsSelector } from '@store/slices/kaseyaTicket';
import { actions } from '@store/actions';

import FieldWrapper from '@common/form/FieldWrapper';
import AutocompleteFormInput from '@common/AutocompleteFormInput/AutocompleteFormInput';
import Loading from '@common/Loading/Loading';
import ReusableButton from '@common/Button/Button';

import useSharedStyles from '@components/CrmTicket/sharedStyles';

const AssignKaseyaContactDialog = ({ open, setOpen, crmDefaultId, record }) => {
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);

  const sharedStyles = useSharedStyles();
  const dispatch = useDispatch();
  const notify = useNotify();
  const { contacts, accounts } = useSelector(KaseyaTicketOptionsSelector);

  const submit = useCallback(
    values => {
      setProcessing(true);
      dispatch(
        ThunkAssignKaseyaTicketContact({
          crmDefaultId,
          ticketId: record.ticketNumber,
          contactId: values.contactId,
        }),
      )
        .unwrap()
        .then(() => {
          dispatch(
            actions.updateItemInList({
              ...record,
              contact: contacts.find(i => i.value === values.contactId).label,
              contactId: values.contact,
            }),
          );
          notify('Ticket Updated', 'success');
          setOpen(false);
        })
        .finally(() => {
          setProcessing(false);
        });
    },
    [dispatch, crmDefaultId, record, contacts],
  );

  const handleAccountChange = useCallback(
    async accountId => {
      setProcessing(true);
      await dispatch(ThunkGetKaseyaContacts({ crmDefaultId, accountId }));
      setProcessing(false);
    },
    [crmDefaultId, dispatch],
  );

  const onMount = useCallback(async () => {
    setLoading(true);
    await dispatch(ThunkGetKaseyaAccounts({ crmDefaultId }));
    if (record?.accountId && record?.accountId !== 0) {
      await handleAccountChange(record.accountId);
    }

    setLoading(false);
  }, [dispatch, crmDefaultId, handleAccountChange, record]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogTitle>Assign Contact</DialogTitle>
      <DialogContent>
        {loading ? (
          <Loading />
        ) : (
          <Form
            onSubmit={submit}
            initialValues={{
              accountId: +record.accountId,
              contactId: +record.contactId,
            }}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <FieldWrapper
                  label="Account"
                  labelSize={12}
                  contentSize={12}
                  fullWidth
                  content={
                    <Field
                      name="accountId"
                      id="accountId"
                      render={AutocompleteFormInput}
                      items={accounts}
                      loading
                    />
                  }
                />

                <FieldWrapper
                  label="Contact"
                  labelSize={12}
                  contentSize={12}
                  fullWidth
                  content={
                    <Field
                      name="contactId"
                      id="contactId"
                      render={AutocompleteFormInput}
                      items={contacts}
                      loading={processing}
                    />
                  }
                />

                <div className={sharedStyles.dialogActionsContainer}>
                  <ReusableButton
                    label="Close"
                    onClick={() => {
                      setOpen(false);
                    }}
                    disabled={processing}
                  />

                  <ReusableButton
                    label="Submit"
                    type="submit"
                    loading={processing}
                    disabled={processing}
                  />
                </div>
              </form>
            )}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

AssignKaseyaContactDialog.propTypes = {
  crmDefaultId: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  record: PropTypes.shape({
    accountId: PropTypes.number,
    contactId: PropTypes.number,
  }),
};
export default AssignKaseyaContactDialog;
