import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'react-admin';

import ReusableButton from '@common/Button/Button';
import iconPlus from '@assets/icons/plusGrey.svg';

const AddRuleActions = ({ handleOnClick }) => {
  const translate = useTranslate();

  return (
    <ReusableButton onClick={handleOnClick}>
      <>
        <img src={iconPlus} alt="iconPlus" />
        {translate('common.queryBuilder.addRule')}
      </>
    </ReusableButton>
  );
};

AddRuleActions.propTypes = {
  handleOnClick: PropTypes.func,
};

export default AddRuleActions;
