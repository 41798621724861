import React, { useCallback, useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
import { Field, Form } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { useNotify } from 'react-admin';
import { OnChange } from 'react-final-form-listeners';

import {
  ThunkCheckCode,
  ThunkPodSaveToContact,
  ThunkSendCheckCode,
  ThunkSendDuoPush,
  ThunkSendMicrosoftPush,
} from '@store/slices/psaPods/thunks';
import { ThunkGetPsaContacts } from '@store/slices/createTicket/thunks';
import { ThunkBeautifyPhoneNumber } from '@store/slices/common/thunks';

import contactVerificationTypes from '@constants/contactVerificationTypes';
import { emailValidator, requiredValidator } from '@utils/validators';
import crmSources from '@constants/crmSources';
import codeValidation from '@constants/codeValidator';

import iconRefresh from '@assets/icons/refresh.svg';

import Input from '@components/Auth/Common/Input';
import ActionButton from '@common/buttons/ActionButton/ActionButton';
import Checkbox from '@common/Checkbox/Checkbox';
import ReusableButton from '@common/Button/Button';
import Timer from '@components/integrations/connectwise/components/Timer';
import AutocompleteFormInput from '@common/AutocompleteFormInput/AutocompleteFormInput';
import usePodSaveContactRoute from '@components/Hooks/usePodSaveContactRoute';

import useStyles from '@components/integrations/connectwise/dialogs/styles';

const phoneCheckboxes = Object.freeze({
  sms: 'sms',
  voice: 'voice',
  link: 'link',
  portal: 'portal',
  portalAndSms: 'portalAndSms',
  duoPush: 'duoPush',
  microsoftPush: 'microsoftPush',
});

const allowedForClientPortal = [
  crmSources.ConnectWise.label,
  crmSources.Autotask.label,
  crmSources.Halo.label,
];

const VerifyContact = ({
  data,
  contactName,
  contactCompanyName,
  validatedObject,
  contactId,
  crmId,
  onSuccess,
  ticketId,
  codeDuration,
  psaType = crmSources.ConnectWise.label,
  handleBack,
  companyId,
  hasDuoIntegration,
  hasMicrosoftAuthPushIntegration,
  unableNotes = false,
}) => {
  const unableSmsFeatureMock = false;
  const [loading, setLoading] = useState(false);
  const [sendSms, setSendSms] = useState(!unableSmsFeatureMock);
  const [sendAsVoice, setSendAsVoice] = useState(false);
  const [sendLink, setSendLink] = useState(unableSmsFeatureMock);
  const [code, setCode] = useState(null);
  const [expired, setExpired] = useState(false);
  const [checkingCode, setCheckingCode] = useState(false);
  const [formValue, setFormValue] = useState(null);
  const [valueChanged, setValueChanged] = useState(false);
  const [psaContacts, setPsaContacts] = useState([]);
  const [sendToPortal, setSendToPortal] = useState(false);
  const [sendPushToDuo, setSendPushToDuo] = useState(false);
  const [sendPushToMicrosoft, setSendPushToMicrosoft] = useState(false);
  const [sendToClientPortalSms, setSendToClientPortalSms] = useState(false);

  const dispatch = useDispatch();
  const classes = useStyles();
  const notify = useNotify();

  const phoneValidating = useMemo(
    () => validatedObject.type === contactVerificationTypes.phone,
    [validatedObject.type],
  );

  const validateValue = values => {
    return validatedObject.type === contactVerificationTypes.phone
      ? {
          value: requiredValidator(values.value),
          note:
            unableNotes && valueChanged
              ? requiredValidator(values.note)
              : undefined,
        }
      : {
          value: emailValidator(values.value),
          note: undefined,
        };
  };

  const validateCode = values => {
    return {
      code: codeValidation(values.code),
    };
  };

  const sendValue = async ({ value, note = undefined }) => {
    setCode(null);
    setLoading(true);
    setFormValue(value);

    let phone = null;

    if (validatedObject.type === contactVerificationTypes.phone) {
      await dispatch(ThunkBeautifyPhoneNumber(value)).then(res => {
        phone = res.payload.data;
      });
    }

    if (sendPushToDuo) {
      dispatch(
        ThunkSendDuoPush({
          crmId,
          contactId,
          email:
            validatedObject.type === contactVerificationTypes.email
              ? value
              : null,
          ticketId: ticketId || data.TicketNumber,
          verificationType: validatedObject.type,
          contactName,
          note: note ?? undefined,
          sendPushToDuo,
          contactCompanyName,
          contactCompanyId: companyId,
        }),
      )
        .unwrap()
        .then(d => {
          if (d) {
            notify('Successfully verified', 'success');
          }
        })
        .catch(e => notify(e.message, 'error'))
        .finally(() => setLoading(false));
    } else if (sendPushToMicrosoft) {
      dispatch(
        ThunkSendMicrosoftPush({
          crmId,
          contactId,
          email:
            validatedObject.type === contactVerificationTypes.email
              ? value
              : null,
          ticketId: ticketId || data.TicketNumber,
          verificationType: validatedObject.type,
          contactName,
          note: note ?? undefined,
          sendPushToMicrosoft,
          contactCompanyName,
          contactCompanyId: companyId,
        }),
      )
        .unwrap()
        .then(d => {
          if (d) {
            notify('Successfully verified', 'success');
          }
        })
        .finally(() => setLoading(false));
    } else {
      dispatch(
        ThunkSendCheckCode({
          crmId,
          contactId,
          phoneNumber:
            validatedObject.type === contactVerificationTypes.phone
              ? phone
              : null,
          email:
            validatedObject.type === contactVerificationTypes.email
              ? value
              : null,
          ticketId: ticketId || data.TicketNumber,
          verificationType: validatedObject.type,
          contactName,
          sendAsVoice,
          sendLink,
          note: note ?? undefined,
          sendToClientPortal: sendToPortal,
          sendToClientPortalSms,
          contactCompanyName,
          contactCompanyId: companyId,
        }),
      )
        .unwrap()
        .then(d => {
          setCode(d.data);
        })
        .catch(e => notify(e.message, 'error'))
        .finally(() => setLoading(false));
    }
  };

  const saveContactRoute = usePodSaveContactRoute({ psaType });

  const saveValueToContact = value => {
    setLoading(true);
    dispatch(
      ThunkPodSaveToContact({
        route: saveContactRoute,
        type: validatedObject.type,
        value,
        crmId: +crmId,
        contactId,
        itemName: validatedObject.name,
        ticketId,
      }),
    )
      .unwrap()
      .catch(e => notify(e.message, 'error'))
      .finally(() => setLoading(false));
  };

  const reSendValue = () => {
    setExpired(false);
    sendValue({ value: formValue });
  };

  const checkCode = values => {
    setCheckingCode(true);
    dispatch(
      ThunkCheckCode({
        crmId,
        code: values.code,
      }),
    )
      .unwrap()
      .then(() => {
        onSuccess(formValue);
      })
      .catch(e => notify(e.message, 'error'))
      .finally(() => setCheckingCode(false));
  };

  const onMount = useCallback(() => {
    setLoading(true);
    dispatch(ThunkGetPsaContacts({ psaId: crmId, companyId, sortAsc: true }))
      .unwrap()
      .then(r => {
        setPsaContacts(r);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [crmId, dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  const setCheckboxesValues = value => {
    switch (value) {
      case phoneCheckboxes.sms:
        setSendAsVoice(false);
        setSendToPortal(false);
        setSendToClientPortalSms(false);
        break;
      case phoneCheckboxes.link:
        setSendAsVoice(false);
        setSendToPortal(false);
        setSendToClientPortalSms(false);
        setSendPushToDuo(false);
        setSendPushToMicrosoft(false);
        break;
      case phoneCheckboxes.voice:
        setSendLink(false);
        setSendToPortal(false);
        setSendSms(false);
        break;
      case phoneCheckboxes.portal:
        setSendAsVoice(false);
        setSendLink(false);
        setSendToClientPortalSms(false);
        setSendSms(false);
        break;
      case phoneCheckboxes.portalAndSms:
        setSendLink(false);
        setSendToPortal(false);
        setSendSms(false);
        break;
      case phoneCheckboxes.duoPush:
        setSendLink(false);
        setSendPushToMicrosoft(false);
        break;
      case phoneCheckboxes.microsoftPush:
        setSendLink(false);
        setSendPushToDuo(false);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div style={{ padding: '5px', boxSizing: 'border-box' }}>
        <Form
          onSubmit={sendValue}
          validate={validateValue}
          initialValues={{ value: validatedObject.value }}
          render={({ handleSubmit, values, form }) => (
            <form onSubmit={handleSubmit} noValidate className={classes.form}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div>Send Verification Code</div>
                <ReusableButton label="Back" onClick={handleBack} />
              </div>
              <div className={classes.formStyle}>
                {phoneValidating && (
                  <>
                    <Field
                      id="psaContact"
                      name="psaContact"
                      fullWidth
                      component={AutocompleteFormInput}
                      placeholder="PSA Contacts"
                      items={psaContacts}
                      disabled={loading || code}
                      label="PSA Contacts"
                    />
                    <OnChange name="psaContact">
                      {value => {
                        const selectedContact = psaContacts.find(
                          i => i.value === value,
                        );

                        form.change('value', selectedContact?.info ?? '');
                      }}
                    </OnChange>
                  </>
                )}

                <Field
                  id="value"
                  name="value"
                  fullWidth
                  component={Input}
                  placeholder="Key name"
                  disabled={loading || code}
                />
                <OnChange name="value">
                  {value => {
                    if (value !== validatedObject.value) {
                      setValueChanged(true);
                    } else {
                      setValueChanged(false);
                    }
                  }}
                </OnChange>

                {valueChanged && phoneValidating && (
                  <Field
                    id="note"
                    name="note"
                    fullWidth
                    component={Input}
                    placeholder="Note"
                    disabled={loading || code}
                  />
                )}

                <div className={classes.checkboxContainer}>
                  {validatedObject.type === contactVerificationTypes.phone && (
                    <>
                      <div
                        className={cx(classes.textBlock, {
                          [classes.disabledTextBlock]: unableSmsFeatureMock,
                        })}
                      >
                        <span>SMS</span>
                        <Checkbox
                          id={phoneCheckboxes.sms}
                          checked={sendSms}
                          onChange={e => {
                            setSendSms(e.target.checked);
                            setCheckboxesValues(e.target.id);
                          }}
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                          disabled={loading || unableSmsFeatureMock}
                        />
                      </div>

                      <div
                        className={cx(classes.textBlock, {
                          [classes.disabledTextBlock]: unableSmsFeatureMock,
                        })}
                      >
                        <span>Phone call</span>
                        <Checkbox
                          id={phoneCheckboxes.voice}
                          checked={sendAsVoice}
                          onChange={e => {
                            setSendAsVoice(e.target.checked);
                            setCheckboxesValues(e.target.id);
                          }}
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                          disabled={loading || unableSmsFeatureMock}
                        />
                      </div>
                    </>
                  )}
                  <div className={classes.textBlock}>
                    <span>Secure link</span>
                    <Checkbox
                      id={phoneCheckboxes.link}
                      checked={sendLink}
                      onChange={e => {
                        setSendLink(e.target.checked);
                        setCheckboxesValues(e.target.id);
                      }}
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                      disabled={loading}
                    />
                  </div>
                  {validatedObject.type === contactVerificationTypes.email &&
                    hasDuoIntegration && (
                      <div className={classes.textBlock}>
                        <span>Duo push</span>
                        <Checkbox
                          id={phoneCheckboxes.duoPush}
                          checked={sendPushToDuo}
                          onChange={e => {
                            setSendPushToDuo(e.target.checked);
                            setCheckboxesValues(e.target.id);
                          }}
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                          disabled={loading}
                        />
                      </div>
                    )}

                  {validatedObject.type === contactVerificationTypes.email &&
                    hasMicrosoftAuthPushIntegration && (
                      <div className={classes.textBlock}>
                        <span>Microsoft Authenticator</span>
                        <Checkbox
                          id={phoneCheckboxes.microsoftPush}
                          checked={sendPushToMicrosoft}
                          onChange={e => {
                            setSendPushToMicrosoft(e.target.checked);
                            setCheckboxesValues(e.target.id);
                          }}
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                          disabled={loading}
                        />
                      </div>
                    )}
                  {validatedObject.type === contactVerificationTypes.phone &&
                    allowedForClientPortal.includes(psaType) && (
                      <>
                        <div className={classes.textBlock}>
                          <span>Client Portal</span>
                          <Checkbox
                            id={phoneCheckboxes.portal}
                            checked={sendToPortal}
                            onChange={e => {
                              setSendToPortal(e.target.checked);
                              setCheckboxesValues(e.target.id);
                            }}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                            disabled={loading}
                          />
                        </div>
                        <div
                          className={cx(classes.textBlock, {
                            [classes.disabledTextBlock]: unableSmsFeatureMock,
                          })}
                        >
                          <span>Client Portal + SMS</span>
                          <Checkbox
                            id={phoneCheckboxes.portalAndSms}
                            checked={sendToClientPortalSms}
                            onChange={e => {
                              setSendToClientPortalSms(e.target.checked);
                              setCheckboxesValues(e.target.id);
                            }}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                            disabled={loading || unableSmsFeatureMock}
                          />
                        </div>
                      </>
                    )}
                </div>

                {unableSmsFeatureMock && (
                  <div
                    style={{
                      color: 'grey',
                      textAlign: 'left',
                      width: '100%',
                      fontSize: '13px',
                    }}
                  >
                    To unable SMS features contact admin to setup verification
                    phone number.
                  </div>
                )}

                <div className={classes.buttonsContainer}>
                  <ReusableButton
                    onClick={() => {
                      saveValueToContact(values.value);
                    }}
                    size="xl"
                    viewType="black"
                    classNameWrapper={classes.contactButton}
                    disabled={loading || code}
                  >
                    Save to Contact
                  </ReusableButton>

                  <ReusableButton
                    type="submit"
                    size="xl"
                    viewType="black"
                    label="Send code"
                    classNameWrapper={classes.sendButtonStyle}
                    disabled={loading || code}
                    loading={loading}
                  />
                </div>
              </div>
            </form>
          )}
        />
      </div>
      <div>
        {code && (
          <div>
            <div className={classes.textBlock}>
              {`A verification code has been sent to the user ${
                code !== 'hide' ? `[${code}]` : ''
              }`}
              <ActionButton
                icon={<img src={iconRefresh} alt="iconRefresh" />}
                handler={reSendValue}
                toolTip="Send again"
              />
            </div>
            <Timer
              expired={expired}
              setExpired={setExpired}
              duration={codeDuration}
            />
            <div>
              <Form
                onSubmit={checkCode}
                validate={validateCode}
                render={({ handleSubmit }) => (
                  <form onSubmit={handleSubmit} noValidate>
                    <div className={classes.formStyle}>
                      <Field
                        id="code"
                        name="code"
                        fullWidth
                        component={Input}
                        placeholder="Verification code"
                        disabled={checkingCode || expired}
                      />
                      <ReusableButton
                        type="submit"
                        size="xl"
                        viewType="black"
                        label="Verify"
                        classNameWrapper={classes.sendButtonStyle}
                        disabled={checkingCode || expired}
                        loading={checkingCode}
                      />
                    </div>
                  </form>
                )}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default VerifyContact;
