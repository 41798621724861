import React from 'react';
import { useTranslate } from 'react-admin';
import { FormHelperText, TextField } from '@material-ui/core';

import useStyles from './inputStyles';

const Input = ({
  meta: { touched, error },
  input: inputProps,
  dateProps,
  endAdornment = undefined,
  disabled = false,
  extraClasses = {},
  autoComplete = 'off',
  helperInfoText,
  ...props
}) => {
  const classes = useStyles();
  const translate = useTranslate();
  const errorMessage =
    error && error.startsWith('ra.') ? translate(error) : error;
  return (
    <div className={classes.inputWrapper}>
      <TextField
        autoComplete="off"
        error={!!(touched && error)}
        helperText={touched && errorMessage}
        InputProps={{
          inputProps: { ...dateProps },
          endAdornment,
          classes: extraClasses,
          autoComplete,
        }}
        {...inputProps}
        {...props}
        fullWidth
        variant="outlined"
        size="small"
        classes={classes}
        disabled={disabled}
      />
      {helperInfoText && (
        <FormHelperText className={classes.helperInfoText}>
          {helperInfoText}
        </FormHelperText>
      )}
    </div>
  );
};
export default Input;
