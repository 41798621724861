import React, { useState } from 'react';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { Form } from 'react-final-form';
import PropTypes from 'prop-types';

import ReusableButton from '@common/Button/Button';

import useSharedStyles from '@components/CrmTicket/sharedStyles';
import { useTicketNotesGenericFunctions } from '@components/CrmTicket/Notes/helpers/useTicketNotesGenericFunctions';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useNotify } from 'react-admin';
import { useTicketNotesFormGenerics } from '../helpers/useTicketNotesFormGenerics';

const AddNoteDialog = ({ open, setOpen, psaType }) => {
  const [processing, setProcessing] = useState(false);

  const sharedStyles = useSharedStyles();
  const dispatch = useDispatch();
  const notify = useNotify();

  const { crmDefaultId, ticketId } = useParams();
  const { dialogForm } = useTicketNotesFormGenerics(processing, psaType);
  const { createNote } = useTicketNotesGenericFunctions({ psaType });

  const submit = async values => {
    setProcessing(true);
    await dispatch(
      createNote({
        crmDefaultId,
        ticketId,
        payload: values,
      }),
    )
      .unwrap()
      .then(() => {
        setOpen(false);
        notify('Note added', 'success');
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogTitle>Add Ticket Note</DialogTitle>

      <DialogContent>
        <Form
          onSubmit={submit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} style={{ width: '500px' }}>
              <div>{dialogForm}</div>
              <div className={sharedStyles.dialogActionsContainer}>
                <ReusableButton
                  label="Close"
                  onClick={() => {
                    setOpen(false);
                  }}
                  disabled={processing}
                />

                <ReusableButton
                  label="Submit"
                  type="submit"
                  loading={processing}
                  disabled={processing}
                />
              </div>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  );
};

AddNoteDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  psaType: PropTypes.string,
};
export default AddNoteDialog;
