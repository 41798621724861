import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { TextField } from '@material-ui/core';
import {
  AttachFile as AttachFileIcon,
  Send as SendIcon,
} from '@material-ui/icons';

import {
  SelectCurrentChatsMessages,
  SelectCurrentChatsMessagesLoading,
} from '@store/slices/liveChats/selectors';
import {
  ThunkGetChatMessages,
  ThunkPushMessageAsNoteToTicket,
  ThunkSendChatMessage,
} from '@store/slices/liveChats/thunks';

import Loading from '@common/Loading/Loading';

import { useNotify } from 'react-admin';
import UploadChatAttachmentDialog from '@components/LiveChat/dialogs/UploadChatAttachmentDialog';

import useStyles from './styles';
import LiveChatMessage from './LiveChatMessage';

const LiveChatWindow = ({ liveChatId, isChatClosed, hasTicket }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const ref = useRef();
  const notify = useNotify();

  const messages = useSelector(SelectCurrentChatsMessages);
  const loading = useSelector(SelectCurrentChatsMessagesLoading);

  const [inputText, setInputText] = useState('');
  const [uploadOpen, setUploadOpen] = useState(false);

  const handlePushMessageToTicket = async ({ id }) => {
    await dispatch(ThunkPushMessageAsNoteToTicket({ messageId: id }))
      .unwrap()
      .then(() => {
        notify('Note Added to Ticket', 'success');
      });
  };

  const handleMessageSend = async item => {
    const payload = {
      chatId: liveChatId,
      message: item,
    };
    await dispatch(ThunkSendChatMessage(payload))
      .unwrap()
      .then(() => {
        setInputText('');
      });
  };

  const onMount = useCallback(async () => {
    await dispatch(ThunkGetChatMessages({ id: liveChatId }));
  }, [dispatch, liveChatId]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  useEffect(() => {
    if (ref?.current) {
      ref.current.scrollIntoView({
        behavior: 'auto',
        block: 'nearest',
        inline: 'start',
      });
    }
  }, [messages, ref]);

  if (loading) return <Loading />;

  return (
    <>
      <div className={classes.chatBody}>
        <div className={classes.chatMessages}>
          {messages.map(message => (
            <LiveChatMessage
              message={{
                ...message,
                senderName: message?.technicianName ?? message?.userName,
              }}
              key={message.id}
              extraMessageText={hasTicket ? 'Add to Ticket Note' : undefined}
              extraMessageAction={handlePushMessageToTicket}
            />
          ))}
          <div ref={ref} />
        </div>

        {!isChatClosed && (
          <div className={classes.chatInput}>
            <TextField
              variant="outlined"
              multiline
              rows={1}
              rowsMax={3}
              value={inputText}
              onChange={({ target }) => {
                setInputText(target.value);
              }}
              onKeyDown={() => {
                if (window.event.ctrlKey && window.event.keyCode === 13) {
                  handleMessageSend(inputText);
                }
              }}
            />
            <AttachFileIcon onClick={() => setUploadOpen(true)} />
            <SendIcon
              onClick={() => {
                handleMessageSend(inputText);
              }}
            />
          </div>
        )}
      </div>
      <UploadChatAttachmentDialog
        chatId={liveChatId}
        setOpen={() => setUploadOpen(false)}
        open={uploadOpen}
      />
    </>
  );
};

LiveChatWindow.propTypes = {
  liveChatId: PropTypes.number.isRequired,
  isChatClosed: PropTypes.bool.isRequired,
};

export default LiveChatWindow;
