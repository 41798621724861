import React from 'react';

import { Avatar, Box, CircularProgress, FormLabel } from '@material-ui/core';
import cx from 'classnames';
import { useNotify, useTranslate } from 'react-admin';
import { sendFormData } from '@services/api';
import appConfig from '@configs/appConfig';

import PropTypes from 'prop-types';
import { INFO_IMAGE_TEXT } from '@constants/common';

import useStyles from './styles';

const UploadImageField = ({
  title,
  label,
  uploadApiUrl,
  imageUrl,
  imageActions,
  avatarVariant,
}) => {
  const classes = useStyles();
  const translate = useTranslate();
  const notify = useNotify();

  const handleChangeFile = async event => {
    const {
      files: [file],
    } = event.target;
    try {
      const formData = new FormData();
      formData.append('file', file);
      imageActions.startLoading();
      const result = await sendFormData(
        `${appConfig.baseUrl + uploadApiUrl}`,
        formData,
      );

      if (!result.ok) {
        const errorData = await result.json();
        notify(errorData.message, 'error');
        return;
      }

      imageActions.finishLoading(await imageUrl(result));
    } catch (e) {
      imageActions.finishLoading(imageActions.imageUri);
      notify(e.message, 'error');
    }
  };

  const handleRemoveImage = () => {
    imageActions.removeImage();
  };

  return (
    <Box className={cx(classes.displayBlock, classes.imageBlock)}>
      <span className={classes.imageText}>{translate(title)}</span>
      <div>
        <Box display="flex" alignItems="center">
          <div style={{ position: 'relative' }}>
            {imageActions.showLoader && (
              <CircularProgress size={0} className={classes.circleLoader} />
            )}
            <Avatar
              classes={{ root: classes.imageRoot }}
              src={imageActions.imageUri}
              variant={avatarVariant}
            />
          </div>
          <div className={classes.spacingLeft}>
            <Box display="flex" alignItems="center">
              <FormLabel
                className={classes.buttonImageText}
                htmlFor="upload-photo"
              >
                {translate('pages.profile.image.upload')}
              </FormLabel>
              <input
                hidden
                onChange={handleChangeFile}
                accept="image/png, image/jpeg"
                type="file"
                id="upload-photo"
              />
              <Box display="flex" alignItems="center">
                <div className={classes.circle} />
              </Box>
              <div
                role="presentation"
                className={classes.buttonImageText}
                onClick={handleRemoveImage}
              >
                {translate('pages.profile.image.remove')}
              </div>
            </Box>
            <div className={classes.imageText}>{label && translate(label)}</div>
            <div className={classes.imageText}>{INFO_IMAGE_TEXT}</div>
          </div>
        </Box>
      </div>
    </Box>
  );
};

UploadImageField.propTypes = {
  title: PropTypes.string.isRequired,
  label: PropTypes.string,
  uploadApiUrl: PropTypes.string.isRequired,
  imageUrl: PropTypes.func.isRequired,
  imageActions: PropTypes.shape({
    imageUri: PropTypes.string,
    showLoader: PropTypes.bool,
    startLoading: PropTypes.func,
    finishLoading: PropTypes.func,
    removeImage: PropTypes.func,
  }).isRequired,
  avatarVariant: PropTypes.string,
};

export default UploadImageField;
