import React, { useState, useCallback } from 'react';
import differenceWith from 'lodash/differenceWith';
import { useDispatch, useSelector } from 'react-redux';
import { useNotify } from 'react-admin';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import { WidthProvider, Responsive } from 'react-grid-layout';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { actions } from '@store/actions';
import { dashboardCurrentTiles } from '@store/selectors/dashboard';
import { deleteTile, updateTiles } from '@components/Dashboard/helper';
import TileForm from '@components/Dashboard/Modals/Tile/TileForm';
import Typography from '@material-ui/core/Typography';
import Tile from './Tile/Tile';
import { transformFromDataGrid, transformToDataGrid } from './helpers';
import { GRID_LAYOUT_OPTIONS, DashboardChangedLayoutType } from './constants';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-resizable/css/styles.css';
// eslint-disable-next-line import/no-absolute-path
import 'react-grid-layout/css/styles.css';

const isUpdateTilesAllowed = true;
const ResponsiveReactGridLayout = WidthProvider(Responsive);

const GridLayout = ({ loading, setLoading, isFullScreenActive }) => {
  const dispatch = useDispatch();
  const notify = useNotify();
  const tiles = useSelector(dashboardCurrentTiles);
  const [formOpen, setFormOpen] = useState(false);
  const [currentTile, setCurrentTile] = useState(null);

  const [rowHeight, setRowHeight] = useState(100);
  const [breakpointsLayouts, setBreakpointsLayouts] = useState({
    xxs: [],
    xs: [],
  });

  const isDesktop = useMediaQuery(t => t.breakpoints.up('sm'));

  const handleWidthChange = useCallback((containerWidth, margin, cols) => {
    setRowHeight((containerWidth - cols * margin[0]) / cols);
  }, []);

  const onLayoutChange = useCallback(
    (currentLayout, allLayouts) => {
      if (!(isUpdateTilesAllowed && isDesktop)) return;

      setBreakpointsLayouts(allLayouts);

      const changedLayouts = differenceWith(
        allLayouts.xs,
        breakpointsLayouts.xs,
        isEqual,
      );

      if (changedLayouts.length && breakpointsLayouts.xs.length) {
        dispatch(
          actions.dashboard_saveChangedLayouts(
            changedLayouts.map(({ i }) => ({
              id: Number(i),
              type: DashboardChangedLayoutType.Resize,
            })),
          ),
        );

        const data = changedLayouts.map(item => {
          const tile = tiles.find(({ id }) => id === Number(item.i)) || {};
          return {
            ...tile,
            ...transformFromDataGrid(item),
          };
        });

        updateTiles(data).catch(e => notify(e.message, 'error'));
      }

      setBreakpointsLayouts(allLayouts);
    },
    [
      breakpointsLayouts.xs,
      dispatch,
      isUpdateTilesAllowed,
      isDesktop,
      tiles,
      notify,
    ],
  );

  const handleDeleteTile = useCallback(
    tileId => {
      setLoading(true);
      deleteTile(tileId)
        .then(() => dispatch(actions.dashboard_removeTile(tileId)))
        .catch(e => notify(e.message, 'error'))
        .finally(() => setLoading(false));
    },
    [dispatch, notify, setLoading],
  );

  const handleEditTile = tile => {
    setCurrentTile(tile);
    setFormOpen(true);
  };

  const renderTile = tile => {
    return (
      <div key={tile.id} data-grid={transformToDataGrid(tile)}>
        <Tile
          item={tile}
          onDelete={handleDeleteTile}
          handleEdit={handleEditTile}
          isFullScreenActive={isFullScreenActive}
        />
      </div>
    );
  };

  return (
    <>
      {!tiles.length && !loading && (
        <Typography style={{ textAlign: 'center' }}>
          Your dashboard is empty. Add tiles with button
          <b>Create New Tile</b>
        </Typography>
      )}
      <ResponsiveReactGridLayout
        measureBeforeMount
        onLayoutChange={onLayoutChange}
        onWidthChange={handleWidthChange}
        layouts={breakpointsLayouts}
        rowHeight={rowHeight}
        isDraggable={isUpdateTilesAllowed && isDesktop}
        isResizable={isUpdateTilesAllowed && isDesktop}
        {...GRID_LAYOUT_OPTIONS}
      >
        {tiles.map(tile => renderTile(tile))}
      </ResponsiveReactGridLayout>
      <TileForm
        data={currentTile}
        open={formOpen}
        onClose={() => setFormOpen(false)}
      />
    </>
  );
};

GridLayout.propTypes = {
  loading: PropTypes.bool,
  isFullScreenActive: PropTypes.bool,
  setLoading: PropTypes.func,
};

export default GridLayout;
