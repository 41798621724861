import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Tooltip, Checkbox as SimpleCheckBox } from '@material-ui/core';
import { partial } from 'lodash';
import cx from 'classnames';
import { useNotify } from 'react-admin';

import ExternalLink from '@ui/components/common/ExternalLink/ExternalLink';

import { getMerakiOrganizationNetworks } from '@ui/components/DataSources/helpers';
import useInfiniteLoader from '@utils/useInfiniteLoader';
import organizationTemplateState from '@constants/organizationTemplateState';
import getFormattedDate from '@utils/getFormattedDate';

import useStyles from '../styles';

const MerakiNetworks = ({
  networks,
  activeNetworkId,
  getNetworkDevices,
  setNetworks,
  activeOrganizationId,
  setSubLoading,
}) => {
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(20);

  const classes = useStyles();
  const { push } = useHistory();
  const notify = useNotify();

  const tooltipText = item =>
    item.templated === organizationTemplateState.none ? (
      ''
    ) : (
      <>
        {item.templateApplier}
        <br />
        {getFormattedDate(item.templateApplyDate)}
      </>
    );

  const handleInfiniteScroll = () => {
    setSubLoading(true);
    setStart(start + 20);
    setEnd(end + 20);
    getMerakiOrganizationNetworks(
      activeNetworkId,
      activeOrganizationId,
      start + 20,
      end + 20,
    )
      .then(res => {
        const data = [...networks, ...res];
        setNetworks([...new Map(data.map(item => [item.id, item])).values()]);
      })
      .catch(err => {
        notify(err.message, 'error');
      })
      .finally(() => {
        setSubLoading(false);
      });
  };

  useInfiniteLoader(
    'networks',
    'network',
    networks.length,
    handleInfiniteScroll,
  );

  useEffect(() => {
    setStart(0);
    setEnd(20);
  }, [networks]);
  return (
    <>
      <Grid className={classes.column} item xs={3} name="networks">
        {networks &&
          networks.map((item, ind) => (
            <button
              key={item.id}
              className={cx(
                classes.baseButton,
                classes.networksButton,
                item.id === activeNetworkId && classes.activeOrganizationButton,
              )}
              onClick={partial(getNetworkDevices, item.id)}
              name={`network${ind}`}
            >
              <span>
                {item.name}
                <ExternalLink url={item.url} />
              </span>
              {item.templated !== organizationTemplateState.none && (
                <Tooltip title={tooltipText(item)}>
                  <SimpleCheckBox
                    indeterminate={
                      item.templated === organizationTemplateState.partial
                    }
                    checked={item.templated === organizationTemplateState.full}
                    style={{
                      padding: '0px',
                      margin: '0px',
                    }}
                    onClick={() => {
                      push(`/alertTemplates?id=${item.templateId}`);
                    }}
                  />
                </Tooltip>
              )}
            </button>
          ))}
      </Grid>
    </>
  );
};

export default MerakiNetworks;
