import React, { useCallback, useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { OnChange } from 'react-final-form-listeners';
import { useNotify } from 'react-admin';
import cx from 'classnames';

import {
  ThunkGetIntegrationBoardStatuses,
  ThunkGetIntegrationBoardTypes,
  ThunkGetIntegrationServiceBoards,
  ThunkGetIntegrationSources,
} from '@store/slices/integration/thunks';
import {
  ThunkCreateConnectWiseTicket,
  ThunkGetConnectWiseCompanies,
  ThunkGetConnectWiseCompanyAgreements,
  ThunkGetConnectWisePriorities,
  ThunkGetConnectWiseSites,
  ThunkGetPsaContacts,
} from '@store/slices/createTicket/thunks';

import crmDefaultFields from '@components/Ticketing/Integrations/pages/updateCrm/CrmDefaults/constants';
import crmSources from '@constants/crmSources';
import { requiredValidator } from '@utils/validators';
import iconPlus from '@assets/icons/refreshItem.svg';

import FieldWrapper from '@common/form/FieldWrapper';
import AutocompleteFormInput from '@common/AutocompleteFormInput/AutocompleteFormInput';
import Loading from '@common/Loading/Loading';
import ReusableButton from '@common/Button/Button';
import DropDown from '@components/Auth/Common/DropDown';
import Input from '@components/Auth/Common/Input';
import TicketLinkDialog from '@components/CreateTicket/dialogs/TicketLinkDialog';
import TicketCreationTemplates from '@components/CreateTicket/components/TicketCreationTemplates';
import TicketCreationInputsTemplates from '@components/CreateTicket/components/TicketCreationInputsTemplates';
import TicketCreationMessagingChannel from '@components/CreateTicket/components/TicketCreationMessagingChannel';

import useSharedStyles from '@components/CrmTicket/sharedStyles';
import ActionButton from '@common/buttons/ActionButton/ActionButton';
import useStyles from '../styles';

const initialValues = {
  companyId: 0,
  contactId: 0,
  siteId: 0,
};

const excludeArray = ['companyId', 'contactId', 'siteId'];

const ConnectWiseCreateTicketForm = ({
  psaId,
  ticketCreationSuccessCallback = () => {},
  init = initialValues,
}) => {
  const [processing, setProcessing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [openTicketLinkDialog, setOpenTicketLinkDialog] = useState(false);
  const [link, setLink] = useState(undefined);
  const [ticketId, setTicketId] = useState(undefined);
  const [channelCreationResponse, setChannelCreationResponse] = useState(
    undefined,
  );

  const [boards, setBoards] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [ticketPriorities, setTicketPriorities] = useState([]);
  const [sources, setSources] = useState();
  const [contacts, setContacts] = useState([]);

  const [boardStatuses, setBoardStatuses] = useState([]);
  const [boardTypes, setBoardTypes] = useState([]);
  const [boardItems, setBoardItems] = useState([]);
  const [boardSubTypes, setSubTypes] = useState([]);
  const [selectedTicketType, setSelectedTicketType] = useState();
  const [sites, setSites] = useState([]);
  const [agreements, setAgreements] = useState([]);

  const dispatch = useDispatch();
  const classes = useStyles();
  const sharedClasses = useSharedStyles();
  const notify = useNotify();

  const validate = values => {
    return {
      companyId: requiredValidator(values.companyId),
      defaultBoardId: requiredValidator(values.defaultBoardId),
      openBoardStatusId: requiredValidator(values.openBoardStatusId),
      typeId: requiredValidator(values.typeId),
      sourceId: requiredValidator(values.sourceId),
      ticketPriority: requiredValidator(values.ticketPriority),
      summary: requiredValidator(values.summary),
      initialDescription: requiredValidator(values.initialDescription),
    };
  };

  const submit = values => {
    setProcessing(true);
    dispatch(ThunkCreateConnectWiseTicket({ psaId, payload: values }))
      .unwrap()
      .then(({ ticketLink, ticketId: createdTicketId, ...rest }) => {
        setLink(ticketLink);
        setTicketId(createdTicketId);
        setOpenTicketLinkDialog(true);
        if (rest.configId && rest.channelId) {
          setChannelCreationResponse({
            ...rest,
            channelCreated: values.channelCreated || false,
          });
        }
        notify('Successfully created', 'success');
        ticketCreationSuccessCallback({
          ...rest,
          ticketId: createdTicketId,
          ticketLink,
        });
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  const handleCompanyChange = async value => {
    if (!value) return;
    await Promise.all([
      dispatch(
        ThunkGetPsaContacts({
          psaId,
          companyId: value,
        }),
      ).unwrap(),
      dispatch(
        ThunkGetConnectWiseSites({
          psaId,
          companyId: value,
        }),
      ).unwrap(),
      dispatch(
        ThunkGetConnectWiseCompanyAgreements({
          psaId,
          companyId: value,
        }),
      ).unwrap(),
    ]).then(res => {
      setContacts(res[0]);
      setSites(res[1]);
      setAgreements(res[2]);
    });
  };

  const handleContactChange = (value, form) => {
    if (!value) return;
    const selected = contacts.find(i => i.value === value);
    form.change('userPhone', selected?.info);
  };

  const handleTypeSelect = (value, form, skipDrop = false) => {
    if (!value) return;
    setSelectedTicketType(value);
    if (!skipDrop) {
      form.batch(() => {
        form.change(crmDefaultFields.subTypeId, '');
        form.change(crmDefaultFields.itemId, '');
      });
    }
  };

  const handleSubTypeSelect = async (value, form, skipDrop = false) => {
    if (!value) return;
    if (!skipDrop) {
      form.change(crmDefaultFields.itemId, '');
    }
    if (value !== '' && boardSubTypes[selectedTicketType]) {
      setBoardItems(
        boardSubTypes[selectedTicketType]
          ?.find(i => i.id === value)
          ?.items.map(({ id, name }) => ({ value: id, label: name })),
      );
    } else {
      setBoardItems([]);
    }
  };

  const handleBoardIdChange = useCallback(
    async (value, form, ignoreDrop = false) => {
      if (!value) return;
      // Dropping related form values
      if (!ignoreDrop) {
        form.batch(() => {
          form.change(crmDefaultFields.typeId, '');
          form.change(crmDefaultFields.subTypeId, '');
          form.change(crmDefaultFields.openBoardStatusId, '');
          form.change(crmDefaultFields.acknowledgedBoardStatusId, '');
          form.change(crmDefaultFields.closedBoardStatusId, '');
          form.change(crmDefaultFields.smsReceivedBoardStatusId, '');
        });
      }

      setProcessing(true);
      await Promise.all([
        new Promise(res =>
          res(
            dispatch(
              ThunkGetIntegrationBoardStatuses({
                id: psaId,
                boardId: value,
              }),
            ).unwrap(),
          ),
        ),
        new Promise(res =>
          res(
            dispatch(
              ThunkGetIntegrationBoardTypes({
                id: psaId,
                boardId: value,
              }),
            ).unwrap(),
          ),
        ),
      ]).then(async res => {
        setBoardStatuses(
          res[0].map(({ id, name }) => ({
            label: name,
            value: id,
          })),
        );
        setBoardTypes(res[1]);
        setSubTypes(
          res[1].reduce((acc, cur) => {
            acc[cur.id] = cur.boardSubTypes;
            return acc;
          }, {}),
        );
      });

      setProcessing(false);
    },
    [dispatch, psaId],
  );

  const onMount = useCallback(async () => {
    setLoading(true);

    await Promise.all([
      new Promise(res =>
        res(
          dispatch(
            ThunkGetIntegrationServiceBoards({
              id: psaId,
            }),
          ).unwrap(),
        ),
      ),
      new Promise(res =>
        res(
          dispatch(
            ThunkGetConnectWisePriorities({
              psaId,
            }),
          ).unwrap(),
        ),
      ),
      new Promise(res =>
        res(
          dispatch(
            ThunkGetConnectWiseCompanies({
              psaId,
            }),
          ).unwrap(),
        ),
      ),
      new Promise(res =>
        res(
          dispatch(
            ThunkGetIntegrationSources({
              id: psaId,
            }),
          ).unwrap(),
        ),
      ),
    ])
      .then(res => {
        setBoards(res[0]);
        setTicketPriorities(res[1]);
        setCompanies(res[2]);
        setSources(res[3]);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch, psaId]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  const handleDialogClose = () => {
    setOpenTicketLinkDialog(false);
    setLink(undefined);
    setTicketId(undefined);
    onMount();
  };

  const onSelect = async (d, form) => {
    setProcessing(true);
    if (d.defaultBoardId) {
      await Promise.all([
        new Promise(res =>
          res(
            dispatch(
              ThunkGetIntegrationBoardStatuses({
                id: psaId,
                boardId: d.defaultBoardId,
              }),
            ).unwrap(),
          ),
        ),
        new Promise(res =>
          res(
            dispatch(
              ThunkGetIntegrationBoardTypes({
                id: psaId,
                boardId: d.defaultBoardId,
              }),
            ).unwrap(),
          ),
        ),
      ]).then(async res => {
        const subtypes = res[1].reduce((acc, cur) => {
          acc[cur.id] = cur.boardSubTypes || [];
          return acc;
        }, {});

        const boardItemsOptions = subtypes[d.typeId]
          ?.find(i => i.id === d.subTypeId)
          ?.items.map(({ id, name }) => ({ value: id, label: name }));

        setBoardStatuses(
          res[0].map(({ id, name }) => ({
            label: name,
            value: id,
          })),
        );
        setBoardTypes(res[1]);
        setSubTypes(subtypes);
        setBoardItems(boardItemsOptions || []);
      });
    }
    if (d.companyId && !init.companyId) {
      await Promise.all([
        dispatch(
          ThunkGetPsaContacts({
            psaId,
            companyId: d.companyId,
          }),
        ).unwrap(),
        dispatch(
          ThunkGetConnectWiseSites({
            psaId,
            companyId: d.companyId,
          }),
        ).unwrap(),
      ]).then(res => {
        setContacts(res[0]);
        setSites(res[1]);
      });
    }

    const fields = form.getRegisteredFields();
    fields
      .filter(f => (!init.companyId ? f : !excludeArray.includes(f)))
      .forEach(field => {
        if (field === 'messagingTemplateId' && !d[field]) {
          return;
        }

        form.change(field, d[field] || '');
        form.change(
          'messagingConfigurationId',
          d.messagingConfigurationId || '',
        );
        form.change('userPhone', d.userPhone || '');
      });

    setProcessing(false);
  };

  useEffect(() => {
    if (init.companyId) {
      handleCompanyChange(init.companyId);
    }
  }, [init.companyId]);

  const handleRefresh = form => {
    form.change('initialDescription', '');
    form.change('summary', '');
    if (!init.companyId) {
      form.change('companyId', null);
      form.change('contactId', null);
      form.change('siteId', null);
    }
    form.change(crmDefaultFields.defaultBoardId, null);
    form.change(crmDefaultFields.typeId, null);
    form.change(crmDefaultFields.subTypeId, null);
    form.change('ticketItemId', null);
    form.change(crmDefaultFields.openBoardStatusId, null);
    form.change(crmDefaultFields.sourceId, null);
    form.change(crmDefaultFields.ticketPriority, null);
    form.change(crmDefaultFields.agreementId, null);
    form.change('createChannel', false);
    form.change('messagingConfigurationId', null);
    form.change('userPhone', '');
    form.change('messagingTemplateId', 0);
  };

  if (loading) return <Loading />;
  return (
    <Form
      onSubmit={submit}
      validate={validate}
      initialValues={init}
      render={({ handleSubmit, form, values }) => (
        <form onSubmit={handleSubmit}>
          {/* Templates */}
          <div className={cx(classes.templatesBox, classes.boxShadow)}>
            <ActionButton
              handler={() => handleRefresh(form)}
              icon={<img src={iconPlus} alt="iconPlus" />}
              toolTip="refresh"
              classNameWrapper={classes.refreshBtn}
            />
            <TicketCreationInputsTemplates
              inputFields={{
                description: 'initialDescription',
                title: 'summary',
              }}
            />

            <TicketCreationTemplates
              psaType={crmSources.ConnectWise.name}
              onSelect={onSelect}
            />
          </div>

          <div className={cx(classes.formContainer, classes.boxShadow)}>
            <FieldWrapper
              label="Summary"
              labelSize={3}
              contentSize={9}
              isRequired
              classNameLabelContainer={classes.removePadding}
              content={
                <Field
                  name="summary"
                  id="summary"
                  render={Input}
                  loading={processing}
                />
              }
            />
            <FieldWrapper
              label="Description"
              labelSize={3}
              contentSize={9}
              isRequired
              classNameLabelContainer={classes.removePadding}
              content={
                <Field
                  name="initialDescription"
                  id="initialDescription"
                  render={Input}
                  multiline
                  minRows={4}
                  loading={processing}
                />
              }
            />
            <FieldWrapper
              label="Company"
              labelSize={3}
              contentSize={9}
              isRequired
              classNameLabelContainer={classes.removePadding}
              content={
                <Field
                  name="companyId"
                  id="companyId"
                  render={AutocompleteFormInput}
                  loading={processing}
                  items={companies}
                />
              }
            />
            <OnChange name="companyId">{handleCompanyChange}</OnChange>

            <FieldWrapper
              label="Contact"
              labelSize={3}
              contentSize={9}
              isRequired
              classNameLabelContainer={classes.removePadding}
              content={
                <Field
                  name="contactId"
                  id="contactId"
                  render={AutocompleteFormInput}
                  loading={processing || !values.companyId || !contacts.length}
                  items={contacts}
                />
              }
            />
            <OnChange name="contactId">
              {value => {
                handleContactChange(value, form);
              }}
            </OnChange>

            <FieldWrapper
              label="Site"
              labelSize={3}
              contentSize={9}
              isRequired
              classNameLabelContainer={classes.removePadding}
              content={
                <Field
                  name="siteId"
                  id="siteId"
                  render={AutocompleteFormInput}
                  loading={processing || !values.companyId || !sites.length}
                  items={sites}
                />
              }
            />

            <FieldWrapper
              label="Board"
              labelSize={3}
              contentSize={9}
              isRequired
              classNameLabelContainer={classes.removePadding}
              content={
                <Field
                  id={crmDefaultFields.defaultBoardId}
                  name={crmDefaultFields.defaultBoardId}
                  size="small"
                  suppressInlineLabel
                  labelName="name"
                  valueName="id"
                  component={DropDown}
                  options={boards}
                  disabled={processing}
                />
              }
            />

            {form
              .getRegisteredFields()
              .some(item => item === crmDefaultFields.defaultBoardId) && (
              <OnChange name={crmDefaultFields.defaultBoardId}>
                {value => {
                  handleBoardIdChange(value, form);
                }}
              </OnChange>
            )}

            <FieldWrapper
              label="Ticket Type"
              labelSize={3}
              contentSize={9}
              isRequired
              classNameLabelContainer={classes.removePadding}
              content={
                <Field
                  id={crmDefaultFields.typeId}
                  name={crmDefaultFields.typeId}
                  size="small"
                  label="Ticket Type"
                  suppressInlineLabel
                  component={DropDown}
                  labelName="name"
                  valueName="id"
                  allowEmpty
                  options={boardTypes}
                  disabled={
                    processing || !values[crmDefaultFields.defaultBoardId]
                  }
                />
              }
            />

            {form
              .getRegisteredFields()
              .some(item => item === crmDefaultFields.typeId) && (
              <OnChange name={crmDefaultFields.typeId}>
                {value => {
                  handleTypeSelect(value, form);
                }}
              </OnChange>
            )}

            <FieldWrapper
              label="Ticket Subtype"
              labelSize={3}
              contentSize={9}
              classNameLabelContainer={classes.removePadding}
              content={
                <Field
                  id={crmDefaultFields.subTypeId}
                  name={crmDefaultFields.subTypeId}
                  size="small"
                  label="Ticket SubType"
                  labelName="name"
                  valueName="id"
                  suppressInlineLabel
                  component={DropDown}
                  allowEmpty
                  options={boardSubTypes[values[crmDefaultFields.typeId]] || []}
                  disabled={processing || !values[crmDefaultFields.typeId]}
                />
              }
            />

            {form
              .getRegisteredFields()
              .some(item => item === crmDefaultFields.subTypeId) && (
              <OnChange name={crmDefaultFields.subTypeId}>
                {value => {
                  handleSubTypeSelect(value, form);
                }}
              </OnChange>
            )}

            <FieldWrapper
              label="Ticket Item"
              labelSize={3}
              contentSize={9}
              classNameLabelContainer={classes.removePadding}
              content={
                <Field
                  name="ticketItemId"
                  id="ticketItemId"
                  render={AutocompleteFormInput}
                  items={boardItems}
                  loading={processing || !values[crmDefaultFields.subTypeId]}
                />
              }
            />

            <FieldWrapper
              label="Ticket Status"
              labelSize={3}
              contentSize={9}
              isRequired
              classNameLabelContainer={classes.removePadding}
              content={
                <Field
                  id={crmDefaultFields.openBoardStatusId}
                  name={crmDefaultFields.openBoardStatusId}
                  size="small"
                  label="New Ticket Status"
                  suppressInlineLabel
                  component={DropDown}
                  options={boardStatuses}
                  disabled={
                    processing || !values[crmDefaultFields.defaultBoardId]
                  }
                />
              }
            />

            <FieldWrapper
              label="Source"
              labelSize={3}
              contentSize={9}
              isRequired
              classNameLabelContainer={classes.removePadding}
              content={
                <Field
                  id={crmDefaultFields.sourceId}
                  name={crmDefaultFields.sourceId}
                  size="small"
                  suppressInlineLabel
                  labelName="name"
                  valueName="id"
                  component={DropDown}
                  options={sources}
                  disabled={processing}
                />
              }
            />

            <FieldWrapper
              label="Ticket Priority"
              labelSize={3}
              contentSize={9}
              isRequired
              classNameLabelContainer={classes.removePadding}
              content={
                <Field
                  id={crmDefaultFields.ticketPriority}
                  name={crmDefaultFields.ticketPriority}
                  size="small"
                  suppressInlineLabel
                  component={DropDown}
                  options={ticketPriorities}
                  disabled={processing}
                />
              }
            />

            <FieldWrapper
              label="Agreement"
              labelSize={3}
              contentSize={9}
              classNameLabelContainer={classes.removePadding}
              content={
                <Field
                  id={crmDefaultFields.agreementId}
                  name={crmDefaultFields.agreementId}
                  size="small"
                  suppressInlineLabel
                  component={DropDown}
                  options={agreements}
                  disabled={processing || !agreements.length}
                />
              }
            />

            <TicketCreationMessagingChannel
              psaType={crmSources.ConnectWise.idx}
              disabled={processing}
            />
          </div>

          <div className={sharedClasses.dialogActionsContainer}>
            <ReusableButton
              viewType="white"
              buttonType="simple"
              label="Submit"
              type="submit"
              disabled={processing}
              loading={processing}
            />
          </div>
          {openTicketLinkDialog && (
            <TicketLinkDialog
              onClose={handleDialogClose}
              link={link}
              open={openTicketLinkDialog}
              ticketId={ticketId}
              channelCreationResponse={channelCreationResponse}
            />
          )}
        </form>
      )}
    />
  );
};

ConnectWiseCreateTicketForm.propTypes = {
  psaId: PropTypes.number.isRequired,
};

export default ConnectWiseCreateTicketForm;
