import { validateRequired } from '@ui/components/common/functions/validators';
import crmDefaultFields from '../updateCrm/CrmDefaults/constants';

export const initialValues = {
  name: '',
  [crmDefaultFields.defaultCompany]: '',
  [crmDefaultFields.defaultBoard]: '',
  [crmDefaultFields.openBoardStatusId]: '',
  [crmDefaultFields.acknowledgedBoardStatusId]: '',
  [crmDefaultFields.closedBoardStatusId]: '',
  [crmDefaultFields.typeId]: '',
  [crmDefaultFields.ticketCategoryId]: '',
  [crmDefaultFields.sourceId]: '',
  [crmDefaultFields.ticketPriority]: null,
  [crmDefaultFields.crmSpecificSettings]: {
    serviceLevelAgreementId: null,
  },
};

export const haloRequiredFieldsType = Object.freeze({
  text: 0,
  date: 1,
  category: 2,
  impact: 3,
  urgency: 4,
});

export const validation = (values, requiredFieldList) => {
  const validate = {
    name: validateRequired(values.name),
    [crmDefaultFields.defaultCompany]: validateRequired(
      values[crmDefaultFields.defaultCompany],
    ),
    [crmDefaultFields.defaultBoard]: validateRequired(
      values[crmDefaultFields.defaultBoard],
    ),
    [crmDefaultFields.openBoardStatusId]: validateRequired(
      values[crmDefaultFields.openBoardStatusId],
    ),
    [crmDefaultFields.closedBoardStatusId]: validateRequired(
      values[crmDefaultFields.closedBoardStatusId],
    ),
    [crmDefaultFields.typeId]: validateRequired(
      values[crmDefaultFields.typeId],
    ),
    [crmDefaultFields.ticketCategoryId]: validateRequired(
      values[crmDefaultFields.ticketCategoryId],
    ),
    [crmDefaultFields.sourceId]: validateRequired(
      values[crmDefaultFields.sourceId],
    ),
  };

  if (Object.keys(requiredFieldList).length) {
    validate.crmSpecificSettings = {};
    Object.keys(requiredFieldList).forEach(fieldKey => {
      const requiredFieldsName = Object.keys(haloRequiredFieldsType);
      const fieldName = requiredFieldsName[fieldKey];
      if (fieldName === 'text' || fieldName === 'date') {
        const crmSpecificSettingsName = requiredFieldList[fieldKey].name;
        validate.crmSpecificSettings[
          crmSpecificSettingsName
        ] = validateRequired(
          values.crmSpecificSettings[crmSpecificSettingsName],
        );
      } else {
        validate[fieldName] = validateRequired(values[fieldName]);
      }
    });
    if (
      Object.values(validate.crmSpecificSettings).filter(Boolean).length === 0
    ) {
      delete validate.crmSpecificSettings;
    }
  }
  return validate;
};
