export const codeValidation = code => {
  const regEx = /^[0-9]{6}$/g;
  if (!code) {
    return 'Required';
  }
  if (!regEx.test(code)) {
    return 'Wrong format';
  }
  return undefined;
};

export default codeValidation;
