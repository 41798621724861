import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@common/Dialog/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import Dialog from '@material-ui/core/Dialog/Dialog';

import { useNotify, useRedirect } from 'react-admin';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell/TableCell';
import ActionButton from '@common/buttons/ActionButton/ActionButton';
import iconLink from '@assets/icons/link.svg';
import Loading from '@common/Loading/Loading';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import { TRIGGER } from '@constants/routes';
import { getTriggers } from './helpers';
import useStyles from './styles';

const TriggersList = ({ isOpen, onClose, letterId }) => {
  const classes = useStyles();
  const notify = useNotify();
  const redirect = useRedirect();
  const [triggers, setTriggers] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isOpen && letterId) {
      setLoading(true);
      getTriggers(letterId)
        .then(d => setTriggers(d))
        .catch(e => notify(e.message, 'error'))
        .finally(() => setLoading(false));
    }
  }, [isOpen, notify, letterId]);

  const getRow = dataRow => {
    return (
      <TableRow key={dataRow.id}>
        <TableCell>{dataRow.id}</TableCell>
        <TableCell>{dataRow.value}</TableCell>
        <TableCell>
          <ActionButton
            icon={<img src={iconLink} alt="openTrigger" />}
            handler={() => redirect(`${TRIGGER}/${dataRow.id}`)}
            toolTip="Trigger details"
          />
        </TableCell>
      </TableRow>
    );
  };

  const getContent = () => {
    if (loading) {
      return <Loading />;
    }
    if (!triggers.length) {
      return <div>No content</div>;
    }
    return (
      <>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>Name</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>{triggers.map(t => getRow(t))}</TableBody>
          </Table>
        </TableContainer>
      </>
    );
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle onClose={onClose}>Activated triggers</DialogTitle>
      <DialogContent>
        <div className={classes.ticketsContainer}>{getContent()}</div>
      </DialogContent>
    </Dialog>
  );
};

TriggersList.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  letterId: PropTypes.string.isRequired,
};

export default TriggersList;
