import React, { useState } from 'react';
import { useRedirect } from 'react-admin';
import PropTypes from 'prop-types';

import ItemMenu from '@common/ItemMenu/ItemMenu';
import LogsDialog from '@components/MailBox/components/Documents/Actions/LogsDialog';
import AssignDialog from '@components/Audit/dialogs/AssignDialog';
import TicketsListDialog from '@components/Audit/TicketsList/TicketsListDialog';

import { openInNewTab } from '@components/Helpers/linkHelper';

import logIcon from '@assets/icons/document_blue.svg';
import iconUser from '@assets/icons/user.svg';
import iconEmail from '@assets/icons/email_active.svg';
import iconDelete from '@assets/icons/delete.svg';
import iconEdit from '@assets/icons/edit.svg';
import iconTicket from '@assets/icons/ticket_active.svg';
import iconTicketInactive from '@assets/icons/ticket_inActive.svg';
import iconAcknowledge from '@assets/icons/shield.svg';
import iconAcknowledgeActive from '@assets/icons/shield_done.svg';
import iconView from '@assets/icons/visibility.svg';

import useStyles from '@common/Styles/actionFieldStyles';
import ActionButton from '@common/buttons/ActionButton/ActionButton';
import AcknowledgeDialog from './AcknowledgeDialog';
import CreateTicket from './CreateTicket';

const ActionField = ({ record = {}, users, handleAssign, handleDelete }) => {
  const redirect = useRedirect();

  const [letterLogs, setLetterLogs] = useState(null);
  const [assignDialogOpen, setAssignDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ticketsPopupOpen, setTicketsPopupOpen] = useState(false);
  const [createTicketDialogOpen, setCreateTicketDialogOpen] = useState(false);
  const [acknowledgeDialog, setAcknowledgeDialog] = useState(false);

  const classes = useStyles();
  const handleAssignDialogSelectValue = value => {
    setLoading(true);
    handleAssign(value, record.id);
    setLoading(false);
    setAssignDialogOpen(false);
  };

  const menuOptions = [
    {
      icon: <img src={logIcon} alt="log" />,
      caption: 'Log',
      handler: () => setLetterLogs(record.logs),
    },
    {
      icon: (
        <img
          src={!record.assigneeName ? iconAcknowledge : iconAcknowledgeActive}
          alt="iconAcknowledge"
        />
      ),
      caption: 'Acknowledge',
      handler: () => setAcknowledgeDialog(true),
    },
    {
      icon: <img src={iconUser} alt="iconUser" />,
      caption: 'Assign to',
      handler: () => setAssignDialogOpen(true),
    },
    {
      icon: <img src={iconEmail} alt="source email" />,
      caption: 'Source doc',
      handler: () => openInNewTab(record.emailLink),
    },
  ];

  return (
    <div className={classes.container}>
      <ActionButton
        icon={<img src={iconView} alt="View" />}
        alt="view"
        handler={() => redirect(`incident/${record.id}`)}
        toolTip="View"
      />
      <ActionButton
        icon={<img src={iconEdit} alt="edit" />}
        alt="edit"
        handler={() => redirect(`incidents/${record.id}`)}
        toolTip="Edit"
      />
      <ActionButton
        icon={<img src={iconDelete} alt="Delete" />}
        alt="Delete"
        handler={() => handleDelete(record.id)}
        toolTip="Delete"
      />
      <ActionButton
        icon={
          <img
            src={
              !record?.ticketIds || !record?.ticketIds.length
                ? iconTicketInactive
                : iconTicket
            }
            alt="iconTicket"
          />
        }
        handler={() =>
          !record?.ticketIds || !record?.ticketIds.length
            ? setCreateTicketDialogOpen(true)
            : setTicketsPopupOpen(true)
        }
        toolTip="Tickets"
        disabledStyling={!record?.ticketIds || !record?.ticketIds.length}
      />

      <ItemMenu options={menuOptions} />

      <LogsDialog
        open={!!letterLogs}
        onClose={() => setLetterLogs(null)}
        logs={letterLogs}
      />
      <AssignDialog
        open={assignDialogOpen}
        onSelect={handleAssignDialogSelectValue}
        onClose={() => setAssignDialogOpen(false)}
        users={users}
        disabled={loading}
      />
      <TicketsListDialog
        isOpen={ticketsPopupOpen}
        onClose={() => setTicketsPopupOpen(false)}
        ticketIds={record?.ticketIds}
      />
      {createTicketDialogOpen && (
        <CreateTicket
          open={createTicketDialogOpen}
          onClose={() => setCreateTicketDialogOpen(false)}
          incidentId={record?.id}
        />
      )}
      {acknowledgeDialog && (
        <AcknowledgeDialog
          open={acknowledgeDialog}
          setOpen={setAcknowledgeDialog}
          incidentId={record?.id}
          isAcknowledged={!!record?.assigneeName}
        />
      )}
    </div>
  );
};

ActionField.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.string,
    dateTriggered: PropTypes.string,
    triggerName: PropTypes.string,
    logs: PropTypes.string,
    assigneeName: PropTypes.string,
  }),
  users: PropTypes.arrayOf(PropTypes.any),
  handleAssign: PropTypes.func,
};

export default ActionField;
