import React, { useMemo, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { createTheme, Divider, ThemeProvider } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useRedirect } from 'react-admin';
import Pusher from 'pusher-js';

import {
  ThunkExternalLogin,
  ThunkLogin,
  ThunkMfaLogin,
  ThunkPreCheck,
} from '@store/slices/auth/thunks';
import {
  FORGOT_PASSWORD_ROUTE,
  HOME_ROUTE,
  REGISTRATION_ROUTE,
} from '@constants/routes';
import { emailValidator, requiredValidator } from '@utils/validators';
import { lightTheme } from '@services/themes/mainTheme';
import externalLoginType from '@constants/externalLoginType';

import {
  parseJwtToken,
  setAllowedResources,
  setTenantImageUrlToStorage,
} from '@services/api';
import { getPusherForAuth } from '@services/requests/common';

import appConfig from '@configs/appConfig';

import Input from '@components/Auth/Common/Input';
import ReusableButton from '@common/Button/Button';

import { ReactComponent as EyeIcon } from '@assets/icons/eye.svg';
import { ReactComponent as EyeClosedIcon } from '@assets/icons/eye-closed.svg';
import { ReactComponent as GoogleIcon } from '@assets/icons/google.svg';
import { ReactComponent as MicrosoftIcon } from '@assets/icons/microsoft.svg';

import localStorage from '@services/localStorage';
import { getTenantImage } from '@components/Settings/TenantSettings/helpers';
import getExternalLogin, { fetchPermissions, useOnLogin } from '../helpers';

import useStyles from '../styles';

const theme = createTheme(lightTheme());

const LoginForm = () => {
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const classes = useStyles();
  const dispatch = useDispatch();
  const { push } = useHistory();
  const redirect = useRedirect();

  const validate = values => {
    return {
      username: values.username
        ? emailValidator(values.username)
        : requiredValidator(values.username),
      password: requiredValidator(values.password),
    };
  };

  const submit = values => {
    setLoading(true);
    if (values.mfaCode) {
      dispatch(ThunkMfaLogin({ payload: values }))
        .unwrap()
        .then(useOnLogin)
        .catch(() => {
          localStorage.removeItem('accessToken');
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      dispatch(ThunkPreCheck({ payload: values }))
        .unwrap()
        .then(data => {
          if (data?.url) {
            redirect(data.url);
          } else {
            dispatch(ThunkLogin({ payload: values }))
              .unwrap()
              .then(useOnLogin)
              .catch(() => {
                localStorage.removeItem('accessToken');
              })
              .finally(() => {
                setLoading(false);
              });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleThirdPartyLogin = type => {
    setLoading(true);
    getExternalLogin(type)
      .then(token => {
        const payload = { token, tokenType: type };

        dispatch(ThunkExternalLogin({ payload }))
          .unwrap()
          .then(async res => {
            if (res?.userId) {
              await fetchPermissions(
                appConfig.baseUrl.concat('/AccessRights/'),
              );

              const claims = parseJwtToken(res.accessToken);
              setAllowedResources(claims.allowedObjects);
              const { tenantImageUrl } = await getTenantImage();
              setTenantImageUrlToStorage(tenantImageUrl);
              setLoading(false);

              push(HOME_ROUTE);
            }
          })
          .catch(() => {
            setLoading(false);
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePusher = type => {
    getPusherForAuth().then(async res => {
      const guid = crypto.randomUUID();

      const pusher = new Pusher(res.key, {
        cluster: res.cluster,
        channelAuthorization: {
          transport: 'ajax',
          endpoint: `${appConfig.baseUrl}/common/authorize-auth-pusher`,
        },
      });

      const channel = await pusher.subscribe(`private-${guid}`);

      channel.bind('client-message', data => {
        dispatch(
          ThunkExternalLogin({
            payload: { tokenType: data.type, token: data.token },
          }),
        )
          .unwrap()
          .then(async logged => {
            if (logged?.userId) {
              await fetchPermissions(
                appConfig.baseUrl.concat('/AccessRights/'),
              );

              const claims = parseJwtToken(logged.accessToken);
              setAllowedResources(claims.allowedObjects);

              setLoading(false);

              push(HOME_ROUTE);
            }
          })
          .finally(() => {
            setLoading(false);
          });
      });

      window.open(
        `${appConfig.authUrl}?type=${type}&sessionId=${guid}`,
        '_blank',
      );
    });
  };

  const desktopMode = useMemo(
    () =>
      navigator.userAgent
        .split(' ')
        .some(i => i.includes('ConnectWiseEmbeddedBrowser')),
    [],
  );

  return (
    <ThemeProvider theme={theme}>
      <Form
        onSubmit={submit}
        validate={validate}
        render={({ handleSubmit }) => (
          <div>
            <div className={classes.typographyContainer}>
              <h5>Login</h5>
              <p>Welcome Back!</p>
            </div>
            <form onSubmit={handleSubmit}>
              <div className={classes.fieldItem}>
                <p>Email</p>
                <Field
                  id="username"
                  name="username"
                  component={Input}
                  placeholder="Enter your email"
                />
              </div>

              <div className={classes.fieldItem}>
                <p>Password</p>
                <Field
                  id="password"
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  component={Input}
                  placeholder="Password"
                  endAdornment={
                    <div
                      onClick={() => setShowPassword(!showPassword)}
                      role="presentation"
                      style={{
                        cursor: 'pointer',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      {!showPassword ? <EyeIcon /> : <EyeClosedIcon />}
                    </div>
                  }
                />
              </div>

              <div
                role="presentation"
                className={classes.link}
                onClick={() => {
                  push(FORGOT_PASSWORD_ROUTE);
                }}
              >
                Forgot Password?
              </div>

              <div className={classes.fieldItem}>
                <p>MFA Code</p>
                <Field
                  id="mfaCode"
                  name="mfaCode"
                  component={Input}
                  placeholder="MFA Code"
                  autoComplete="off"
                />
              </div>

              <div>
                <ReusableButton
                  label="Login"
                  type="submit"
                  loading={loading}
                  disabled={loading}
                  classNameWrapper={classes.button}
                />
              </div>
            </form>

            {/* TODO add custom divider with text */}
            <Divider variant="middle" />

            <div>
              <div className={classes.externalLoginsContainer}>
                {desktopMode ? (
                  <>
                    <GoogleIcon
                      onClick={() => {
                        handlePusher(externalLoginType.Google);
                      }}
                    />

                    <MicrosoftIcon
                      onClick={() => {
                        handlePusher(externalLoginType.Microsoft);
                      }}
                    />
                  </>
                ) : (
                  <>
                    <GoogleIcon
                      onClick={() => {
                        handleThirdPartyLogin(externalLoginType.Google);
                      }}
                    />
                    <MicrosoftIcon
                      onClick={() => {
                        handleThirdPartyLogin(externalLoginType.Microsoft);
                      }}
                    />
                  </>
                )}
              </div>

              <div className={classes.signUpContainer}>
                Don’t have an account?
                <Link to={REGISTRATION_ROUTE}>Sign Up</Link>
              </div>
            </div>
          </div>
        )}
      />
    </ThemeProvider>
  );
};

export default LoginForm;
