import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import crmSources from '@constants/crmSources';
import {
  getPodDisabledTooltip,
  TEXT_OPEN_POD_DIALOG,
  TEXT_OPEN_POD_SECURE_DATA,
} from '@constants/texts/pod';

import SMSIcon from '@assets/icons/sms.svg';
import ShieldPersonIcon from '@assets/icons/shield_person.svg';
import SyncIcon from '@assets/icons/secureDataSend.svg';

import PodDialog from '@components/PodDialog';
import ItemMenu from '@common/ItemMenu/ItemMenu';
import ActionFieldItem from '@common/ActionButtons/ActionFieldItem';
import CreateMessagingChannelDialog from '@ui/components/Messaging/dialogs/CreateMessagingChannelDialog';

import useStyles from '../../styles';

const SupersOpsTicketTableActions = ({ record, crmId, crmDefaultId }) => {
  const [openCreateChannelDialog, setOpenCreateChannelDialog] = useState(false);
  const [openSecureDialog, setOpenSecureDialog] = useState(false);
  const [openPodDialog, setOpenPodDialog] = useState(false);

  const classes = useStyles();

  const disabled = !record?.requester?.userId;
  const disabledToolTip = disabled
    ? '. This option is disabled because this ticket doesn’t have a Requester.'
    : '';

  const menuOptions = useMemo(
    () => [
      {
        icon: <img src={SMSIcon} alt="createChannel" />,
        caption: 'Add SMS Channel',
        handler: () => {
          setOpenCreateChannelDialog(true);
        },
      },
    ],
    [],
  );

  return (
    <div className={classes.tableActions}>
      <ActionFieldItem
        alt={TEXT_OPEN_POD_DIALOG}
        handler={() => {
          setOpenPodDialog(true);
        }}
        toolTip={
          disabled ? getPodDisabledTooltip('Requester') : TEXT_OPEN_POD_DIALOG
        }
        icon={ShieldPersonIcon}
        ignoreDefaultIconSize
        disabled={disabled}
      />
      <ActionFieldItem
        alt={TEXT_OPEN_POD_SECURE_DATA}
        handler={() => setOpenSecureDialog(true)}
        toolTip={
          disabled
            ? getPodDisabledTooltip('Requester')
            : TEXT_OPEN_POD_SECURE_DATA
        }
        icon={SyncIcon}
        ignoreDefaultIconSize
        disabled={disabled}
      />

      <ItemMenu
        options={menuOptions}
        itemsHeightMultiplier={10}
        openOnHover
        disabled={disabled}
        toolTip={disabled ? `SMS Channel ${disabledToolTip}` : undefined}
      />

      {openPodDialog && record.requester?.userId && (
        <PodDialog
          itemId={record?.id}
          onClose={() => {
            setOpenPodDialog(false);
          }}
          itemType="ticket"
          psaId={crmId}
          psa={crmSources.SuperOps.label}
        />
      )}

      {openCreateChannelDialog && (
        <CreateMessagingChannelDialog
          open={openCreateChannelDialog}
          setOpen={setOpenCreateChannelDialog}
          record={record}
          crmDefaultId={crmDefaultId}
          crmType={crmSources.SuperOps.name}
        />
      )}

      {openSecureDialog && (
        <PodDialog
          itemId={record?.id}
          onClose={() => {
            setOpenSecureDialog(false);
          }}
          itemType="ticket"
          psaId={crmId}
          psa={crmSources.SuperOps.label}
          page="secureData"
        />
      )}
    </div>
  );
};

SupersOpsTicketTableActions.propTypes = {
  record: PropTypes.shape({
    ticketNumber: PropTypes.string.isRequired,
    crmDefaultId: PropTypes.number.isRequired,
    requester: PropTypes.shape({
      userId: PropTypes.number,
    }),
  }),
  crmDefaultId: PropTypes.number,
  crmId: PropTypes.number,
};
export default SupersOpsTicketTableActions;
