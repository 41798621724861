import React from 'react';
import { useTranslate } from 'react-admin';
import { Field, Form } from 'react-final-form';
import PropTypes from 'prop-types';

import { OnChange } from 'react-final-form-listeners';
import Loading from '@common/Loading/Loading';
import DropDown from '@components/Auth/Common/DropDown';
import FieldWrapper from '@common/form/FieldWrapper';
import FormSwitch from '@common/FilterInputs/Switch';
import Paper from '@common/Paper/Paper';

import Input from '@common/Input/Input';
import ActionButton from '@common/buttons/ActionButton/ActionButton';
import ReusableButton from '@common/Button/Button';
import iconPlus from '@assets/icons/plusGrey.svg';
import { actions } from '@store/actions';
import iconArrow from '@assets/icons/arrowGrey.svg';
import { basePermissions } from '@constants/permissions';
import useDisabled from '@components/Hooks/useDisabled';
import useTemplateVariables from '@components/Ticketing/Notifications/hooks/useTemplateVariables';
import { getTemplates } from '@components/Ticketing/Notifications/DmSettings/helpers';
import TemplateMenu from '@components/Ticketing/Notifications/components/TemplateMenu';
import { getTicketSettings, postTicketSettings } from './helpers';
import useTicketSetting from '../../hooks/useTicketSetting';
import useTicketingSettings from '../../hooks/useTicketingSettings';

import useStyles from './styles';

const TicketingDMSettings = ({ match }) => {
  const translate = useTranslate();
  const classes = useStyles();

  const paramsId = match.params.id;

  const {
    submit,
    loading,
    handleBack,
    data,
    setData,
    isCreate,
    anchorEl,
    setAnchorEl,
  } = useTicketSetting({
    paramsId,
    action: actions.saveTicketSetting,
    tileKey: 'isTicket',
    preventOnGettingSetting: true,
    apiPostRequest: postTicketSettings,
    apiGetSetting: getTicketSettings,
  });

  const { templateVariables } = useTemplateVariables({ func: getTemplates });

  const {
    defaultsOptions,
    crmOptions,
    handleCrmChanged,
    allDefaults,
  } = useTicketingSettings({
    paramsId,
    setData,
    isCreate,
    getDetailsFunc: getTicketSettings,
  });

  const { editMode, disabled } = useDisabled(
    loading,
    'notificationSettings',
    basePermissions.update,
  );

  const dataLoaded = typeof data.id !== 'undefined' && !!allDefaults;
  return dataLoaded ? (
    <Paper>
      <Form
        onSubmit={submit}
        initialValues={data}
        render={({ handleSubmit, form }) => (
          <form className={classes.form} onSubmit={handleSubmit} noValidate>
            <div className={classes.contentFormWrapper}>
              <FieldWrapper
                label="PSA"
                labelSize={3}
                content={
                  <div>
                    <Field
                      id="crm"
                      name="crm"
                      component={DropDown}
                      options={crmOptions}
                      disabled={disabled}
                    />
                    <OnChange name="crm">
                      {value => {
                        handleCrmChanged(value);
                      }}
                    </OnChange>
                  </div>
                }
              />
              <FieldWrapper
                label="PSA Defaults"
                labelSize={3}
                content={
                  <Field
                    id="crmTicketDefaultId"
                    name="crmTicketDefaultId"
                    component={DropDown}
                    options={defaultsOptions}
                    disabled={disabled || !defaultsOptions.length}
                  />
                }
              />
              <FieldWrapper
                label="Ticket Subject"
                labelSize={3}
                content={
                  <div className={classes.ticketSubject}>
                    <ActionButton
                      handler={event => setAnchorEl(event.currentTarget)}
                      icon={<img src={iconPlus} alt="iconPlus" />}
                      toolTip="Add template variable"
                      disabled={disabled}
                    />
                    <Field
                      id="ticketSubject"
                      name="ticketSubject"
                      component={Input}
                      fullWidth
                      styleType="main"
                      inputView="text"
                      disabled={disabled}
                    />
                  </div>
                }
              />
              <FieldWrapper
                label="Close ticket when recover"
                labelSize={3}
                content={
                  <Field
                    id="closeAfterRecovering"
                    name="closeAfterRecovering"
                    type="checkbox"
                    component={FormSwitch}
                    disabled={disabled}
                  />
                }
              />
            </div>
            <div className={classes.buttonWrapper}>
              <ReusableButton
                size="md"
                type="button"
                classNameWrapper={classes.button}
                disabled={loading}
                onClick={handleBack}
              >
                <>
                  <img src={iconArrow} alt="iconArrow" />
                  {translate('resources.buttons.back')}
                </>
              </ReusableButton>
              {editMode && (
                <ReusableButton
                  size="md"
                  viewType="black"
                  type="submit"
                  classNameWrapper={classes.button}
                  disabled={loading}
                  loading={loading}
                >
                  {translate('resources.billing.updateButton')}
                </ReusableButton>
              )}
            </div>
            <TemplateMenu
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              form={form}
              name="ticketSubject"
              templateVariables={templateVariables}
            />
          </form>
        )}
      />
    </Paper>
  ) : (
    <Loading />
  );
};

TicketingDMSettings.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.string }),
  }),
};

export default TicketingDMSettings;
