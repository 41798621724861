import React from 'react';
import PropTypes from 'prop-types';
import ConnectWiseCreateTicketForm from '@components/CreateTicket/components/ConnectWiseCreateTicketForm';

const CreateTicketWrapper = ({ psaId, contactInfo }) => {
  return (
    <ConnectWiseCreateTicketForm
      psaId={psaId}
      init={{
        contactId: contactInfo.ContactId,
        companyId: contactInfo.CompanyId,
        siteId: contactInfo.SiteId,
      }}
    />
  );
};

CreateTicketWrapper.propTypes = {
  psaId: PropTypes.number.isRequired,
  contactInfo: PropTypes.shape({
    ContactId: PropTypes.number,
    CompanyId: PropTypes.number,
    SiteId: PropTypes.number,
  }),
};

export default CreateTicketWrapper;
