import React, { useEffect, useMemo, useRef, useState } from 'react';
import { TextField, useNotify, useRedirect, useTranslate } from 'react-admin';
import cx from 'classnames';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';
import moment from 'moment';

import DataGrid from '@lib/bundle';
import { defaultDateFormat } from '@constants/formats';
import { removeLetter, reParseMessage } from '@components/MailBox/helpers';
import StatusBadge from '@common/LetterStatuses/StatusBadge';
import ReusableButton from '@common/Button/Button';
import TabPanel from '@common/TabPanel/TabPanel';
import CustomRichTextField from '@common/RichTetField';
import CreateButton from '@common/CreateButton/CreateButton';
import ConfirmDialog from '@common/ConfirmDialog/ConfirmDialog';
import useCurrentBody from '@common/hooks/useCurrentBody';
import DeleteIcon from '@assets/icons/trash-box.svg';
import refreshIcon from '@assets/icons/refresh.svg';

import Header from '@common/MuiVirtualizedTable/components/Header';
import Cell from '@common/MuiVirtualizedTable/components/Cell';
import getItemName from '@components/Helpers/fileNameHelpers';
import Loading from '@common/Loading/Loading';
import { getLetterDetails } from './helpers';
import { mailBodyView } from './params';
import Attachments from './Attachments';
import {
  displayAsHtml,
  doesTypeSupports,
  prepareHeaders,
} from '../Parsers/components/Create/components/DisplayEmail/attachments/helper';
import getAttachmentBody from '../Parsers/components/Create/components/DisplayEmail/attachments/attachmentsProcessor';

import useStyles from './styles';

const MailView = ({ bodyIndex, body, useHtml }) => {
  const classes = useStyles();
  const ref = useRef();
  const currentBody = useCurrentBody(body);
  const [shadowRoot, setShadowRoot] = useState(null);
  const [isCopied, setCopied] = useState(false);
  const notify = useNotify();

  const handleCopyToClipboard = text => {
    navigator.clipboard.writeText(text);
    notify('Copied to clipboard', 'success');
    setCopied(true);
  };

  const copyButtonText = useMemo(() => (isCopied ? 'Copied' : 'Copy'), [
    isCopied,
  ]);

  useEffect(() => {
    if (ref.current && !shadowRoot) {
      const rootShadow = ref.current.attachShadow({ mode: 'open' });
      /**
       * making spread for "ref.current.children" into array for working with
       * method of array "forEach"
       */
      const htmlChildren = [...ref.current.children];
      htmlChildren.forEach(child => {
        rootShadow.appendChild(child);
      });
      setShadowRoot(rootShadow);
    }
    /**
     * passed "bodyIndex" into dependency for making rerender
     */
  }, [ref, bodyIndex, shadowRoot]);

  return (
    <Grid className={classes.mailBody}>
      {bodyIndex === 2 && (
        <ReusableButton
          classNameWrapper={classes.copyButton}
          textSize="mini"
          size="sm"
          onClick={() => {
            handleCopyToClipboard(currentBody.body);
          }}
        >
          {copyButtonText}
        </ReusableButton>
      )}

      {[0, 3].includes(bodyIndex) || useHtml ? (
        <CustomRichTextField
          source="body"
          record={currentBody}
          innerRef={ref}
          component="div"
          className={classes.textStyle}
        />
      ) : (
        <TextField
          source="body"
          record={currentBody}
          component="pre"
          className={cx(classes.textStyle, classes.jsonTextStyle)}
        />
      )}
    </Grid>
  );
};

const rowHeight = 90;
const headerHeight = 55;

const Letter = ({ match, unauthorized = false, errorHandler = null }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const redirect = useRedirect();
  const notify = useNotify();

  const [data, setData] = useState({});
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  useEffect(() => {
    getLetterDetails(match.params.letterId)
      .then(response => {
        setData(response);
      })
      .catch(error => {
        if (unauthorized && errorHandler) {
          errorHandler(error);
        } else {
          notify(error.message, 'warning');
          redirect(`/mailbox/${match.params.mailBoxId}/documents`);
        }
      });
  }, [match.params.letterId, match.params.mailBoxId, notify, redirect]);

  const dataLoaded = data && data.letterContent;

  const headers = useMemo(() => prepareHeaders(data?.letterContent?.headers), [
    data?.letterContent?.headers,
  ]);

  const getBody = bodyView => {
    if (!dataLoaded) {
      return { body: '' };
    }
    let content;
    switch (bodyView) {
      case mailBodyView[0].value:
        if (!data.letterContent.htmlBody) {
          content = data.letterContent.textBody;
        } else {
          content = data.letterContent.htmlBody;
        }
        break;
      case mailBodyView[1].value:
        content = data.letterContent.textBody;
        break;
      case mailBodyView[2].value:
        content = JSON.stringify(data.letterContent, null, 2);
        break;
      case mailBodyView[3].value:
        content = headers;
        break;
      default:
        content = '';
    }
    return { body: content };
  };

  const getText = value => translate('resources.letter.'.concat(value));
  const getPrettyDate = date => moment(date).format(defaultDateFormat);

  const handleBack = () =>
    redirect('/mailbox/'.concat(match.params.mailBoxId).concat('/documents'));

  const handleAddEditParserClick = () => {
    if (data.parserId) {
      redirect(`${match.params.letterId}/parser/${data.parserId}/updateParser`);
    } else {
      redirect(`${match.params.letterId}/createParser`);
    }
  };

  const tabs = mailBodyView
    .filter(i => !unauthorized || i.value !== mailBodyView[2].value)
    .map((item, index) => ({
      label: item.label,
      value: <MailView body={getBody(item.value)} bodyIndex={index} />,
    }));

  // Show tabs with attachments
  if (data.letterContent?.attachments?.length) {
    data.letterContent.attachments.forEach(e => {
      if (doesTypeSupports(e.name)) {
        e.useCasualView = true;
        const item = {
          label: getItemName(e.name),
          value: (
            <MailView
              body={getAttachmentBody(e)}
              bodyIndex={tabs.length + 1}
              useHtml={displayAsHtml(e.name)}
            />
          ),
        };
        tabs.push(item);
      }
    });
  }

  const getFormattedDate = value => {
    return value ? moment(value).format('MM/D/YY HH:mm') : '-';
  };

  const onDeleteHandler = () => {
    setDeleteId(data.id);
    setDeleteDialogOpen(true);
  };

  const onDeleteLetterHandler = () => {
    removeLetter(deleteId)
      .then(() => {
        setDeleteDialogOpen(false);
        setDeleteId(null);
        handleBack();
      })
      .catch(e => {
        notify(e.message, 'error');
        setDeleteDialogOpen(false);
        setDeleteId(null);
      });
  };

  const onCancelDeleteHandler = () => {
    setDeleteDialogOpen(false);
    setDeleteId(null);
  };

  const handleReParser = async () => {
    const result = await reParseMessage(data.id);
    if (result) {
      notify(getText('reParseQueued'));
    } else {
      notify(getText('reParserFailed'), 'error');
    }
  };

  return dataLoaded ? (
    <div className={classes.root}>
      <Grid item xs={12} className={classes.containerSpacing}>
        <div className={classes.headerWrapper}>
          {!unauthorized && (
            <div>
              <ReusableButton
                classNameWrapper={classes.buttonWrapper}
                onClick={handleBack}
              >
                <>
                  <ArrowBackIcon className={classes.iconBlack} />
                  {translate(getText('back'))}
                </>
              </ReusableButton>
            </div>
          )}
          <Grid container className={classes.container}>
            <Grid item sm={4}>
              <Grid container alignItems="center" direction="column">
                <Grid item>
                  <span className={classes.header}>{getText('processed')}</span>
                </Grid>
                <Grid item>
                  <span>{getFormattedDate(data.lastParsedDate)}</span>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={4}>
              <Grid container alignItems="center" direction="column">
                <Grid item>
                  <span className={classes.header}>{getText('template')}</span>
                </Grid>
                <div>
                  <span>{data.parserName || '-'}</span>
                </div>
              </Grid>
            </Grid>
            <Grid item sm={4}>
              <Grid container alignItems="center" direction="column">
                <span className={classes.header}>{getText('status')}</span>
                <div className={classes.badgeContainer}>
                  <StatusBadge status={data.status} />
                </div>
              </Grid>
            </Grid>
          </Grid>
          {!unauthorized && (
            <div className={classes.buttonContainer}>
              <ReusableButton
                onClick={handleReParser}
                classNameWrapper={cx(classes.buttonWrapper, classes.btnStyle)}
                size="md"
              >
                <>
                  <img src={refreshIcon} alt="refreshIcon" />
                  {translate('resources.mailBox.documents.bulkActions.reparse')}
                </>
              </ReusableButton>

              <CreateButton
                onClick={handleAddEditParserClick}
                size="md"
                classNameWrapper={cx(classes.buttonWrapper, classes.btnStyle)}
                label={`${data.parserId ? 'Edit' : 'Add'} Parser`}
              />
              <ReusableButton
                viewType="red"
                classNameWrapper={cx(classes.buttonWrapper, classes.btnStyle)}
                onClick={onDeleteHandler}
              >
                <>
                  <img src={DeleteIcon} alt="delete icon" />
                  Delete
                </>
              </ReusableButton>
            </div>
          )}
        </div>
      </Grid>
      <Grid item xs={12} className={classes.containerSpacing}>
        <DataGrid
          columns={[
            {
              key: 'date',
              name: 'Received',
              formatter: Cell,
              headerRenderer: Header,
              minWidth: 50,
            },
            {
              key: 'fromName',
              name: 'From',
              formatter: Cell,
              headerRenderer: Header,
              minWidth: 130,
            },
            {
              key: 'subject',
              name: 'Subject',
              formatter: Cell,
              headerRenderer: Header,
              minWidth: 120,
            },
            {
              key: 'attachments',
              name: 'Attachments',
              formatter: Cell,
              headerRenderer: Header,
              minWidth: 150,
            },
          ]}
          rows={[
            {
              date: getPrettyDate(data.letterContent.date),
              subject: data.letterContent.subject,
              fromName: data.letterContent.from,
              attachments: (
                <Attachments attachments={data.letterContent.attachments} />
              ),
            },
          ]}
          headerRowHeight={headerHeight}
          rowHeight={rowHeight}
          className={cx('rdg-light', classes.dataGridWrapper)}
        />
      </Grid>

      <Grid item xs={12} style={{ margin: '10px 0' }}>
        <TabPanel
          tabs={tabs}
          viewTypeLink="flooded"
          classFlexContainer={classes.flexWrap}
          classNameWrapperTabsContent={classes.tabsContentWrapper}
          classNameWrapperTabs={classes.tabsHeaderWrapper}
        />
      </Grid>
      <ConfirmDialog
        open={!!deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        onAccept={onDeleteLetterHandler}
        onCancel={onCancelDeleteHandler}
        title={translate('resources.mailBox.documents.deleteLetterCaption')}
        content={translate('resources.mailBox.documents.deleteLetterText')}
      />
    </div>
  ) : (
    <Loading
      classNameWrapper={unauthorized ? classes.unauthorizedLoader : null}
    />
  );
};

MailView.propTypes = {
  bodyIndex: PropTypes.number,
  body: PropTypes.objectOf(PropTypes.any),
  useHtml: PropTypes.bool,
};

Letter.propType = {
  match: PropTypes.objectOf(PropTypes.any),
};

export default Letter;
