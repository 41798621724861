import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, Form, useForm } from 'react-final-form';
import { useDispatch } from 'react-redux';

import { ThunkGeneratePassword } from '@store/slices/secureData/thunks';

import FieldWrapper from '@common/form/FieldWrapper';
import Checkbox from '@common/Checkbox/Checkbox';
import ReusableButton from '@common/Button/Button';
import Input from '@components/Auth/Common/Input';

import useStyles from '../styles';

const formSize = {
  label: 3,
  content: 9,
};

const PasswordGenerator = ({ handleClose }) => {
  const [processing, setProcessing] = useState(false);

  const form = useForm();
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleGeneratePassword = params => {
    setProcessing(true);
    dispatch(ThunkGeneratePassword({ params }))
      .unwrap()
      .then(res => {
        form.change('value', res.data);
        // handleClose();
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  return (
    <div>
      <Form
        onSubmit={handleGeneratePassword}
        initialValues={{
          length: 14,
          useLowercase: true,
          useUppercase: true,
          useNumbers: true,
          useSpecialChars: true,
        }}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className={classes.formContainer}>
            <div className={classes.align}>
              <FieldWrapper
                label="Password Length"
                labelSize={formSize.label}
                contentSize={formSize.content}
                fullWidth
                classNameContainer={classes.noWrap}
                content={
                  <Field
                    id="length"
                    name="length"
                    component={Input}
                    disabled={processing}
                    type="number"
                    dateProps={{
                      min: 5,
                      max: 30,
                      style: { minWidth: '70px' },
                    }}
                  />
                }
              />

              <FieldWrapper
                label="Use Upper Case"
                labelSize={formSize.label}
                contentSize={formSize.content}
                fullWidth
                classNameContainer={classes.noWrap}
                content={
                  <Field
                    id="useUppercase"
                    name="useUppercase"
                    type="checkbox"
                    component={Checkbox}
                    disabled={processing}
                  />
                }
              />
            </div>

            <div className={classes.align}>
              <FieldWrapper
                label="Use Numbers"
                labelSize={formSize.label}
                contentSize={formSize.content}
                classNameContainer={classes.noWrap}
                content={
                  <Field
                    id="useNumbers"
                    name="useNumbers"
                    fullWidth
                    type="checkbox"
                    component={Checkbox}
                    disabled={processing}
                  />
                }
              />

              <FieldWrapper
                label="Use Special Characters"
                labelSize={formSize.label}
                contentSize={formSize.content}
                classNameContainer={classes.noWrap}
                content={
                  <Field
                    id="useSpecialChars"
                    name="useSpecialChars"
                    type="checkbox"
                    component={Checkbox}
                    disabled={processing}
                  />
                }
              />
            </div>

            <div className={classes.align}>
              <ReusableButton label="Generate" type="submit" />

              <ReusableButton label="Hide" onClick={handleClose} />
            </div>
          </form>
        )}
      />
    </div>
  );
};

PasswordGenerator.propTypes = {
  handleClose: PropTypes.func,
};

export default PasswordGenerator;
