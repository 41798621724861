import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { createTheme, ThemeProvider, Tooltip } from '@material-ui/core';
import { ExitToAppRounded } from '@material-ui/icons';
import { useGetOne, useLogout } from 'react-admin';

import { ThunkGetHaloPodDataByTicketId } from '@store/slices/psaPods/thunks';
import { changeStateOfPodPageFlag, setPodData } from '@store/slices/psaPods';
import { MessagingChannelSelector } from '@store/slices/messaging';

import crmSources from '@constants/crmSources';
import { lightTheme } from '@services/themes/mainTheme';
import resources from '@constants/resources';
import { actions } from '@store/actions';

import EndUserVerification from '@components/integrations/connectwise/tabs/EndUserVerification';
import Loading from '@common/Loading/Loading';
import SecureLink from '@components/integrations/secureLink';
import TabPanel from '@common/TabPanel/TabPanel';
import DisplayCompanyPolicies from '@components/integrations/components/DisplayCompanyPolicies';
import PsaPodTechVerification from '@components/integrations/connectwise/tabs/PsaPodTechVerification';
import useSocketForHistory from '@components/integrations/connectwise/hooks/useSocketForHistory';
import ClientChatsWrapper from '@components/integrations/connectwise/components/ClientChatsWrapper';
import LocalStorage from '@constants/localStorage';
import { parseJwtToken } from '@services/api';
import MessagingComponent from '../connectwise/components/MessagingComponent';

import useStyles from './styles';

import { getPodPhoneNumber } from '../helpers';

const theme = createTheme(lightTheme);

const HaloPod = ({ location: { search }, match }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(undefined);

  const dispatch = useDispatch();
  const logout = useLogout();
  const classes = useStyles();
  const urlParams = useMemo(() => new URLSearchParams(search), []);
  const ticketIdParam = urlParams.get('ticketId')?.replace(/^0+/, '');

  const channel = useSelector(MessagingChannelSelector);
  const phoneNumber = useMemo(() => {
    return getPodPhoneNumber(data);
  }, [data]);

  const { data: userData } = useGetOne(
    resources.user,
    localStorage.getItem('userId'),
  );

  useEffect(() => {
    if (userData) {
      dispatch(actions.setCurrentUser(userData));
      localStorage.setItem('tenantId', userData.tenantId);
    }
  }, [userData, dispatch]);

  const onMount = useCallback(() => {
    setLoading(true);
    dispatch(
      ThunkGetHaloPodDataByTicketId({
        ticketId: ticketIdParam,
        crmId: match.params.crmId,
      }),
    )
      .unwrap()
      .then(res => {
        setData(res);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch, match.params.crmId, ticketIdParam]);

  const tabs = [
    {
      label: 'End User Verification',
      value: (
        <EndUserVerification
          data={data}
          match={match}
          onRefresh={onMount}
          psaType={crmSources.Halo.label}
          fromTable
        />
      ),
    },
    data?.clientPortalChats?.length >= 1 && {
      label: 'Chat',
      value: <ClientChatsWrapper clientPortalChats={data?.clientPortalChats} />,
    },
    {
      label: 'SMS',
      value: (
        <MessagingComponent
          data={data}
          crmId={match.params.crmId}
          psaType={crmSources.Halo.name}
          crmType={crmSources.Halo.idx}
          ticketId={ticketIdParam}
          phoneNumber={phoneNumber}
          channel={channel}
        />
      ),
    },
    {
      label: 'Send Secure Data',
      value: (
        <SecureLink
          userPhone={data?.ContactPhone}
          userEmail={data?.ContactEmail}
          psaContactId={data?.ContactId}
          localTicketId={ticketIdParam}
          psaId={match.params.crmId}
          psaType={crmSources.Halo.label}
        />
      ),
    },
    {
      label: 'Tech Verification',
      value: <PsaPodTechVerification crmId={match.params.crmId} data={data} />,
    },
  ];

  useEffect(() => {
    onMount();
  }, [onMount]);

  useEffect(() => {
    // first load setting properties for pod in store
    const screen = urlParams.get('screen');
    dispatch(changeStateOfPodPageFlag(true));
    if (match.params.crmId !== 'crmId' && Number(match.params.crmId)) {
      dispatch(
        setPodData({
          crmId: match.params.crmId,
          ticketId: ticketIdParam,
          screen,
          type: 'halo',
        }),
      );
    }
  }, [dispatch, match, urlParams, ticketIdParam]);

  //* Add this custom for creating socket connection for this pod page
  useSocketForHistory(match?.params?.crmId, data?.ContactId);

  const token = localStorage.getItem(LocalStorage.ACCESS_TOKEN);

  useEffect(() => {
    if (userData?.id && token) {
      const claims = parseJwtToken(token);

      window.pendo.initialize({
        visitor: {
          id: +userData.id,
          email: userData.email,
          fullName: userData.username,
        },
        account: {
          id: userData.tenantId,
          tenantName: claims.tenantAlias,
        },
      });
    }
  }, [userData?.id, token]);

  if (loading) return <Loading />;
  return (
    <ThemeProvider theme={theme}>
      <div>
        <DisplayCompanyPolicies
          crmId={match.params.crmId}
          companyId={data?.CompanyId}
        />

        <div className={classes.container}>
          <TabPanel
            tabs={tabs.filter(i => i) || []}
            viewTypeLink="main"
            classNameWrapperTabs={classes.tabsWrapper}
            isTransparent
            classNameTabTitle={classes.tabTitle}
            classNameContentWrapper={classes.tabContent}
            classFlexContainer={classes.gap}
          />
        </div>

        <div
          onClick={logout}
          role="presentation"
          className={classes.logOutContainer}
        >
          <Tooltip title="Log Out">
            <ExitToAppRounded />
          </Tooltip>
        </div>
      </div>
    </ThemeProvider>
  );
};

HaloPod.propTypes = {
  location: PropTypes.shape({ search: PropTypes.string }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      crmId: PropTypes.string,
      defaultId: PropTypes.string,
    }),
  }),
};

export default HaloPod;
