import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { useDispatch } from 'react-redux';

import { ThunkAddPassword } from '@store/slices/passwords/thunks';
import { actions } from '@store/actions';

import maxLengthValidator from '@utils/validators/maxLengthValidator';
import { NEVER_EXPIRED } from '@constants/commonConstants';
import expirationPeriodOptions from '@constants/expirationPeriodOptions';

import ReusableButton from '@common/Button/Button';
import Input from '@components/Auth/Common/Input';
import DropDown from '@components/Auth/Common/DropDown';
import FieldWrapper from '@common/form/FieldWrapper';

import useStyles from '../../Admin/styles';

const initialDate = new Date(moment().add(1, 'days'));

const CreateDialog = ({ open, onClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [periodValue, setPeriodValue] = useState(0);
  const [dateValue, setDateValue] = useState(initialDate);
  const [datePickerVisible, setDataPickerVisible] = useState(true);
  const [processing, setProcessing] = useState(false);

  const setInitValues = () => {
    setPeriodValue(0);
    setDateValue(initialDate);
    setDataPickerVisible(true);
  };

  const onPeriodChange = value => {
    setDataPickerVisible(true);
    setPeriodValue(value);
    switch (value) {
      case expirationPeriodOptions.day.value:
        setDateValue(initialDate);
        break;
      case expirationPeriodOptions.tenDays.value:
        setDateValue(new Date(moment().add(10, 'd')));
        break;
      case expirationPeriodOptions.month.value:
        setDateValue(new Date(moment().add(1, 'M')));
        break;
      case expirationPeriodOptions.year.value:
        setDateValue(new Date(moment().add(1, 'y')));
        break;
      case expirationPeriodOptions.custom.value:
        break;
      case expirationPeriodOptions.never.value:
        setDataPickerVisible(false);
        setDateValue(
          new Date(moment().set({ year: NEVER_EXPIRED, month: 1, day: 1 })),
        );
        break;
      default:
        setDateValue(0);
        setDateValue(initialDate);
        break;
    }
  };

  const onDateChange = value => {
    setDateValue(value);
    setPeriodValue(4);
  };

  const validate = values => {
    return {
      name: maxLengthValidator(values.name, 30),
      sourcePassword: maxLengthValidator(values.sourcePassword, 30),
      confirmPassword: maxLengthValidator(values.confirmPassword, 30),
    };
  };

  const onCancel = useCallback(() => {
    setInitValues();
    onClose();
  }, [onClose]);

  const submit = useCallback(
    values => {
      const payload = {
        ExpirationDateUtc: moment.utc(dateValue).toISOString(),
        ...values,
      };
      setProcessing(true);
      dispatch(ThunkAddPassword(payload))
        .unwrap()
        .then(data => {
          dispatch(actions.updateItemInList(data));
          onClose();
        })
        .finally(() => {
          setProcessing(false);
        });
    },
    [dispatch, dateValue, onClose],
  );

  return (
    <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
      <Form
        onSubmit={submit}
        validate={validate}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} noValidate className={classes.form}>
            <DialogTitle id="simple-dialog-title">
              Generate new Password
            </DialogTitle>
            <DialogContent>
              <div className={classes.formContainer}>
                <FieldWrapper
                  label="Name"
                  labelSize={4}
                  contentSize={8}
                  fullWidth
                  content={
                    <Field
                      id="name"
                      name="name"
                      fullWidth
                      component={Input}
                      disabled={processing}
                    />
                  }
                />

                <FieldWrapper
                  label="Password"
                  labelSize={4}
                  contentSize={8}
                  fullWidth
                  content={
                    <Field
                      id="sourcePassword"
                      name="sourcePassword"
                      fullWidth
                      component={Input}
                      disabled={processing}
                    />
                  }
                />

                <FieldWrapper
                  label="Secret phrase"
                  labelSize={4}
                  contentSize={8}
                  fullWidth
                  content={
                    <Field
                      id="confirmPassword"
                      name="confirmPassword"
                      fullWidth
                      component={Input}
                      disabled={processing}
                    />
                  }
                />

                <FieldWrapper
                  label="Expire in"
                  labelSize={4}
                  contentSize={8}
                  fullWidth
                  content={
                    <DropDown
                      disabled={processing}
                      options={Object.values(expirationPeriodOptions)}
                      classNameWrapper={classes.marginTop}
                      input={{
                        value: periodValue,
                        onChange: e => onPeriodChange(+e.target.value),
                      }}
                    />
                  }
                />

                {datePickerVisible && (
                  <DatePicker
                    selected={dateValue}
                    onChange={onDateChange}
                    inline
                    disabled={processing}
                  />
                )}
              </div>
            </DialogContent>
            <DialogActions>
              <ReusableButton
                label="resources.buttons.cancel"
                onClick={onCancel}
                disabled={processing}
              />
              <ReusableButton
                type="submit"
                viewType="black"
                label="resources.buttons.confirm"
                disabled={processing}
                loading={processing}
              />
            </DialogActions>
          </form>
        )}
      />
    </Dialog>
  );
};

CreateDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CreateDialog;
